<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="640" persistent>
        <v-card>
          <v-card-title>
            <span>{{ $vuetify.lang.t("$vuetify.key.K_%E7%8A%B6%E6%80%81%E9%87%8F%E5%91%8A%E8%AD%A6%E9%85%8D%E7%BD%AE") }}</span>
            <v-spacer></v-spacer>
            <v-icon @click="add">mdi-plus</v-icon>
          </v-card-title>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="items"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:[`item.statevalue`]="{ item }">
              <v-text-field
                solo
                flat
                v-model="item.statevalue"
                hide-details
              ></v-text-field>
            </template>
            <template v-slot:[`item.statename`]="{ item }">
              <v-text-field
                solo
                flat
                v-model="item.statename"
                hide-details
              ></v-text-field>
            </template>
            <template v-slot:[`item.alarmdesc`]="{ item }">
              <v-text-field
                solo
                flat
                v-model="item.alarmdesc"
                hide-details
              ></v-text-field>
            </template>
            <template v-slot:[`item.isalarm`]="{ item }">
              <v-switch dense v-model="item.isalarm"></v-switch>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon @click="remove(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">{{ $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A") }}</v-btn>
            <v-btn text @click="show = false">{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "model"],
  data() {
    return {
      show: false,
      headers: [
        {
          value: "statevalue",
          text: this.$vuetify.lang.t("$vuetify.key.K_%E7%8A%B6%E6%80%81%E5%80%BC"),
          sortable: false,
        },
        {
          value: "statename",
          text: this.$vuetify.lang.t("$vuetify.key.K_%E7%8A%B6%E6%80%81%E5%90%8D"),
          sortable: false,
        },
        {
          value: "alarmdesc",
          text: this.$vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6%E6%8F%8F%E8%BF%B0"),
          sortable: false,
        },
        {
          value: "isalarm",
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%98%AF%E5%90%A6%E5%91%8A%E8%AD%A6"),
          sortable: false,
        },
        {
          value: "actions",
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%93%8D%E4%BD%9C"),
          sortable: false,
        },
      ],
      items: [],
    };
  },
  computed: {
    valueDisplay() {
      if (this.value) {
        for (let i in this.value) {
          if (this.value[i].isalarm) return this.$vuetify.lang.t("$vuetify.key.K_%E5%B7%B2%E9%85%8D%E7%BD%AE");
        }
      }
      return this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%AA%E9%85%8D%E7%BD%AE");
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.items = JSON.parse(JSON.stringify(this.value));
        }
      },
    },
  },
  methods: {
    add() {
      this.items.push({
        statevalue: "",
        statename: "",
        alarmdesc: "",
        isalarm: false,
        uuid: "",
      });
    },
    remove(item) {
      for (let i in this.items) {
        if (this.items[i] == item) {
          this.items.splice(parseInt(i), 1);
          break;
        }
      }
    },
    submit() {
      this.$emit("change", this.items);
      this.show = false;
    },
  },
};
</script>
