<template>
  <div class="main">
    <v-navbar>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="
              editModel = {};
              editShow = true;
            "
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $vuetify.lang.t("$vuetify.key.K_%E6%96%B0%E5%BB%BA%E7%94%A8%E6%88%B7") }}</span>
      </v-tooltip>
      <v-text-field
        v-model="filter"
        :label="$vuetify.lang.t('$vuetify.key.K_%E6%90%9C%E7%B4%A2')"
        hide-details
        dense
        outlined
        prepend-inner-icon="mdi-magnify"
        style="max-width: 250px"
      ></v-text-field>
    </v-navbar>
    <div class="scroll">
      <v-data-table
        :headers="headers"
        :items="matchItems"
        :mobile-breakpoint="800"
        dark
        style="background-color: transparent"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.user`]="{ item }">
          <span>{{ item.user ? item.user.name : "" }}</span>
        </template>
        <template v-slot:[`item.sex`]="{ item }">
          <span v-if="item.sex == 1">{{ $vuetify.lang.t("$vuetify.key.K_%E7%94%B7") }}</span>
          <span v-if="item.sex == 2">{{ $vuetify.lang.t("$vuetify.key.K_%E5%A5%B3") }}</span>
        </template>
        <template v-slot:[`item.usergroup`]="{ item }">
          <span>
            {{ item.usergroup.map((o) => o.name).join(",") }}
          </span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            text
            class="link-text"
            @click="
              pwdId = item.user.id;
              pwdShow = true;
            "
          >
            <span>{{ $vuetify.lang.t("$vuetify.key.K_%E4%BF%AE%E6%94%B9%E5%AF%86%E7%A0%81") }}</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="
              moveModel = item;
              moveShow = true;
            "
          >
            <span>{{ $vuetify.lang.t("$vuetify.key.K_%E8%BF%81%E5%87%BA") }}</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="
              deleteModel = item;
              deleteShow = true;
            "
          >
            <span>{{ $vuetify.lang.t("$vuetify.key.K_%E5%88%A0%E9%99%A4") }}</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="
              editModel = item;
              editShow = true;
            "
          >
            <span>{{ $vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E8%BE%91") }}</span>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <UserDialog
      v-model="editShow"
      :model="editModel"
      @update="getUsers"
    ></UserDialog>
    <OrgSelector
      v-model="moveShow"
      :label="$vuetify.lang.t('$vuetify.key.K_%E8%AF%B7%E9%80%89%E6%8B%A9%E8%A6%81%E8%BF%81%E5%85%A5%E7%9A%84%E6%9C%BA%E6%9E%84')"
      @select="changeOrg"
    ></OrgSelector>
    <ChangePasswordDialog
      v-model="pwdShow"
      :userId="pwdId"
    ></ChangePasswordDialog>
    <v-confirm
      v-model="deleteShow"
      :label="`${$vuetify.lang.t('$vuetify.key.K_%E6%98%AF%E5%90%A6%E5%88%A0%E9%99%A4%E7%94%A8%E6%88%B7')} ${deleteModel.name} ?`"
      @ok="deleteUser"
    ></v-confirm>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
import UserDialog from "../components/UserDialog.vue";
import OrgSelector from "../components/OrgSelector.vue";
import ChangePasswordDialog from "../components/ChangePasswordDialog.vue";

export default {
  components: { UserDialog, OrgSelector, ChangePasswordDialog },
  data() {
    return {
      loading: true,

      filter: "",
      headers: [
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E7%94%A8%E6%88%B7%E5%90%8D"), value: "user" },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%A7%93%E5%90%8D"), value: "name" },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E6%80%A7%E5%88%AB"), value: "sex" },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%B7%A5%E5%8F%B7"), value: "empno" },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E9%83%A8%E9%97%A8"), value: "company" },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E8%81%8C%E4%BD%8D"), value: "position" },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E8%AF%AD%E9%9F%B3%E5%8F%B7%E7%A0%81"), value: "telephone" },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E7%9F%AD%E4%BF%A1%E5%8F%B7%E7%A0%81"), value: "mobilephone" },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E9%82%AE%E7%AE%B1"), value: "email" },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E8%A7%92%E8%89%B2"), value: "usergroup" },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E6%93%8D%E4%BD%9C"), value: "actions", sortable: false, align: "end" },
      ],
      items: [],

      editShow: false,
      editModel: {},

      deleteShow: false,
      deleteModel: {},

      moveShow: false,
      moveModel: {},

      pwdShow: false,
      pwdId: "",
    };
  },
  computed: {
    matchItems() {
      if (this.filter) {
        return this.items.filter(this.matches);
      } else {
        return this.items;
      }
    },
  },
  mounted() {
    client.$on("orgChange", this.getUsers);
    this.getUsers();
  },
  beforeDestroy() {
    client.$off("orgChange", this.getUsers);
  },
  methods: {
    matches(item) {
      return item.name.indexOf(this.filter) != -1;
    },
    async getUsers() {
      let result = [];
      client.$emit(
        "startBusy",
        "ConfigUser.getUsers",
        this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E7%94%A8%E6%88%B7%2C%E8%AF%B7%E7%A8%8D%E5%80%99")
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.personMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
            parentId: client.org.id,
          },
          orgGroup: 3,
        });
        if (res.Persons && res.Persons.length) {
          res.Persons.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.Persons.length; i++) {
            let person = res.Persons[i];
            result.push(person);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigUser.getUsers");
      this.items = result;
      this.loading = false;
    },
    async deleteUser() {
      client.$emit(
        "startBusy",
        "ConfigUser.deleteUser",
        this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E7%94%A8%E6%88%B7%2C%E8%AF%B7%E7%A8%8D%E5%80%99")
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.personMessage, {
          mcd: {
            operate: proto.OperateMode.deleteOpt,
            range: this.deleteModel.uuid,
          },
        });
        await client.send(proto.MESSAGE_TYPE.userMessage, {
          mcd: {
            operate: proto.OperateMode.deleteOpt,
            range: this.deleteModel.uuid,
          },
          users: [
            {
              uuid: this.deleteModel.user.id,
              account: this.deleteModel.user.name,
              password: "del",
              type: 2,
            },
          ],
        });
        this.getUsers();
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigUser.deleteUser");
    },
    async changeOrg(org) {
      client.$emit(
        "startBusy",
        "ConfigUser.changeOrg",
        this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%BF%81%E7%A7%BB%E7%94%A8%E6%88%B7%2C%E8%AF%B7%E7%A8%8D%E5%80%99")
      );
      await proto.sleep(100);
      try {
        if (!org || !org.id) throw this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%BA%E6%9E%84%E4%BF%A1%E6%81%AF%E6%9C%89%E8%AF%AF");
        if (!this.moveModel) throw this.$vuetify.lang.t("$vuetify.key.K_%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF%E7%BC%BA%E5%A4%B1");
        await client.send(proto.MESSAGE_TYPE.personMessage, {
          mcd: {
            operate: proto.OperateMode.modifyOpt,
            parentId: org.id,
          },
          Persons: [this.moveModel],
        });
        this.getUsers();
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigUser.changeOrg");
    },
  },
};
</script>
