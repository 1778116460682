<template>
  <v-dialog :value="value" persistent max-width="540">
    <v-card>
      <v-card-title class="text-subtitle-1">{{ $vuetify.lang.t("$vuetify.key.K_%E6%93%8D%E4%BD%9C%E7%A1%AE%E8%AE%A4") }}</v-card-title>
      <v-card-text
        v-text="label"
        class="text-h5 font-weight-bold text-center"
      ></v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain @click="ok" color="success">{{ okText || $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A") }}</v-btn>
        <v-btn plain @click="cancel">{{ cancelText || $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "okText", "cancelText"],
  methods: {
    ok() {
      this.$emit("ok");
      this.$emit("change", false);
    },
    cancel() {
      this.$emit("cancel");
      this.$emit("change", false);
    },
  },
};
</script>
