<template>
  <div class="main">
    <v-navbar></v-navbar>
    <v-expansion-panels dark class="flex-shrink-0">
      <v-expansion-panel style="background-color: transparent">
        <v-expansion-panel-header>{{ $vuetify.lang.t("$vuetify.key.K_%E7%AD%9B%E9%80%89%E6%9D%A1%E4%BB%B6") }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row align="baseline">
              <v-col cols="6" sm="3" lg="2">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.key.K_%E7%94%A8%E6%88%B7%E5%90%8D')"
                  v-model="account"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.key.K_%E5%AE%A2%E6%88%B7%E7%AB%AF%E5%9C%B0%E5%9D%80')"
                  v-model="host"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-select
                  :label="$vuetify.lang.t('$vuetify.key.K_%E6%93%8D%E4%BD%9C%E7%BB%93%E6%9E%9C')"
                  v-model="optresult"
                  :items="optresults"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-select
                  :label="$vuetify.lang.t('$vuetify.key.K_%E6%97%B6%E9%97%B4%E6%9D%A1%E4%BB%B6')"
                  v-model="condition"
                  :items="conditions"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-menu
                  v-model="dateStartShow"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateStart"
                      :label="
                        condition == 6
                          ? $vuetify.lang.t('$vuetify.key.startDate')
                          : $vuetify.lang.t('$vuetify.key.date')
                      "
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateStart"
                    :max="dateEnd"
                    @input="dateStartShow = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="3" lg="2" v-if="condition == 6">
                <v-menu
                  v-model="dateEndShow"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateEnd"
                      :label="$vuetify.lang.t('$vuetify.key.K_%E7%BB%93%E6%9D%9F%E6%97%A5%E6%9C%9F')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateEnd"
                    :min="dateStart"
                    @input="dateEndShow = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-btn block color="rgba(255,255,255,0.2)" @click="query(1)">
                  {{ $vuetify.lang.t("$vuetify.key.K_%E6%9F%A5%E8%AF%A2") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="scroll flex-grow-1 flex-shrink-1">
      <v-data-table
        :headers="headers"
        :items="records"
        dark
        style="background-color: transparent"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.optresult`]="{ item }">
          <span>{{ item.optresult ? $vuetify.lang.t("$vuetify.key.K_%E6%88%90%E5%8A%9F") : $vuetify.lang.t("$vuetify.key.K_%E5%A4%B1%E8%B4%A5") }}</span>
        </template>
      </v-data-table>
    </div>
    <v-divider></v-divider>
    <v-pagination
      v-model="pageIndex"
      :length="pageCount"
      :total-visible="7"
      @input="query($event)"
    ></v-pagination>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  data() {
    return {
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E5%8F%B7"),
          value: "id",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E7%94%A8%E6%88%B7%E5%90%8D"),
          value: "uaccount",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E5%A7%93%E5%90%8D"),
          value: "uname",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E5%AE%A2%E6%88%B7%E7%AB%AF%E5%9C%B0%E5%9D%80"),
          value: "hostname",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%8F%8F%E8%BF%B0"),
          value: "targetobj",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%93%8D%E4%BD%9C%E7%BB%93%E6%9E%9C"),
          value: "optresult",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%97%B6%E9%97%B4"),
          value: "datetime",
          sortable: false,
        },
      ],

      account: "",
      host: "",
      optresult: -1,
      optresults: [
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%85%A8%E9%83%A8"), value: -1 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%A4%B1%E8%B4%A5"), value: 0 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E6%88%90%E5%8A%9F"), value: 1 },
      ],

      condition: 1,
      conditions: [
        { text: "=", value: 1 },
        { text: ">", value: 2 },
        { text: ">=", value: 4 },
        { text: "<", value: 3 },
        { text: "<=", value: 5 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E6%97%B6%E9%97%B4%E6%AE%B5"), value: 6 },
      ],

      dateStartShow: false,
      dateStart: undefined,
      dateEndShow: false,
      dateEnd: undefined,

      recordType: 0,
      records: [],
      pageIndex: 0,
      pageCount: 0,
      recordCount: 0,
    };
  },
  mounted() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) month = "0" + month;
    let day = date.getDate();
    if (day < 10) day = "0" + day;
    this.dateStart = this.dateEnd = `${year}-${month}-${day}`;
    client.$on("orgChange", this.orgChange);
    this.orgChange();
  },
  beforeDestroy() {
    client.$off("orgChange", this.orgChange);
  },
  methods: {
    orgChange() {
      this.records = [];
      this.pageIndex = 0;
      this.pageCount = 0;
      this.recordCount = 0;
      this.query(1);
    },
    sqlParam(val) {
      return val.replace(/'/g, "");
    },
    add(date, days) {
      let d = new Date(date);
      let t = d.getTime();
      t = t + 86400000 * days;
      d = new Date(t);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      if (month < 10) month = "0" + month;
      let day = d.getDate();
      if (day < 10) day = "0" + day;
      return `${year}-${month}-${day}`;
    },
    getCondition() {
      let result = [""];
      if (this.account) {
        result.push(`user.account like '%${this.sqlParam(this.account)}%'`);
      }
      if (this.host) {
        result.push(`systemlog.host like '%${this.sqlParam(this.host)}%'`);
      }
      if (this.optresult != -1) {
        result.push(
          `systemlog.optresult='${this.sqlParam(this.optresult.toString())}'`
        );
      }
      switch (this.condition) {
        case 1: // =
          result.push(
            `systemlog.optdt between '${this.dateStart}' and '${this.add(
              this.dateStart,
              1
            )}'`
          );
          break;
        case 2: // >
          result.push(`systemlog.optdt >= '${this.add(this.dateStart, 1)}'`);
          break;
        case 3: // <
          result.push(`systemlog.optdt < '${this.dateStart}'`);
          break;
        case 4: // >=
          result.push(`systemlog.optdt >= '${this.dateStart}'`);
          break;
        case 5: // <=
          result.push(`systemlog.optdt < '${this.add(this.dateStart, 1)}'`);
          break;
        case 6: // between
          result.push(
            `systemlog.optdt between '${this.dateStart}' and '${this.add(
              this.dateEnd,
              1
            )}'`
          );
          break;
      }
      return result.join(" and ") + " ";
    },
    async query(page) {
      let result = [];
      client.$emit("startBusy", "HistoryLog.query", this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E8%AE%B0%E5%BD%95%2C%E8%AF%B7%E7%A8%8D%E5%80%99"));
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.sysLogMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
          conditionClause: this.getCondition(),
          rpp: 20,
          pageindex: page - 1,
        });
        this.recordType = this.eventType;
        this.pageIndex = res.pageindex + 1;
        this.pageCount = res.pagecount || 0;
        this.recordCount = res.recordcount || 0;
        if (res.sls && res.sls.length) {
          res.sls.sort((a, b) => {
            return a.id > b.id ? -1 : 1;
          });
          for (let i = 0; i < res.sls.length; i++) {
            result.push(res.sls[i]);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "HistoryLog.query");
      this.records = result;
    },
  },
};
</script>
