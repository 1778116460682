<template>
  <v-dialog :value="show" max-width="800" persistent>
    <v-card>
      <v-card-title>
        <span>{{ $vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6%E5%88%97%E8%A1%A8") }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="getData">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-data-table dense :headers="headers" :items="details">
        <template v-slot:[`item.obj`]="{ item }">
          <span>{{ objName(item.obj) }}</span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span v-if="item.type == 1">{{ $vuetify.lang.t("$vuetify.key.K_%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA") }}</span>
          <span v-if="item.type == 2">{{ $vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E5%A4%87") }}</span>
          <span v-if="item.type == 3">{{ $vuetify.lang.t("$vuetify.key.K_%E6%B5%8B%E7%82%B9") }}</span>
          <span v-if="item.type == 4">{{ $vuetify.lang.t("$vuetify.key.K_%E9%A1%B5%E9%9D%A2") }}</span>
          <span v-if="item.type == 5">{{ $vuetify.lang.t("$vuetify.key.K_%E6%9C%BA%E6%9E%84") }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";

export default {
  model: {
    prop: "show",
    event: "change",
  },
  props: { show: Boolean },
  data() {
    return {
      headers: [
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6%E6%97%B6%E9%97%B4"), value: "createtime", width: 168 },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E5%AF%B9%E8%B1%A1%E5%90%8D%E7%A7%B0"),
          value: "obj",
          width: 150,
          sort: (a, b) => {
            let aname = this.objName(a);
            let bname = this.objName(b);
            return aname.localeCompare(bname);
          },
        },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%AF%B9%E8%B1%A1%E7%B1%BB%E5%9E%8B"), value: "type", width: 150 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6%E6%8F%8F%E8%BF%B0"), value: "description" },
      ],
      details: [],
    };
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.getData();
      },
    },
  },
  methods: {
    async getData() {
      client.$emit(
        "startBusy",
        "AlarmDetailDialog.getData",
        this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E6%95%B0%E6%8D%AE%2C%E8%AF%B7%E7%A8%8D%E5%80%99")
      );
      await proto.sleep(100);
      try {
        let res = await client.send(
          proto.MESSAGE_TYPE.realTimeDetailMessage,
          {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
            },
          }
        );
        this.details = res.details || [];
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "AlarmDetailDialog.getData");
    },
    objName(obj) {
      let result = [];
      if (obj.pname) result.push(obj.pname);
      if (obj.name) result.push(obj.name);
      return result.join("-");
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>