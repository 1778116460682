<template>
  <v-dialog v-model="show" persistent max-width="480">
    <v-card>
      <v-card-title>{{ $vuetify.lang.t("$vuetify.key.K_%E6%B7%BB%E5%8A%A0%E9%A1%B5%E9%9D%A2") }}</v-card-title>
      <v-card-text>
        <v-text-field :label="$vuetify.lang.t('$vuetify.key.K_%E9%A1%B5%E9%9D%A2%E5%90%8D%E7%A7%B0')" v-model="name"></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain @click="ok">{{ $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A") }}</v-btn>
        <v-btn plain @click="cancel">{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import client from "../utils/client";
export default {
  data() {
    return {
      show: false,
      name: "",
    };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    ok() {
      if (!this.name) {
        client.$emit("toast", this.$vuetify.lang.t("$vuetify.key.K_%E8%AF%B7%E8%BE%93%E5%85%A5%E5%90%8D%E7%A7%B0"));
        return;
      }
      this.show = false;
      this.$emit("close", this.name);
    },
    cancel() {
      this.show = false;
      this.$emit("close", null);
    },
  },
};
</script>
