var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-text-field',{attrs:{"label":_vm.label,"value":_vm.valueDisplay,"readonly":"","filled":"","background-color":"transparent","hide-details":""}},[_c('template',{slot:"append"},[_c('v-btn',{attrs:{"icon":"","tile":""},on:{"click":function($event){_vm.show = true}}},[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1),_c('v-dialog',{attrs:{"width":"640","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.key.K_%E7%8A%B6%E6%80%81%E9%87%8F%E5%91%8A%E8%AD%A6%E9%85%8D%E7%BD%AE")))]),_c('v-spacer'),_c('v-icon',{on:{"click":_vm.add}},[_vm._v("mdi-plus")])],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.statevalue",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"solo":"","flat":"","hide-details":""},model:{value:(item.statevalue),callback:function ($$v) {_vm.$set(item, "statevalue", $$v)},expression:"item.statevalue"}})]}},{key:"item.statename",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"solo":"","flat":"","hide-details":""},model:{value:(item.statename),callback:function ($$v) {_vm.$set(item, "statename", $$v)},expression:"item.statename"}})]}},{key:"item.alarmdesc",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"solo":"","flat":"","hide-details":""},model:{value:(item.alarmdesc),callback:function ($$v) {_vm.$set(item, "alarmdesc", $$v)},expression:"item.alarmdesc"}})]}},{key:"item.isalarm",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"dense":""},model:{value:(item.isalarm),callback:function ($$v) {_vm.$set(item, "isalarm", $$v)},expression:"item.isalarm"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.remove(item)}}},[_vm._v("mdi-delete")])]}}],null,true)}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")))]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88")))])],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }