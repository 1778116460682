<template>
  <v-dialog v-model="show" persistent max-width="480">
    <v-card>
      <v-card-title>{{
        $vuetify.lang.t("$vuetify.key.K_%E6%B7%BB%E5%8A%A0%E7%BB%91%E5%AE%9A")
      }}</v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model="uuid"
          :label="$vuetify.lang.t('$vuetify.key.K_%E6%8E%A7%E4%BB%B6')"
          item-value="uuid"
          item-text="name"
          :items="controls"
        ></v-autocomplete>
        <v-autocomplete
          v-model="prop"
          :label="$vuetify.lang.t('$vuetify.key.K_%E5%B1%9E%E6%80%A7')"
          :items="props"
        ></v-autocomplete>
        <v-select
          v-model="srcType"
          :label="$vuetify.lang.t('$vuetify.key.K_%E6%BA%90%E7%B1%BB%E5%9E%8B')"
          :items="srcTypes"
        ></v-select>
        <v-select
          v-model="valueType"
          :label="
            $vuetify.lang.t(
              '$vuetify.key.K_%E6%95%B0%E6%8D%AE%E7%B1%BB%E5%9E%8B'
            )
          "
          :items="valueTypes"
        ></v-select>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain @click="ok">{{
          $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")
        }}</v-btn>
        <v-btn plain @click="cancel">{{
          $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import client from "../utils/client";
import editors from "../zht-ui/editor";
import uuid from "../utils/uuid";
export default {
  props: { controls: Array },
  data() {
    return {
      show: false,
      uuid: "",
      prop: "",
      props: [],
      srcTypes: [
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%BA%E6%9E%84"),
          value: "org",
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E7%9B%91%E6%8E%A7%E5%8D%95%E5%85%83"
          ),
          value: "agent",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E5%A4%87"),
          value: "device",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%B5%8B%E7%82%B9"),
          value: "spot",
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E7%BB%9F%E8%AE%A1"
          ),
          value: "statistics",
        },
        { text: "API", value: "api" },
      ],
      srcType: "",
      valueTypes: [],
      valueType: "",
    };
  },
  watch: {
    uuid: function() {
      this.prop = "";
      let result = [];
      if (this.uuid) {
        for (let i in this.controls) {
          if (this.controls[i].uuid == this.uuid) {
            let control = this.controls[i];
            let bases = ["size", "location", "style", "config"];
            for (let j in bases) {
              let base = bases[j];
              for (let attr in control[base]) {
                let value = `${base}.${attr}`;
                if (editors[value]) {
                  let text = this.$vuetify.lang.t(editors[value].name);
                  result.push({ text, value });
                }
              }
            }
            break;
          }
        }
      }
      this.props = result;
    },
    srcType: async function() {
      this.valueType = "";
      let result = [];
      if (this.srcType) {
        switch (this.srcType) {
          case "org":
            result.push({
              text: this.$vuetify.lang.t(
                "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%8A%B6%E6%80%81"
              ),
              value: "alarm",
            });
            break;
          case "agent":
            result.push({
              text: this.$vuetify.lang.t(
                "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%8A%B6%E6%80%81"
              ),
              value: "alarm",
            });
            result.push({
              text: this.$vuetify.lang.t(
                "$vuetify.key.K_%E8%81%94%E6%9C%BA%E7%8A%B6%E6%80%81"
              ),
              value: "status",
            });
            break;
          case "device":
            result.push({
              text: this.$vuetify.lang.t(
                "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%8A%B6%E6%80%81"
              ),
              value: "alarm",
            });
            result.push({
              text: this.$vuetify.lang.t(
                "$vuetify.key.K_%E8%81%94%E6%9C%BA%E7%8A%B6%E6%80%81"
              ),
              value: "status",
            });
            break;
          case "spot":
            result.push({
              text: this.$vuetify.lang.t(
                "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%8A%B6%E6%80%81"
              ),
              value: "alarm",
            });
            result.push({
              text: this.$vuetify.lang.t(
                "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E6%95%B0%E6%8D%AE"
              ),
              value: "data",
            });
            result.push({
              text: this.$vuetify.lang.t(
                "$vuetify.key.K_%E5%8E%86%E5%8F%B2%E6%95%B0%E6%8D%AE"
              ),
              value: "history",
            });
            break;
          case "statistics":
            result.push({
              text: this.$vuetify.lang.t(
                "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E7%BB%9F%E8%AE%A1%E5%80%BC"
              ),
              value: "statistics",
            });
            break;
          case "api":
            result.push({
              text: this.$vuetify.lang.t(
                "$vuetify.key.K_%E5%AE%A2%E6%88%B7%E7%AB%AF%E8%84%9A%E6%9C%AC"
              ),
              value: decodeURIComponent(
                "%E5%AE%A2%E6%88%B7%E7%AB%AF%E8%84%9A%E6%9C%AC"
              ),
            });
            try {
              let host = client.getAddress();
              let res = await axios.post(`${host}/api/list`);
              if (res.data && res.data.api) {
                for (let i in res.data.api) {
                  result.push({
                    text: res.data.api[i],
                    value: res.data.api[i],
                  });
                }
              }
            } catch (e) {
              console.log(e);
            }
            break;
        }
      }
      this.valueTypes = result;
    },
  },
  mounted() {
    this.show = true;
  },
  methods: {
    ok() {
      if (!this.uuid || !this.prop || !this.srcType || !this.valueType) {
        client.$emit(
          "toast",
          this.$vuetify.lang.t(
            "$vuetify.key.K_%E9%85%8D%E7%BD%AE%E4%B8%8D%E5%AE%8C%E6%95%B4"
          )
        );
        return;
      }
      this.show = false;
      this.$emit("close", {
        id: uuid(),
        uuid: this.uuid,
        prop: this.prop,
        srcType: this.srcType,
        valueType: this.valueType,
        src: [],
        JScript: "return values.join(',')",
        interval: "",
        param: "",
      });
    },
    cancel() {
      this.show = false;
      this.$emit("close", null);
    },
  },
};
</script>
