<style scoped>
.bind-tips {
  max-width: 400px;
}
.bind-tips > p {
  word-break: break-all;
}
</style>
<template>
  <v-dialog v-model="show" persistent max-width="480">
    <v-card>
      <v-card-title>{{
        $vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E8%BE%91%E7%BB%91%E5%AE%9A")
      }}</v-card-title>
      <v-divider></v-divider>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-text-field
              :label="$vuetify.lang.t('$vuetify.key.K_%E6%8E%A7%E4%BB%B6')"
              :value="controlName"
              disabled
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="$vuetify.lang.t('$vuetify.key.K_%E5%B1%9E%E6%80%A7')"
              :value="propertyName"
              disabled
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="
                $vuetify.lang.t('$vuetify.key.K_%E6%BA%90%E7%B1%BB%E5%9E%8B')
              "
              :value="srcTypes[this.value.srcType]"
              disabled
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :label="
                $vuetify.lang.t(
                  '$vuetify.key.K_%E6%95%B0%E6%8D%AE%E7%B1%BB%E5%9E%8B'
                )
              "
              :value="valueTypes[this.value.valueType] || this.value.valueType"
              disabled
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <div
          class="d-flex align-center"
          v-if="value.valueType != 'statistics' && value.srcType != 'api'"
        >
          <div>
            {{ $vuetify.lang.t("$vuetify.key.K_%E7%BB%91%E5%AE%9A%E6%BA%90") }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="selectorShow = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
        <ObjectSelector
          v-model="selectorShow"
          @add="addSrc"
          :type="value.srcType"
        ></ObjectSelector>
        <v-card
          outlined
          class="mb-4"
          v-if="value.valueType != 'statistics' && value.srcType != 'api'"
        >
          <v-data-table
            dense
            :headers="headers"
            :items="src"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon @click="up(item)">
                <v-icon>mdi-arrow-up-bold</v-icon>
              </v-btn>
              <v-btn icon @click="down(item)">
                <v-icon>mdi-arrow-down-bold</v-icon>
              </v-btn>
              <v-btn icon @click="removeSrc(item)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <v-text-field
          v-if="value.srcType == 'api'"
          :label="
            $vuetify.lang.t(
              '$vuetify.key.K_%E6%89%A7%E8%A1%8C%E9%97%B4%E9%9A%94(%E5%8D%95%E4%BD%8D%EF%BC%9A%E7%A7%92%EF%BC%8C%E8%87%B3%E5%B0%91%E9%97%B4%E9%9A%945%E7%A7%92)'
            )
          "
          v-model="interval"
          hide-details
          outlined
          dense
          class="mt-3"
        ></v-text-field>
        <v-textarea
          v-if="
            value.srcType == 'api' &&
              value.valueType !=
                decodeURIComponent(
                  '%E5%AE%A2%E6%88%B7%E7%AB%AF%E8%84%9A%E6%9C%AC'
                )
          "
          :label="$vuetify.lang.t('$vuetify.key.K_API%E5%8F%82%E6%95%B0')"
          v-model="param"
          no-resize
          rows="3"
          hide-details
          outlined
          class="mt-3"
        ></v-textarea>
        <div class="d-flex mb-1">
          <v-spacer></v-spacer>
          <v-btn
            text
            class="link-text"
            @click="JScript = `return values.join(',')`"
          >
            {{
              $vuetify.lang.t(
                "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E6%95%B0%E6%8D%AE"
              )
            }}
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="JScript = `return values[0].map((d)=>d.time).join(',')`"
          >
            {{
              $vuetify.lang.t(
                "$vuetify.key.K_%E5%8E%86%E5%8F%B2%E6%95%B0%E6%8D%AE%E6%97%B6%E9%97%B4"
              )
            }}
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="JScript = `return values[0].map((d)=>d.value).join(',')`"
          >
            {{
              $vuetify.lang.t(
                "$vuetify.key.K_%E5%8E%86%E5%8F%B2%E6%95%B0%E6%8D%AE%E5%80%BC"
              )
            }}
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="JScript = `return values[0].s6`"
          >
            {{
              $vuetify.lang.t(
                "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E7%BB%9F%E8%AE%A1"
              )
            }}
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on">
                mdi-comment-question
              </v-icon>
            </template>
            <div class="bind-tips">
              <p>{{ $vuetify.lang.t("$vuetify.key.BindingSourceTips1") }}</p>
              <p>{{ $vuetify.lang.t("$vuetify.key.BindingSourceTips2") }}</p>
              <p>{{ $vuetify.lang.t("$vuetify.key.BindingSourceTips3") }}</p>
              <p>{{ $vuetify.lang.t("$vuetify.key.BindingSourceTips4") }}</p>
              <p>{{ $vuetify.lang.t("$vuetify.key.BindingSourceTips5") }}</p>
              <p>{{ $vuetify.lang.t("$vuetify.key.BindingSourceTips6") }}</p>
              <p>{{ $vuetify.lang.t("$vuetify.key.BindingSourceTips7") }}</p>
            </div>
          </v-tooltip>
        </div>
        <v-textarea
          :label="
            $vuetify.lang.t(
              '$vuetify.key.K_%E6%95%B0%E6%8D%AE%E8%BD%AC%E6%8D%A2%E8%84%9A%E6%9C%AC'
            )
          "
          v-model="JScript"
          no-resize
          rows="3"
          hide-details
          outlined
        ></v-textarea>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain @click="ok">{{
          $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")
        }}</v-btn>
        <v-btn plain @click="cancel">{{
          $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import editors from "../zht-ui/editor";
import ObjectSelector from "./ObjectSelector.vue";
export default {
  components: { ObjectSelector },
  props: { value: Object, controls: Array },
  data() {
    return {
      show: false,
      selectorShow: false,
      srcTypes: {
        org: this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%BA%E6%9E%84"),
        agent: this.$vuetify.lang.t(
          "$vuetify.key.K_%E7%9B%91%E6%8E%A7%E5%8D%95%E5%85%83"
        ),
        device: this.$vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E5%A4%87"),
        spot: this.$vuetify.lang.t("$vuetify.key.K_%E6%B5%8B%E7%82%B9"),
        statistics: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E7%BB%9F%E8%AE%A1"
        ),
        api: "API",
      },
      valueTypes: {
        alarm: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%8A%B6%E6%80%81"
        ),
        status: this.$vuetify.lang.t(
          "$vuetify.key.K_%E8%81%94%E6%9C%BA%E7%8A%B6%E6%80%81"
        ),
        data: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E6%95%B0%E6%8D%AE"
        ),
        history: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%8E%86%E5%8F%B2%E6%95%B0%E6%8D%AE"
        ),
        statistics: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E7%BB%9F%E8%AE%A1%E5%80%BC"
        ),
      },
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E5%90%8D%E7%A7%B0"),
          value: "name",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%93%8D%E4%BD%9C"),
          value: "actions",
          sortable: false,
        },
      ],
      src: [],
      JScript: "",
      interval: "",
      param: "",
    };
  },
  computed: {
    controlName() {
      for (let i in this.controls) {
        if (this.controls[i].uuid == this.value.uuid)
          return this.controls[i].name;
      }
      return this.$vuetify.lang.t("$vuetify.key.K_%E5%B7%B2%E5%88%A0%E9%99%A4");
    },
    propertyName() {
      let prop = this.value.prop;
      if (editors[prop]) return this.$vuetify.lang.t(editors[prop].name);
      return this.$vuetify.lang.t(
        "$vuetify.key.K_%E6%9C%AA%E7%9F%A5%E5%B1%9E%E6%80%A7"
      );
    },
  },
  mounted() {
    this.show = true;
    this.src = JSON.parse(JSON.stringify(this.value.src));
    this.JScript = this.value.JScript;
    this.interval = this.value.interval || "10";
    this.param =
      this.value.param ||
      decodeURIComponent(
        "return%20%7B%22%E5%8F%82%E6%95%B0%22%3A%22%E5%80%BC%22%7D"
      );
  },
  methods: {
    addSrc(item) {
      this.src.push(item);
    },
    removeSrc(item) {
      for (let i = 0; i < this.src.length; i++) {
        if (this.src[i] == item) {
          this.src.splice(i, 1);
          break;
        }
      }
    },
    up(item) {
      for (let i = 0; i < this.src.length; i++) {
        if (this.src[i] == item) {
          if (i > 0) {
            this.src.splice(i, 1);
            this.src.splice(i - 1, 0, item);
          }
          break;
        }
      }
    },
    down(item) {
      for (let i = 0; i < this.src.length; i++) {
        if (this.src[i] == item) {
          if (i < this.src.length - 1) {
            this.src.splice(i, 1);
            this.src.splice(i + 1, 0, item);
          }
          break;
        }
      }
    },
    ok() {
      this.show = false;
      this.$emit("close", {
        src: this.src,
        JScript: this.JScript,
        interval: this.interval,
        param: this.param,
      });
    },
    cancel() {
      this.show = false;
      this.$emit("close", null);
    },
  },
};
</script>
