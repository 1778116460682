<template>
  <v-text-field
    :label="$vuetify.lang.t('$vuetify.key.K_%E4%BA%8B%E4%BB%B6')"
    :value="`${value.length}${$vuetify.lang.t('$vuetify.key.K_%E9%A1%B9')}`"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="showDialog">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>
            <div>{{ $vuetify.lang.t("$vuetify.key.K_%E4%BA%8B%E4%BB%B6%E9%85%8D%E7%BD%AE") }}</div>
            <v-spacer></v-spacer>
            <v-btn icon @click="add"><v-icon>mdi-plus</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-list v-if="items.length">
            <v-list-item v-for="item in items" :key="item.uuid">
              <v-list-item-content>
                <div class="d-flex">
                  <v-text-field
                    :label="$vuetify.lang.t('$vuetify.key.K_%E5%90%8D%E7%A7%B0')"
                    v-model="item.name"
                    dense
                    hide-details
                  ></v-text-field>
                </div>
              </v-list-item-content>
              <v-list-item-content>
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.key.K_%E5%8A%A8%E4%BD%9C')"
                  :value="
                    item.action.type && actions[item.action.type]
                      ? actions[item.action.type]
                      : $vuetify.lang.t('$vuetify.key.K_%E6%9C%AA%E9%85%8D%E7%BD%AE')
                  "
                  dense
                  hide-details
                  readonly
                >
                  <template slot="append">
                    <v-btn
                      icon
                      tile
                      x-small
                      @click="
                        editModel = item;
                        editShow = true;
                      "
                    >
                      <v-icon>mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="remove(item)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-container v-else>
            <h3>{{ $vuetify.lang.t("$vuetify.key.K_%E6%B2%A1%E6%9C%89%E5%8A%A8%E4%BD%9C%E9%A1%B9") }}</h3>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">{{ $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A") }}</v-btn>
            <v-btn text @click="show = false">{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ActionDialog
        v-model="editShow"
        :model="editModel"
        :controls="controls"
        @submit="editSubmit"
      ></ActionDialog>
    </template>
  </v-text-field>
</template>

<script>
import uuid from "../../utils/uuid";
import ActionDialog from "../../components/ActionDialog.vue";

export default {
  components: { ActionDialog },
  model: {
    prop: "value",
    event: "change",
  },
  props: ["controls", "value"],
  data() {
    return {
      actions: {
        "prop-set": this.$vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E7%BD%AE%E6%8E%A7%E4%BB%B6%E5%B1%9E%E6%80%A7"),
        "spot-ctrl": this.$vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E7%BD%AE%E6%B5%8B%E7%82%B9%E5%80%BC"),
        navigate: this.$vuetify.lang.t("$vuetify.key.K_%E9%A1%B5%E9%9D%A2%E8%B7%B3%E8%BD%AC"),
        "new-tab": this.$vuetify.lang.t("$vuetify.key.OpenNewTab"),
      },
      items: [],
      show: false,
      editShow: false,
      editModel: null,
    };
  },
  methods: {
    showDialog() {
      this.items = JSON.parse(JSON.stringify(this.value));
      this.show = true;
    },
    editSubmit(action) {
      if (this.editModel) this.editModel.action = action;
    },
    add() {
      this.items.push({
        uuid: uuid(),
        name: this.newName(this.$vuetify.lang.t("$vuetify.key.K_%E5%8A%A8%E4%BD%9C")),
        action: {},
      });
    },
    remove(item) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i] == item) {
          this.items.splice(i, 1);
          break;
        }
      }
    },
    submit() {
      this.change(this.items);
      this.show = false;
    },
    change(value) {
      this.$emit("change", value);
    },
    newName(name) {
      let index = 1;
      let result = name;
      let exist = true;
      while (exist) {
        result = `${name}${index}`;
        exist = false;
        for (let i in this.items) {
          let item = this.items[i];
          if (item.name == result) {
            exist = true;
            break;
          }
        }
        if (exist) index++;
        else break;
      }
      return result;
    },
  },
};
</script>
