<template>
  <v-dialog :value="show" persistent max-width="480">
    <v-card>
      <v-card-title>
        <div>{{ $vuetify.lang.t("$vuetify.key.K_%E6%B7%BB%E5%8A%A0%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA") }}</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        v-model="agent"
        :headers="headers"
        :items="agents"
        item-key="uuid"
        show-select
        single-select
        hide-default-header
      ></v-data-table>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="submit">{{ $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A") }}</v-btn>
        <v-btn text @click="close">{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  props: { show: Boolean },
  model: {
    prop: "show",
    event: "change",
  },
  data() {
    return {
      headers: [{ text: this.$vuetify.lang.t("$vuetify.key.K_%E5%90%8D%E7%A7%B0"), value: "name" }],
      agent: [],
      agents: [],
    };
  },
  watch: {
    show: function () {
      if (this.show) {
        this.agent = [];
        this.init();
      }
    },
  },
  methods: {
    async init() {
      let result = [];
      client.$emit(
        "startBusy",
        "AddAgentDialog.init",
        this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA%2C%E8%AF%B7%E7%A8%8D%E5%80%99")
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.agentMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
        });
        if (res.agents) {
          res.agents.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i in res.agents) {
            result.push(res.agents[i]);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "AddAgentDialog.init");
      this.agents = result;
    },
    submit() {
      if (this.agent.length) {
        this.$emit("submit", this.agent[0]);
      }
      this.close();
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>
