<template>
  <v-container
    fluid
    class="login-bg"
    :style="{ display: show ? 'flex' : 'none' }"
    style="overflow: auto; flex-direction: column; min-height: 100%;"
  >
    <v-toolbar dark flat color="transparent" style="flex-grow: 0">
      <v-spacer></v-spacer>
      <v-btn icon @click="changeLocal">
        <v-icon>mdi-translate</v-icon>
      </v-btn>
    </v-toolbar>
    <div style="flex-grow: 1" class="d-flex align-center justify-center">
      <v-card max-width="400" class="pa-3 flex-grow-1">
        <v-form @submit.prevent="login">
          <v-card-title class="flex-column">
            <div>
              <h2 class="my-3" style="word-break: keep-all; text-align: center">
                {{ $vuetify.lang.t("$vuetify.key.IMCP") }}
              </h2>
              <div class="d-flex align-center">
                <div
                  class="flex-grow-1"
                  style="border-top: 1px solid black"
                ></div>
                <h4 class="mx-3">iVisual</h4>
                <div
                  class="flex-grow-1"
                  style="border-top: 1px solid black"
                ></div>
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="host" :label="$vuetify.lang.t('$vuetify.key.K_%E6%9C%8D%E5%8A%A1%E5%99%A8%E5%9C%B0%E5%9D%80')"></v-text-field>
            <v-text-field v-model="port" :label="$vuetify.lang.t('$vuetify.key.K_%E6%9C%8D%E5%8A%A1%E5%99%A8%E7%AB%AF%E5%8F%A3')"></v-text-field>
            <v-text-field v-model="account" :label="$vuetify.lang.t('$vuetify.key.K_%E7%94%A8%E6%88%B7%E5%90%8D')"></v-text-field>
            <v-text-field
              v-model="password"
              :label="$vuetify.lang.t('$vuetify.key.K_%E5%AF%86%E7%A0%81')"
              type="password"
            ></v-text-field>
            <v-select
              v-model="developer"
              :label="$vuetify.lang.t('$vuetify.key.K_%E7%94%A8%E6%88%B7%E7%B1%BB%E5%9E%8B')"
              :items="[
                { text: $vuetify.lang.t('$vuetify.key.K_%E5%BC%80%E5%8F%91%E8%80%85'), value: true },
                { text: $vuetify.lang.t('$vuetify.key.K_%E5%B7%A5%E7%A8%8B%E7%94%A8%E6%88%B7'), value: false },
              ]"
              hide-details
            ></v-select>
          </v-card-text>
          <v-card-text>
            <v-btn block color="primary" type="submit">{{ $vuetify.lang.t("$vuetify.key.K_%E7%99%BB%E5%BD%95") }}</v-btn>
          </v-card-text>
        </v-form>
      </v-card>
    </div>
    <v-footer dark color="transparent" style="flex-grow: 0" class="text-center">
      <v-col class="text-center" cols="12">
        <span>&copy;SZZHT 2021-{{ new Date().getFullYear() }}</span>
        <span class="pl-3">Ver {{ app.version }}.{{ buildno }}</span>
      </v-col>
    </v-footer>
  </v-container>
</template>

<script>
import client from "../utils/client";
import store from "../utils/store";
import app from "../../package.json";
import buildno from "../buildno";
export default {
  props: { show: Boolean },
  data() {
    return {
      app: app,
      buildno,
      host: "localhost",
      port: 6780,
      account: "",
      password: "",
      developer: false,
    };
  },
  created() {
    this.host = store.get("login-host") || "localhost";
    this.port = store.get("login-port") || 6780;
    this.account = store.get("login-account") || "";
    this.developer = store.get("login-developer") === "true";
  },
  watch: {
    show() {
      this.password = "";
    },
  },
  methods: {
    changeLocal() {
      let current = this.$vuetify.lang.current;
      this.$vuetify.lang.current = current == "en" ? "zhHans" : "en";
      store.set("lang", this.$vuetify.lang.current);
    },
    async login() {
      store.set("login-host", this.host);
      store.set("login-port", this.port);
      store.set("login-account", this.account);
      store.set("login-developer", this.developer);
      try {
        await client.login(
          this.host,
          this.port,
          this.account,
          this.password,
          this.developer
        );
      } catch (error) {
        client.$emit("toast", error);
      }
    },
  },
};
</script>
