export default [
    { key: "zht-div", name: "$vuetify.key.K_%E5%8C%BA%E5%9D%97", icon: "mdi-border-all-variant" },
    { key: "zht-component", name: "$vuetify.key.K_%E5%AD%90%E9%A1%B5%E6%B5%8F%E8%A7%88%E5%99%A8", icon: "mdi-view-compact" },
    { key: "zht-tabs", name: "$vuetify.key.K_%E5%AF%BC%E8%88%AA", icon: "mdi-menu" },
    { key: "zht-csv", name: "$vuetify.key.K_CSV%E8%A1%A8%E6%A0%BC", icon: "mdi-table" },
    { key: "zht-svg", name: "$vuetify.key.K_SVG%E7%9F%A2%E9%87%8F%E5%9B%BE", icon: "mdi-image" },
    { key: "zht-bar", name: "$vuetify.key.K_%E6%9F%B1%E7%8A%B6%E5%9B%BE", icon: "mdi-chart-bar" },
    { key: "zht-line", name: "$vuetify.key.K_%E6%9B%B2%E7%BA%BF%E5%9B%BE", icon: "mdi-chart-bell-curve-cumulative", },
    { key: "zht-pie", name: "$vuetify.key.K_%E9%A5%BC%E5%9B%BE", icon: "mdi-chart-arc" },
    { key: "zht-gauge", name: "$vuetify.key.K_%E4%BB%AA%E8%A1%A8", icon: "mdi-gauge" },
    { key: "zht-chart", name: "$vuetify.key.K_%E8%87%AA%E5%AE%9A%E4%B9%89%E5%9B%BE%E8%A1%A8", icon: "mdi-chart-multiple" },
    { key: "zht-field", name: "$vuetify.key.K_%E9%A2%9C%E8%89%B2%E5%9C%BA", icon: "mdi-cast-connected" },
    { key: "zht-textbox", name: "$vuetify.key.K_%E8%BE%93%E5%85%A5%E6%A1%86", icon: "mdi-form-textbox" },
    { key: "zht-combobox", name: "$vuetify.key.K_%E9%80%89%E6%8B%A9%E6%A1%86", icon: "mdi-form-dropdown" },
    { key: "zht-button", name: "$vuetify.key.K_%E6%8C%89%E9%92%AE", icon: "mdi-gesture-tap-button" },
    { key: "zht-icon", name: "$vuetify.key.K_%E5%9B%BE%E6%A0%87", icon: "mdi-emoticon-cool-outline" },
    { key: "zht-map", name: "$vuetify.key.K_%E8%A1%8C%E6%94%BF%E5%9C%B0%E5%9B%BE", icon: "mdi-map" },
    { key: "zht-bmap", name: "$vuetify.key.K_%E5%AE%9A%E4%BD%8D%E5%9C%B0%E5%9B%BE", icon: "mdi-map-marker" },
    { key: "zht-rtsp", name: "$vuetify.key.K_RTSP%E8%A7%86%E9%A2%91", icon: "mdi-webcam" },
    { key: "zht-rtsp-playback", name: "$vuetify.key.K_RTSP%E5%9B%9E%E6%94%BE", icon: "mdi-video" },
    { key: "zht-spotlist", name: "$vuetify.key.K_%E6%B5%8B%E7%82%B9%E5%88%97%E8%A1%A8", icon: "mdi-format-list-numbered-rtl" },
    { key: "zht-browser", name: "$vuetify.key.K_%E5%A4%96%E9%93%BE%E7%BD%91%E9%A1%B5", icon: "mdi-application" },
];
