<style>
.toolbar-actions {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}
.toolbar-actions > *:nth-child(1) {
  margin-left: 0 !important;
}
.toolbar-actions > * {
  margin-left: 12px !important;
}
</style>

<template>
  <v-tabs
    dark
    show-arrows
    background-color="rgba(255,255,255,0.1)"
    class="flex-grow-0 elevation-4"
    style="z-index: 1"
  >
    <div class="fill-height d-flex flex-nowrap align-center flex-grow-1 px-3">
      <v-btn icon @click="globalEmit('showOrganization')" v-if="!hideOrg">
        <v-icon>mdi-sitemap</v-icon>
      </v-btn>
      <v-btn
        text
        class="px-0"
        @click="globalEmit('showOrganization')"
        v-if="!hideOrg"
        v-text="orgName"
      ></v-btn>
      <slot name="left"></slot>
      <v-spacer></v-spacer>
      <div class="toolbar-actions">
        <slot></slot>
      </div>
    </div>
  </v-tabs>
</template>

<script>
import client from "../utils/client";
export default {
  props: { hideOrg: Boolean },
  computed: {
    orgName() {
      return client.org.name;
    },
  },
  methods: {
    globalEmit(eventName) {
      client.$emit(eventName);
    },
  },
};
</script>