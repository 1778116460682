var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.show,"persistent":"","max-width":"480"}},[_c('v-card',[_c('v-card-title',[_c('div',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%8A%A8%E4%BD%9C%E7%BC%96%E8%BE%91" ))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-select',{attrs:{"label":_vm.$vuetify.lang.t(
            '$vuetify.key.K_%E5%8A%A8%E4%BD%9C%E7%B1%BB%E5%9E%8B'
          ),"items":_vm.types},on:{"change":function($event){_vm.action.srcType = 'const'}},model:{value:(_vm.action.type),callback:function ($$v) {_vm.$set(_vm.action, "type", $$v)},expression:"action.type"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.action.type == 'navigate'),expression:"action.type == 'navigate'"}],attrs:{"value":_vm.action.page.name,"label":_vm.$vuetify.lang.t('$vuetify.key.K_%E9%A1%B5%E9%9D%A2'),"readonly":""}},[_c('template',{slot:"append"},[_c('v-btn',{attrs:{"icon":"","tile":"","x-small":""},on:{"click":function($event){_vm.pageShow = true}}},[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)],1)],2),_c('ObjectSelector',{attrs:{"type":"page","single":true},on:{"select":_vm.pageSelect},model:{value:(_vm.pageShow),callback:function ($$v) {_vm.pageShow=$$v},expression:"pageShow"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.action.type == 'spot-ctrl'),expression:"action.type == 'spot-ctrl'"}],attrs:{"value":_vm.action.spot.name,"label":_vm.$vuetify.lang.t('$vuetify.key.K_%E6%B5%8B%E7%82%B9'),"readonly":""}},[_c('template',{slot:"append"},[_c('v-btn',{attrs:{"icon":"","tile":"","x-small":""},on:{"click":function($event){_vm.spotShow = true}}},[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)],1)],2),_c('ObjectSelector',{attrs:{"type":"spot","single":true},on:{"select":_vm.spotSelect},model:{value:(_vm.spotShow),callback:function ($$v) {_vm.spotShow=$$v},expression:"spotShow"}}),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.action.type == 'spot-ctrl'),expression:"action.type == 'spot-ctrl'"}],attrs:{"label":_vm.$vuetify.lang.t('$vuetify.key.K_%E5%80%BC%E6%9D%A5%E6%BA%90'),"items":_vm.srcTypes},model:{value:(_vm.action.srcType),callback:function ($$v) {_vm.$set(_vm.action, "srcType", $$v)},expression:"action.srcType"}}),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.action.type == 'prop-set' ||
            (_vm.action.type == 'spot-ctrl' && _vm.action.srcType == 'property')
        ),expression:"\n          action.type == 'prop-set' ||\n            (action.type == 'spot-ctrl' && action.srcType == 'property')\n        "}],attrs:{"label":_vm.$vuetify.lang.t('$vuetify.key.K_%E6%8E%A7%E4%BB%B6'),"item-value":"uuid","item-text":"name","items":_vm.controls},model:{value:(_vm.action.uuid),callback:function ($$v) {_vm.$set(_vm.action, "uuid", $$v)},expression:"action.uuid"}}),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.action.type == 'prop-set' ||
            (_vm.action.type == 'spot-ctrl' && _vm.action.srcType == 'property')
        ),expression:"\n          action.type == 'prop-set' ||\n            (action.type == 'spot-ctrl' && action.srcType == 'property')\n        "}],attrs:{"label":_vm.$vuetify.lang.t('$vuetify.key.K_%E5%B1%9E%E6%80%A7'),"items":_vm.props},model:{value:(_vm.action.prop),callback:function ($$v) {_vm.$set(_vm.action, "prop", $$v)},expression:"action.prop"}}),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.action.type == 'prop-set' ||
            _vm.action.type == 'new-tab' ||
            (_vm.action.type == 'spot-ctrl' && _vm.action.srcType == 'const')
        ),expression:"\n          action.type == 'prop-set' ||\n            action.type == 'new-tab' ||\n            (action.type == 'spot-ctrl' && action.srcType == 'const')\n        "}],attrs:{"label":_vm.$vuetify.lang.t('$vuetify.key.K_%E5%80%BC')},model:{value:(_vm.action.value),callback:function ($$v) {_vm.$set(_vm.action, "value", $$v)},expression:"action.value"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")))]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }