<template>
  <div class="main">
    <div class="pa-4" style="max-width: 360px">
      <v-select dark :label="$vuetify.lang.t('$vuetify.key.K_%E6%95%B0%E6%8D%AE%E4%BF%9D%E5%AD%98%E7%AD%96%E7%95%A5')" v-model="pp" :items="pps"></v-select>
      <v-text-field
        dark
        v-if="pp != 1 && pp != 3"
        :label="$vuetify.lang.t('$vuetify.key.K_%E6%AD%A3%E5%B8%B8%E4%BF%9D%E5%AD%98%E5%91%A8%E6%9C%9F')"
        v-model="timePeriod"
      >
        <template slot="append">{{ $vuetify.lang.t("$vuetify.key.K_%E7%A7%92") }}</template>
      </v-text-field>
      <v-text-field
        dark
        v-if="pp != 1 && pp != 3"
        :label="$vuetify.lang.t('$vuetify.key.K_%E5%91%8A%E8%AD%A6%E4%BF%9D%E5%AD%98%E5%91%A8%E6%9C%9F')"
        v-model="alarmpersist"
      >
        <template slot="append">{{ $vuetify.lang.t("$vuetify.key.K_%E7%A7%92") }}</template>
      </v-text-field>
      <v-text-field
        dark
        v-if="pp != 1 && pp != 2"
        :label="$vuetify.lang.t('$vuetify.key.K_%E5%8F%98%E5%8C%96%E7%8E%87')"
        v-model="varianceRatio"
      >
        <template slot="append">%</template>
      </v-text-field>
      <v-select dark :label="$vuetify.lang.t('$vuetify.key.K_%E6%95%B0%E6%8D%AE%E6%B8%85%E9%99%A4%E7%AD%96%E7%95%A5')" v-model="cp" :items="cps"></v-select>
      <v-text-field dark v-if="cp != 1" :label="$vuetify.lang.t('$vuetify.key.K_%E4%BF%9D%E7%95%99%E6%97%B6%E9%95%BF')" v-model="saveTerm">
        <template slot="append">
          <span v-if="cp == 2">{{ $vuetify.lang.t("$vuetify.key.K_%E5%A4%A9") }}</span>
          <span v-if="cp == 3">{{ $vuetify.lang.t("$vuetify.key.K_%E6%9C%88") }}</span>
          <span v-if="cp == 4">{{ $vuetify.lang.t("$vuetify.key.K_%E5%B9%B4") }}</span>
        </template>
      </v-text-field>
      <v-btn block dark color="rgba(255,255,255,0.2)" @click="submit">
        {{ $vuetify.lang.t("$vuetify.key.K_%E6%8F%90%E4%BA%A4") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  data() {
    return {
      pp: 1,
      pps: [
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%85%A8%E9%83%A8%E4%BF%9D%E5%AD%98"), value: 1 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%91%A8%E6%9C%9F%E6%80%A7%E4%BF%9D%E5%AD%98"), value: 2 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%8F%98%E5%8C%96%E7%8E%87%E4%BF%9D%E5%AD%98"), value: 3 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%91%A8%E6%9C%9F%E6%80%A7%E4%B8%8E%E5%8F%98%E5%8C%96%E7%8E%87%E4%BF%9D%E5%AD%98"), value: 4 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%91%A8%E6%9C%9F%E6%80%A7%E6%88%96%E5%8F%98%E5%8C%96%E7%8E%87%E4%BF%9D%E5%AD%98"), value: 5 },
      ],
      timePeriod: 0,
      alarmpersist: 0,
      varianceRatio: 0,

      cp: 1,
      cps: [
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E6%B0%B8%E4%B9%85%E4%BF%9D%E5%AD%98"), value: 1 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E4%BF%9D%E5%AD%98%E5%A4%A9%E6%95%B0"), value: 2 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E4%BF%9D%E5%AD%98%E6%9C%88%E6%95%B0"), value: 3 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E4%BF%9D%E5%AD%98%E5%B9%B4%E6%95%B0"), value: 4 },
      ],
      saveTerm: 0,
    };
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    async getSetting() {
      client.$emit(
        "startBusy",
        "SystemStorage.getSetting",
        this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E8%B5%84%E6%BA%90%2C%E8%AF%B7%E7%A8%8D%E5%80%99")
      );
      await proto.sleep(100);
      try {
        let res = await client.send(
          proto.MESSAGE_TYPE.commonRtdPersistPolicyMessage,
          {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
              parentId: client.project.id,
            },
          }
        );
        this.pp = res.pp || 1;
        this.timePeriod = res.timePeriod || 0;
        this.alarmpersist = res.alarmpersist || 0;
        this.varianceRatio = res.varianceRatio || 0;
        this.cp = res.cp || 1;
        this.saveTerm = res.saveTerm || 0;
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "SystemStorage.getSetting");
    },
    async submit() {
      client.$emit(
        "startBusy",
        "SystemStorage.submit",
        this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E4%BF%9D%E5%AD%98%E7%AD%96%E7%95%A5%2C%E8%AF%B7%E7%A8%8D%E5%80%99")
      );
      await proto.sleep(100);
      try {
        let timePeriod = parseInt(this.timePeriod);
        let alarmpersist = parseInt(this.alarmpersist);
        let varianceRatio = parseInt(this.varianceRatio);
        let saveTerm = parseInt(this.saveTerm);
        if (
          !(
            timePeriod >= 0 &&
            alarmpersist >= 0 &&
            varianceRatio >= 0 &&
            saveTerm >= 0
          )
        ) {
          throw this.$vuetify.lang.t("$vuetify.key.K_%E6%8F%90%E4%BA%A4%E7%9A%84%E6%95%B0%E6%8D%AE%E4%B8%8D%E5%90%88%E6%B3%95");
        }
        await client.send(
          proto.MESSAGE_TYPE.commonRtdPersistPolicyMessage,
          {
            mcd: {
              operate: proto.OperateMode.createOpt,
              parentId: client.project.id,
            },
            pp: this.pp,
            timePeriod,
            alarmpersist,
            varianceRatio,
            cp: this.cp,
            saveTerm,
          }
        );
        client.$emit("toast", this.$vuetify.lang.t("$vuetify.key.K_%E4%BF%9D%E5%AD%98%E6%88%90%E5%8A%9F"), "success");
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "SystemStorage.submit");
    },
  },
};
</script>
