<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>
            <div>
              {{ label }}{{ $vuetify.lang.t("$vuetify.key.WordSpace")
              }}{{ $vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E8%BE%91") }}
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="add">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-data-table :headers="headers" :items="items">
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                icon
                @click="
                  editModel = item;
                  editShow = true;
                "
              >
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
              <v-btn icon @click="remove(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">{{
              $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")
            }}</v-btn>
            <v-btn text @click="show = false">{{
              $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editShow" width="480" persistent>
        <v-card>
          <v-card-title>
            <div>
              {{
                $vuetify.lang.t(
                  "$vuetify.key.K_%E6%97%B6%E9%97%B4%E7%BC%96%E8%BE%91"
                )
              }}
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="editShow = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-if="editModel">
            <v-select
              :label="$vuetify.lang.t('$vuetify.key.K_%E6%98%9F%E6%9C%9F')"
              v-model="editModel.weekday"
              :items="weekdays"
            ></v-select>
            <v-select
              :label="$vuetify.lang.t('$vuetify.key.K_%E6%97%B6')"
              v-model="editModel.hour"
              :items="hours"
            ></v-select>
            <v-select
              :label="$vuetify.lang.t('$vuetify.key.K_%E5%88%86')"
              v-model="editModel.minute"
              :items="minutes"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="editShow = false">{{
              $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,

      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%98%9F%E6%9C%9F"),
          value: "weekday",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%97%B6"),
          value: "hour",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E5%88%86"),
          value: "minute",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%93%8D%E4%BD%9C"),
          value: "actions",
          align: "end",
        },
      ],
      items: [],

      weekdays: [],
      hours: [],
      minutes: [],

      editModel: null,
      editShow: false,
    };
  },
  computed: {
    valueDisplay() {
      if (this.value && this.value.length)
        return this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%B7%B2%E9%85%8D%E7%BD%AE"
        );
      return this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%AA%E9%85%8D%E7%BD%AE");
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          let list = this.value;
          this.items = JSON.parse(JSON.stringify(list));
        }
      },
    },
  },
  mounted() {
    for (let i = 1; i <= 7; i++) {
      this.weekdays.push(i);
    }
    for (let i = 0; i <= 23; i++) {
      this.hours.push(i);
    }
    for (let i = 0; i <= 59; i++) {
      this.minutes.push(i);
    }
  },
  methods: {
    add() {
      let model = { weekday: 1, hour: 0, minute: 0 };
      this.items.push(model);
      this.editModel = model;
      this.editShow = true;
    },
    remove(item) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i] == item) {
          this.items.splice(i, 1);
        }
      }
    },
    submit() {
      this.$emit("change", this.items);
      this.show = false;
    },
  },
};
</script>
