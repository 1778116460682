<template>
  <v-dialog :value="show" @input="close" persistent max-width="540">
    <v-card>
      <v-card-title>
        <div>
          {{ objectName[type] }}{{ $vuetify.lang.t("$vuetify.key.WordSpace")
          }}{{ $vuetify.lang.t("$vuetify.key.K_%E9%80%89%E6%8B%A9") }}
        </div>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row align="baseline">
          <v-col v-if="type == 'spot' || type == 'device' || type == 'agent'">
            <v-text-field
              v-model="agentName"
              :label="
                $vuetify.lang.t(
                  '$vuetify.key.K_%E7%9B%91%E6%8E%A7%E5%8D%95%E5%85%83'
                )
              "
              hide-details
            ></v-text-field>
          </v-col>
          <v-col v-if="type == 'spot' || type == 'device'">
            <v-text-field
              v-model="deviceName"
              :label="$vuetify.lang.t('$vuetify.key.K_%E8%AE%BE%E5%A4%87')"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col v-if="type == 'spot'">
            <v-text-field
              v-model="spotName"
              :label="$vuetify.lang.t('$vuetify.key.K_%E6%B5%8B%E7%82%B9')"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col v-if="type == 'page'">
            <v-text-field
              v-model="pageName"
              :label="$vuetify.lang.t('$vuetify.key.K_%E9%A1%B5%E9%9D%A2')"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col v-if="type == 'org'">
            <v-text-field
              v-model="orgName"
              :label="$vuetify.lang.t('$vuetify.key.K_%E6%9C%BA%E6%9E%84')"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn @click="query">{{
              $vuetify.lang.t("$vuetify.key.K_%E6%90%9C%E7%B4%A2")
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table dense :headers="headers" :items="items" height="240">
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            v-if="single"
            icon
            :disabled="item.disable"
            @click="select(item)"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn v-else icon :disabled="item.disable" @click="add(item)">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  model: {
    prop: "show",
    event: "change",
  },
  props: { show: Boolean, type: String, single: Boolean },
  data() {
    return {
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E5%90%8D%E7%A7%B0"),
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%93%8D%E4%BD%9C"),
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      objectName: {
        org: this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%BA%E6%9E%84"),
        agent: this.$vuetify.lang.t(
          "$vuetify.key.K_%E7%9B%91%E6%8E%A7%E5%8D%95%E5%85%83"
        ),
        device: this.$vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E5%A4%87"),
        spot: this.$vuetify.lang.t("$vuetify.key.K_%E6%B5%8B%E7%82%B9"),
        page: this.$vuetify.lang.t("$vuetify.key.K_%E9%A1%B5%E9%9D%A2"),
      },
      items: [],
      orgName: "",
      agentName: "",
      deviceName: "",
      spotName: "",
      pageName: "",
    };
  },
  watch: {
    show: {
      handler() {
        if (this.show) this.query();
      },
    },
    type: {
      handler() {
        this.items = [];
      },
    },
  },
  methods: {
    async query() {
      client.$emit(
        "startBusy",
        "ObjectSelector.query",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E6%90%9C%E7%B4%A2%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      let queryObjType = 1;
      let scopeexpr = ["1=1"];
      switch (this.type) {
        case "org":
          queryObjType = 5;
          break;
        case "agent":
          queryObjType = 1;
          if (this.agentName) scopeexpr.push(`name like '%${this.agentName}%'`);
          break;
        case "device":
          queryObjType = 2;
          if (this.agentName)
            scopeexpr.push(`a.name like '%${this.agentName}%'`);
          if (this.deviceName)
            scopeexpr.push(`d.name like '%${this.deviceName}%'`);
          break;
        case "spot":
          queryObjType = 3;
          if (this.agentName)
            scopeexpr.push(`a.name like '%${this.agentName}%'`);
          if (this.deviceName)
            scopeexpr.push(`d.name like '%${this.deviceName}%'`);
          if (this.spotName) scopeexpr.push(`s.name like '%${this.spotName}%'`);
          break;
        case "page":
          queryObjType = 4;
          if (this.pageName) scopeexpr.push(`name like '%${this.pageName}%'`);
          break;
      }
      try {
        if (queryObjType != 5) {
          let res = await client.send(proto.MESSAGE_TYPE.queryMessage, {
            mcd: {
              operate: proto.OperateMode.queryOpt,
              range: "0",
            },
            rpp: 500,
            pageindex: 0,
            queryCondition: {
              project: client.project.id,
              scope: 3,
              queryConditionType: 3,
              act: 1,
              queryObjType,
              scopeexpr: " " + scopeexpr.join(" and ") + " ",
            },
          });
          let result = [];
          if (res.results) {
            for (let i in res.results) {
              let record = res.results[i];
              let names = [];
              if (record.agentname) names.push(record.agentname);
              if (record.devicename) names.push(record.devicename);
              if (record.spotname) names.push(record.spotname);
              result.push({
                id: record.uuid,
                name: names.join("-"),
                disable: false,
              });
            }
          }
          this.items = result;
        } else {
          let res = await client.send(proto.MESSAGE_TYPE.organizationMessage, {
            mcd: {
              operate: proto.OperateMode.queryOpt,
              range: "0",
              parentId: client.project.id,
            },
          });
          let result = [];
          if (res.orgs && res.orgs.length) {
            this.treeList(result, res.orgs[0], "");
          }
          this.items = result;
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ObjectSelector.query");
    },
    treeList(array, model, base) {
      array.push({
        id: model.id,
        name: base + model.name,
        disable: false,
      });
      for (let i in model.child) {
        this.treeList(array, model.child[i], `${base + model.name}-`);
      }
    },
    select(item) {
      this.$emit("select", {
        id: item.id,
        name: item.name,
      });
      this.close();
    },
    add(item) {
      this.$emit("add", {
        id: item.id,
        name: item.name,
      });
      item.disable = true;
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>
