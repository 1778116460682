<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title
            >{{ label }}{{ $vuetify.lang.t("$vuetify.key.WordSpace")
            }}{{
              $vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E8%BE%91")
            }}</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text v-if="editModel">
            <template v-if="tasktype == 2">
              <v-select
                :label="
                  $vuetify.lang.t(
                    '$vuetify.key.K_%E5%B7%A1%E6%A3%80%E7%B1%BB%E5%9E%8B'
                  )
                "
                v-model="editModel.srctype"
                :items="srctypes"
              ></v-select>
              <div>
                {{
                  $vuetify.lang.t(
                    "$vuetify.key.K_%E5%B7%A1%E6%A3%80%E5%86%85%E5%AE%B9"
                  )
                }}
              </div>
              <v-card class="px-3">
                <v-chip-group
                  v-model="editModel.cps"
                  active-class="primary--text"
                  multiple
                  column
                >
                  <v-chip v-show="false"></v-chip>
                  <v-chip>{{
                    $vuetify.lang.t(
                      "$vuetify.key.K_%E5%BD%93%E5%89%8D%E6%B5%8B%E7%82%B9%E5%91%8A%E8%AD%A6%E6%80%BB%E6%95%B0"
                    )
                  }}</v-chip>
                  <v-chip>{{
                    $vuetify.lang.t(
                      "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%BA%A7%E5%88%AB%E5%88%86%E7%B1%BB%E7%BB%9F%E8%AE%A1"
                    )
                  }}</v-chip>
                  <v-chip>{{
                    $vuetify.lang.t(
                      "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%B1%BB%E5%9E%8B%E5%88%86%E7%B1%BB%E7%BB%9F%E8%AE%A1"
                    )
                  }}</v-chip>
                  <v-chip v-show="editModel.srctype == 1">{{
                    $vuetify.lang.t(
                      "$vuetify.key.K_%E7%A6%81%E7%94%A8%E6%95%B0%E9%87%8F"
                    )
                  }}</v-chip>
                  <v-chip>{{
                    $vuetify.lang.t(
                      "$vuetify.key.K_%E7%A6%BB%E7%BA%BF%E6%95%B0%E9%87%8F"
                    )
                  }}</v-chip>
                  <v-chip>{{
                    $vuetify.lang.t(
                      "$vuetify.key.K_%E5%9C%A8%E7%BA%BF%E6%95%B0%E9%87%8F"
                    )
                  }}</v-chip>
                </v-chip-group>
              </v-card>
              <div class="d-flex align-center">
                <span>{{
                  $vuetify.lang.t(
                    "$vuetify.key.K_%E5%B7%A1%E6%A3%80%E5%AF%B9%E8%B1%A1"
                  )
                }}</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="selectorShow = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
              <v-card>
                <v-data-table :headers="headers" :items="editModel.ids" dense>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn icon @click="remove(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </template>
            <template v-if="tasktype != 5">
              <zht-editor-object
                :label="
                  $vuetify.lang.t(
                    '$vuetify.key.K_%E7%9F%AD%E4%BF%A1%E5%8F%91%E9%80%81%E5%99%A8'
                  )
                "
                :options="{ type: 'notifier' }"
                v-model="editModel.smsnotifier"
              ></zht-editor-object>
              <zht-editor-object
                :label="
                  $vuetify.lang.t(
                    '$vuetify.key.K_%E9%82%AE%E4%BB%B6%E5%8F%91%E9%80%81%E5%99%A8'
                  )
                "
                :options="{ type: 'notifier' }"
                v-model="editModel.emailnotifier"
              ></zht-editor-object>
              <zht-editor-object
                :label="
                  $vuetify.lang.t(
                    '$vuetify.key.K_%E5%BE%AE%E4%BF%A1%E5%8F%91%E9%80%81%E5%99%A8'
                  )
                "
                :options="{ type: 'notifier' }"
                v-model="editModel.wechatnotifier"
              ></zht-editor-object>
              <zht-editor-object
                :label="
                  $vuetify.lang.t(
                    '$vuetify.key.K_%E6%8E%A5%E6%94%B6%E7%94%A8%E6%88%B7'
                  )
                "
                :options="{ type: 'person' }"
                v-model="editModel.persons"
              ></zht-editor-object>
            </template>
            <template v-if="tasktype == 5">
              <div class="d-flex align-center">
                <span>{{
                  $vuetify.lang.t(
                    "$vuetify.key.K_%E6%8E%A7%E5%88%B6%E6%B5%8B%E7%82%B9"
                  )
                }}</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="selectorShow = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
              <v-card>
                <v-data-table :headers="headers" :items="editModel.ids" dense>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn icon @click="remove(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </template>
            <zht-editor-text
              v-if="tasktype >= 5"
              :label="$vuetify.lang.t('$vuetify.key.K_%E5%86%85%E5%AE%B9')"
              v-model="editModel.content"
            ></zht-editor-text>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">{{
              $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")
            }}</v-btn>
            <v-btn text @click="show = false">{{
              $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <ObjectSelector
        v-model="selectorShow"
        :type="objectType"
        :single="false"
        @add="add"
      ></ObjectSelector>
    </template>
  </v-text-field>
</template>

<script>
import ObjectSelector from "./ObjectSelector.vue";
export default {
  components: {
    ObjectSelector,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value", "model"],
  data() {
    return {
      show: false,
      editModel: null,
      srctypes: [
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA"
          ),
          value: 1,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E5%A4%87"),
          value: 2,
        },
      ],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E5%90%8D%E7%A7%B0"),
          value: "name",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%93%8D%E4%BD%9C"),
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      selectorShow: false,
    };
  },
  computed: {
    tasktype() {
      return this.model.tasktype;
    },
    objectType() {
      if (this.tasktype == 5) {
        return "spot";
      } else if (this.editModel) {
        return this.editModel.srctype == 1 ? "agent" : "device";
      }
      return "";
    },
    valueDisplay() {
      if (!this.value)
        return this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%9C%AA%E9%85%8D%E7%BD%AE"
        );
      switch (this.tasktype) {
        case 1:
        case 2:
        case 4:
        case 6:
          return this.value.persons.length
            ? this.$vuetify.lang.t("$vuetify.key.K_%E5%B7%B2%E9%85%8D%E7%BD%AE")
            : this.$vuetify.lang.t(
                "$vuetify.key.K_%E6%9C%AA%E9%85%8D%E7%BD%AE"
              );
        case 5:
          return this.value.ids.length
            ? this.$vuetify.lang.t("$vuetify.key.K_%E5%B7%B2%E9%85%8D%E7%BD%AE")
            : this.$vuetify.lang.t(
                "$vuetify.key.K_%E6%9C%AA%E9%85%8D%E7%BD%AE"
              );
        default:
          return "";
      }
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          let model = this.value || {
            cps: [],
            srctype: 1,
            ids: [],
            smsnotifier: { id: "", name: "" },
            emailnotifier: { id: "", name: "" },
            persons: [],
            content: "",
            wechatnotifier: { id: "", name: "" },
          };
          this.editModel = JSON.parse(JSON.stringify(model));
        }
      },
    },
  },
  methods: {
    add(item) {
      for (let i in this.editModel.ids) {
        if (this.editModel.ids[i].id == item.id) {
          return;
        }
      }
      this.editModel.ids.push(item);
    },
    remove(item) {
      for (let i = 0; i < this.editModel.ids.length; i++) {
        if (this.editModel.ids[i].id == item.id) {
          this.editModel.ids.splice(i, 1);
        }
      }
    },
    submit() {
      this.$emit("change", this.editModel);
      this.show = false;
    },
  },
};
</script>
