export default {
    get: function (key) {
        if (window.localStorage) {
            return window.localStorage.getItem(key) || "";
        } else {
            let name = key + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i].trim();
                if (c.indexOf(name) == 0) return unescape(c.substring(name.length, c.length));
            }
            return "";
        }
    },
    set: function (key, value) {
        if (window.localStorage) {
            return window.localStorage.setItem(key, value);
        } else {
            document.cookie = key + "=" + escape(value) + '; path=/; expires=Fri, 01 Jan 2100 00:00:00 GMT';
        }
    },
    del: function (key) {
        if (window.localStorage) {
            return window.localStorage.removeItem(key);
        } else {
            document.cookie = key + '=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }
}
