import uuid from '../utils/uuid';

function Size() {
    this.size = this.size || {};
    this.size.width = 100;
    this.size.height = 100;
}

function Location() {
    this.location = this.location || {};
    this.location.x = 0;
    this.location.y = 0;
}

function Style() {
    this.style = this.style || {};
    this.style.opacity = 1.0;
    this.style['box-shadow'] = '0px 0px 3px #000000';
    this.style['transform'] = '';
    this.style['transform-origin'] = 'center';
}

function Animate() {
    this.style = this.style || {};
    this.style['animation-name'] = '';
    this.style['offset-path'] = ``;
    this.style['offset-distance'] = '0%';
    this.style['animation-duration'] = '2s';
    this.style['animation-delay'] = '0s';
    this.style['animation-timing-function'] = 'linear';
    this.style['animation-iteration-count'] = 'infinite';
    this.style['animation-direction'] = 'normal';
}

function Actions() {
    this.config = this.config || {};
    this.config.actions = this.config.actions || [];
}

function ZhtUI() {
    Location.call(this);
    Size.call(this);
    Style.call(this);
    this.uuid = uuid();
    this.type = "";
    this.name = "";
    this.spotName = "";
    this.config = this.config || {};
}

function ZhtDiv() {
    ZhtUI.call(this);
    Animate.call(this);
    Actions.call(this);
    this.type = 'zht-div';
    this.name = "div";

    this.style['padding'] = '12px';

    this.style['border-style'] = 'solid';
    this.style['border-width'] = '0px';
    this.style['border-color'] = '#000000';
    this.style['border-radius'] = '0px';

    this.style['color'] = '#000';
    this.style['background-color'] = '';
    this.style['background-image'] = '';
    this.style['background-repeat'] = 'no-repeat';
    this.style['background-position'] = 'center center';
    this.style['background-size'] = '100% 100%';
    this.style['backdrop-filter'] = '';

    this.style['text-align'] = 'left';

    this.style['font-style'] = 'normal';
    this.style['font-size'] = '16px';
    this.style['font-weight'] = 'normal';
    this.style['line-height'] = '16px';

    this.config = this.config || {};
    this.config.text = '';
    this.config.lines = 'single';
}

function ZhtComponent() {
    ZhtUI.call(this);
    this.type = 'zht-component';
    this.name = "sub frame";
    this.config = this.config || {};
    this.config.pageId = '';
}

function ZhtTabs() {
    ZhtUI.call(this);
    this.type = 'zht-tabs';
    this.name = "tabs";
    this.size.width = 128;
    this.size.height = 256;
    this.style['color'] = '#1E88E5';
    this.style['background-color'] = 'transparent';
    this.style['font-size'] = '16px';
    this.style['font-weight'] = 'normal';
    this.style['padding'] = '12px';
    this.config = this.config || {};
    this.config.theme = 'light';
    this.config.direction = 'vertical';
    this.config.tabs = [];
    this.config.pageContainer = "";
}

function ZhtRtsp() {
    ZhtUI.call(this);
    this.type = 'zht-rtsp';
    this.name = "rtsp";
    this.size.width = 480;
    this.size.height = 360;
    this.config = this.config || {};
    this.config.rtsp = '';
}

function ZhtRtspPlayback() {
    ZhtUI.call(this);
    this.type = 'zht-rtsp-playback';
    this.name = "rtsp-playback";
    this.size.width = 480;
    this.size.height = 360;
    this.config = this.config || {};
    this.config.host = '';
    this.config.port = 554;
    this.config.account = '';
    this.config.password = '';
    this.config.manufacturer = '1';
}

function ZhtChart() {
    ZhtUI.call(this);
    this.type = 'zht-chart';
    this.name = "chart";
    this.size.width = 480;
    this.size.height = 360;
    this.config = this.config || {};
    this.config.chartOption = 'var option={}';
}

function ZhtLine() {
    ZhtUI.call(this);
    this.type = 'zht-line';
    this.name = "line";
    this.size.width = 480;
    this.size.height = 360;
    this.style['color'] = '#42A5F5';
    this.config = this.config || {};
    this.config.xData = 'Mon,Tue,Wed,Thu,Fri,Sat,Sun';
    this.config.yData = '120,200,150,80,70,110,130';
}

function ZhtBar() {
    ZhtUI.call(this);
    this.type = 'zht-bar';
    this.name = "bar";
    this.size.width = 480;
    this.size.height = 360;
    this.style['color'] = '#42A5F5';
    this.config = this.config || {};
    this.config.xData = 'Mon,Tue,Wed,Thu,Fri,Sat,Sun';
    this.config.yData = '120,200,150,80,70,110,130';
}

function ZhtPie() {
    ZhtUI.call(this);
    this.type = 'zht-pie';
    this.name = "pie";
    this.size.width = 480;
    this.size.height = 360;
    this.config = this.config || {};
    this.config.pieRadius = '40%,60%';
    this.config.xData = 'Mon,Tue,Wed,Thu,Fri,Sat,Sun';
    this.config.yData = '120,200,150,80,70,110,130';
}

function ZhtGauge() {
    ZhtUI.call(this);
    this.type = 'zht-gauge';
    this.name = "gauge";
    this.size.width = 256;
    this.size.height = 256;
    this.style['color'] = '#000';
    this.style['font-size'] = "12px";
    this.config = this.config || {};
    this.config.gaugeRadius = '100%';
    this.config.gaugeCenter = '50%,50%';
    this.config.min = 0;
    this.config.max = 100;
    this.config.splitNumber = 10;
    this.config.startAngle = 225;
    this.config.endAngle = -45;
    this.config.value = 66;
    this.config.unit = 'V';
}

function ZhtMap() {
    ZhtUI.call(this);
    this.type = 'zht-map';
    this.name = "map";
    this.size.width = 480;
    this.size.height = 360;
    this.style['border-color'] = '#888';
    this.style['color'] = '#000';
    this.style['background-color'] = '#B3E5FC';
    this.config = this.config || {};
    this.config.map = 'beijing';
    this.config.mapJson = '';
    this.config.legend = 'left-top';
    this.config.tabs = [];
    this.config.stateData = '';
    this.config.alarmData = '';
}

function ZhtBMap() {
    ZhtUI.call(this);
    this.type = 'zht-bmap';
    this.name = "bmap";
    this.size.width = 480;
    this.size.height = 360;
    this.style['color'] = '#007bff';
    this.config = this.config || {};
    this.config.mapCenter = "116.40354,39.923425";
    this.config.mapZoom = 11;
    this.config.mapPoint = [];
}

function ZhtTextBox() {
    ZhtUI.call(this);
    this.type = 'zht-textbox';
    this.name = "textbox";
    this.size.width = 150;
    this.size.height = 36;
    delete this.style['box-shadow'];
    this.style['background-color'] = '';
    this.style['font-style'] = 'normal';
    this.style['font-size'] = '16px';
    this.style['font-weight'] = 'normal';
    this.config = this.config || {};
    this.config.text = '';
    this.config.theme = 'light';
}

function ZhtComboBox() {
    ZhtUI.call(this);
    this.type = 'zht-combobox';
    this.name = "combobox";
    this.size.width = 150;
    this.size.height = 36;
    delete this.style['box-shadow'];
    this.style['background-color'] = '';
    this.style['font-style'] = 'normal';
    this.style['font-size'] = '16px';
    this.style['font-weight'] = 'normal';
    this.config = this.config || {};
    this.config.selected = "";
    this.config.options = [];
    this.config.theme = 'light';
}

function ZhtButton() {
    ZhtUI.call(this);
    Actions.call(this);
    this.type = 'zht-button';
    this.name = "button";
    this.size.width = 150;
    this.size.height = 36;
    this.style['background-color'] = '';
    this.style['font-style'] = 'normal';
    this.style['font-size'] = '16px';
    this.style['font-weight'] = 'normal';
    this.config = this.config || {};
    this.config.text = 'button';
    this.config.theme = 'light';
}

function ZhtIcon() {
    ZhtUI.call(this);
    Animate.call(this);
    Actions.call(this);
    this.type = 'zht-icon';
    this.name = "icon";
    this.size.width = 36;
    this.size.height = 36;
    delete this.style['box-shadow'];
    this.style['color'] = '';
    this.style['font-size'] = '36px';
    this.config = this.config || {};
    this.config.icon = '';
}

function ZhtCSV() {
    ZhtUI.call(this);
    this.type = 'zht-csv';
    this.name = "csv";
    this.size.width = 360;
    this.size.height = 240;
    this.style['color'] = '#000000';
    this.style['border-color'] = '#888';
    this.style['font-size'] = '16px';
    this.config = this.config || {};
    this.config.csv = 'Name,value\\nname1,value1';
    this.config.dense = false;
}

function ZhtField() {
    ZhtUI.call(this);
    this.type = 'zht-field';
    this.name = "field";
    this.size.width = 360;
    this.size.height = 240;
    this.style.opacity = 0.5;
    this.config = this.config || {};
    this.config.min = 0.0;
    this.config.max = 50.0;
    this.config.points = "0,0\n360,240";
    this.config.pointsData = "0.0,50.0";
}

function ZhtSpotList() {
    ZhtUI.call(this);
    this.type = 'zht-spotlist';
    this.name = "spotlist";
    this.size.width = 480;
    this.size.height = 360;
    this.style.opacity = 1;
    this.style['box-shadow'] = '0px 0px 3px #00FF00';
    this.style['border-style'] = 'solid';
    this.style['border-width'] = '1px';
    this.style['border-color'] = '#00FF00';
    this.style['border-radius'] = '4px';
    this.style['color'] = '#FFF';
    this.style['background-color'] = 'rgba(0,255,0,0.2)';
    this.style['padding'] = '4px 8px';
    this.style['margin'] = '4px';
    this.style['font-style'] = 'normal';
    this.style['font-size'] = '16px';
    this.style['font-weight'] = 'normal';
    this.config = this.config || {};
    this.config.deviceId = "";
    this.config.spotNumbers = "1-12,13,14-24";
    this.config.cols = 4;
    this.config.alarmColor = "#FFF";
    this.config.alarmBorderColor = '#FF0000';
    this.config.alarmBackgroundColor = 'rgba(255,0,0,0.2)';
    this.config.alarmBoxShadow = '0px 0px 3px #FF0000';
}

function ZhtBrowser() {
    ZhtUI.call(this);
    this.type = 'zht-browser';
    this.name = "web page";
    this.size.width = 640;
    this.size.height = 480;
    this.style.opacity = 1.0;
    this.config = this.config || {};
    this.config.url = "";
}

function ZhtSVG() {
    ZhtUI.call(this);
    Animate.call(this);
    Actions.call(this);
    this.type = 'zht-svg';
    this.name = "svg";
    this.size.width = 200;
    this.size.height = 100;
    this.style.opacity = 1.0;
    this.style['box-shadow'] = '';
    this.config = this.config || {};
    this.config.svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202 102" width="100%" height="100%" preserveAspectRatio="none">
    <g id="root">
        <rect id="rect" x="1" y="1" width="200" height="100" stroke="#009FFD" stroke-width="2" fill="transparent" stroke-dasharray="10 180 20 80 20 180 20 80 10 0" stroke-dashoffset="0"/>
        <text id="text" text-anchor="middle" alignment-baseline="middle" font-size="36" x="101" y="51" fill="#009FFD">SVG</text>
    </g>
    <style>
    #root{
      cursor: pointer;
      user-select: none;
    }
    #rect {
      transition: 0.5s all ease;
    }
    #text {
      transition: 0.3s all ease;
    }
    #root:hover #rect {
      stroke-dasharray: 100 0 150 0 150 0 150 0 50 0;
    }
    #root:active #rect {
      stroke: #fff;
    }
    #root:active #text {
      fill: #fff;
    }
    </style>
</svg>`;
}

export default {
    ZhtDiv,
    ZhtComponent,
    ZhtTabs,
    ZhtRtsp,
    ZhtRtspPlayback,
    ZhtChart,
    ZhtLine,
    ZhtBar,
    ZhtPie,
    ZhtGauge,
    ZhtMap,
    ZhtBMap,
    ZhtTextBox,
    ZhtComboBox,
    ZhtButton,
    ZhtIcon,
    ZhtCSV,
    ZhtField,
    ZhtSpotList,
    ZhtBrowser,
    ZhtSVG,
}
