<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>
            <div>
              {{ label }}{{ $vuetify.lang.t("$vuetify.key.WordSpace")
              }}{{ $vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E8%BE%91") }}
            </div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              :label="
                $vuetify.lang.t(
                  '$vuetify.key.K_%E8%81%94%E5%8A%A8%E7%9B%AE%E6%A0%87'
                )
              "
              :value="name"
              readonly
              filled
              background-color="transparent"
              hide-details
            >
              <template slot="append">
                <v-btn icon tile @click="selectorShow = true">
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
            </v-text-field>
            <v-text-field
              :label="
                $vuetify.lang.t('$vuetify.key.K_%E6%8E%A7%E5%88%B6%E5%80%BC')
              "
              v-model="dstvalue"
              filled
              background-color="transparent"
              hide-details
            >
            </v-text-field>
            <zht-editor-text
              :label="
                $vuetify.lang.t('$vuetify.key.K_%E4%BC%98%E5%85%88%E7%BA%A7')
              "
              v-model="priority"
            ></zht-editor-text>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">{{
              $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")
            }}</v-btn>
            <v-btn text @click="show = false">{{
              $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88")
            }}</v-btn>
          </v-card-actions>
          <ObjectSelector
            v-model="selectorShow"
            type="spot"
            :single="true"
            @select="select"
          ></ObjectSelector>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import ObjectSelector from "./ObjectSelector.vue";
export default {
  components: {
    ObjectSelector,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,

      uuid: "",
      name: "",
      dstvalue: "",
      priority: 1,

      selectorShow: false,
    };
  },
  computed: {
    valueDisplay() {
      if (this.value && this.value.uuid) return this.value.name;
      return this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%AA%E9%85%8D%E7%BD%AE");
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.uuid = this.value ? this.value.uuid : "";
          this.name = this.value ? this.value.name : "";
          this.dstvalue = this.value ? this.value.dstvalue : "";
          this.priority = this.value ? this.value.priority : 1;
        }
      },
    },
  },
  methods: {
    select(item) {
      this.uuid = item.id;
      this.name = item.name;
    },
    submit() {
      this.$emit("change", {
        uuid: this.uuid,
        name: this.name,
        dstvalue: this.dstvalue,
        priority: this.priority,
      });
      this.show = false;
    },
  },
};
</script>
