var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-text-field',{attrs:{"label":_vm.label,"value":_vm.valueDisplay,"readonly":"","filled":"","background-color":"transparent","hide-details":""}},[_c('template',{slot:"append"},[_c('v-btn',{attrs:{"icon":"","tile":""},on:{"click":function($event){_vm.show = true}}},[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1),_c('v-dialog',{attrs:{"width":"480","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.label)+_vm._s(_vm.$vuetify.lang.t("$vuetify.key.WordSpace"))+_vm._s(_vm.$vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E8%BE%91")))]),_c('v-divider'),(_vm.editModel)?_c('v-card-text',[(_vm.tasktype == 2)?[_c('v-select',{attrs:{"label":_vm.$vuetify.lang.t(
                  '$vuetify.key.K_%E5%B7%A1%E6%A3%80%E7%B1%BB%E5%9E%8B'
                ),"items":_vm.srctypes},model:{value:(_vm.editModel.srctype),callback:function ($$v) {_vm.$set(_vm.editModel, "srctype", $$v)},expression:"editModel.srctype"}}),_c('div',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%B7%A1%E6%A3%80%E5%86%85%E5%AE%B9" ))+" ")]),_c('v-card',{staticClass:"px-3"},[_c('v-chip-group',{attrs:{"active-class":"primary--text","multiple":"","column":""},model:{value:(_vm.editModel.cps),callback:function ($$v) {_vm.$set(_vm.editModel, "cps", $$v)},expression:"editModel.cps"}},[_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]}),_c('v-chip',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%BD%93%E5%89%8D%E6%B5%8B%E7%82%B9%E5%91%8A%E8%AD%A6%E6%80%BB%E6%95%B0" )))]),_c('v-chip',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%BA%A7%E5%88%AB%E5%88%86%E7%B1%BB%E7%BB%9F%E8%AE%A1" )))]),_c('v-chip',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%B1%BB%E5%9E%8B%E5%88%86%E7%B1%BB%E7%BB%9F%E8%AE%A1" )))]),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(_vm.editModel.srctype == 1),expression:"editModel.srctype == 1"}]},[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E7%A6%81%E7%94%A8%E6%95%B0%E9%87%8F" )))]),_c('v-chip',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E7%A6%BB%E7%BA%BF%E6%95%B0%E9%87%8F" )))]),_c('v-chip',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%9C%A8%E7%BA%BF%E6%95%B0%E9%87%8F" )))])],1)],1),_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%B7%A1%E6%A3%80%E5%AF%B9%E8%B1%A1" )))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.selectorShow = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.editModel.ids,"dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)]:_vm._e(),(_vm.tasktype != 5)?[_c('zht-editor-object',{attrs:{"label":_vm.$vuetify.lang.t(
                  '$vuetify.key.K_%E7%9F%AD%E4%BF%A1%E5%8F%91%E9%80%81%E5%99%A8'
                ),"options":{ type: 'notifier' }},model:{value:(_vm.editModel.smsnotifier),callback:function ($$v) {_vm.$set(_vm.editModel, "smsnotifier", $$v)},expression:"editModel.smsnotifier"}}),_c('zht-editor-object',{attrs:{"label":_vm.$vuetify.lang.t(
                  '$vuetify.key.K_%E9%82%AE%E4%BB%B6%E5%8F%91%E9%80%81%E5%99%A8'
                ),"options":{ type: 'notifier' }},model:{value:(_vm.editModel.emailnotifier),callback:function ($$v) {_vm.$set(_vm.editModel, "emailnotifier", $$v)},expression:"editModel.emailnotifier"}}),_c('zht-editor-object',{attrs:{"label":_vm.$vuetify.lang.t(
                  '$vuetify.key.K_%E5%BE%AE%E4%BF%A1%E5%8F%91%E9%80%81%E5%99%A8'
                ),"options":{ type: 'notifier' }},model:{value:(_vm.editModel.wechatnotifier),callback:function ($$v) {_vm.$set(_vm.editModel, "wechatnotifier", $$v)},expression:"editModel.wechatnotifier"}}),_c('zht-editor-object',{attrs:{"label":_vm.$vuetify.lang.t(
                  '$vuetify.key.K_%E6%8E%A5%E6%94%B6%E7%94%A8%E6%88%B7'
                ),"options":{ type: 'person' }},model:{value:(_vm.editModel.persons),callback:function ($$v) {_vm.$set(_vm.editModel, "persons", $$v)},expression:"editModel.persons"}})]:_vm._e(),(_vm.tasktype == 5)?[_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E6%8E%A7%E5%88%B6%E6%B5%8B%E7%82%B9" )))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.selectorShow = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.editModel.ids,"dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)]:_vm._e(),(_vm.tasktype >= 5)?_c('zht-editor-text',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.key.K_%E5%86%85%E5%AE%B9')},model:{value:(_vm.editModel.content),callback:function ($$v) {_vm.$set(_vm.editModel, "content", $$v)},expression:"editModel.content"}}):_vm._e()],2):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")))]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88")))])],1)],1)],1),_c('ObjectSelector',{attrs:{"type":_vm.objectType,"single":false},on:{"add":_vm.add},model:{value:(_vm.selectorShow),callback:function ($$v) {_vm.selectorShow=$$v},expression:"selectorShow"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }