<template>
  <v-dialog :value="show" persistent max-width="480">
    <v-card>
      <v-card-title>
        <div>{{ isEdit ? $vuetify.lang.t("$vuetify.key.EditResources") : $vuetify.lang.t("$vuetify.key.CreateResources") }}</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-text-field v-model="name" :label="$vuetify.lang.t('$vuetify.key.K_%E5%90%8D%E7%A7%B0')"></v-text-field>
        <v-file-input
          v-model="file"
          :rules="rules"
          :label="$vuetify.lang.t('$vuetify.key.K_%E6%96%87%E4%BB%B6')"
          accept="image/gif, image/png, image/jpeg"
          show-size
          prepend-icon=""
        ></v-file-input>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="submit">{{ $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A") }}</v-btn>
        <v-btn text @click="close">{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import uuid from "../utils/uuid";
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  model: {
    prop: "show",
    event: "change",
  },
  props: { show: Boolean, model: Object },
  data() {
    return {
      name: "",
      file: undefined,
      rules: [
        (value) =>
          !value ||
          value.size <= 5242880 ||
          this.$vuetify.lang.t("$vuetify.key.K_%E6%96%87%E4%BB%B6%E5%A4%A7%E5%B0%8F%E5%BF%85%E9%A1%BB%E5%B0%8F%E4%BA%8E5MB"),
      ],
    };
  },
  computed: {
    isEdit() {
      return this.model.uuid !== undefined;
    },
  },
  watch: {
    show: function () {
      if (this.show) {
        this.name = this.model.name;
        this.file = undefined;
      }
    },
  },
  methods: {
    getFileURL(file) {
      let url = null;
      if (window.createObjectURL !== undefined) {
        url = window.createObjectURL(file);
      } else if (window.URL !== undefined) {
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL !== undefined) {
        url = window.webkitURL.createObjectURL(file);
      }
      return url;
    },
    getFileBlob(url) {
      return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.responseType = "blob";
        xhr.onload = function () {
          if (xhr.status === 200) {
            let blob = xhr.response;
            let fileReader = new FileReader();
            fileReader.onloadend = function (e) {
              fileReader = null;
              xhr = null;
              resolve(e.target.result);
            };
            fileReader.readAsDataURL(blob);
          }
        };
        xhr.onerror = function () {
          xhr = null;
          reject();
        };
        xhr.send();
      });
    },
    getThumbnail(url) {
      return new Promise((resolve, reject) => {
        try {
          let canvas = document.createElement("canvas");
          canvas.width = 100;
          canvas.height = 100;
          let ctx = canvas.getContext("2d");
          let img = document.createElement("img");
          img.onload = function () {
            try {
              ctx.drawImage(img, 0, 0, 100, 100);
              let base64 = canvas.toDataURL();
              img = null;
              canvas = null;
              resolve(base64);
            } catch (e) {
              reject(e);
            }
          };
          img.onerror = function () {
            reject(this.$vuetify.lang.t("$vuetify.key.K_%E5%9B%BE%E7%89%87%E5%86%85%E5%AE%B9%E5%B7%B2%E6%8D%9F%E5%9D%8F"));
          };
          img.src = url;
        } catch (e) {
          reject(e);
        }
      });
    },
    getBase64Data(base64) {
      let index = base64.indexOf("base64,");
      if (index != -1) {
        return base64.slice(index + 7);
      } else {
        return base64;
      }
    },
    async submit() {
      try {
        if (!this.name) throw this.$vuetify.lang.t("$vuetify.key.K_%E5%90%8D%E7%A7%B0%E4%B8%8D%E8%83%BD%E4%B8%BA%E7%A9%BA");
        let msg = {
          resType: 2,
          mcd: {
            operate: this.isEdit
              ? proto.OperateMode.updateOpt
              : proto.OperateMode.createOpt,
            range: "-1",
          },
          resources: [
            {
              uuid: this.isEdit ? this.model.uuid : uuid(),
              name: this.name,
              type: this.model.type,
              libtype: 1,
            },
          ],
        };
        if (!this.isEdit && !this.file) {
          client.$emit("toast", this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%AA%E9%80%89%E6%8B%A9%E6%96%87%E4%BB%B6"));
          return;
        }
        if (this.file) {
          if (this.file.size > 5242880) return;
          let url = this.getFileURL(this.file);
          let blob = await this.getFileBlob(url);
          let thumbnail = await this.getThumbnail(url);
          blob = this.getBase64Data(blob);
          thumbnail = this.getBase64Data(thumbnail);
          msg.resources[0].content = blob;
          msg.resources[0].thumbnail = thumbnail;
          msg.resources[0].type = this.file.type == "image/gif" ? 3 : 1;
        }
        client.$emit(
          "startBusy",
          "ResourceDialog.submit",
          this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E6%8F%90%E4%BA%A4%E8%B5%84%E6%BA%90%2C%E8%AF%B7%E7%A8%8D%E5%80%99")
        );
        await proto.sleep(100);
        await client.send(proto.MESSAGE_TYPE.resourceMessage, msg);
        this.$emit("update");
        this.close();
      } catch (e) {
        client.$emit("toast", e.toString());
      }
      client.$emit("endBusy", "ResourceDialog.submit");
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>
