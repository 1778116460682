<template>
  <v-text-field
    :label="label"
    :value="valueDisplay"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="show = true">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" width="480" persistent>
        <v-card>
          <v-card-title>
            <div>
              {{ label }}{{ $vuetify.lang.t("$vuetify.key.WordSpace")
              }}{{ $vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E8%BE%91") }}
            </div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-toolbar flat>
            <v-select
              :label="
                $vuetify.lang.t(
                  '$vuetify.key.K_%E5%AF%B9%E8%B1%A1%E7%B1%BB%E5%9E%8B'
                )
              "
              v-model="type"
              :items="types"
              hide-details
              outlined
              dense
              @change="items = []"
            ></v-select>
            <v-btn icon @click="selectorShow = true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-header
            :hide-default-footer="singleSelect"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon @click="remove(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">{{
              $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")
            }}</v-btn>
            <v-btn text @click="show = false">{{
              $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88")
            }}</v-btn>
          </v-card-actions>
          <ObjectSelector
            v-model="selectorShow"
            :type="objectType"
            :single="singleSelect"
            @select="select"
            @add="add"
          ></ObjectSelector>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import ObjectSelector from "./ObjectSelector.vue";
export default {
  components: {
    ObjectSelector,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: ["label", "value"],
  data() {
    return {
      show: false,

      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E5%90%8D%E7%A7%B0"),
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%93%8D%E4%BD%9C"),
          value: "actions",
          align: "end",
        },
      ],
      items: [],

      type: 1,
      types: [
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA"
          ),
          value: 1,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E5%A4%87"),
          value: 2,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%B5%8B%E7%82%B9"),
          value: 3,
        },
      ],

      selectorShow: false,
    };
  },
  computed: {
    objectType() {
      if (this.type == 1) return "agent";
      if (this.type == 2) return "device";
      return "spot";
    },
    valueDisplay() {
      let list;
      if (this.singleSelect) list = [this.value];
      else list = this.value;
      let names = [];
      for (let i in list) {
        names.push(list[i].name);
      }
      return names.join(",");
    },
    singleSelect() {
      return Object.prototype.toString.call(this.value) !== "[object Array]";
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          let list;
          if (this.singleSelect) list = [this.value];
          else list = this.value;
          if (list.length) this.type = list[0].linkType;
          this.items = JSON.parse(JSON.stringify(list));
        }
      },
    },
  },
  methods: {
    select(item) {
      this.items = [{ id: item.id, name: item.name, linkType: this.type }];
    },
    add(item) {
      for (let i in this.items) {
        if (this.items[i].id == item.id) {
          return;
        }
      }
      this.items.push({ id: item.id, name: item.name, linkType: this.type });
    },
    remove(item) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id == item.id) {
          this.items.splice(i, 1);
        }
      }
    },
    submit() {
      if (this.singleSelect) {
        if (this.items.length) {
          this.$emit("change", this.items[0]);
        } else {
          this.$emit("change", { id: "", name: "", linkType: 1 });
        }
      } else {
        this.$emit("change", this.items);
      }
      this.show = false;
    },
  },
};
</script>
