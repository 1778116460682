import client from "./client";
import proto from "./proto";

export default {
    loading: 'data:image/png;base64,',
    cache: {},
    getResource: async function (id) {
        if (this.cache[id]) {
            return this.cache[id];
        }
        try {
            let res = await client.send(proto.MESSAGE_TYPE.resourceMessage, {
                resType: 2,
                mcd: {
                    operate: proto.OperateMode.retrieveOpt,
                    range: "-1",
                },
                resources: [{ uuid: id }],
            });
            if (res.resources && res.resources.length == 1) {
                let resource = res.resources[0];
                let mime = resource.type == 3 ? "data:image/gif" : "data:image/png";
                let data = resource.content;
                let raw = window.atob(data);
                let length = raw.length;
                let array = new Uint8Array(length);
                for (let i = 0; i < length; i++) {
                    array[i] = raw.charCodeAt(i);
                }
                let blob = new Blob([array], { type: mime });
                let url = '';
                if (window.createObjectURL !== undefined) {
                    url = window.createObjectURL(blob);
                } else if (window.URL !== undefined) {
                    url = window.URL.createObjectURL(blob);
                } else if (window.webkitURL !== undefined) {
                    url = window.webkitURL.createObjectURL(blob);
                }
                this.cache[id] = url;
                return url;
            }
        } catch (error) {
            client.$emit("toast", error);
        }
        return this.loading;
    },
    clear: function () {
        this.cache = {};
    },
}
