<style scoped>
h1 {
  text-align: center;
}
</style>

<template>
  <v-row v-show="show" align="center" class="login-bg white--text">
    <v-col>
      <h1>
        {{
          $vuetify.lang.t("$vuetify.key.K_%E6%AC%A2%E8%BF%8E%E4%BD%BF%E7%94%A8")
        }}
      </h1>
      <h1>
        {{
          $vuetify.lang.t(
            "$vuetify.key.K_%E7%BB%BC%E5%90%88%E7%9B%91%E6%8E%A7%E5%B9%B3%E5%8F%B0"
          )
        }}
      </h1>
      <h1>iVisual</h1>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: { show: Boolean },
};
</script>
