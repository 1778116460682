import Vue from 'vue'
import App from './App.vue'
import vuetify from './vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './zht-ui/animation.css'
import Views from './views'
import ZhtUI from './zht-ui'
import Confirm from './components/Confirm.vue'
import NavBar from './components/NavBar.vue'
import BaiduMap from 'vue-baidu-map'

import client from './utils/client'

Vue.config.productionTip = false;
Vue.use(Views);
Vue.use(ZhtUI);
Vue.use(BaiduMap, { ak: 'TpEkwgZhxsjkIwUABpTl0A3FpbgFV9cU' });
Vue.component('v-confirm', Confirm);
Vue.component('v-navbar', NavBar);

new Vue({
  vuetify,
  render: h => h(App),
  created() {
    setTimeout(function () {
      client.init();
    }, 2000);
  },
  beforeDestroy() {
    client.cleanUp();
  },
}).$mount('#app')
