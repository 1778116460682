<template>
  <v-dialog :value="show" persistent max-width="540">
    <v-card>
      <v-card-title>{{
        $vuetify.lang.t(
          "$vuetify.key.K_%E5%BF%AB%E9%80%9F%E6%9B%BF%E6%8D%A2%E7%BB%91%E5%AE%9A%E6%BA%90"
        )
      }}</v-card-title>
      <v-card-subtitle>
        {{
          $vuetify.lang.t(
            "$vuetify.key.K_%E9%80%89%E6%8B%A9%E4%B8%80%E4%B8%AA%E8%AE%BE%E5%A4%87"
          )
        }}，{{
          $vuetify.lang.t(
            "$vuetify.key.K_%E8%87%AA%E5%8A%A8%E5%8C%B9%E9%85%8D%E6%8E%A7%E4%BB%B6%E6%B5%8B%E7%82%B9%E5%90%8D%E4%B8%8E%E8%AE%BE%E5%A4%87%E6%B5%8B%E7%82%B9%E5%90%8D"
          )
        }}，{{
          $vuetify.lang.t(
            "$vuetify.key.K_%E5%90%8D%E7%A7%B0%E7%9B%B8%E5%90%8C%E5%B9%B6%E4%B8%94%E6%BA%90%E7%B1%BB%E5%9E%8B%E4%B8%BA%E6%B5%8B%E7%82%B9%E7%9A%84%E7%BB%91%E5%AE%9A%E8%BF%9B%E8%A1%8C%E6%BA%90%E6%9B%BF%E6%8D%A2"
          )
        }}。
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-text-field
          :label="$vuetify.lang.t('$vuetify.key.K_%E8%AE%BE%E5%A4%87')"
          :value="device.nam"
          readonly
          hide-details
        >
          <template slot="append">
            <v-btn icon tile @click="deviceShow = true">
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <div>
          {{
            $vuetify.lang.t(
              "$vuetify.key.K_%E9%A2%84%E8%AE%A1%E6%9B%BF%E6%8D%A2%E7%BB%91%E5%AE%9A%EF%BC%9A"
            )
          }}
        </div>
        <v-data-table
          :headers="headers"
          :items="mayChange"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.prop`]="{ item }">
            <div v-text="propertyName(item.prop)"></div>
          </template>
          <template v-slot:[`item.srcType`]="{ item }">
            <div v-text="srcTypes[item.srcType]"></div>
          </template>
          <template v-slot:[`item.valueType`]="{ item }">
            <div v-text="valueTypes[item.valueType]"></div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="success" @click="submit">{{
          $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")
        }}</v-btn>
        <v-btn text @click="close">{{
          $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88")
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <ObjectSelector
      v-model="deviceShow"
      type="device"
      :single="true"
      @select="deviceChange"
    ></ObjectSelector>
  </v-dialog>
</template>

<script>
import ObjectSelector from "./ObjectSelector.vue";
import proto from "../utils/proto";
import client from "../utils/client";
import editors from "../zht-ui/editor";

export default {
  components: { ObjectSelector },
  model: {
    prop: "show",
    event: "change",
  },
  props: ["show", "bindings", "controls"],
  data() {
    return {
      deviceShow: false,
      device: {
        id: "",
        name: this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%9C%AA%E9%80%89%E6%8B%A9"
        ),
      },
      spots: [],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%8E%A7%E4%BB%B6"),
          value: "controlName",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E5%B1%9E%E6%80%A7"),
          value: "prop",
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%BA%90%E7%B1%BB%E5%9E%8B"
          ),
          value: "srcType",
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%95%B0%E6%8D%AE%E7%B1%BB%E5%9E%8B"
          ),
          value: "valueType",
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%BA%90%E6%95%B0%E9%87%8F"
          ),
          value: "srcCount",
        },
      ],
      srcTypes: {
        org: this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%BA%E6%9E%84"),
        agent: this.$vuetify.lang.t(
          "$vuetify.key.K_%E7%9B%91%E6%8E%A7%E5%8D%95%E5%85%83"
        ),
        device: this.$vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E5%A4%87"),
        spot: this.$vuetify.lang.t("$vuetify.key.K_%E6%B5%8B%E7%82%B9"),
        statistics: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E7%BB%9F%E8%AE%A1"
        ),
      },
      valueTypes: {
        alarm: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%8A%B6%E6%80%81"
        ),
        status: this.$vuetify.lang.t(
          "$vuetify.key.K_%E8%81%94%E6%9C%BA%E7%8A%B6%E6%80%81"
        ),
        data: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E6%95%B0%E6%8D%AE"
        ),
        history: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%8E%86%E5%8F%B2%E6%95%B0%E6%8D%AE"
        ),
        statistics: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E7%BB%9F%E8%AE%A1%E5%80%BC"
        ),
      },
    };
  },
  computed: {
    mayChange() {
      let result = [];
      for (let i in this.bindings) {
        let binding = this.bindings[i];
        if (binding.srcType == "spot") {
          for (let j in this.controls) {
            let control = this.controls[j];
            if (control.uuid == binding.uuid) {
              for (let k in this.spots) {
                let spot = this.spots[k];
                if (spot.name == control.spotName) {
                  result.push({
                    uuid: binding.uuid,
                    prop: binding.prop,
                    srcType: binding.srcType,
                    valueType: binding.valueType,
                    srcCount: binding.src.length,
                    controlName: control.name,
                    spotId: spot.uuid,
                    spotName: `${this.device.name}-${spot.name}`,
                  });
                }
              }
              break;
            }
          }
        }
      }
      return result;
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.device = {
            id: "",
            name: this.$vuetify.lang.t(
              "$vuetify.key.K_%E6%9C%AA%E9%80%89%E6%8B%A9"
            ),
          };
          this.spots = [];
        }
      },
    },
  },
  methods: {
    propertyName(prop) {
      if (editors[prop]) return this.$vuetify.lang.t(editors[prop].name);
      return this.$vuetify.lang.t(
        "$vuetify.key.K_%E6%9C%AA%E7%9F%A5%E5%B1%9E%E6%80%A7"
      );
    },
    deviceChange(model) {
      this.device = model;
      this.getSpots();
    },
    async getSpots() {
      client.$emit(
        "startBusy",
        "ReplaceBinding.getSpots",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E6%B5%8B%E7%82%B9%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.spotMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: this.device.id,
          },
          act: 0,
        });
        console.log(this.device, res.spots);
        if (res.spots && res.spots.length) {
          res.spots.sort((a, b) => {
            return a.no > b.no ? 1 : -1;
          });
          this.spots = res.spots;
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ReplaceBinding.getSpots");
    },
    submit() {
      this.$emit("submit", this.mayChange);
      this.close();
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>
