export default {
    "uuid": { name: "ID", editor: "zht-editor-text", options: { readonly: true } },
    "type": { name: "$vuetify.key.K_%E7%B1%BB%E5%9E%8B", editor: "zht-editor-text", options: { readonly: true } },
    "name": { name: "$vuetify.key.K_%E5%90%8D%E7%A7%B0", editor: "zht-editor-text" },
    "spotName": { name: "$vuetify.key.K_%E6%B5%8B%E7%82%B9%E5%90%8D%E7%A7%B0", editor: "zht-editor-text" },

    "location.x": { name: "$vuetify.key.K_%E6%B0%B4%E5%B9%B3%E5%9D%90%E6%A0%87", editor: "zht-editor-text" },
    "location.y": { name: "$vuetify.key.K_%E5%9E%82%E7%9B%B4%E5%9D%90%E6%A0%87", editor: "zht-editor-text" },

    "size.width": { name: "$vuetify.key.K_%E5%AE%BD%E5%BA%A6", editor: "zht-editor-text" },
    "size.height": { name: "$vuetify.key.K_%E9%AB%98%E5%BA%A6", editor: "zht-editor-text" },

    "style.opacity": { name: "$vuetify.key.K_%E9%80%8F%E6%98%8E%E5%BA%A6", editor: "zht-editor-text" },
    "style.box-shadow": { name: "$vuetify.key.K_%E9%98%B4%E5%BD%B1", editor: "zht-editor-shadow" },
    "style.transform": { name: "$vuetify.key.K_%E5%8F%98%E6%8D%A2", editor: "zht-editor-transform" },

    "style.padding": { name: "$vuetify.key.K_%E5%86%85%E8%BE%B9%E8%B7%9D", editor: "zht-editor-text" },
    "style.margin": { name: "$vuetify.key.K_%E5%A4%96%E8%BE%B9%E8%B7%9D", editor: "zht-editor-text" },

    "style.border-style": {
        name: "$vuetify.key.K_%E8%BE%B9%E6%A1%86%E6%A0%B7%E5%BC%8F",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E5%AE%9E%E7%BA%BF", value: "solid" },
            { text: "$vuetify.key.K_%E8%99%9A%E7%BA%BF", value: "dashed" },
            { text: "$vuetify.key.K_%E7%82%B9%E7%8A%B6", value: "dotted" },
            { text: "$vuetify.key.K_%E5%8F%8C%E7%BA%BF", value: "double" },
        ],
    },
    "style.border-width": { name: "$vuetify.key.K_%E8%BE%B9%E6%A1%86%E5%A4%A7%E5%B0%8F", editor: "zht-editor-text" },
    "style.border-color": { name: "$vuetify.key.K_%E8%BE%B9%E6%A1%86%E9%A2%9C%E8%89%B2", editor: "zht-editor-color" },
    "style.border-radius": { name: "$vuetify.key.K_%E8%BE%B9%E6%A1%86%E5%9C%86%E8%A7%92", editor: "zht-editor-text" },

    "style.color": { name: "$vuetify.key.K_%E5%89%8D%E6%99%AF%E8%89%B2", editor: "zht-editor-color" },
    "style.background-color": { name: "$vuetify.key.K_%E8%83%8C%E6%99%AF%E8%89%B2", editor: "zht-editor-color" },
    "style.background-image": { name: "$vuetify.key.K_%E8%83%8C%E6%99%AF%E5%9B%BE", editor: "zht-editor-image" },
    "style.background-repeat": {
        name: "$vuetify.key.K_%E8%83%8C%E6%99%AF%E9%87%8D%E5%A4%8D",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E4%B8%8D%E9%87%8D%E5%A4%8D", value: "no-repeat" },
            { text: "$vuetify.key.K_%E6%B0%B4%E5%B9%B3%E9%87%8D%E5%A4%8D", value: "repeat-x" },
            { text: "$vuetify.key.K_%E5%9E%82%E7%9B%B4%E9%87%8D%E5%A4%8D", value: "repeat-y" },
            { text: "$vuetify.key.K_%E9%87%8D%E5%A4%8D", value: "repeat" },
        ],
    },
    "style.background-position": {
        name: "$vuetify.key.K_%E8%83%8C%E6%99%AF%E4%BD%8D%E7%BD%AE",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E5%B1%85%E4%B8%AD", value: "center center" },
            { text: "$vuetify.key.K_%E4%B8%8A", value: "top center" },
            { text: "$vuetify.key.K_%E4%B8%8B", value: "bottom center" },
            { text: "$vuetify.key.K_%E5%B7%A6", value: "center left" },
            { text: "$vuetify.key.K_%E5%8F%B3", value: "center right" },
        ],
    },
    "style.background-size": {
        name: "$vuetify.key.K_%E8%83%8C%E6%99%AF%E5%B0%BA%E5%AF%B8",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E5%8E%9F%E5%9B%BE", value: "auto" },
            { text: "$vuetify.key.K_%E6%8B%89%E4%BC%B8", value: "100% 100%" },
            { text: "$vuetify.key.K_%E5%8C%85%E5%90%AB", value: "contain" },
            { text: "$vuetify.key.K_%E8%A6%86%E7%9B%96", value: "cover" },
        ],
    },
    "style.backdrop-filter": { name: "$vuetify.key.BackdropFilter", editor: "zht-editor-text" },

    "style.text-align": {
        name: "$vuetify.key.K_%E6%96%87%E6%9C%AC%E5%AF%B9%E9%BD%90",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E5%B7%A6%E5%AF%B9%E9%BD%90", value: "left" },
            { text: "$vuetify.key.K_%E5%8F%B3%E5%AF%B9%E9%BD%90", value: "right" },
            { text: "$vuetify.key.K_%E5%B1%85%E4%B8%AD", value: "center" },
            { text: "$vuetify.key.K_%E4%B8%A4%E7%AB%AF%E5%AF%B9%E9%BD%90", value: "justify" },
        ],
    },

    "style.font-style": {
        name: "$vuetify.key.K_%E5%AD%97%E4%BD%93%E6%A0%B7%E5%BC%8F",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E9%BB%98%E8%AE%A4", value: "normal" },
            { text: "$vuetify.key.K_%E6%96%9C%E4%BD%93", value: "italic" },
            { text: "$vuetify.key.K_%E5%80%BE%E6%96%9C", value: "oblique" },
        ],
    },
    "style.font-size": { name: "$vuetify.key.K_%E5%AD%97%E4%BD%93%E5%A4%A7%E5%B0%8F", editor: "zht-editor-text" },
    "style.font-weight": {
        name: "$vuetify.key.K_%E5%AD%97%E4%BD%93%E7%B2%97%E7%BB%86",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E9%BB%98%E8%AE%A4", value: "normal" },
            { text: "$vuetify.key.K_%E7%B2%97%E4%BD%93", value: "bold" },
            { text: "$vuetify.key.K_%E5%8A%A0%E7%B2%97", value: "bolder" },
            { text: "$vuetify.key.K_%E5%8A%A0%E7%BB%86", value: "lighter" },
        ],
    },
    "style.line-height": { name: "$vuetify.key.LineHeight", editor: "zht-editor-text" },
    "style.animation-name": {
        name: "$vuetify.key.K_%E5%8A%A8%E7%94%BB%E7%89%B9%E6%95%88",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E6%97%A0", value: "" },
            { text: "$vuetify.key.K_%E8%B7%AF%E5%BE%84%E5%8A%A8%E7%94%BB", value: "path-animate" },
            { text: "$vuetify.key.K_%E9%A1%BA%E6%97%B6%E9%92%88%E6%97%8B%E8%BD%AC", value: "spin" },
            { text: "$vuetify.key.K_%E9%80%86%E6%97%B6%E9%92%88%E6%97%8B%E8%BD%AC", value: "spin-reverse" },
            { text: "$vuetify.key.K_%E9%97%AA%E7%83%811", value: "twinkle" },
            { text: "$vuetify.key.K_%E9%97%AA%E7%83%812", value: "twinkle-reverse" },
            { text: "$vuetify.key.K_%E6%B0%B4%E5%B9%B3%E6%99%83%E5%8A%A8", value: "shake-horizon" },
            { text: "$vuetify.key.K_%E5%9E%82%E7%9B%B4%E6%99%83%E5%8A%A8", value: "shake-vertical" },
            { text: "$vuetify.key.K_%E5%B7%A6%E7%A7%BB", value: "move-left" },
            { text: "$vuetify.key.K_%E5%8F%B3%E7%A7%BB", value: "move-right" },
            { text: "$vuetify.key.K_%E4%B8%8A%E7%A7%BB", value: "move-top" },
            { text: "$vuetify.key.K_%E4%B8%8B%E7%A7%BB", value: "move-bottom" },
            { text: "$vuetify.key.K_%E5%B7%A6%E4%B8%8A%E7%A7%BB", value: "move-left-top" },
            { text: "$vuetify.key.K_%E5%8F%B3%E4%B8%8A%E7%A7%BB", value: "move-right-top" },
            { text: "$vuetify.key.K_%E5%8F%B3%E4%B8%8B%E7%A7%BB", value: "move-right-bottom" },
            { text: "$vuetify.key.K_%E5%B7%A6%E4%B8%8B%E7%A7%BB", value: "move-left-bottom" },
        ],
    },
    "style.offset-path": { name: "$vuetify.key.K_%E5%8A%A8%E7%94%BB%E8%B7%AF%E5%BE%84", editor: "zht-editor-textarea" },
    "style.animation-duration": { name: "$vuetify.key.K_%E5%8A%A8%E7%94%BB%E6%97%B6%E9%95%BF", editor: "zht-editor-text" },
    "style.animation-delay": { name: "$vuetify.key.K_%E5%8A%A8%E7%94%BB%E5%BB%B6%E8%BF%9F", editor: "zht-editor-text" },
    "style.animation-timing-function": {
        name: "$vuetify.key.K_%E5%8A%A8%E7%94%BB%E9%80%9F%E5%BA%A6%E6%9B%B2%E7%BA%BF",
        editor: "zht-editor-enum",
        options: [
            { text: "linear", value: "linear" },
            { text: "ease", value: "ease" },
            { text: "ease-in", value: "ease-in" },
            { text: "ease-out", value: "ease-out" },
            { text: "ease-in-out", value: "ease-in-out" },
        ],
    },
    "style.animation-direction": {
        name: "$vuetify.key.K_%E5%8A%A8%E7%94%BB%E9%87%8D%E5%A4%8D%E6%96%B9%E5%90%91",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E6%AD%A3%E5%90%91", value: "normal" },
            { text: "$vuetify.key.K_%E6%AD%A3%E5%8F%8D%E5%90%91", value: "alternate" },
        ],
    },

    "config.text": { name: "$vuetify.key.K_%E5%86%85%E5%AE%B9", editor: "zht-editor-text" },
    "config.selected": { name: "$vuetify.key.K_%E9%80%89%E9%A1%B9%E5%80%BC", editor: "zht-editor-text" },
    "config.options": { name: "$vuetify.key.K_%E9%80%89%E9%A1%B9", editor: "zht-editor-options" },
    "config.map": {
        name: "$vuetify.key.K_%E8%A1%8C%E6%94%BF%E5%8C%BA%E5%88%92",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E5%AE%89%E5%BE%BD", value: "anhui" },
            { text: "$vuetify.key.K_%E6%BE%B3%E9%97%A8", value: "aomen" },
            { text: "$vuetify.key.K_%E5%8C%97%E4%BA%AC", value: "beijing" },
            { text: "$vuetify.key.K_%E9%87%8D%E5%BA%86", value: "chongqing" },
            { text: "$vuetify.key.K_%E7%A6%8F%E5%BB%BA", value: "fujian" },
            { text: "$vuetify.key.K_%E7%94%98%E8%82%83", value: "gansu" },
            { text: "$vuetify.key.K_%E5%B9%BF%E4%B8%9C", value: "guangdong" },
            { text: "$vuetify.key.K_%E5%B9%BF%E8%A5%BF", value: "guangxi" },
            { text: "$vuetify.key.K_%E8%B4%B5%E5%B7%9E", value: "guizhou" },
            { text: "$vuetify.key.K_%E6%B5%B7%E5%8D%97", value: "hainan" },
            { text: "$vuetify.key.K_%E6%B2%B3%E5%8C%97", value: "hebei" },
            { text: "$vuetify.key.K_%E9%BB%91%E9%BE%99%E6%B1%9F", value: "heilongjiang" },
            { text: "$vuetify.key.K_%E6%B2%B3%E5%8D%97", value: "henan" },
            { text: "$vuetify.key.K_%E6%B9%96%E5%8C%97", value: "hubei" },
            { text: "$vuetify.key.K_%E6%B9%96%E5%8D%97", value: "hunan" },
            { text: "$vuetify.key.K_%E6%B1%9F%E8%8B%8F", value: "jiangsu" },
            { text: "$vuetify.key.K_%E6%B1%9F%E8%A5%BF", value: "jiangxi" },
            { text: "$vuetify.key.K_%E5%90%89%E6%9E%97", value: "jilin" },
            { text: "$vuetify.key.K_%E8%BE%BD%E5%AE%81", value: "liaoning" },
            { text: "$vuetify.key.K_%E5%86%85%E8%92%99%E5%8F%A4", value: "neimenggu" },
            { text: "$vuetify.key.K_%E5%AE%81%E5%A4%8F", value: "ningxia" },
            { text: "$vuetify.key.K_%E9%9D%92%E6%B5%B7", value: "qinghai" },
            { text: "$vuetify.key.K_%E5%B1%B1%E4%B8%9C", value: "shandong" },
            { text: "$vuetify.key.K_%E4%B8%8A%E6%B5%B7", value: "shanghai" },
            { text: "$vuetify.key.K_%E5%B1%B1%E8%A5%BF", value: "shanxi" },
            { text: "$vuetify.key.K_%E9%99%95%E8%A5%BF", value: "shanxi1" },
            { text: "$vuetify.key.K_%E5%9B%9B%E5%B7%9D", value: "sichuan" },
            { text: "$vuetify.key.K_%E5%8F%B0%E6%B9%BE", value: "taiwan" },
            { text: "$vuetify.key.K_%E5%A4%A9%E6%B4%A5", value: "tianjin" },
            { text: "$vuetify.key.K_%E9%A6%99%E6%B8%AF", value: "xianggang" },
            { text: "$vuetify.key.K_%E6%96%B0%E7%96%86", value: "xinjiang" },
            { text: "$vuetify.key.K_%E8%A5%BF%E8%97%8F", value: "xizang" },
            { text: "$vuetify.key.K_%E4%BA%91%E5%8D%97", value: "yunnan" },
            { text: "$vuetify.key.K_%E6%B5%99%E6%B1%9F", value: "zhejiang" },
            { text: "$vuetify.key.K_%E5%85%B6%E4%BB%96", value: "other" },
        ],
    },
    "config.mapJson": { name: "$vuetify.key.K_%E5%9C%B0%E5%9B%BE%E6%95%B0%E6%8D%AE", editor: "zht-editor-text" },
    "config.stateData": { name: "$vuetify.key.K_%E8%81%94%E6%9C%BA%E7%8A%B6%E6%80%81%E6%95%B0%E6%8D%AE", editor: "zht-editor-text" },
    "config.alarmData": { name: "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%8A%B6%E6%80%81%E6%95%B0%E6%8D%AE", editor: "zht-editor-text" },
    "config.legend": {
        name: "$vuetify.key.K_%E5%9B%BE%E4%BE%8B%E4%BD%8D%E7%BD%AE",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E5%B7%A6%E4%B8%8A", value: "left-top" },
            { text: "$vuetify.key.K_%E5%8F%B3%E4%B8%8A", value: "right-top" },
            { text: "$vuetify.key.K_%E5%B7%A6%E4%B8%8B", value: "left-bottom" },
            { text: "$vuetify.key.K_%E5%8F%B3%E4%B8%8B", value: "right-bottom" },
            { text: "$vuetify.key.K_%E9%9A%90%E8%97%8F", value: "hidden" },
        ],
    },
    "config.xData": { name: "$vuetify.key.K_%E6%A8%AA%E8%BD%B4%E6%95%B0%E6%8D%AE", editor: "zht-editor-text" },
    "config.yData": { name: "$vuetify.key.K_%E7%BA%B5%E8%BD%B4%E6%95%B0%E6%8D%AE", editor: "zht-editor-text" },
    "config.pieRadius": { name: "$vuetify.key.K_%E9%A5%BC%E5%9B%BE%E5%8D%8A%E5%BE%84", editor: "zht-editor-text" },
    "config.min": { name: "$vuetify.key.K_%E6%9C%80%E5%B0%8F%E5%80%BC", editor: "zht-editor-text" },
    "config.max": { name: "$vuetify.key.K_%E6%9C%80%E5%A4%A7%E5%80%BC", editor: "zht-editor-text" },
    "config.startAngle": { name: "$vuetify.key.K_%E8%B5%B7%E5%A7%8B%E8%A7%92%E5%BA%A6", editor: "zht-editor-text" },
    "config.endAngle": { name: "$vuetify.key.K_%E7%BB%93%E6%9D%9F%E8%A7%92%E5%BA%A6", editor: "zht-editor-text" },
    "config.value": { name: "$vuetify.key.K_%E5%80%BC", editor: "zht-editor-text" },
    "config.unit": { name: "$vuetify.key.K_%E5%8D%95%E4%BD%8D", editor: "zht-editor-text" },
    "config.rtsp": { name: "$vuetify.key.K_%E8%A7%86%E9%A2%91%E5%9C%B0%E5%9D%80", editor: "zht-editor-rtsp" },
    "config.pageId": { name: "$vuetify.key.K_%E9%A1%B5%E9%9D%A2", editor: "zht-editor-page" },
    "config.tabs": { name: "$vuetify.key.K_%E5%AF%BC%E8%88%AA%E9%A1%B9", editor: "zht-editor-tabs" },
    "config.pageContainer": { name: "$vuetify.key.K_%E5%AD%90%E9%A1%B5%E6%B5%8F%E8%A7%88%E5%99%A8ID", editor: "zht-editor-text" },
    "config.mapPoint": { name: "$vuetify.key.K_%E5%9C%B0%E5%9B%BE%E5%9D%90%E6%A0%87%E7%82%B9", editor: "zht-editor-mappoint" },
    "config.mapCenter": { name: "$vuetify.key.K_%E5%9C%B0%E5%9B%BE%E4%B8%AD%E5%BF%83", editor: "zht-editor-lnglat" },
    "config.mapZoom": { name: "$vuetify.key.K_%E5%9C%B0%E5%9B%BE%E7%BC%A9%E6%94%BE%E7%BA%A7%E5%88%AB", editor: "zht-editor-text" },
    "config.gaugeRadius": { name: "$vuetify.key.K_%E4%BB%AA%E8%A1%A8%E7%9B%B4%E5%BE%84", editor: "zht-editor-text" },
    "config.gaugeCenter": { name: "$vuetify.key.K_%E4%BB%AA%E8%A1%A8%E4%B8%AD%E5%BF%83", editor: "zht-editor-text" },
    "config.splitNumber": { name: "$vuetify.key.K_%E5%88%BB%E5%BA%A6%E5%88%86%E6%AE%B5", editor: "zht-editor-text" },

    "config.theme": {
        name: "$vuetify.key.K_%E9%A3%8E%E6%A0%BC",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E6%98%8E%E4%BA%AE", value: "light" },
            { text: "$vuetify.key.K_%E9%BB%91%E6%9A%97", value: "dark" },
        ],
    },
    "config.direction": {
        name: "$vuetify.key.K_%E6%96%B9%E5%90%91",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E6%B0%B4%E5%B9%B3", value: "horizontal" },
            { text: "$vuetify.key.K_%E5%9E%82%E7%9B%B4", value: "vertical" },
        ],
    },
    "config.host": { name: "$vuetify.key.K_IP%E5%9C%B0%E5%9D%80", editor: "zht-editor-text" },
    "config.port": { name: "$vuetify.key.K_IP%E7%AB%AF%E5%8F%A3", editor: "zht-editor-text" },
    "config.account": { name: "$vuetify.key.K_%E7%94%A8%E6%88%B7%E5%90%8D", editor: "zht-editor-text" },
    "config.password": { name: "$vuetify.key.K_%E5%AF%86%E7%A0%81", editor: "zht-editor-password" },
    "config.manufacturer": {
        name: "$vuetify.key.K_%E8%A7%86%E9%A2%91%E5%8E%82%E5%95%86",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E6%B5%B7%E5%BA%B7", value: "1" },
        ],
    },
    "config.icon": { name: "$vuetify.key.K_%E5%9B%BE%E6%A0%87", editor: "zht-editor-icon" },
    "config.csv": { name: "$vuetify.key.K_CSV%E6%95%B0%E6%8D%AE", editor: "zht-editor-textarea" },
    "config.points": { name: "$vuetify.key.K_%E7%82%B9%E5%9D%90%E6%A0%87", editor: "zht-editor-textarea" },
    "config.pointsData": { name: "$vuetify.key.K_%E7%82%B9%E6%95%B0%E6%8D%AE", editor: "zht-editor-text" },
    "config.dense": {
        name: "$vuetify.key.K_%E9%97%B4%E8%B7%9D",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E5%AE%BD%E6%9D%BE", value: false },
            { text: "$vuetify.key.K_%E7%B4%A7%E5%87%91", value: true },
        ],
    },
    "config.chartOption": { name: "$vuetify.key.K_%E5%9B%BE%E8%A1%A8%E9%80%89%E9%A1%B9", editor: "zht-editor-textarea" },
    "config.deviceId": { name: "$vuetify.key.K_%E8%AE%BE%E5%A4%87ID", editor: "zht-editor-device" },
    "config.spotNumbers": { name: "$vuetify.key.K_%E6%B5%8B%E7%82%B9%E7%BC%96%E5%8F%B7", editor: "zht-editor-text" },
    "config.cols": {
        name: "$vuetify.key.K_%E5%88%97%E6%95%B0",
        editor: "zht-editor-enum",
        options: [
            { text: "1", value: 12 },
            { text: "2", value: 6 },
            { text: "3", value: 4 },
            { text: "4", value: 3 },
            { text: "6", value: 2 },
            { text: "12", value: 1 },
        ],
    },
    "config.alarmColor": { name: "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E5%89%8D%E6%99%AF%E8%89%B2", editor: "zht-editor-color" },
    "config.alarmBorderColor": { name: "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E8%BE%B9%E6%A1%86%E8%89%B2", editor: "zht-editor-color" },
    "config.alarmBackgroundColor": { name: "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E8%83%8C%E6%99%AF%E8%89%B2", editor: "zht-editor-color" },
    "config.alarmBoxShadow": { name: "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E9%98%B4%E5%BD%B1", editor: "zht-editor-shadow" },
    "config.lines": {
        name: "$vuetify.key.K_%E8%A1%8C%E6%95%B0",
        editor: "zht-editor-enum",
        options: [
            { text: "$vuetify.key.K_%E5%8D%95%E8%A1%8C", value: "single" },
            { text: "$vuetify.key.K_%E5%A4%9A%E8%A1%8C", value: "multi" },
        ],
    },
    "config.url": { name: "$vuetify.key.K_%E5%9C%B0%E5%9D%80", editor: "zht-editor-text" },
    "config.svg": { name: "SVG", editor: "zht-editor-textarea" },
}