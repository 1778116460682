import { en, zhHans } from 'vuetify/lib/locale'

const dict = {
    'WordSpace': { en: ' ', zhHans: '' },
    'IMCP': { en: 'Integrated Monitor Control Platform', zhHans: 'IMCP综合监控平台' },
    'BindingSourceTips1': { en: '', zhHans: '脚本传入参数为数组values，返回参数为绑定属性值。' },
    'BindingSourceTips2': { en: '', zhHans: '其中values结构如下：' },
    'BindingSourceTips3': { en: '', zhHans: '实时数据 values = ["测点1值","测点2值","测点3值"]' },
    'BindingSourceTips4': { en: '', zhHans: '告警状态 values = [1,2,3,4,5,6,7,8,9,10,11,12] 对应ALARM_TYPE。其中7,8为正常,其余为各类型告警' },
    'BindingSourceTips5': { en: '', zhHans: '联机状态 values = [0,1,2] 对应STATUS_VALUE。其中0为离线,1为在线,2为禁用' },
    'BindingSourceTips6': { en: '', zhHans: '历史数据 values = [[{time:"2020-02-02 02:02:02",value:"1"},{time:"2020-02-02 02:02:03",value:"2"}]]。注意2层数据，第1层为测点数组，第2层为历史数据数组' },
    'BindingSourceTips7': { en: '', zhHans: '实时统计 values = [{s1:0,s2:0,s3:0,s4:0,s5:0,s6:0,s7:0,s8:0,s9:0,s10:0,s11:0,s12:0}]。对应StatisticalType s1:监控主机总数 s2:监控主机在线数 s3:监控主机离线数 s4:监控主机禁用数 s5:监控主机告警数 s6:当前告警数 s7:未确认事件数 s8:未确认告警数 s9:设备总数 s10:设备在线数 s11:设备离线数 s12:设备告警数' },
    'startDate': { en: 'Start Date', zhHans: '开始日期' },
    'date': { en: 'Date', zhHans: '日期' },
    'SuggestLandscape': { en: 'Suggest Viewing In Landscape Mmode', zhHans: '建议使用横屏方式查看' },
    'SuggestPortrait': { en: 'Suggest Viewing In Portrait Mode', zhHans: '建议使用竖屏方式查看' },
    'EditResources': { en: 'Edit Resources', zhHans: '编辑资源' },
    'CreateResources': { en: 'Create Resources', zhHans: '新建资源' },
    'EditUser': { en: 'Edit User', zhHans: '编辑用户' },
    'CreateUser': { en: 'Create User', zhHans: '新建用户' },
    'Edit': { en: 'Edit', zhHans: '编辑' },
    'Create': { en: 'Create', zhHans: '新建' },
    'BackdropFilter': { en: 'Backdrop Filter', zhHans: '背景滤镜' },
    'LineHeight': { en: 'Line Height', zhHans: '行高' },
    'OpenNewTab': { en: 'Open New Tab', zhHans: '打开新标签' },
    'BrowserNotSupported': { en: 'Browser Not Supported', zhHans: '浏览器不允许打开新标签' },
    'Started': { en: 'Started', zhHans: '已启动' },
    'Stopped': { en: 'Stopped', zhHans: '已停止' },
    // ----------------------------------------
    "K_%E5%8F%91%E7%8E%B0%E6%96%B0%E7%89%88%E6%9C%AC%EF%BC%8C%E8%AF%B7%E5%88%B7%E6%96%B0%E6%AD%A4%E9%A1%B5%E9%9D%A2%E4%BB%A5%E8%8E%B7%E5%8F%96%E6%9C%80%E6%96%B0%E4%BD%93%E9%AA%8C%E3%80%82": {
        "en": "Found A New Version, Please Refresh This Page For The Latest Experience.",
        "zhHans": "发现新版本，请刷新此页面以获取最新体验。"
    },
    "K_%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF": {
        "en": "User Information",
        "zhHans": "用户信息"
    },
    "K_%E7%99%BB%E5%87%BA": {
        "en": "Logout",
        "zhHans": "登出"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%BF%9E%E6%8E%A5%EF%BC%8C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Connecting, Please Wait...",
        "zhHans": "正在连接，请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E9%87%8D%E8%BF%9E%EF%BC%8C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Reconnecting, Please Wait...",
        "zhHans": "正在重连，请稍候..."
    },
    "K_%E5%8F%96%E6%B6%88": {
        "en": "Cancel",
        "zhHans": "取消"
    },
    "K_%E8%BF%94%E5%9B%9E%E7%99%BB%E5%BD%95": {
        "en": "Return To Login",
        "zhHans": "返回登录"
    },
    "K_%E6%98%AF%E5%90%A6%E9%80%80%E5%87%BA%E7%94%A8%E6%88%B7%3F": {
        "en": "Sure To Logout?",
        "zhHans": "是否退出用户?"
    },
    "K_%E6%9C%AA%E6%89%BE%E5%88%B0%E5%B7%A5%E7%A8%8B%EF%BC%8C%E8%AF%B7%E4%BB%8EiShow%E4%B8%AD%E9%85%8D%E7%BD%AE%E5%B7%A5%E7%A8%8B": {
        "en": "Project Not Found, Please Configure The Project From IShow",
        "zhHans": "未找到工程，请从iShow中配置工程"
    },
    "K_%E9%A1%B5%E9%9D%A2%E7%9B%91%E6%8E%A7": {
        "en": "Page Monitor",
        "zhHans": "页面监控"
    },
    "K_%E6%9C%AA%E7%9F%A5%E9%94%99%E8%AF%AF": {
        "en": "Unknown Error",
        "zhHans": "未知错误"
    },
    "K_%E5%8A%A8%E4%BD%9C%E7%BC%96%E8%BE%91": {
        "en": "Action Edit",
        "zhHans": "动作编辑"
    },
    "K_%E5%8A%A8%E4%BD%9C%E7%B1%BB%E5%9E%8B": {
        "en": "Action Type",
        "zhHans": "动作类型"
    },
    "K_%E9%A1%B5%E9%9D%A2": {
        "en": "Page",
        "zhHans": "页面"
    },
    "K_%E6%B5%8B%E7%82%B9": {
        "en": "Spot",
        "zhHans": "测点"
    },
    "K_%E5%80%BC%E6%9D%A5%E6%BA%90": {
        "en": "Value Source",
        "zhHans": "值来源"
    },
    "K_%E6%8E%A7%E4%BB%B6": {
        "en": "Control",
        "zhHans": "控件"
    },
    "K_%E5%B1%9E%E6%80%A7": {
        "en": "Property",
        "zhHans": "属性"
    },
    "K_%E5%80%BC": {
        "en": "Value",
        "zhHans": "值"
    },
    "K_%E7%A1%AE%E5%AE%9A": {
        "en": "OK",
        "zhHans": "确定"
    },
    "K_%E8%AE%BE%E7%BD%AE%E6%8E%A7%E4%BB%B6%E5%B1%9E%E6%80%A7": {
        "en": "Set Control Properties",
        "zhHans": "设置控件属性"
    },
    "K_%E8%AE%BE%E7%BD%AE%E6%B5%8B%E7%82%B9%E5%80%BC": {
        "en": "Set Spot Value",
        "zhHans": "设置测点值"
    },
    "K_%E9%A1%B5%E9%9D%A2%E8%B7%B3%E8%BD%AC": {
        "en": "Page Jump",
        "zhHans": "页面跳转"
    },
    "K_%E6%8E%A7%E4%BB%B6%E5%B1%9E%E6%80%A7": {
        "en": "Control Properties",
        "zhHans": "控件属性"
    },
    "K_%E5%B8%B8%E9%87%8F": {
        "en": "Constant",
        "zhHans": "常量"
    },
    "K_%E6%B7%BB%E5%8A%A0%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA": {
        "en": "Add Agent",
        "zhHans": "添加监控主机"
    },
    "K_%E5%90%8D%E7%A7%B0": {
        "en": "Name",
        "zhHans": "名称"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Agent, Please Wait...",
        "zhHans": "正在获取监控主机,请稍候..."
    },
    "K_%E6%B7%BB%E5%8A%A0%E7%BB%91%E5%AE%9A": {
        "en": "Add Binding",
        "zhHans": "添加绑定"
    },
    "K_%E6%BA%90%E7%B1%BB%E5%9E%8B": {
        "en": "Source Type",
        "zhHans": "源类型"
    },
    "K_%E6%95%B0%E6%8D%AE%E7%B1%BB%E5%9E%8B": {
        "en": "Data Type",
        "zhHans": "数据类型"
    },
    "K_%E6%9C%BA%E6%9E%84": {
        "en": "Organization",
        "zhHans": "机构"
    },
    "K_%E7%9B%91%E6%8E%A7%E5%8D%95%E5%85%83": {
        "en": "Agent",
        "zhHans": "监控单元"
    },
    "K_%E8%AE%BE%E5%A4%87": {
        "en": "Device",
        "zhHans": "设备"
    },
    "K_%E5%AE%9E%E6%97%B6%E7%BB%9F%E8%AE%A1": {
        "en": "Real-time Statistics",
        "zhHans": "实时统计"
    },
    "K_%E5%91%8A%E8%AD%A6%E7%8A%B6%E6%80%81": {
        "en": "Alarm Status",
        "zhHans": "告警状态"
    },
    "K_%E8%81%94%E6%9C%BA%E7%8A%B6%E6%80%81": {
        "en": "Online Status",
        "zhHans": "联机状态"
    },
    "K_%E5%AE%9E%E6%97%B6%E6%95%B0%E6%8D%AE": {
        "en": "Real Time Data",
        "zhHans": "实时数据"
    },
    "K_%E5%8E%86%E5%8F%B2%E6%95%B0%E6%8D%AE": {
        "en": "Historical Data",
        "zhHans": "历史数据"
    },
    "K_%E5%AE%9E%E6%97%B6%E7%BB%9F%E8%AE%A1%E5%80%BC": {
        "en": "Real-time Statistics",
        "zhHans": "实时统计值"
    },
    "K_%E5%AE%A2%E6%88%B7%E7%AB%AF%E8%84%9A%E6%9C%AC": {
        "en": "Client Script",
        "zhHans": "客户端脚本"
    },
    "K_%E9%85%8D%E7%BD%AE%E4%B8%8D%E5%AE%8C%E6%95%B4": {
        "en": "Incomplete Configuration",
        "zhHans": "配置不完整"
    },
    "K_%E6%B7%BB%E5%8A%A0%E8%AE%BE%E5%A4%87": {
        "en": "Add Device",
        "zhHans": "添加设备"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E8%AE%BE%E5%A4%87%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Device, Please Wait...",
        "zhHans": "正在获取设备,请稍候..."
    },
    "K_%E6%B7%BB%E5%8A%A0%E9%A1%B5%E9%9D%A2": {
        "en": "Add Page",
        "zhHans": "添加页面"
    },
    "K_%E9%A1%B5%E9%9D%A2%E5%90%8D%E7%A7%B0": {
        "en": "Page Name",
        "zhHans": "页面名称"
    },
    "K_%E8%AF%B7%E8%BE%93%E5%85%A5%E5%90%8D%E7%A7%B0": {
        "en": "Please Enter Name",
        "zhHans": "请输入名称"
    },
    "K_%E5%91%8A%E8%AD%A6%E6%8F%8F%E8%BF%B0": {
        "en": "Alarm Description",
        "zhHans": "告警描述"
    },
    "K_%E5%B7%B2%E9%85%8D%E7%BD%AE": {
        "en": "Configured",
        "zhHans": "已配置"
    },
    "K_%E6%9C%AA%E9%85%8D%E7%BD%AE": {
        "en": "Not Configured",
        "zhHans": "未配置"
    },
    "K_%E6%81%A2%E5%A4%8D%E6%8F%8F%E8%BF%B0": {
        "en": "Recovery Description",
        "zhHans": "恢复描述"
    },
    "K_%E8%B6%85%E4%B8%8A%E9%99%90": {
        "en": "Over Upper Limit",
        "zhHans": "超上限"
    },
    "K_%E8%B6%85%E4%B8%8B%E9%99%90": {
        "en": "Over Lower Limit",
        "zhHans": "超下限"
    },
    "K_%E4%B8%B4%E4%B8%8A%E9%99%90": {
        "en": "Near Lower Limit",
        "zhHans": "临上限"
    },
    "K_%E4%B8%B4%E4%B8%8B%E9%99%90": {
        "en": "Near Lower Limit",
        "zhHans": "临下限"
    },
    "K_%E7%8A%B6%E6%80%81%E5%8F%98%E5%8C%96": {
        "en": "Status Change",
        "zhHans": "状态变化"
    },
    "K_%E7%A6%BB%E7%BA%BF": {
        "en": "Offline",
        "zhHans": "离线"
    },
    "K_%E6%9E%81%E4%B8%8A%E9%99%90": {
        "en": "Extreme Upper Limit",
        "zhHans": "极上限"
    },
    "K_%E6%9E%81%E4%B8%8B%E9%99%90": {
        "en": "Extreme Lower Limit",
        "zhHans": "极下限"
    },
    "K_%E5%91%8A%E8%AD%A6%E5%88%97%E8%A1%A8": {
        "en": "Alarm List",
        "zhHans": "告警列表"
    },
    "K_%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA": {
        "en": "Agent",
        "zhHans": "监控主机"
    },
    "K_%E5%91%8A%E8%AD%A6%E6%97%B6%E9%97%B4": {
        "en": "Alarm Time",
        "zhHans": "告警时间"
    },
    "K_%E5%AF%B9%E8%B1%A1%E5%90%8D%E7%A7%B0": {
        "en": "Object Name",
        "zhHans": "对象名称"
    },
    "K_%E5%AF%B9%E8%B1%A1%E7%B1%BB%E5%9E%8B": {
        "en": "Object Type",
        "zhHans": "对象类型"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E6%95%B0%E6%8D%AE%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Data, Please Wait...",
        "zhHans": "正在获取数据,请稍候..."
    },
    "K_%E7%BC%96%E8%BE%91%E7%BB%91%E5%AE%9A": {
        "en": "Edit Binding",
        "zhHans": "编辑绑定"
    },
    "K_%E7%BB%91%E5%AE%9A%E6%BA%90": {
        "en": "Binding Source",
        "zhHans": "绑定源"
    },
    "K_%E6%89%A7%E8%A1%8C%E9%97%B4%E9%9A%94(%E5%8D%95%E4%BD%8D%EF%BC%9A%E7%A7%92%EF%BC%8C%E8%87%B3%E5%B0%91%E9%97%B4%E9%9A%945%E7%A7%92)": {
        "en": "Execution Interval (unit: Seconds, At Least 5 Seconds)",
        "zhHans": "执行间隔(单位：秒，至少间隔5秒)"
    },
    "K_API%E5%8F%82%E6%95%B0": {
        "en": "API Parameters",
        "zhHans": "API参数"
    },
    "K_%E5%8E%86%E5%8F%B2%E6%95%B0%E6%8D%AE%E6%97%B6%E9%97%B4": {
        "en": "Historical Data Time",
        "zhHans": "历史数据时间"
    },
    "K_%E5%8E%86%E5%8F%B2%E6%95%B0%E6%8D%AE%E5%80%BC": {
        "en": "Historical Data Value",
        "zhHans": "历史数据值"
    },
    "K_%E6%95%B0%E6%8D%AE%E8%BD%AC%E6%8D%A2%E8%84%9A%E6%9C%AC": {
        "en": "Data Conversion Script",
        "zhHans": "数据转换脚本"
    },
    "K_%E6%93%8D%E4%BD%9C": {
        "en": "Operate",
        "zhHans": "操作"
    },
    "K_%E5%B7%B2%E5%88%A0%E9%99%A4": {
        "en": "Deleted",
        "zhHans": "已删除"
    },
    "K_%E6%9C%AA%E7%9F%A5%E5%B1%9E%E6%80%A7": {
        "en": "Unknown Attribute",
        "zhHans": "未知属性"
    },
    "K_%E4%BF%AE%E6%94%B9%E5%AF%86%E7%A0%81": {
        "en": "Change Password",
        "zhHans": "修改密码"
    },
    "K_%E5%AF%86%E7%A0%81": {
        "en": "Password",
        "zhHans": "密码"
    },
    "K_%E7%A1%AE%E8%AE%A4%E5%AF%86%E7%A0%81": {
        "en": "Confirm Password",
        "zhHans": "确认密码"
    },
    "K_%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF%E6%9C%89%E8%AF%AF": {
        "en": "User Information Is Wrong",
        "zhHans": "用户信息有误"
    },
    "K_%E5%AF%86%E7%A0%81%E4%B8%8D%E8%83%BD%E4%B8%BA%E7%A9%BA": {
        "en": "Password Cannot Be Empty",
        "zhHans": "密码不能为空"
    },
    "K_%E5%AF%86%E7%A0%81%E4%B8%8D%E4%B8%80%E8%87%B4": {
        "en": "Passwords Are Inconsistent",
        "zhHans": "密码不一致"
    },
    "K_%E6%AD%A3%E5%9C%A8%E4%BF%AE%E6%94%B9%E5%AF%86%E7%A0%81%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Changing Password, Please Wait...",
        "zhHans": "正在修改密码,请稍候..."
    },
    "K_%E6%9C%AA%E6%89%BE%E5%88%B0%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF": {
        "en": "User Information Not Found",
        "zhHans": "未找到用户信息"
    },
    "K_%E6%93%8D%E4%BD%9C%E7%A1%AE%E8%AE%A4": {
        "en": "Operation Confirmation",
        "zhHans": "操作确认"
    },
    "K_": {
        "en": "!!",
        "zhHans": ""
    },
    "K_%E7%BC%96%E8%BE%91": {
        "en": "Edit",
        "zhHans": "编辑"
    },
    "K_%E8%81%94%E5%8A%A8%E7%9B%AE%E6%A0%87": {
        "en": "Linkage Target",
        "zhHans": "联动目标"
    },
    "K_%E6%8E%A7%E5%88%B6%E5%80%BC": {
        "en": "Control Value",
        "zhHans": "控制值"
    },
    "K_%E4%BC%98%E5%85%88%E7%BA%A7": {
        "en": "Priority",
        "zhHans": "优先级"
    },
    "K_%E6%A8%A1%E6%9D%BF": {
        "en": "Template",
        "zhHans": "模板"
    },
    "K_%E8%84%9A%E6%9C%AC%E7%BC%96%E8%BE%91": {
        "en": "Script Edit",
        "zhHans": "脚本编辑"
    },
    "K_%E5%80%BC%E7%B1%BB%E5%9E%8B": {
        "en": "Value Type",
        "zhHans": "值类型"
    },
    "K_%E6%9C%8D%E5%8A%A1%E5%99%A8%E5%9C%B0%E5%9D%80": {
        "en": "Server Address",
        "zhHans": "服务器地址"
    },
    "K_%E6%9C%8D%E5%8A%A1%E5%99%A8%E7%AB%AF%E5%8F%A3": {
        "en": "Server Port",
        "zhHans": "服务器端口"
    },
    "K_%E7%94%A8%E6%88%B7%E5%90%8D": {
        "en": "Username",
        "zhHans": "用户名"
    },
    "K_%E7%94%A8%E6%88%B7%E7%B1%BB%E5%9E%8B": {
        "en": "User Type",
        "zhHans": "用户类型"
    },
    "K_%E5%BC%80%E5%8F%91%E8%80%85": {
        "en": "Developer",
        "zhHans": "开发者"
    },
    "K_%E5%B7%A5%E7%A8%8B%E7%94%A8%E6%88%B7": {
        "en": "Engineering Users",
        "zhHans": "工程用户"
    },
    "K_%E7%99%BB%E5%BD%95": {
        "en": "Log In",
        "zhHans": "登录"
    },
    "K_%E7%BB%8F%E7%BA%AC%E5%BA%A6%E9%80%89%E6%8B%A9": {
        "en": "Longitude And Latitude Selection",
        "zhHans": "经纬度选择"
    },
    "K_%E4%BD%9C%E4%B8%BA%E5%91%8A%E8%AD%A6%E5%8F%91%E9%80%81%E5%99%A8": {
        "en": "As Alarm Sender",
        "zhHans": "作为告警发送器"
    },
    "K_%E5%90%AF%E7%94%A8": {
        "en": "Enable",
        "zhHans": "启用"
    },
    "K_%E5%8F%91%E9%80%81%E5%99%A8%E7%B1%BB%E5%9E%8B": {
        "en": "Transmitter Type",
        "zhHans": "发送器类型"
    },
    "K_%E5%8F%91%E9%80%81%E6%97%B6%E6%AE%B5": {
        "en": "Sending Period",
        "zhHans": "发送时段"
    },
    "K_%E5%91%8A%E8%AD%A6%E5%B1%95%E7%A4%BA": {
        "en": "Alarm Display",
        "zhHans": "告警展示"
    },
    "K_%E9%82%AE%E4%BB%B6%E5%91%8A%E8%AD%A6": {
        "en": "Email Alert",
        "zhHans": "邮件告警"
    },
    "K_%E7%94%B5%E8%AF%9D%E5%91%8A%E8%AD%A6": {
        "en": "Telephone Alarm",
        "zhHans": "电话告警"
    },
    "K_%E7%9F%AD%E4%BF%A1%E5%91%8A%E8%AD%A6": {
        "en": "SMS Alert",
        "zhHans": "短信告警"
    },
    "K_%E5%A3%B0%E5%85%89%E5%91%8A%E8%AD%A6": {
        "en": "Sound And Light Alarm",
        "zhHans": "声光告警"
    },
    "K_%E5%B9%BF%E6%92%AD%E5%91%8A%E8%AD%A6": {
        "en": "Broadcast Alarm",
        "zhHans": "广播告警"
    },
    "K_%E5%BE%AE%E4%BF%A1%E5%91%8A%E8%AD%A6": {
        "en": "WeChat Alert",
        "zhHans": "微信告警"
    },
    "K_%E4%B8%8D%E8%AE%BE%E7%BD%AE": {
        "en": "Not Set",
        "zhHans": "不设置"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E6%97%B6%E6%AE%B5%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Period, Please Wait...",
        "zhHans": "正在获取时段,请稍候..."
    },
    "K_%E9%80%89%E6%8B%A9": {
        "en": " Select",
        "zhHans": "选择"
    },
    "K_%E6%90%9C%E7%B4%A2": {
        "en": "Search",
        "zhHans": "搜索"
    },
    "K_%E6%AD%A3%E5%9C%A8%E6%90%9C%E7%B4%A2%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Searching, Please Wait...",
        "zhHans": "正在搜索,请稍候..."
    },
    "K_%E6%9C%BA%E6%9E%84%E6%A0%91": {
        "en": "Organization Tree",
        "zhHans": "机构树"
    },
    "K_%E6%B7%BB%E5%8A%A0%E6%9C%BA%E6%9E%84": {
        "en": "Add Organization",
        "zhHans": "添加机构"
    },
    "K_%E5%88%A0%E9%99%A4%E6%9C%BA%E6%9E%84": {
        "en": "Delete Organization",
        "zhHans": "删除机构"
    },
    "K_%E7%BC%96%E8%BE%91%E6%9C%BA%E6%9E%84": {
        "en": "Edit Organization",
        "zhHans": "编辑机构"
    },
    "K_%E4%B8%8A%E7%A7%BB": {
        "en": "Move Up",
        "zhHans": "上移"
    },
    "K_%E4%B8%8B%E7%A7%BB": {
        "en": "Move Down",
        "zhHans": "下移"
    },
    "K_%E5%88%B7%E6%96%B0": {
        "en": "Refresh",
        "zhHans": "刷新"
    },
    "K_%E6%98%AF%E5%90%A6%E5%88%A0%E9%99%A4%E6%9C%BA%E6%9E%84%3F": {
        "en": "Sure To Delete Organization?",
        "zhHans": "是否删除机构?"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E6%9C%BA%E6%9E%84%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Organization, Please Wait...",
        "zhHans": "正在获取机构,请稍候..."
    },
    "K_%E6%97%A0%E6%9C%BA%E6%9E%84": {
        "en": "No Organization",
        "zhHans": "无机构"
    },
    "K_%E6%AD%A3%E5%9C%A8%E6%8F%90%E4%BA%A4%E6%9C%BA%E6%9E%84%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Submitting Organization, Please Wait...",
        "zhHans": "正在提交机构,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E6%9C%BA%E6%9E%84%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Deleting Organization, Please Wait...",
        "zhHans": "正在删除机构,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E6%8E%92%E5%BA%8F%E6%9C%BA%E6%9E%84%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Sorting Organizations, Please Wait...",
        "zhHans": "正在排序机构,请稍候..."
    },
    "K_%E8%AF%B7%E9%80%89%E6%8B%A9%E4%B8%80%E4%B8%AA%E6%9C%BA%E6%9E%84": {
        "en": "Please Select An Organization",
        "zhHans": "请选择一个机构"
    },
    "K_%E6%9C%AA%E6%89%BE%E5%88%B0%E6%9C%BA%E6%9E%84%E4%BF%A1%E6%81%AF": {
        "en": "Organization Information Not Found",
        "zhHans": "未找到机构信息"
    },
    "K_%E6%8F%92%E4%BB%B6%E9%80%89%E6%8B%A9": {
        "en": "Plug-in Selection",
        "zhHans": "插件选择"
    },
    "K_%E4%B8%BB%E8%A2%AB%E5%8A%A8": {
        "en": "Active And Passive",
        "zhHans": "主被动"
    },
    "K_%E4%BC%A0%E8%BE%93%E5%8D%8F%E8%AE%AE": {
        "en": "Transport Protocol",
        "zhHans": "传输协议"
    },
    "K_%E8%BF%9E%E6%8E%A5%E6%A8%A1%E5%BC%8F": {
        "en": "Connection Mode",
        "zhHans": "连接模式"
    },
    "K_%E6%B3%A2%E7%89%B9%E7%8E%87": {
        "en": "Baud Rate",
        "zhHans": "波特率"
    },
    "K_%E6%95%B0%E6%8D%AE%E4%BD%8D": {
        "en": "Data Bits",
        "zhHans": "数据位"
    },
    "K_%E6%A0%A1%E9%AA%8C%E4%BD%8D": {
        "en": "Check Digit",
        "zhHans": "校验位"
    },
    "K_%E5%81%9C%E6%AD%A2%E4%BD%8D": {
        "en": "Stop Bit",
        "zhHans": "停止位"
    },
    "K_%E8%A2%AB%E5%8A%A8": {
        "en": "Passive",
        "zhHans": "被动"
    },
    "K_%E4%B8%BB%E5%8A%A8": {
        "en": "Initiative",
        "zhHans": "主动"
    },
    "K_%E5%BF%AB%E9%80%9F%E6%9B%BF%E6%8D%A2%E7%BB%91%E5%AE%9A%E6%BA%90": {
        "en": "Quickly Replace Binding Sources",
        "zhHans": "快速替换绑定源"
    },
    "K_%E9%80%89%E6%8B%A9%E4%B8%80%E4%B8%AA%E8%AE%BE%E5%A4%87": {
        "en": "Choose A Device",
        "zhHans": "选择一个设备"
    },
    "K_%E8%87%AA%E5%8A%A8%E5%8C%B9%E9%85%8D%E6%8E%A7%E4%BB%B6%E6%B5%8B%E7%82%B9%E5%90%8D%E4%B8%8E%E8%AE%BE%E5%A4%87%E6%B5%8B%E7%82%B9%E5%90%8D": {
        "en": "Automatically Match Control Spot Names And Device Spot Names",
        "zhHans": "自动匹配控件测点名与设备测点名"
    },
    "K_%E5%90%8D%E7%A7%B0%E7%9B%B8%E5%90%8C%E5%B9%B6%E4%B8%94%E6%BA%90%E7%B1%BB%E5%9E%8B%E4%B8%BA%E6%B5%8B%E7%82%B9%E7%9A%84%E7%BB%91%E5%AE%9A%E8%BF%9B%E8%A1%8C%E6%BA%90%E6%9B%BF%E6%8D%A2": {
        "en": "Source Replacement For Bindings With The Same Name And Source Type Of Spot",
        "zhHans": "名称相同并且源类型为测点的绑定进行源替换"
    },
    "K_%E9%A2%84%E8%AE%A1%E6%9B%BF%E6%8D%A2%E7%BB%91%E5%AE%9A%EF%BC%9A": {
        "en": "Expected Replacement Binding:",
        "zhHans": "预计替换绑定："
    },
    "K_%E6%9C%AA%E9%80%89%E6%8B%A9": {
        "en": "Not Selected",
        "zhHans": "未选择"
    },
    "K_%E6%BA%90%E6%95%B0%E9%87%8F": {
        "en": "Number Of Sources",
        "zhHans": "源数量"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E6%B5%8B%E7%82%B9%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Spots, Please Wait...",
        "zhHans": "正在获取测点,请稍候..."
    },
    "K_%E6%96%87%E4%BB%B6": {
        "en": "File",
        "zhHans": "文件"
    },
    "K_%E6%96%87%E4%BB%B6%E5%A4%A7%E5%B0%8F%E5%BF%85%E9%A1%BB%E5%B0%8F%E4%BA%8E5MB": {
        "en": "File Size Must Be Less Than 5MB",
        "zhHans": "文件大小必须小于5MB"
    },
    "K_%E5%9B%BE%E7%89%87%E5%86%85%E5%AE%B9%E5%B7%B2%E6%8D%9F%E5%9D%8F": {
        "en": "Image Content Is Corrupted",
        "zhHans": "图片内容已损坏"
    },
    "K_%E5%90%8D%E7%A7%B0%E4%B8%8D%E8%83%BD%E4%B8%BA%E7%A9%BA": {
        "en": "Name Cannot Be Empty",
        "zhHans": "名称不能为空"
    },
    "K_%E6%9C%AA%E9%80%89%E6%8B%A9%E6%96%87%E4%BB%B6": {
        "en": "No File Selected",
        "zhHans": "未选择文件"
    },
    "K_%E6%AD%A3%E5%9C%A8%E6%8F%90%E4%BA%A4%E8%B5%84%E6%BA%90%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Submitting Resources, Please Wait...",
        "zhHans": "正在提交资源,请稍候..."
    },
    "K_%E6%B5%8B%E7%82%B9%E6%8E%A7%E5%88%B6": {
        "en": "Spot Control",
        "zhHans": "测点控制"
    },
    "K_%E6%B5%8B%E7%82%B9%E5%90%8D%E7%A7%B0": {
        "en": "Spot Name",
        "zhHans": "测点名称"
    },
    "K_%E6%B5%8B%E7%82%B9%E5%80%BC": {
        "en": "Spot Value",
        "zhHans": "测点值"
    },
    "K_%E7%8A%B6%E6%80%81%E9%87%8F%E5%91%8A%E8%AD%A6%E9%85%8D%E7%BD%AE": {
        "en": "Status Quantity Alarm Configuration",
        "zhHans": "状态量告警配置"
    },
    "K_%E7%8A%B6%E6%80%81%E5%80%BC": {
        "en": "Status Value",
        "zhHans": "状态值"
    },
    "K_%E7%8A%B6%E6%80%81%E5%90%8D": {
        "en": "Status Name",
        "zhHans": "状态名"
    },
    "K_%E6%98%AF%E5%90%A6%E5%91%8A%E8%AD%A6": {
        "en": "Enable Alarm",
        "zhHans": "是否告警"
    },
    "K_%E5%B7%A1%E6%A3%80%E7%B1%BB%E5%9E%8B": {
        "en": "Inspection Type",
        "zhHans": "巡检类型"
    },
    "K_%E5%B7%A1%E6%A3%80%E5%86%85%E5%AE%B9": {
        "en": "Inspection Content",
        "zhHans": "巡检内容"
    },
    "K_%E5%BD%93%E5%89%8D%E6%B5%8B%E7%82%B9%E5%91%8A%E8%AD%A6%E6%80%BB%E6%95%B0": {
        "en": "Total Number Of Current Spot Alarms",
        "zhHans": "当前测点告警总数"
    },
    "K_%E5%91%8A%E8%AD%A6%E7%BA%A7%E5%88%AB%E5%88%86%E7%B1%BB%E7%BB%9F%E8%AE%A1": {
        "en": "Alarm Level Classification Statistics",
        "zhHans": "告警级别分类统计"
    },
    "K_%E5%91%8A%E8%AD%A6%E7%B1%BB%E5%9E%8B%E5%88%86%E7%B1%BB%E7%BB%9F%E8%AE%A1": {
        "en": "Alarm Type Classification Statistics",
        "zhHans": "告警类型分类统计"
    },
    "K_%E7%A6%81%E7%94%A8%E6%95%B0%E9%87%8F": {
        "en": "Banned Quantity",
        "zhHans": "禁用数量"
    },
    "K_%E7%A6%BB%E7%BA%BF%E6%95%B0%E9%87%8F": {
        "en": "Offline Quantity",
        "zhHans": "离线数量"
    },
    "K_%E5%9C%A8%E7%BA%BF%E6%95%B0%E9%87%8F": {
        "en": "Online Quantity",
        "zhHans": "在线数量"
    },
    "K_%E5%B7%A1%E6%A3%80%E5%AF%B9%E8%B1%A1": {
        "en": "Inspection Objects",
        "zhHans": "巡检对象"
    },
    "K_%E7%9F%AD%E4%BF%A1%E5%8F%91%E9%80%81%E5%99%A8": {
        "en": "SMS Sender",
        "zhHans": "短信发送器"
    },
    "K_%E9%82%AE%E4%BB%B6%E5%8F%91%E9%80%81%E5%99%A8": {
        "en": "Mailer",
        "zhHans": "邮件发送器"
    },
    "K_%E5%BE%AE%E4%BF%A1%E5%8F%91%E9%80%81%E5%99%A8": {
        "en": "WeChat Sender",
        "zhHans": "微信发送器"
    },
    "K_%E6%8E%A5%E6%94%B6%E7%94%A8%E6%88%B7": {
        "en": "Receive User",
        "zhHans": "接收用户"
    },
    "K_%E6%8E%A7%E5%88%B6%E6%B5%8B%E7%82%B9": {
        "en": "Control Spot",
        "zhHans": "控制测点"
    },
    "K_%E5%86%85%E5%AE%B9": {
        "en": "Content",
        "zhHans": "内容"
    },
    "K_%E6%97%B6%E9%97%B4%E7%BC%96%E8%BE%91": {
        "en": "Time Edit",
        "zhHans": "时间编辑"
    },
    "K_%E6%98%9F%E6%9C%9F": {
        "en": "Week",
        "zhHans": "星期"
    },
    "K_%E6%97%B6": {
        "en": "Hour",
        "zhHans": "时"
    },
    "K_%E5%88%86": {
        "en": "Point",
        "zhHans": "分"
    },
    "K_%E6%9C%AA%E7%A1%AE%E8%AE%A4%E5%91%8A%E8%AD%A6": {
        "en": "Unconfirmed Alarm",
        "zhHans": "未确认告警"
    },
    "K_%E7%A1%AE%E8%AE%A4%E9%80%89%E4%B8%AD": {
        "en": "Confirm Selection",
        "zhHans": "确认选中"
    },
    "K_%E7%A1%AE%E8%AE%A4%E5%85%A8%E9%83%A8": {
        "en": "Confirm All",
        "zhHans": "确认全部"
    },
    "K_%E8%B6%85%E4%B8%8A%E9%99%90%E5%91%8A%E8%AD%A6": {
        "en": "Over Upper Limit Alarm",
        "zhHans": "超上限告警"
    },
    "K_%E8%B6%85%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6": {
        "en": "Over Lower Limit Alarm",
        "zhHans": "超下限告警"
    },
    "K_%E4%B8%B4%E4%B8%8A%E9%99%90%E5%91%8A%E8%AD%A6": {
        "en": "Near Upper Limit Alarm",
        "zhHans": "临上限告警"
    },
    "K_%E4%B8%B4%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6": {
        "en": "Near Lower Limit Alarm",
        "zhHans": "临下限告警"
    },
    "K_%E6%95%B0%E6%8D%AE%E5%BC%82%E5%B8%B8%E5%91%8A%E8%AD%A6": {
        "en": "Data Anomaly Alarm",
        "zhHans": "数据异常告警"
    },
    "K_%E7%8A%B6%E6%80%81%E5%BC%82%E5%B8%B8%E5%91%8A%E8%AD%A6": {
        "en": "Abnormal Status Alarm",
        "zhHans": "状态异常告警"
    },
    "K_%E5%91%8A%E8%AD%A6%E6%81%A2%E5%A4%8D": {
        "en": "Alarm Recovery",
        "zhHans": "告警恢复"
    },
    "K_%E8%81%94%E6%9C%BA%E6%81%A2%E5%A4%8D": {
        "en": "Online Recovery",
        "zhHans": "联机恢复"
    },
    "K_%E8%84%B1%E6%9C%BA%E5%91%8A%E8%AD%A6": {
        "en": "Offline Alarm",
        "zhHans": "脱机告警"
    },
    "K_%E6%96%87%E6%9C%AC%E6%8A%A5%E8%AD%A6": {
        "en": "Text Alarm",
        "zhHans": "文本报警"
    },
    "K_%E6%9E%81%E4%B8%8A%E9%99%90%E5%91%8A%E8%AD%A6": {
        "en": "Extreme Upper Limit Alarm",
        "zhHans": "极上限告警"
    },
    "K_%E6%9E%81%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6": {
        "en": "Extreme Lower Limit Alarm",
        "zhHans": "极下限告警"
    },
    "K_%E5%A4%84%E7%90%86%E6%84%8F%E8%A7%81": {
        "en": "Handling Opinions",
        "zhHans": "处理意见"
    },
    "K_%E7%BC%96%E5%8F%B7": {
        "en": "ID",
        "zhHans": "编号"
    },
    "K_%E4%BA%A7%E7%94%9F%E6%97%B6%E9%97%B4": {
        "en": "Generation Time",
        "zhHans": "产生时间"
    },
    "K_%E5%91%8A%E8%AD%A6%E5%AF%B9%E8%B1%A1": {
        "en": "Object Type",
        "zhHans": "对象类型"
    },
    "K_%E5%91%8A%E8%AD%A6%E7%B1%BB%E5%9E%8B": {
        "en": "Alarm Type",
        "zhHans": "告警类型"
    },
    "K_%E5%91%8A%E8%AD%A6%E7%BA%A7%E5%88%AB": {
        "en": "Alarm Level",
        "zhHans": "告警级别"
    },
    "K_%E5%BD%93%E5%89%8D%E6%9C%BA%E6%9E%84": {
        "en": "Current Organization",
        "zhHans": "当前机构"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E8%AE%B0%E5%BD%95%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Records, Please Wait...",
        "zhHans": "正在获取记录,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E7%A1%AE%E8%AE%A4%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Confirming, Please Wait...",
        "zhHans": "正在确认,请稍候..."
    },
    "K_%E7%94%A8%E6%88%B7%E5%90%8D*": {
        "en": "Username*",
        "zhHans": "用户名*"
    },
    "K_%E5%AF%86%E7%A0%81*": {
        "en": "Password*",
        "zhHans": "密码*"
    },
    "K_%E7%A1%AE%E8%AE%A4%E5%AF%86%E7%A0%81*": {
        "en": "Confirm Password*",
        "zhHans": "确认密码*"
    },
    "K_%E5%A7%93%E5%90%8D*": {
        "en": "Name*",
        "zhHans": "姓名*"
    },
    "K_%E6%80%A7%E5%88%AB": {
        "en": "Gender",
        "zhHans": "性别"
    },
    "K_%E5%B7%A5%E5%8F%B7": {
        "en": "ID",
        "zhHans": "工号"
    },
    "K_%E9%83%A8%E9%97%A8": {
        "en": "Department",
        "zhHans": "部门"
    },
    "K_%E8%81%8C%E4%BD%8D": {
        "en": "Position",
        "zhHans": "职位"
    },
    "K_%E8%AF%AD%E9%9F%B3%E5%8F%B7%E7%A0%81": {
        "en": "Phone Number",
        "zhHans": "语音号码"
    },
    "K_%E7%9F%AD%E4%BF%A1%E5%8F%B7%E7%A0%81": {
        "en": "SMS Number",
        "zhHans": "短信号码"
    },
    "K_%E9%82%AE%E7%AE%B1": {
        "en": "Mail",
        "zhHans": "邮箱"
    },
    "K_%E8%A7%92%E8%89%B2": {
        "en": "Role",
        "zhHans": "角色"
    },
    "K_%E7%94%B7": {
        "en": "Male",
        "zhHans": "男"
    },
    "K_%E5%A5%B3": {
        "en": "Female",
        "zhHans": "女"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E4%BF%A1%E6%81%AF%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Information, Please Wait...",
        "zhHans": "正在获取信息,请稍候..."
    },
    "K_%E4%BF%A1%E6%81%AF%E4%B8%8D%E5%AE%8C%E6%95%B4": {
        "en": "Incomplete Information",
        "zhHans": "信息不完整"
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%88%9B%E5%BB%BA%E8%B4%A6%E5%8F%B7%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Creating Account, Please Wait...",
        "zhHans": "正在创建账号,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%88%9B%E5%BB%BA%E7%94%A8%E6%88%B7%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Creating User, Please Wait...",
        "zhHans": "正在创建用户,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E4%BF%AE%E6%94%B9%E7%94%A8%E6%88%B7%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Updating User, Please Wait...",
        "zhHans": "正在修改用户,请稍候..."
    },
    "K_%E5%A7%93%E5%90%8D": {
        "en": "Name",
        "zhHans": "姓名"
    },
    "K_%E5%BC%80%E5%8F%91%E8%80%85%E5%B8%90%E6%88%B7%E6%B2%A1%E6%9C%89%E5%AF%B9%E5%BA%94%E7%9A%84%E9%85%8D%E7%BD%AE%E9%A1%B9": {
        "en": "There Is No Corresponding Configuration Item For The Developer Account",
        "zhHans": "开发者帐户没有对应的配置项"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving User Information, Please Wait...",
        "zhHans": "正在获取用户信息,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E4%BF%AE%E6%94%B9%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Updating User Information, Please Wait...",
        "zhHans": "正在修改用户信息,请稍候..."
    },
    "K_%E5%8A%9F%E8%83%BD%E8%8F%9C%E5%8D%95": {
        "en": "Function Menu",
        "zhHans": "功能菜单"
    },
    "K_%E8%AF%AD%E9%9F%B3%E9%80%89%E6%8B%A9": {
        "en": "Voice Selection",
        "zhHans": "语音选择"
    },
    "K_%E5%A3%B0%E9%9F%B3": {
        "en": "Sound",
        "zhHans": "声音"
    },
    "K_%E8%AF%AD%E9%80%9F": {
        "en": "Speaking Speed",
        "zhHans": "语速"
    },
    "K_%E9%9F%B3%E9%AB%98": {
        "en": "Pitch",
        "zhHans": "音高"
    },
    "K_%E6%99%93%E5%8C%97--%E5%A5%B3--%E8%BE%BD%E5%AE%81": {
        "en": "Xiaobei--Female--Liaoning",
        "zhHans": "晓北--女--辽宁"
    },
    "K_%E6%99%93%E8%BE%B0--%E5%A5%B3--%E5%A4%A7%E9%99%86": {
        "en": "Xiaochen--Female--Mainland",
        "zhHans": "晓辰--女--大陆"
    },
    "K_%E6%99%93%E6%B6%B5--%E5%A5%B3--%E5%A4%A7%E9%99%86": {
        "en": "Xiaohan--Female--Mainland",
        "zhHans": "晓涵--女--大陆"
    },
    "K_%E6%99%93%E6%A2%A6--%E5%A5%B3--%E5%A4%A7%E9%99%86": {
        "en": "Xiaomeng--Female--Mainland",
        "zhHans": "晓梦--女--大陆"
    },
    "K_%E6%99%93%E5%A2%A8--%E5%A5%B3--%E5%A4%A7%E9%99%86": {
        "en": "Xiaomo--Female--Mainland",
        "zhHans": "晓墨--女--大陆"
    },
    "K_%E6%99%93%E5%A6%AE--%E5%A5%B3--%E9%99%95%E8%A5%BF": {
        "en": "Xiaoni--Female--Shaanxi",
        "zhHans": "晓妮--女--陕西"
    },
    "K_%E6%99%93%E7%A7%8B--%E5%A5%B3--%E5%A4%A7%E9%99%86": {
        "en": "Xiaoqiu--Female--Mainland",
        "zhHans": "晓秋--女--大陆"
    },
    "K_%E6%99%93%E7%9D%BF--%E5%A5%B3--%E5%A4%A7%E9%99%86": {
        "en": "Xiao Rui--Female--Mainland",
        "zhHans": "晓睿--女--大陆"
    },
    "K_%E6%99%93%E5%8F%8C--%E5%A5%B3--%E5%A4%A7%E9%99%86": {
        "en": "Xiaoshuang--Female--Mainland",
        "zhHans": "晓双--女--大陆"
    },
    "K_%E6%99%93%E6%99%93--%E5%A5%B3--%E5%A4%A7%E9%99%86": {
        "en": "Xiaoxiao--Female--Mainland",
        "zhHans": "晓晓--女--大陆"
    },
    "K_%E6%99%93%E8%90%B1--%E5%A5%B3--%E5%A4%A7%E9%99%86": {
        "en": "Xiaoxuan--Female--Mainland",
        "zhHans": "晓萱--女--大陆"
    },
    "K_%E6%99%93%E9%A2%9C--%E5%A5%B3--%E5%A4%A7%E9%99%86": {
        "en": "Xiaoyan--Female--Mainland",
        "zhHans": "晓颜--女--大陆"
    },
    "K_%E6%99%93%E4%BC%8A--%E5%A5%B3--%E5%A4%A7%E9%99%86": {
        "en": "Xiaoyi--Female--Mainland",
        "zhHans": "晓伊--女--大陆"
    },
    "K_%E6%99%93%E6%82%A0--%E5%A5%B3--%E5%A4%A7%E9%99%86": {
        "en": "Xiaoyou--Female--Mainland",
        "zhHans": "晓悠--女--大陆"
    },
    "K_%E6%99%93%E7%94%84--%E5%A5%B3--%E5%A4%A7%E9%99%86": {
        "en": "Xiao Zhen--Female--Mainland",
        "zhHans": "晓甄--女--大陆"
    },
    "K_%E6%9B%89%E4%BD%B3--%E5%A5%B3--%E9%A6%99%E6%B8%AF": {
        "en": "Xiaojia--Female--Hong Kong",
        "zhHans": "曉佳--女--香港"
    },
    "K_%E6%9B%89%E6%9B%BC--%E5%A5%B3--%E9%A6%99%E6%B8%AF": {
        "en": "Xiaoman--Female--Hong Kong",
        "zhHans": "曉曼--女--香港"
    },
    "K_%E6%9B%89%E9%9B%A8--%E5%A5%B3--%E5%8F%B0%E6%B9%BE": {
        "en": "Xiaoyu--Female--Taiwan",
        "zhHans": "曉雨--女--台湾"
    },
    "K_%E6%9B%89%E8%87%BB--%E5%A5%B3--%E5%8F%B0%E6%B9%BE": {
        "en": "Xiaozhen--Female--Taiwan",
        "zhHans": "曉臻--女--台湾"
    },
    "K_%E4%BA%91%E7%99%BB--%E7%94%B7--%E6%B2%B3%E5%8D%97": {
        "en": "Yundeng--Male--Henan",
        "zhHans": "云登--男--河南"
    },
    "K_%E4%BA%91%E6%9E%AB--%E7%94%B7--%E5%A4%A7%E9%99%86": {
        "en": "Yun Feng--Male--Mainland",
        "zhHans": "云枫--男--大陆"
    },
    "K_%E4%BA%91%E7%9A%93--%E7%94%B7--%E5%A4%A7%E9%99%86": {
        "en": "Yun Hao--Male--Mainland",
        "zhHans": "云皓--男--大陆"
    },
    "K_%E4%BA%91%E5%81%A5--%E7%94%B7--%E5%A4%A7%E9%99%86": {
        "en": "Yun Jian--Male--Mainland",
        "zhHans": "云健--男--大陆"
    },
    "K_%E4%BA%91%E5%B8%8C--%E7%94%B7--%E5%A4%A7%E9%99%86": {
        "en": "Yun Xi--Male--Mainland",
        "zhHans": "云希--男--大陆"
    },
    "K_%E4%BA%91%E5%B8%8C--%E7%94%B7--%E5%9B%9B%E5%B7%9D": {
        "en": "Yun Xi--Male--Sichuan",
        "zhHans": "云希--男--四川"
    },
    "K_%E4%BA%91%E5%A4%8F--%E7%94%B7--%E5%A4%A7%E9%99%86": {
        "en": "Yunxia--Male--Mainland",
        "zhHans": "云夏--男--大陆"
    },
    "K_%E4%BA%91%E7%BF%94--%E7%94%B7--%E5%B1%B1%E4%B8%9C": {
        "en": "Yunxiang--Male--Shandong",
        "zhHans": "云翔--男--山东"
    },
    "K_%E4%BA%91%E6%89%AC--%E7%94%B7--%E5%A4%A7%E9%99%86": {
        "en": "Yun Yang--Male--Mainland",
        "zhHans": "云扬--男--大陆"
    },
    "K_%E4%BA%91%E9%87%8E--%E7%94%B7--%E5%A4%A7%E9%99%86": {
        "en": "Yun Ye--Male--Mainland",
        "zhHans": "云野--男--大陆"
    },
    "K_%E4%BA%91%E6%B3%BD--%E7%94%B7--%E5%A4%A7%E9%99%86": {
        "en": "Yunze--Male--Mainland",
        "zhHans": "云泽--男--大陆"
    },
    "K_%E9%9B%B2%E9%BE%8D--%E7%94%B7--%E9%A6%99%E6%B8%AF": {
        "en": "Yunlong--Male--Hong Kong",
        "zhHans": "雲龍--男--香港"
    },
    "K_%E9%9B%B2%E5%93%B2--%E7%94%B7--%E5%8F%B0%E6%B9%BE": {
        "en": "Yunzhe--Male--Taiwan",
        "zhHans": "雲哲--男--台湾"
    },
    "K_%E6%AC%A2%E8%BF%8E%E4%BD%BF%E7%94%A8": {
        "en": "Welcome",
        "zhHans": "欢迎使用"
    },
    "K_%E7%BB%BC%E5%90%88%E7%9B%91%E6%8E%A7%E5%B9%B3%E5%8F%B0": {
        "en": "Integrated Monitor Control Platform",
        "zhHans": "IMCP综合监控平台"
    },
    "K_%E7%BC%96%E7%A0%81%E6%9C%89%E8%AF%AF": {
        "en": "Wrong Encoding",
        "zhHans": "编码有误"
    },
    "K_%E9%80%9A%E8%AE%AF%E6%8F%A1%E6%89%8B%E5%A4%B1%E8%B4%A5": {
        "en": "Communication Handshake Failed",
        "zhHans": "通讯握手失败"
    },
    "K_%E6%B6%88%E6%81%AF%E9%95%BF%E5%BA%A6%E6%9C%89%E8%AF%AF": {
        "en": "Message Length Is Wrong",
        "zhHans": "消息长度有误"
    },
    "K_%E6%B6%88%E6%81%AF%E5%86%85%E5%AE%B9%E9%95%BF%E5%BA%A6%E6%9C%89%E8%AF%AF": {
        "en": "Message Content Length Is Wrong",
        "zhHans": "消息内容长度有误"
    },
    "K_%E8%BD%AF%E4%BB%B6%E6%9C%AA%E6%8E%88%E6%9D%83%2C%20%E8%AF%B7%E8%81%94%E7%B3%BB%E4%BE%9B%E5%BA%94%E5%95%86!%20Unauthorized%20software%2C%20please%20contact%20the%20supplier!%20": {
        "en": "Unauthorized software, please contact the supplier!",
        "zhHans": "软件未授权, 请联系供应商!"
    },
    "K_%E8%BF%9E%E6%8E%A5%E5%A4%B1%E8%B4%A5": {
        "en": "Connection Failed",
        "zhHans": "连接失败"
    },
    "K_%E5%8F%91%E9%80%81%E7%99%BB%E5%BD%95%E6%B6%88%E6%81%AF%E6%97%B6": {
        "en": "When Sending A Login Message",
        "zhHans": "发送登录消息时"
    },
    "K_%E4%B8%BB%E5%8A%A8%E6%96%AD%E5%BC%80%E8%BF%9E%E6%8E%A5": {
        "en": "Actively Disconnect",
        "zhHans": "主动断开连接"
    },
    "K_%E6%AD%A4%E8%AF%B7%E6%B1%82%E5%B7%B2%E8%A2%AB%E5%B1%8F%E8%94%BD%EF%BC%9A%E6%8F%90%E4%BA%A4%E5%8F%82%E6%95%B0%E5%B8%A6%E6%9C%89%E9%9D%9E%E6%B3%95%E5%AD%97%E7%AC%A6%EF%BC%8C%E4%BC%9A%E6%8D%9F%E5%AE%B3%E7%B3%BB%E7%BB%9F%E6%95%B0%E6%8D%AE": {
        "en": "This Request Has Been Blocked: The Submitted Parameters Contain Illegal Characters And Will Damage System Data.",
        "zhHans": "此请求已被屏蔽：提交参数带有非法字符，会损害系统数据"
    },
    "K_%E8%AF%B7%E6%B1%82%E8%B6%85%E6%97%B6": {
        "en": "Request Timeout",
        "zhHans": "请求超时"
    },
    "K_%E5%9B%A0%E6%9C%8D%E5%8A%A1%E7%AB%AF%E5%A4%9A%E6%AC%A1%E6%97%A0%E5%93%8D%E5%BA%94": {
        "en": "The Server Failed To Respond Multiple Times",
        "zhHans": "因服务端多次无响应"
    },
    "K_%E5%AE%A2%E6%88%B7%E7%AB%AF%E5%8F%96%E6%B6%88%E6%AD%A4%E6%93%8D%E4%BD%9C": {
        "en": "Client Cancels This Operation",
        "zhHans": "客户端取消此操作"
    },
    "K_%E4%BF%9D%E5%AD%98": {
        "en": "Save",
        "zhHans": "保存"
    },
    "K_%E5%93%81%E7%89%8C": {
        "en": "Brand",
        "zhHans": "品牌"
    },
    "K_%E5%9E%8B%E5%8F%B7": {
        "en": "Model",
        "zhHans": "型号"
    },
    "K_%E4%BE%9B%E5%BA%94%E5%95%86": {
        "en": "Supplier",
        "zhHans": "供应商"
    },
    "K_%E8%B4%AD%E4%B9%B0%E6%97%A5%E6%9C%9F": {
        "en": "Purchase Date",
        "zhHans": "购买日期"
    },
    "K_%E8%B4%A8%E4%BF%9D%E6%97%A5%E6%9C%9F": {
        "en": "Warranty Date",
        "zhHans": "质保日期"
    },
    "K_%E8%B4%9F%E8%B4%A3%E4%BA%BA": {
        "en": "Person In Charge",
        "zhHans": "负责人"
    },
    "K_%E8%81%94%E7%B3%BB%E6%96%B9%E5%BC%8F": {
        "en": "Contact Information",
        "zhHans": "联系方式"
    },
    "K_%E7%BB%B4%E4%BF%AE%E8%AE%B0%E5%BD%95": {
        "en": "Maintenance Records",
        "zhHans": "维修记录"
    },
    "K_%E5%A4%87%E6%B3%A8": {
        "en": "Remark",
        "zhHans": "备注"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E8%B5%84%E4%BA%A7%E4%BF%A1%E6%81%AF%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Asset Information, Please Wait...",
        "zhHans": "正在获取资产信息,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E4%BF%9D%E5%AD%98%E8%B5%84%E4%BA%A7%E4%BF%A1%E6%81%AF%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Saving Asset Information, Please Wait...",
        "zhHans": "正在保存资产信息,请稍候..."
    },
    "K_%E5%B7%A5%E7%A8%8B%E8%B5%84%E4%BA%A7%E4%BF%A1%E6%81%AF%E8%AF%B7%E5%8B%BF%E5%88%A0%E9%99%A4": {
        "en": "Please Do Not Delete Project Asset Information",
        "zhHans": "工程资产信息请勿删除"
    },
    "K_%3C%E5%A4%9A%E6%9D%A1%E9%80%89%E4%B8%AD%E9%A1%B9%3E": {
        "en": "<Multiple Selected Items>",
        "zhHans": "<多条选中项>"
    },
    "K_%E8%BF%94%E5%9B%9E%E4%B8%8A%E4%B8%80%E7%BA%A7": {
        "en": "Return To Previous Level",
        "zhHans": "返回上一级"
    },
    "K_%E4%BB%A5%E5%A4%AA%E7%BD%91%E5%8F%A3": {
        "en": "Ethernet Port",
        "zhHans": "以太网口"
    },
    "K_%E4%B8%B2%E5%8F%A3": {
        "en": "Serial Port",
        "zhHans": "串口"
    },
    "K_%E5%8F%A3": {
        "en": "Mouth",
        "zhHans": "口"
    },
    "K_%E5%86%85%E9%83%A8%E7%AB%AF%E5%8F%A3": {
        "en": "Internal Port",
        "zhHans": "内部端口"
    },
    "K_%E9%80%9A%E7%94%A8%E7%AB%AF%E5%8F%A3": {
        "en": "Common Port",
        "zhHans": "通用端口"
    },
    "K_%E6%A8%A1%E6%8B%9F%E9%87%8F": {
        "en": "Analog Quantity",
        "zhHans": "模拟量"
    },
    "K_%E5%AD%97%E7%AC%A6%E4%B8%B2": {
        "en": "String",
        "zhHans": "字符串"
    },
    "K_%E5%BC%80%E5%85%B3%E9%87%8F": {
        "en": "Switch Value",
        "zhHans": "开关量"
    },
    "K_%E6%95%B0%E6%8D%AE%E5%9D%97": {
        "en": "Data Block",
        "zhHans": "数据块"
    },
    "K_%E7%8A%B6%E6%80%81%E9%87%8F": {
        "en": "Status Quantity",
        "zhHans": "状态量"
    },
    "K_%E5%90%91%E4%B8%8A%E5%90%8C%E6%AD%A5": {
        "en": "Sync Up",
        "zhHans": "向上同步"
    },
    "K_%E5%90%91%E4%B8%8B%E5%90%8C%E6%AD%A5": {
        "en": "Sync Down",
        "zhHans": "向下同步"
    },
    "K_%E8%BF%81%E5%87%BA": {
        "en": "Move Out",
        "zhHans": "迁出"
    },
    "K_%E5%88%A0%E9%99%A4": {
        "en": "Delete",
        "zhHans": "删除"
    },
    "K_%E6%9F%A5%E7%9C%8B": {
        "en": "Detail",
        "zhHans": "查看"
    },
    "K_%E6%94%BE%E5%BC%83%E4%BF%AE%E6%94%B9": {
        "en": "Cancel",
        "zhHans": "放弃修改"
    },
    "K_%E8%AF%B7%E9%80%89%E6%8B%A9%E8%A6%81%E8%BF%81%E5%85%A5%E7%9A%84%E6%9C%BA%E6%9E%84": {
        "en": "Please Select An Organization You Want To Move To",
        "zhHans": "请选择要迁入的机构"
    },
    "K_%E6%98%AF%E5%90%A6%E5%88%A0%E9%99%A4%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA%3F": {
        "en": "Sure To Delete Agent?",
        "zhHans": "是否删除监控主机?"
    },
    "K_%E6%98%AF%E5%90%A6%E5%88%A0%E9%99%A4%E8%AE%BE%E5%A4%87%3F": {
        "en": "Sure To Delete Device?",
        "zhHans": "是否删除设备?"
    },
    "K_%E6%8F%8F%E8%BF%B0": {
        "en": "Description",
        "zhHans": "描述"
    },
    "K_%E6%8C%82%E8%BD%BD%E7%82%B9": {
        "en": "Mount Point",
        "zhHans": "挂载点"
    },
    "K_%E7%AB%AF%E5%8F%A3%E7%B1%BB%E5%9E%8B": {
        "en": "Port Type",
        "zhHans": "端口类型"
    },
    "K_%E7%AB%AF%E5%8F%A3%E7%BC%96%E5%8F%B7": {
        "en": "Port Number",
        "zhHans": "端口编号"
    },
    "K_%E5%80%8D%E7%8E%87": {
        "en": "Magnification",
        "zhHans": "倍率"
    },
    "K_%E8%AE%BE%E5%A4%87%E5%9C%B0%E5%9D%80": {
        "en": "Device Address",
        "zhHans": "设备地址"
    },
    "K_IP%E5%9C%B0%E5%9D%80": {
        "en": "IP Address",
        "zhHans": "IP地址"
    },
    "K_IP%E7%AB%AF%E5%8F%A3": {
        "en": "IP Port",
        "zhHans": "IP端口"
    },
    "K_%E5%B8%90%E6%88%B7": {
        "en": "Account",
        "zhHans": "帐户"
    },
    "K_%E5%AE%89%E8%A3%85%E4%BD%8D%E7%BD%AE": {
        "en": "Installation Location",
        "zhHans": "安装位置"
    },
    "K_%E9%87%87%E9%9B%86%E5%91%A8%E6%9C%9F": {
        "en": "Collection Cycle",
        "zhHans": "采集周期"
    },
    "K_%E7%AB%AF%E5%8F%A3%E5%8F%82%E6%95%B0": {
        "en": "Port Parameters",
        "zhHans": "端口参数"
    },
    "K_%E6%8F%92%E4%BB%B6": {
        "en": "Plug-in",
        "zhHans": "插件"
    },
    "K_%E6%BA%A2%E5%87%BA%E8%8C%83%E5%9B%B4": {
        "en": "Overflow Range",
        "zhHans": "溢出范围"
    },
    "K_%E5%BC%82%E5%B8%B8%E6%95%B0%E6%8D%AE%E5%A4%84%E7%90%86%E8%A7%84%E5%88%99": {
        "en": "Exception Data Processing Rules",
        "zhHans": "异常数据处理规则"
    },
    "K_%E6%8A%9B%E5%BC%83": {
        "en": "Abandon",
        "zhHans": "抛弃"
    },
    "K_%E5%91%8A%E8%AD%A6": {
        "en": "Alarm",
        "zhHans": "告警"
    },
    "K_%E5%85%B6%E4%BB%96%E5%B1%9E%E6%80%A7": {
        "en": "Other Properties",
        "zhHans": "其他属性"
    },
    "K_%E6%95%B0%E6%8D%AE%E4%BF%9D%E5%AD%98": {
        "en": "Data Saving",
        "zhHans": "数据保存"
    },
    "K_%E4%B8%8D%E4%BF%9D%E5%AD%98": {
        "en": "Don't Save",
        "zhHans": "不保存"
    },
    "K_%E4%BF%9D%E5%AD%98%E7%AD%96%E7%95%A5": {
        "en": "Save Policy",
        "zhHans": "保存策略"
    },
    "K_%E5%85%A8%E5%B1%80%E7%AD%96%E7%95%A5": {
        "en": "Global Policy",
        "zhHans": "全局策略"
    },
    "K_%E5%85%A8%E9%83%A8%E4%BF%9D%E5%AD%98": {
        "en": "Save All",
        "zhHans": "全部保存"
    },
    "K_%E5%91%A8%E6%9C%9F%E6%80%A7": {
        "en": "Cyclical",
        "zhHans": "周期性"
    },
    "K_%E5%8F%98%E5%8C%96%E7%8E%87": {
        "en": "Rate Of Change",
        "zhHans": "变化率"
    },
    "K_%E5%91%A8%E6%9C%9F%E6%80%A7%E4%B8%8E%E5%8F%98%E5%8C%96%E7%8E%87": {
        "en": "Cyclicity And Rate Of Change",
        "zhHans": "周期性与变化率"
    },
    "K_%E5%91%A8%E6%9C%9F%E6%80%A7%E6%88%96%E5%8F%98%E5%8C%96%E7%8E%87": {
        "en": "Cyclicity Or Rate Of Change",
        "zhHans": "周期性或变化率"
    },
    "K_%E6%AD%A3%E5%B8%B8%E4%BF%9D%E5%AD%98%E5%91%A8%E6%9C%9F(%E7%A7%92)": {
        "en": "Normal Storage Period (seconds)",
        "zhHans": "正常保存周期(秒)"
    },
    "K_%E5%91%8A%E8%AD%A6%E4%BF%9D%E5%AD%98%E5%91%A8%E6%9C%9F(%E7%A7%92)": {
        "en": "Alarm Storage Period (seconds)",
        "zhHans": "告警保存周期(秒)"
    },
    "K_%E5%8F%98%E5%8C%96%E7%8E%87(%25)": {
        "en": "Change Rate (%)",
        "zhHans": "变化率(%)"
    },
    "K_%E6%95%B0%E6%8D%AE%E5%85%B1%E4%BA%AB": {
        "en": "Data Sharing",
        "zhHans": "数据共享"
    },
    "K_%E5%85%B1%E4%BA%AB": {
        "en": "Shared",
        "zhHans": "共享"
    },
    "K_%E4%B8%8D%E5%85%B1%E4%BA%AB": {
        "en": "Do Not Share",
        "zhHans": "不共享"
    },
    "K_%E4%BD%9C%E4%B8%BA%E5%8F%91%E9%80%81%E5%99%A8": {
        "en": "As Transmitter",
        "zhHans": "作为发送器"
    },
    "K_%E4%B8%8D%E5%91%8A%E8%AD%A6": {
        "en": "No Alarm",
        "zhHans": "不告警"
    },
    "K_%E7%8A%B6%E6%80%81%E5%91%8A%E8%AD%A6%E9%85%8D%E7%BD%AE": {
        "en": "Status Alarm Configuration",
        "zhHans": "状态告警配置"
    },
    "K_%E5%B8%B8%E6%80%81%E5%80%BC": {
        "en": "Normal Value",
        "zhHans": "常态值"
    },
    "K_%E5%91%8A%E8%AD%A6%E9%99%90%E7%BA%A7": {
        "en": "Alarm Level",
        "zhHans": "告警限级"
    },
    "K_%E9%BB%98%E8%AE%A4": {
        "en": "Default",
        "zhHans": "默认"
    },
    "K_%E4%B8%80%E9%99%90%E7%BA%A7": {
        "en": "One Level",
        "zhHans": "一限级"
    },
    "K_%E4%BA%8C%E9%99%90%E7%BA%A7": {
        "en": "Second Level",
        "zhHans": "二限级"
    },
    "K_%E4%B8%89%E9%99%90%E7%BA%A7": {
        "en": "Three Limited Levels",
        "zhHans": "三限级"
    },
    "K_%E4%B8%8A%E9%99%90": {
        "en": "Upper Limit",
        "zhHans": "上限"
    },
    "K_%E4%B8%8B%E9%99%90": {
        "en": "Lower Limit",
        "zhHans": "下限"
    },
    "K_%E6%9E%81%E4%B8%8A%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6%E7%BA%A7%E5%88%AB": {
        "en": "Extreme Upper And Lower Limit Alarm Levels",
        "zhHans": "极上下限告警级别"
    },
    "K_%E4%B8%B4%E4%B8%8A%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6%E7%BA%A7%E5%88%AB": {
        "en": "Near Upper And Lower Limit Alarm Levels",
        "zhHans": "临上下限告警级别"
    },
    "K_%E6%BB%9E%E5%9B%9E%E6%96%B9%E5%BC%8F": {
        "en": "Hysteretic Mode",
        "zhHans": "滞回方式"
    },
    "K_%E7%AA%81%E5%8F%98%E8%BF%87%E6%BB%A4": {
        "en": "Mutation Filtering",
        "zhHans": "突变过滤"
    },
    "K_%E7%A6%81%E6%AD%A2%E9%87%8D%E5%A4%8D": {
        "en": "No Duplication",
        "zhHans": "禁止重复"
    },
    "K_%E6%BB%9E%E5%9B%9E%E6%97%B6%E9%97%B4": {
        "en": "Delay Time",
        "zhHans": "滞回时间"
    },
    "K_%E6%BB%9E%E5%9B%9E%E5%80%BC": {
        "en": "Hysteresis Value",
        "zhHans": "滞回值"
    },
    "K_%E5%91%8A%E8%AD%A6%E6%97%B6%E6%AE%B5": {
        "en": "Alarm Period",
        "zhHans": "告警时段"
    },
    "K_%E5%91%8A%E8%AD%A6%E5%8F%91%E9%80%81%E5%99%A8": {
        "en": "Alarm Transmitter",
        "zhHans": "告警发送器"
    },
    "K_%E6%8E%A5%E8%AD%A6%E7%BB%84": {
        "en": "Receive Group",
        "zhHans": "接警组"
    },
    "K_%E7%B1%BB%E5%9E%8B": {
        "en": "Type",
        "zhHans": "类型"
    },
    "K_%E8%AE%BE%E5%A4%87%E6%95%B0": {
        "en": "Number Of Devices",
        "zhHans": "设备数"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E7%AB%AF%E5%8F%A3%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Port, Please Wait...",
        "zhHans": "正在获取端口,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E4%BF%9D%E5%AD%98%E9%85%8D%E7%BD%AE%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Saving Configuration, Please Wait...",
        "zhHans": "正在保存配置,请稍候..."
    },
    "K_%E5%AF%B9%E8%B1%A1%E6%A8%A1%E5%9E%8B%E4%B8%8D%E6%AD%A3%E7%A1%AE": {
        "en": "Incorrect Object Model",
        "zhHans": "对象模型不正确"
    },
    "K_%E6%AD%A3%E5%9C%A8%E6%B7%BB%E5%8A%A0%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Adding Agent, Please Wait...",
        "zhHans": "正在添加监控主机,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Deleting Agent, Please Wait...",
        "zhHans": "正在删除监控主机,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E6%B7%BB%E5%8A%A0%E8%AE%BE%E5%A4%87%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Adding Device, Please Wait...",
        "zhHans": "正在添加设备,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E8%AE%BE%E5%A4%87%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Deleting Device, Please Wait...",
        "zhHans": "正在删除设备,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%BF%81%E7%A7%BB%E5%AF%B9%E8%B1%A1%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Moving Objects, Please Wait...",
        "zhHans": "正在迁移对象,请稍候..."
    },
    "K_%E6%9C%BA%E6%9E%84%E4%BF%A1%E6%81%AF%E6%9C%89%E8%AF%AF": {
        "en": "Organizational Information Is Incorrect",
        "zhHans": "机构信息有误"
    },
    "K_%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF%E7%BC%BA%E5%A4%B1": {
        "en": "User Information Is Missing",
        "zhHans": "用户信息缺失"
    },
    "K_%E5%AF%BC%E5%85%A5%E9%A1%B5%E9%9D%A2": {
        "en": "Import Page",
        "zhHans": "导入页面"
    },
    "K_%E5%AF%BC%E5%87%BA%E6%9C%BA%E6%9E%84%E4%B8%8B%E6%89%80%E6%9C%89%E9%A1%B5%E9%9D%A2": {
        "en": "Export All Pages Under The Organization",
        "zhHans": "导出机构下所有页面"
    },
    "K_%E6%96%B0%E5%BB%BA%E9%A1%B5%E9%9D%A2": {
        "en": "Create New Page",
        "zhHans": "新建页面"
    },
    "K_%E8%BF%94%E5%9B%9E": {
        "en": "Return",
        "zhHans": "返回"
    },
    "K_%E5%BD%93%E5%89%8D%E4%B8%BB%E9%A1%B5": {
        "en": "Current Homepage",
        "zhHans": "当前主页"
    },
    "K_%E8%AE%BE%E4%B8%BA%E4%B8%BB%E9%A1%B5": {
        "en": "Set As Homepage",
        "zhHans": "设为主页"
    },
    "K_%E7%BC%96%E8%BE%91%E9%A1%B5%E9%9D%A2": {
        "en": "Edit Page",
        "zhHans": "编辑页面"
    },
    "K_%E5%AF%BC%E5%87%BA%E9%A1%B5%E9%9D%A2": {
        "en": "Export Page",
        "zhHans": "导出页面"
    },
    "K_%E5%88%A0%E9%99%A4%E9%A1%B5%E9%9D%A2": {
        "en": "Delete Page",
        "zhHans": "删除页面"
    },
    "K_%E6%B2%A1%E6%9C%89%E9%A1%B5%E9%9D%A2": {
        "en": "No Page",
        "zhHans": "没有页面"
    },
    "K_%E6%8E%A7%E4%BB%B6%E7%AE%B1": {
        "en": "Control Box",
        "zhHans": "控件箱"
    },
    "K_%E9%A1%B5%E9%9D%A2%E6%8E%A7%E4%BB%B6": {
        "en": "Page Controls",
        "zhHans": "页面控件"
    },
    "K_%E5%88%A0%E9%99%A4%E6%8E%A7%E4%BB%B6": {
        "en": "Delete Control",
        "zhHans": "删除控件"
    },
    "K_%E5%A4%8D%E5%88%B6": {
        "en": "Copy",
        "zhHans": "复制"
    },
    "K_%E7%B2%98%E8%B4%B4": {
        "en": "Paste",
        "zhHans": "粘贴"
    },
    "K_%E7%BD%AE%E4%BA%8E%E5%BA%95%E5%B1%82": {
        "en": "Put On Bottom",
        "zhHans": "置于底层"
    },
    "K_%E4%B8%8B%E4%B8%80%E5%B1%82": {
        "en": "Next Level",
        "zhHans": "下一层"
    },
    "K_%E4%B8%8A%E4%B8%80%E5%B1%82": {
        "en": "Previous Level",
        "zhHans": "上一层"
    },
    "K_%E7%BD%AE%E4%BA%8E%E9%A1%B6%E5%B1%82": {
        "en": "Bring To Top",
        "zhHans": "置于顶层"
    },
    "K_%E5%B7%A6%E7%AB%AF%E5%AF%B9%E9%BD%90": {
        "en": "Align Left",
        "zhHans": "左端对齐"
    },
    "K_%E6%B0%B4%E5%B9%B3%E5%B1%85%E4%B8%AD": {
        "en": "Center Horizontally",
        "zhHans": "水平居中"
    },
    "K_%E5%8F%B3%E7%AB%AF%E5%AF%B9%E9%BD%90": {
        "en": "Align Right",
        "zhHans": "右端对齐"
    },
    "K_%E9%A1%B6%E7%AB%AF%E5%AF%B9%E9%BD%90": {
        "en": "Align Top",
        "zhHans": "顶端对齐"
    },
    "K_%E5%9E%82%E7%9B%B4%E5%B1%85%E4%B8%AD": {
        "en": "Center Vertically",
        "zhHans": "垂直居中"
    },
    "K_%E5%BA%95%E7%AB%AF%E5%AF%B9%E9%BD%90": {
        "en": "Bottom Aligned",
        "zhHans": "底端对齐"
    },
    "K_%E5%A4%8D%E5%88%B6%E6%A0%B7%E5%BC%8F": {
        "en": "Copy Style",
        "zhHans": "复制样式"
    },
    "K_%E7%B2%98%E8%B4%B4%E6%A0%B7%E5%BC%8F": {
        "en": "Paste Style",
        "zhHans": "粘贴样式"
    },
    "K_%E7%BC%A9%E6%94%BE": {
        "en": "Zoom",
        "zhHans": "缩放"
    },
    "K_%E5%B1%9E%E6%80%A7%E7%BC%96%E8%BE%91%E5%99%A8": {
        "en": "Property Editor",
        "zhHans": "属性编辑器"
    },
    "K_%E9%A1%B5%E9%9D%A2%E5%B1%9E%E6%80%A7": {
        "en": "Page Properties",
        "zhHans": "页面属性"
    },
    "K_%E5%9F%BA%E6%9C%AC": {
        "en": "Basic",
        "zhHans": "基本"
    },
    "K_%E4%BD%8D%E7%BD%AE": {
        "en": "Location",
        "zhHans": "位置"
    },
    "K_%E5%A4%A7%E5%B0%8F": {
        "en": "Size",
        "zhHans": "大小"
    },
    "K_%E6%A0%B7%E5%BC%8F": {
        "en": "Style",
        "zhHans": "样式"
    },
    "K_%E9%AB%98%E7%BA%A7%E5%8F%82%E6%95%B0": {
        "en": "Advanced Parameters",
        "zhHans": "高级参数"
    },
    "K_%E7%A1%AE%E5%AE%9A%E5%88%A0%E9%99%A4%E9%A1%B5%E9%9D%A2": {
        "en": "Confirm To Delete Page",
        "zhHans": "确定删除页面"
    },
    "K_%E5%90%97": {
        "en": "",
        "zhHans": "吗"
    },
    "K_%E8%AF%B7%E9%80%89%E6%8B%A9%E5%AF%BC%E5%85%A5%E9%A1%B5%E9%9D%A2%E7%9A%84%E6%96%B9%E5%BC%8F%EF%BC%9A": {
        "en": "Please Choose How To Import Pages:",
        "zhHans": "请选择导入页面的方式："
    },
    "K_%E8%A6%86%E7%9B%96%E7%8E%B0%E6%9C%89%E9%A1%B5%E9%9D%A2": {
        "en": "Overwrite Existing Page",
        "zhHans": "覆盖现有页面"
    },
    "K_%E4%BD%9C%E4%B8%BA%E6%A8%A1%E6%9D%BF%E6%96%B0%E5%BB%BA%E9%A1%B5%E9%9D%A2": {
        "en": "Create A New Page As A Template",
        "zhHans": "作为模板新建页面"
    },
    "K_%E5%BD%93%E5%89%8D%E9%A1%B5%E9%9D%A2%E6%9C%AA%E4%BF%9D%E5%AD%98%EF%BC%8C%E5%88%87%E6%8D%A2%E7%95%8C%E9%9D%A2%E5%B0%86%E5%AF%BC%E8%87%B4%E5%BD%93%E5%89%8D%E7%BC%96%E8%BE%91%E5%86%85%E5%AE%B9%E4%B8%A2%E5%A4%B1%EF%BC%81": {
        "en": "The Current Page Has Not Been Saved. Switching Interfaces Will Cause The Current Editing Content To Be Lost!",
        "zhHans": "当前页面未保存，切换界面将导致当前编辑内容丢失！"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E4%B8%BB%E9%A1%B5%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Home Page, Please Wait...",
        "zhHans": "正在获取主页,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%AE%BE%E7%BD%AE%E4%B8%BB%E9%A1%B5%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Setting Up Homepage, Please Wait...",
        "zhHans": "正在设置主页,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%8A%A0%E8%BD%BD%E9%A1%B5%E9%9D%A2%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Loading Page, Please Wait...",
        "zhHans": "正在加载页面,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%88%9B%E5%BB%BA%E9%A1%B5%E9%9D%A2%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Page Is Being Created, Please Wait...",
        "zhHans": "正在创建页面,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E9%A1%B5%E9%9D%A2%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Deleting Page, Please Wait...",
        "zhHans": "正在删除页面,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E4%BF%9D%E5%AD%98%E9%A1%B5%E9%9D%A2%E9%85%8D%E7%BD%AE%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Saving Page Configuration, Please Wait...",
        "zhHans": "正在保存页面配置,请稍候..."
    },
    "K_%E4%BF%9D%E5%AD%98%E6%88%90%E5%8A%9F": {
        "en": "Saved Successfully",
        "zhHans": "保存成功"
    },
    "K_%E5%89%AF%E6%9C%AC": {
        "en": "Copy",
        "zhHans": "副本"
    },
    "K_%E7%B2%98%E8%B4%B4%E6%A0%B7%E5%BC%8F%E5%BF%85%E9%A1%BB%E6%98%AF%E7%9B%B8%E5%90%8C%E7%B1%BB%E5%9E%8B%E7%9A%84%E9%A1%B5%E9%9D%A2%E6%8E%A7%E4%BB%B6": {
        "en": "The Pasted Style Must Be The Same Type Of Page Control",
        "zhHans": "粘贴样式必须是相同类型的页面控件"
    },
    "K_%E6%96%87%E4%BB%B6%E5%86%85%E5%AE%B9%E9%94%99%E8%AF%AF": {
        "en": "File Content Error",
        "zhHans": "文件内容错误"
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%AF%BC%E5%85%A5%E9%A1%B5%E9%9D%A2%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Importing Page, Please Wait...",
        "zhHans": "正在导入页面,请稍候..."
    },
    "K_%E6%97%B6%E6%AE%B5": {
        "en": "Period",
        "zhHans": "时段"
    },
    "K_%E6%97%B6%E9%97%B4": {
        "en": "Time",
        "zhHans": "时间"
    },
    "K_%E6%98%9F%E6%9C%9F%E4%B8%80": {
        "en": "Monday",
        "zhHans": "星期一"
    },
    "K_%E6%98%9F%E6%9C%9F%E4%BA%8C": {
        "en": "Tuesday",
        "zhHans": "星期二"
    },
    "K_%E6%98%9F%E6%9C%9F%E4%B8%89": {
        "en": "Wednesday",
        "zhHans": "星期三"
    },
    "K_%E6%98%9F%E6%9C%9F%E5%9B%9B": {
        "en": "Thursday",
        "zhHans": "星期四"
    },
    "K_%E6%98%9F%E6%9C%9F%E4%BA%94": {
        "en": "Friday",
        "zhHans": "星期五"
    },
    "K_%E6%98%9F%E6%9C%9F%E5%85%AD": {
        "en": "Saturday",
        "zhHans": "星期六"
    },
    "K_%E6%98%9F%E6%9C%9F%E5%A4%A9": {
        "en": "Sunday",
        "zhHans": "星期天"
    },
    "K_%E6%98%AF%E5%90%A6%E5%88%A0%E9%99%A4%E6%97%B6%E6%AE%B5%3F": {
        "en": "Sure To Delete Period?",
        "zhHans": "是否删除时段?"
    },
    "K_%E6%98%AF%E5%90%A6%E5%88%A0%E9%99%A4%E6%97%B6%E9%97%B4%3F": {
        "en": "Sure To Delete Time?",
        "zhHans": "是否删除时间?"
    },
    "K_%E6%B7%BB%E5%8A%A0": {
        "en": "Add",
        "zhHans": "添加"
    },
    "K_%E5%BC%80%E5%A7%8B%E6%97%B6%E9%97%B4": {
        "en": "Start Time",
        "zhHans": "开始时间"
    },
    "K_%E7%BB%93%E6%9D%9F%E6%97%B6%E9%97%B4": {
        "en": "End Time",
        "zhHans": "结束时间"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E6%97%B6%E9%97%B4%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Time, Please Wait...",
        "zhHans": "正在获取时间,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E6%8F%90%E4%BA%A4%E6%97%B6%E6%AE%B5%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Submitting Period, Please Wait...",
        "zhHans": "正在提交时段,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E6%97%B6%E6%AE%B5%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Deleting Period, Please Wait...",
        "zhHans": "正在删除时段,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E6%8F%90%E4%BA%A4%E6%97%B6%E9%97%B4%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Submitting Time, Please Wait...",
        "zhHans": "正在提交时间,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E6%97%B6%E9%97%B4%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Deleting Time, Please Wait...",
        "zhHans": "正在删除时间,请稍候..."
    },
    "K_%E6%B7%BB%E5%8A%A0%E7%AD%96%E7%95%A5": {
        "en": "Add Policy",
        "zhHans": "添加策略"
    },
    "K_%E5%91%8A%E8%AD%A6%E5%85%B3%E8%81%94": {
        "en": "Alarm Association",
        "zhHans": "告警关联"
    },
    "K_%E8%AE%BE%E5%A4%87%E8%81%94%E5%8A%A8": {
        "en": "Device Linkage",
        "zhHans": "设备联动"
    },
    "K_%E5%AE%9A%E6%97%B6%E4%BB%BB%E5%8A%A1": {
        "en": "Scheduled Tasks",
        "zhHans": "定时任务"
    },
    "K_%E6%98%AF%E5%90%A6%E5%88%A0%E9%99%A4%E6%AD%A4%E7%AD%96%E7%95%A5%3F": {
        "en": "Sure To Delete This Policy?",
        "zhHans": "是否删除此策略?"
    },
    "K_%E5%85%B3%E8%81%94%E6%BA%90": {
        "en": "Associated Source",
        "zhHans": "关联源"
    },
    "K_%E5%85%B3%E8%81%94%E6%9D%A1%E4%BB%B6": {
        "en": "Associated Conditions",
        "zhHans": "关联条件"
    },
    "K_%E5%85%B3%E8%81%94%E7%9B%AE%E6%A0%87": {
        "en": "Associated Target",
        "zhHans": "关联目标"
    },
    "K_%E8%81%94%E5%8A%A8%E6%BA%90": {
        "en": "Linkage Source",
        "zhHans": "联动源"
    },
    "K_%E4%BD%BF%E7%94%A8%E8%84%9A%E6%9C%AC": {
        "en": "Use Script",
        "zhHans": "使用脚本"
    },
    "K_%E4%BD%BF%E7%94%A8": {
        "en": "Use",
        "zhHans": "使用"
    },
    "K_%E4%B8%8D%E4%BD%BF%E7%94%A8": {
        "en": "Not Used",
        "zhHans": "不使用"
    },
    "K_%E8%81%94%E5%8A%A8%E8%84%9A%E6%9C%AC": {
        "en": "Linkage Script",
        "zhHans": "联动脚本"
    },
    "K_%E8%A7%A6%E5%8F%91%E6%9D%A1%E4%BB%B6": {
        "en": "Trigger Condition",
        "zhHans": "触发条件"
    },
    "K_%E6%9D%A1%E4%BB%B6%E5%80%BC": {
        "en": "Condition Value",
        "zhHans": "条件值"
    },
    "K_%E5%BB%B6%E6%97%B6%E6%97%B6%E9%97%B4(%E7%A7%92)": {
        "en": "Delay Time (seconds)",
        "zhHans": "延时时间(秒)"
    },
    "K_%E9%97%B4%E9%9A%94%E6%97%B6%E9%97%B4(%E7%A7%92)": {
        "en": "Interval Time (seconds)",
        "zhHans": "间隔时间(秒)"
    },
    "K_%E9%87%8D%E5%A4%8D%E6%AC%A1%E6%95%B0": {
        "en": "Repeat Times",
        "zhHans": "重复次数"
    },
    "K_%E4%BB%BB%E5%8A%A1%E7%B1%BB%E5%9E%8B": {
        "en": "Task Type",
        "zhHans": "任务类型"
    },
    "K_%E7%B3%BB%E7%BB%9F%E8%BF%90%E8%A1%8C%E7%8A%B6%E5%86%B5": {
        "en": "System Health",
        "zhHans": "系统运行状况"
    },
    "K_%E6%99%BA%E8%83%BD%E5%B7%A1%E6%A3%80": {
        "en": "Intelligent Inspection",
        "zhHans": "智能巡检"
    },
    "K_%E6%8E%88%E6%9D%83%E5%88%B0%E6%9C%9F%E6%8F%90%E9%86%92": {
        "en": "Authorization Expiration Reminder",
        "zhHans": "授权到期提醒"
    },
    "K_%E5%AE%9A%E6%97%B6%E6%8E%A7%E5%88%B6": {
        "en": "Timing Control",
        "zhHans": "定时控制"
    },
    "K_%E5%AE%9A%E6%97%B6%E6%8F%90%E9%86%92": {
        "en": "Regular Reminder",
        "zhHans": "定时提醒"
    },
    "K_%E6%89%A7%E8%A1%8C%E6%97%B6%E9%97%B4": {
        "en": "Execution Time",
        "zhHans": "执行时间"
    },
    "K_%E4%BB%BB%E5%8A%A1%E5%86%85%E5%AE%B9": {
        "en": "Task Content",
        "zhHans": "任务内容"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E5%91%8A%E8%AD%A6%E5%85%B3%E8%81%94%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Alarm Association, Please Wait...",
        "zhHans": "正在获取告警关联,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E8%AE%BE%E5%A4%87%E8%81%94%E5%8A%A8%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Device Linkage, Please Wait...",
        "zhHans": "正在获取设备联动,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E5%AE%9A%E6%97%B6%E4%BB%BB%E5%8A%A1%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Scheduled Tasks, Please Wait...",
        "zhHans": "正在获取定时任务,请稍候..."
    },
    "K_%E8%81%94%E5%8A%A8%E6%BA%90%E6%88%96%E8%81%94%E5%8A%A8%E7%9B%AE%E6%A0%87%E6%9C%AA%E8%AE%BE%E7%BD%AE": {
        "en": "Linkage Source Or Linkage Target Is Not Set",
        "zhHans": "联动源或联动目标未设置"
    },
    "K_%E8%AF%B7%E9%80%89%E6%8B%A9%E7%B1%BB%E5%9E%8B": {
        "en": "Please Select Type",
        "zhHans": "请选择类型"
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E7%AD%96%E7%95%A5%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Deleting Policy, Please Wait...",
        "zhHans": "正在删除策略,请稍候..."
    },
    "K_%E7%94%A8%E6%88%B7": {
        "en": "User",
        "zhHans": "用户"
    },
    "K_%E6%98%AF%E5%90%A6%E5%88%A0%E9%99%A4%E6%8E%A5%E8%AD%A6%E7%BB%84%3F": {
        "en": "Sure To Delete Receive Group?",
        "zhHans": "是否删除接警组?"
    },
    "K_%E6%98%AF%E5%90%A6%E7%A7%BB%E9%99%A4%E7%94%A8%E6%88%B7%3F": {
        "en": "Sure To Remove User?",
        "zhHans": "是否移除用户?"
    },
    "K_%E6%B7%BB%E5%8A%A0%E7%94%A8%E6%88%B7": {
        "en": "Add User",
        "zhHans": "添加用户"
    },
    "K_%E6%89%80%E5%B1%9E%E6%9C%BA%E6%9E%84": {
        "en": "Organization",
        "zhHans": "所属机构"
    },
    "K_%E9%82%AE%E7%AE%B1%E5%9C%B0%E5%9D%80": {
        "en": "Email Address",
        "zhHans": "邮箱地址"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E6%8E%A5%E8%AD%A6%E7%BB%84%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Receive Group, Please Wait...",
        "zhHans": "正在获取接警组,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E7%94%A8%E6%88%B7%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Users, Please Wait...",
        "zhHans": "正在获取用户,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E6%8F%90%E4%BA%A4%E6%8E%A5%E8%AD%A6%E7%BB%84%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Submitting Receive Group, Please Wait...",
        "zhHans": "正在提交接警组,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E6%8E%A5%E8%AD%A6%E7%BB%84%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Deleting Receive Group, Please Wait...",
        "zhHans": "正在删除接警组,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E6%B7%BB%E5%8A%A0%E7%94%A8%E6%88%B7%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Adding User, Please Wait...",
        "zhHans": "正在添加用户,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%B0%83%E6%95%B4%E7%94%A8%E6%88%B7%E9%A1%BA%E5%BA%8F%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Adjusting User Order, Please Wait...",
        "zhHans": "正在调整用户顺序,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E7%A7%BB%E9%99%A4%E7%94%A8%E6%88%B7%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Removing User, Please Wait...",
        "zhHans": "正在移除用户,请稍候..."
    },
    "K_%E5%AF%BC%E5%85%A5%E8%B5%84%E6%BA%90": {
        "en": "Import Resources",
        "zhHans": "导入资源"
    },
    "K_%E5%AF%BC%E5%87%BA%E8%B5%84%E6%BA%90": {
        "en": "Export Resources",
        "zhHans": "导出资源"
    },
    "K_%E4%B8%8A%E4%BC%A0%E6%96%B0%E8%B5%84%E6%BA%90": {
        "en": "Upload New Resources",
        "zhHans": "上传新资源"
    },
    "K_%E6%B2%A1%E6%9C%89%E8%B5%84%E6%BA%90": {
        "en": "No Resources",
        "zhHans": "没有资源"
    },
    "K_%E4%B8%8B%E8%BD%BD": {
        "en": "Download",
        "zhHans": "下载"
    },
    "K_%E7%94%9F%E6%88%90%E7%BC%A9%E7%95%A5%E5%9B%BE": {
        "en": "Generate Thumbnails",
        "zhHans": "生成缩略图"
    },
    "K_%E6%98%AF%E5%90%A6%E5%88%A0%E9%99%A4": {
        "en": "Sure To Delete",
        "zhHans": "是否删除"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E8%B5%84%E6%BA%90%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Resources, Please Wait...",
        "zhHans": "正在获取资源,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%AF%BC%E5%87%BA%E8%B5%84%E6%BA%90%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Exporting Resources, Please Wait...",
        "zhHans": "正在导出资源,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%AF%BC%E5%87%BA%E8%B5%84%E6%BA%90": {
        "en": "Exporting Resources",
        "zhHans": "正在导出资源"
    },
    "K_%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Please Wait",
        "zhHans": "请稍候"
    },
    "K_%E8%B5%84%E6%BA%90": {
        "en": "Resource",
        "zhHans": "资源"
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%AF%BC%E5%85%A5%E8%B5%84%E6%BA%90%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Importing Resources, Please Wait...",
        "zhHans": "正在导入资源,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E6%B7%BB%E5%8A%A0%E8%B5%84%E6%BA%90": {
        "en": "Adding Resources",
        "zhHans": "正在添加资源"
    },
    "K_%E6%AD%A3%E5%9C%A8%E6%9B%B4%E6%96%B0%E8%B5%84%E6%BA%90": {
        "en": "Updating Resources",
        "zhHans": "正在更新资源"
    },
    "K_%E6%AD%A3%E5%9C%A8%E4%B8%8B%E8%BD%BD%E8%B5%84%E6%BA%90%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Downloading Resources, Please Wait...",
        "zhHans": "正在下载资源,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E7%94%9F%E6%88%90%E7%BC%A9%E7%95%A5%E5%9B%BE%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Generating Thumbnails, Please Wait...",
        "zhHans": "正在生成缩略图,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E8%B5%84%E6%BA%90%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Deleting Resources, Please Wait...",
        "zhHans": "正在删除资源,请稍候..."
    },
    "K_%E6%96%B0%E5%BB%BA%E7%94%A8%E6%88%B7": {
        "en": "Create New User",
        "zhHans": "新建用户"
    },
    "K_%E6%98%AF%E5%90%A6%E5%88%A0%E9%99%A4%E7%94%A8%E6%88%B7": {
        "en": "Sure To Delete User",
        "zhHans": "是否删除用户"
    },
    "K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E7%94%A8%E6%88%B7%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Deleting User, Please Wait...",
        "zhHans": "正在删除用户,请稍候..."
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%BF%81%E7%A7%BB%E7%94%A8%E6%88%B7%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Moving Users, Please Wait...",
        "zhHans": "正在迁移用户,请稍候..."
    },
    "K_%E7%AD%9B%E9%80%89%E6%9D%A1%E4%BB%B6": {
        "en": "Filter",
        "zhHans": "筛选条件"
    },
    "K_%E6%97%B6%E9%97%B4%E6%9D%A1%E4%BB%B6": {
        "en": "Time Condition",
        "zhHans": "时间条件"
    },
    "K_%E7%BB%93%E6%9D%9F%E6%97%A5%E6%9C%9F": {
        "en": "End Date",
        "zhHans": "结束日期"
    },
    "K_%E6%9F%A5%E8%AF%A2": {
        "en": "Query",
        "zhHans": "查询"
    },
    "K_%E6%97%B6%E9%97%B4%E6%AE%B5": {
        "en": "Period",
        "zhHans": "时间段"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E7%9B%91%E6%8E%A7%E5%8D%95%E5%85%83%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Agent, Please Wait...",
        "zhHans": "正在获取监控单元,请稍候..."
    },
    "K_%E4%BA%8B%E4%BB%B6%E7%B1%BB%E5%9E%8B": {
        "en": "Event Type",
        "zhHans": "事件类型"
    },
    "K_%E7%B3%BB%E7%BB%9F%E4%BA%8B%E4%BB%B6": {
        "en": "System Events",
        "zhHans": "系统事件"
    },
    "K_%E8%BF%90%E7%BB%B4%E4%BA%8B%E4%BB%B6": {
        "en": "Operation And Maintenance Events",
        "zhHans": "运维事件"
    },
    "K_%E5%91%8A%E8%AD%A6%E4%BA%8B%E4%BB%B6": {
        "en": "Alarm Event",
        "zhHans": "告警事件"
    },
    "K_%E5%85%A8%E9%83%A8": {
        "en": "All",
        "zhHans": "全部"
    },
    "K_%E7%A1%AE%E8%AE%A4%E4%BA%BA": {
        "en": "Confirmor",
        "zhHans": "确认人"
    },
    "K_%E7%A1%AE%E8%AE%A4%E6%97%B6%E9%97%B4": {
        "en": "Confirmation Time",
        "zhHans": "确认时间"
    },
    "K_%E4%BA%8B%E4%BB%B6%E6%8F%8F%E8%BF%B0": {
        "en": "Event Description",
        "zhHans": "事件描述"
    },
    "K_%E6%9C%AA%E7%9F%A5": {
        "en": "Unknown",
        "zhHans": "未知"
    },
    "K_%E5%AE%A2%E6%88%B7%E7%AB%AF%E5%9C%B0%E5%9D%80": {
        "en": "Client Address",
        "zhHans": "客户端地址"
    },
    "K_%E6%93%8D%E4%BD%9C%E7%BB%93%E6%9E%9C": {
        "en": "Operation Result",
        "zhHans": "操作结果"
    },
    "K_%E5%A4%B1%E8%B4%A5": {
        "en": "Fail",
        "zhHans": "失败"
    },
    "K_%E6%88%90%E5%8A%9F": {
        "en": "Success",
        "zhHans": "成功"
    },
    "K_%E7%9B%91%E6%8E%A7": {
        "en": "Monitor",
        "zhHans": "监控"
    },
    "K_%E8%AE%BE%E5%A4%87%E7%9B%91%E6%8E%A7": {
        "en": "Device Monitor",
        "zhHans": "设备监控"
    },
    "K_%E9%85%8D%E7%BD%AE-%E9%A1%B5%E9%9D%A2": {
        "en": "Configuration-Page",
        "zhHans": "配置-页面"
    },
    "K_%E9%A1%B5%E9%9D%A2%E7%BB%84%E6%80%81": {
        "en": "Page Design",
        "zhHans": "页面组态"
    },
    "K_%E8%B5%84%E6%BA%90%E9%85%8D%E7%BD%AE": {
        "en": "Resource",
        "zhHans": "资源配置"
    },
    "K_%E9%85%8D%E7%BD%AE-%E8%AE%BE%E5%A4%87": {
        "en": "Configuration-Device",
        "zhHans": "配置-设备"
    },
    "K_%E8%AE%BE%E5%A4%87%E7%BB%84%E6%80%81": {
        "en": "Device",
        "zhHans": "设备组态"
    },
    "K_%E7%AD%96%E7%95%A5%E9%85%8D%E7%BD%AE": {
        "en": "Policy",
        "zhHans": "策略配置"
    },
    "K_%E8%B5%84%E4%BA%A7%E4%BF%A1%E6%81%AF": {
        "en": "Assets",
        "zhHans": "资产信息"
    },
    "K_%E9%85%8D%E7%BD%AE-%E7%94%A8%E6%88%B7": {
        "en": "Config-User",
        "zhHans": "配置-用户"
    },
    "K_%E7%94%A8%E6%88%B7%E7%AE%A1%E7%90%86": {
        "en": "User",
        "zhHans": "用户管理"
    },
    "K_%E6%8E%A5%E8%AD%A6%E7%BB%84%E7%AE%A1%E7%90%86": {
        "en": "Receive Group",
        "zhHans": "接警组管理"
    },
    "K_%E6%8E%A5%E8%AD%A6%E6%97%B6%E6%AE%B5%E7%AE%A1%E7%90%86": {
        "en": "Alarm Period",
        "zhHans": "接警时段管理"
    },
    "K_%E8%AE%B0%E5%BD%95": {
        "en": "Record",
        "zhHans": "记录"
    },
    "K_%E5%8E%86%E5%8F%B2%E4%BA%8B%E4%BB%B6": {
        "en": "Historical Events",
        "zhHans": "历史事件"
    },
    "K_%E6%93%8D%E4%BD%9C%E6%97%A5%E5%BF%97": {
        "en": "Operation Log",
        "zhHans": "操作日志"
    },
    "K_%E7%B3%BB%E7%BB%9F": {
        "en": "System",
        "zhHans": "系统"
    },
    "K_%E5%AD%98%E5%82%A8%E9%85%8D%E7%BD%AE": {
        "en": "Storage",
        "zhHans": "存储配置"
    },
    "K_%E5%85%B3%E9%97%AD%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5": {
        "en": "Turn Off Voice Broadcast",
        "zhHans": "关闭语音播报"
    },
    "K_%E5%BC%80%E5%90%AF%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5": {
        "en": "Turn On Voice Broadcast",
        "zhHans": "开启语音播报"
    },
    "K_%E5%85%B3%E9%97%AD%E5%91%8A%E8%AD%A6%E5%AD%97%E5%B9%95": {
        "en": "Turn Off Warning Subtitles",
        "zhHans": "关闭告警字幕"
    },
    "K_%E5%BC%80%E5%90%AF%E5%91%8A%E8%AD%A6%E5%AD%97%E5%B9%95": {
        "en": "Turn On Warning Subtitles",
        "zhHans": "开启告警字幕"
    },
    "K_%E5%9C%A8%E7%BA%BF": {
        "en": "Online",
        "zhHans": "在线"
    },
    "K_%E7%A6%81%E7%94%A8": {
        "en": "Disable",
        "zhHans": "禁用"
    },
    "K_%E6%AD%A3%E5%B8%B8": {
        "en": "Normal",
        "zhHans": "正常"
    },
    "K_%E6%96%87%E6%9C%AC%E5%91%8A%E8%AD%A6": {
        "en": "Text Alert",
        "zhHans": "文本告警"
    },
    "K_%E6%8E%A7%E5%88%B6": {
        "en": "Control",
        "zhHans": "控制"
    },
    "K_%E5%9C%B0%E5%9D%80": {
        "en": "Address",
        "zhHans": "地址"
    },
    "K_%E5%BD%93%E5%89%8D%E5%80%BC": {
        "en": "Current Data",
        "zhHans": "当前值"
    },
    "K_%E6%AD%A3%E5%9C%A8%E6%8E%A7%E5%88%B6%E6%B5%8B%E7%82%B9%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Controlling Spots, Please Wait...",
        "zhHans": "正在控制测点,请稍候..."
    },
    "K_%E6%8E%A7%E5%88%B6%E6%88%90%E5%8A%9F": {
        "en": "Control Success",
        "zhHans": "控制成功"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E7%8A%B6%E6%80%81%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Status, Please Wait...",
        "zhHans": "正在获取状态,请稍候..."
    },
    "K_%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5%E5%B7%B2%E5%BC%80%E5%90%AF": {
        "en": "Voice Broadcast Is Turned On",
        "zhHans": "语音播报已开启"
    },
    "K_%E5%BD%93%E5%89%8D%E8%AE%BE%E5%A4%87%E6%97%A0%E6%B3%95%E8%BF%9B%E8%A1%8C%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5": {
        "en": "The Current Device Cannot Perform Voice Broadcast.",
        "zhHans": "当前设备无法进行语音播报"
    },
    "K_%E8%BF%94%E5%9B%9E%E5%89%8D%E4%B8%80%E9%A1%B5": {
        "en": "Return To Previous Page",
        "zhHans": "返回前一页"
    },
    "K_%E8%BF%94%E5%9B%9E%E4%B8%BB%E9%A1%B5": {
        "en": "Return To Home Page",
        "zhHans": "返回主页"
    },
    "K_%E9%A1%B5%E9%9D%A2%E8%BD%AE%E8%AF%A2": {
        "en": "Page Polling",
        "zhHans": "页面轮询"
    },
    "K_%E6%8B%89%E4%BC%B8%E6%96%B9%E5%BC%8F": {
        "en": "Stretching Method",
        "zhHans": "拉伸方式"
    },
    "K_%E5%85%A8%E5%B1%8F%E6%B5%8F%E8%A7%88": {
        "en": "View Full Screen",
        "zhHans": "全屏浏览"
    },
    "K_%E9%80%80%E5%87%BA%E5%85%A8%E5%B1%8F": {
        "en": "Exit Full Screen",
        "zhHans": "退出全屏"
    },
    "K_%E6%B7%BB%E5%8A%A0%E8%BD%AE%E8%AF%A2%E9%A1%B5%E9%9D%A2": {
        "en": "Add Polling Page",
        "zhHans": "添加轮询页面"
    },
    "K_%E8%BD%AE%E8%AF%A2%E7%8A%B6%E6%80%81%EF%BC%9A": {
        "en": "Polling Status:",
        "zhHans": "轮询状态："
    },
    "K_%E5%81%9C%E6%AD%A2%E8%BD%AE%E8%AF%A2": {
        "en": "Stop Polling",
        "zhHans": "停止轮询"
    },
    "K_%E5%90%AF%E5%8A%A8%E8%BD%AE%E8%AF%A2": {
        "en": "Start Polling",
        "zhHans": "启动轮询"
    },
    "K_%E5%8E%9F%E5%A7%8B%E5%B0%BA%E5%AF%B8": {
        "en": "Original Size",
        "zhHans": "原始尺寸"
    },
    "K_%E6%B0%B4%E5%B9%B3%E6%8B%89%E4%BC%B8": {
        "en": "Horizontal Stretch",
        "zhHans": "水平拉伸"
    },
    "K_%E5%85%A8%E5%B1%8F%E6%8B%89%E4%BC%B8": {
        "en": "Full Screen",
        "zhHans": "全屏拉伸"
    },
    "K_%E6%AD%A4%E6%9C%BA%E6%9E%84%E6%B2%A1%E6%9C%89%E8%AE%BE%E7%BD%AE%E4%B8%BB%E9%A1%B5": {
        "en": "This Organization Does Not Have A Home Page",
        "zhHans": "此机构没有设置主页"
    },
    "K_%E6%B2%A1%E6%9C%89%E6%9B%B4%E5%A4%9A%E7%9A%84%E6%B5%8F%E8%A7%88%E5%8E%86%E5%8F%B2": {
        "en": "No More Browsing History",
        "zhHans": "没有更多的浏览历史"
    },
    "K_%E6%95%B0%E6%8D%AE%E4%BF%9D%E5%AD%98%E7%AD%96%E7%95%A5": {
        "en": "Data Save Policy",
        "zhHans": "数据保存策略"
    },
    "K_%E6%AD%A3%E5%B8%B8%E4%BF%9D%E5%AD%98%E5%91%A8%E6%9C%9F": {
        "en": "Normal Save Period",
        "zhHans": "正常保存周期"
    },
    "K_%E7%A7%92": {
        "en": "Second",
        "zhHans": "秒"
    },
    "K_%E5%91%8A%E8%AD%A6%E4%BF%9D%E5%AD%98%E5%91%A8%E6%9C%9F": {
        "en": "Alarm Save Period",
        "zhHans": "告警保存周期"
    },
    "K_%E6%95%B0%E6%8D%AE%E6%B8%85%E9%99%A4%E7%AD%96%E7%95%A5": {
        "en": "Data Purge Policy",
        "zhHans": "数据清除策略"
    },
    "K_%E4%BF%9D%E7%95%99%E6%97%B6%E9%95%BF": {
        "en": "Save Time",
        "zhHans": "保留时长"
    },
    "K_%E5%A4%A9": {
        "en": "Day",
        "zhHans": "天"
    },
    "K_%E6%9C%88": {
        "en": "Month",
        "zhHans": "月"
    },
    "K_%E5%B9%B4": {
        "en": "Year",
        "zhHans": "年"
    },
    "K_%E6%8F%90%E4%BA%A4": {
        "en": "Submit",
        "zhHans": "提交"
    },
    "K_%E5%91%A8%E6%9C%9F%E6%80%A7%E4%BF%9D%E5%AD%98": {
        "en": "Periodicity Save",
        "zhHans": "周期性保存"
    },
    "K_%E5%8F%98%E5%8C%96%E7%8E%87%E4%BF%9D%E5%AD%98": {
        "en": "Rate Of Change Save",
        "zhHans": "变化率保存"
    },
    "K_%E5%91%A8%E6%9C%9F%E6%80%A7%E4%B8%8E%E5%8F%98%E5%8C%96%E7%8E%87%E4%BF%9D%E5%AD%98": {
        "en": "Periodicity And Rate Of Change Save",
        "zhHans": "周期性与变化率保存"
    },
    "K_%E5%91%A8%E6%9C%9F%E6%80%A7%E6%88%96%E5%8F%98%E5%8C%96%E7%8E%87%E4%BF%9D%E5%AD%98": {
        "en": "Periodicity Or Rate Of Change Save",
        "zhHans": "周期性或变化率保存"
    },
    "K_%E6%B0%B8%E4%B9%85%E4%BF%9D%E5%AD%98": {
        "en": "Save Permanently",
        "zhHans": "永久保存"
    },
    "K_%E4%BF%9D%E5%AD%98%E5%A4%A9%E6%95%B0": {
        "en": "Number Of Days To Save",
        "zhHans": "保存天数"
    },
    "K_%E4%BF%9D%E5%AD%98%E6%9C%88%E6%95%B0": {
        "en": "Number Of Months To Save",
        "zhHans": "保存月数"
    },
    "K_%E4%BF%9D%E5%AD%98%E5%B9%B4%E6%95%B0": {
        "en": "Number Of Years To Save",
        "zhHans": "保存年数"
    },
    "K_%E6%AD%A3%E5%9C%A8%E4%BF%9D%E5%AD%98%E7%AD%96%E7%95%A5%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Saving Policy, Please Wait...",
        "zhHans": "正在保存策略,请稍候..."
    },
    "K_%E6%8F%90%E4%BA%A4%E7%9A%84%E6%95%B0%E6%8D%AE%E4%B8%8D%E5%90%88%E6%B3%95": {
        "en": "The Data Submitted Is Illegal",
        "zhHans": "提交的数据不合法"
    },
    "K_%E6%B0%B4%E5%B9%B3%E5%9D%90%E6%A0%87": {
        "en": "Horizontal Coordinate",
        "zhHans": "水平坐标"
    },
    "K_%E5%9E%82%E7%9B%B4%E5%9D%90%E6%A0%87": {
        "en": "Vertical Coordinate",
        "zhHans": "垂直坐标"
    },
    "K_%E5%AE%BD%E5%BA%A6": {
        "en": "Width",
        "zhHans": "宽度"
    },
    "K_%E9%AB%98%E5%BA%A6": {
        "en": "Height",
        "zhHans": "高度"
    },
    "K_%E9%80%8F%E6%98%8E%E5%BA%A6": {
        "en": "Opacity",
        "zhHans": "透明度"
    },
    "K_%E9%98%B4%E5%BD%B1": {
        "en": "Shadow",
        "zhHans": "阴影"
    },
    "K_%E5%8F%98%E6%8D%A2": {
        "en": "Transform",
        "zhHans": "变换"
    },
    "K_%E5%86%85%E8%BE%B9%E8%B7%9D": {
        "en": "Padding",
        "zhHans": "内边距"
    },
    "K_%E5%A4%96%E8%BE%B9%E8%B7%9D": {
        "en": "Margins",
        "zhHans": "外边距"
    },
    "K_%E8%BE%B9%E6%A1%86%E6%A0%B7%E5%BC%8F": {
        "en": "Border Style",
        "zhHans": "边框样式"
    },
    "K_%E5%AE%9E%E7%BA%BF": {
        "en": "Solid",
        "zhHans": "实线"
    },
    "K_%E8%99%9A%E7%BA%BF": {
        "en": "Dashed",
        "zhHans": "虚线"
    },
    "K_%E7%82%B9%E7%8A%B6": {
        "en": "Dotted",
        "zhHans": "点状"
    },
    "K_%E5%8F%8C%E7%BA%BF": {
        "en": "Double",
        "zhHans": "双线"
    },
    "K_%E8%BE%B9%E6%A1%86%E5%A4%A7%E5%B0%8F": {
        "en": "Border Size",
        "zhHans": "边框大小"
    },
    "K_%E8%BE%B9%E6%A1%86%E9%A2%9C%E8%89%B2": {
        "en": "Border Color",
        "zhHans": "边框颜色"
    },
    "K_%E8%BE%B9%E6%A1%86%E5%9C%86%E8%A7%92": {
        "en": "Border Rounded Corners",
        "zhHans": "边框圆角"
    },
    "K_%E5%89%8D%E6%99%AF%E8%89%B2": {
        "en": "Foreground Color",
        "zhHans": "前景色"
    },
    "K_%E8%83%8C%E6%99%AF%E8%89%B2": {
        "en": "Background Color",
        "zhHans": "背景色"
    },
    "K_%E8%83%8C%E6%99%AF%E5%9B%BE": {
        "en": "Background Image",
        "zhHans": "背景图"
    },
    "K_%E8%83%8C%E6%99%AF%E9%87%8D%E5%A4%8D": {
        "en": "Background Repeat",
        "zhHans": "背景重复"
    },
    "K_%E4%B8%8D%E9%87%8D%E5%A4%8D": {
        "en": "Not Repeated",
        "zhHans": "不重复"
    },
    "K_%E6%B0%B4%E5%B9%B3%E9%87%8D%E5%A4%8D": {
        "en": "Repeat Horizontal",
        "zhHans": "水平重复"
    },
    "K_%E5%9E%82%E7%9B%B4%E9%87%8D%E5%A4%8D": {
        "en": "Repeat Vertically",
        "zhHans": "垂直重复"
    },
    "K_%E9%87%8D%E5%A4%8D": {
        "en": "Repeat",
        "zhHans": "重复"
    },
    "K_%E8%83%8C%E6%99%AF%E4%BD%8D%E7%BD%AE": {
        "en": "Background Position",
        "zhHans": "背景位置"
    },
    "K_%E5%B1%85%E4%B8%AD": {
        "en": "Center",
        "zhHans": "居中"
    },
    "K_%E4%B8%8A": {
        "en": "Top",
        "zhHans": "上"
    },
    "K_%E4%B8%8B": {
        "en": "Bottom",
        "zhHans": "下"
    },
    "K_%E5%B7%A6": {
        "en": "Left",
        "zhHans": "左"
    },
    "K_%E5%8F%B3": {
        "en": "Right",
        "zhHans": "右"
    },
    "K_%E8%83%8C%E6%99%AF%E5%B0%BA%E5%AF%B8": {
        "en": "Background Size",
        "zhHans": "背景尺寸"
    },
    "K_%E5%8E%9F%E5%9B%BE": {
        "en": "Original",
        "zhHans": "原图"
    },
    "K_%E6%8B%89%E4%BC%B8": {
        "en": "Stretch",
        "zhHans": "拉伸"
    },
    "K_%E5%8C%85%E5%90%AB": {
        "en": "Contain",
        "zhHans": "包含"
    },
    "K_%E8%A6%86%E7%9B%96": {
        "en": "Cover",
        "zhHans": "覆盖"
    },
    "K_%E6%96%87%E6%9C%AC%E5%AF%B9%E9%BD%90": {
        "en": "Text Alignment",
        "zhHans": "文本对齐"
    },
    "K_%E5%B7%A6%E5%AF%B9%E9%BD%90": {
        "en": "Align Left",
        "zhHans": "左对齐"
    },
    "K_%E5%8F%B3%E5%AF%B9%E9%BD%90": {
        "en": "Align Right",
        "zhHans": "右对齐"
    },
    "K_%E4%B8%A4%E7%AB%AF%E5%AF%B9%E9%BD%90": {
        "en": "Justify",
        "zhHans": "两端对齐"
    },
    "K_%E5%AD%97%E4%BD%93%E6%A0%B7%E5%BC%8F": {
        "en": "Font Style",
        "zhHans": "字体样式"
    },
    "K_%E6%96%9C%E4%BD%93": {
        "en": "Italic",
        "zhHans": "斜体"
    },
    "K_%E5%80%BE%E6%96%9C": {
        "en": "Oblique",
        "zhHans": "倾斜"
    },
    "K_%E5%AD%97%E4%BD%93%E5%A4%A7%E5%B0%8F": {
        "en": "Font Size",
        "zhHans": "字体大小"
    },
    "K_%E5%AD%97%E4%BD%93%E7%B2%97%E7%BB%86": {
        "en": "Font Weight",
        "zhHans": "字体粗细"
    },
    "K_%E7%B2%97%E4%BD%93": {
        "en": "Bold",
        "zhHans": "粗体"
    },
    "K_%E5%8A%A0%E7%B2%97": {
        "en": "Bolder",
        "zhHans": "加粗"
    },
    "K_%E5%8A%A0%E7%BB%86": {
        "en": "Lighter",
        "zhHans": "加细"
    },
    "K_%E5%8A%A8%E7%94%BB%E7%89%B9%E6%95%88": {
        "en": "Animation Effects",
        "zhHans": "动画特效"
    },
    "K_%E6%97%A0": {
        "en": "None",
        "zhHans": "无"
    },
    "K_%E8%B7%AF%E5%BE%84%E5%8A%A8%E7%94%BB": {
        "en": "Path Animation",
        "zhHans": "路径动画"
    },
    "K_%E9%A1%BA%E6%97%B6%E9%92%88%E6%97%8B%E8%BD%AC": {
        "en": "Rotate Clockwise",
        "zhHans": "顺时针旋转"
    },
    "K_%E9%80%86%E6%97%B6%E9%92%88%E6%97%8B%E8%BD%AC": {
        "en": "Counter Clockwise",
        "zhHans": "逆时针旋转"
    },
    "K_%E9%97%AA%E7%83%811": {
        "en": "Flash 1",
        "zhHans": "闪烁1"
    },
    "K_%E9%97%AA%E7%83%812": {
        "en": "Flash 2",
        "zhHans": "闪烁2"
    },
    "K_%E6%B0%B4%E5%B9%B3%E6%99%83%E5%8A%A8": {
        "en": "Horizontal Shaking",
        "zhHans": "水平晃动"
    },
    "K_%E5%9E%82%E7%9B%B4%E6%99%83%E5%8A%A8": {
        "en": "Vertical Shaking",
        "zhHans": "垂直晃动"
    },
    "K_%E5%B7%A6%E7%A7%BB": {
        "en": "Move Left",
        "zhHans": "左移"
    },
    "K_%E5%8F%B3%E7%A7%BB": {
        "en": "Move Right",
        "zhHans": "右移"
    },
    "K_%E5%B7%A6%E4%B8%8A%E7%A7%BB": {
        "en": "Move Left Up",
        "zhHans": "左上移"
    },
    "K_%E5%8F%B3%E4%B8%8A%E7%A7%BB": {
        "en": "Move Up Right",
        "zhHans": "右上移"
    },
    "K_%E5%8F%B3%E4%B8%8B%E7%A7%BB": {
        "en": "Move Right Down",
        "zhHans": "右下移"
    },
    "K_%E5%B7%A6%E4%B8%8B%E7%A7%BB": {
        "en": "Move Left Down",
        "zhHans": "左下移"
    },
    "K_%E5%8A%A8%E7%94%BB%E8%B7%AF%E5%BE%84": {
        "en": "Animation Path",
        "zhHans": "动画路径"
    },
    "K_%E5%8A%A8%E7%94%BB%E6%97%B6%E9%95%BF": {
        "en": "Animation Duration",
        "zhHans": "动画时长"
    },
    "K_%E5%8A%A8%E7%94%BB%E5%BB%B6%E8%BF%9F": {
        "en": "Animation Delay",
        "zhHans": "动画延迟"
    },
    "K_%E5%8A%A8%E7%94%BB%E9%80%9F%E5%BA%A6%E6%9B%B2%E7%BA%BF": {
        "en": "Animation Speed Curve",
        "zhHans": "动画速度曲线"
    },
    "K_%E5%8A%A8%E7%94%BB%E9%87%8D%E5%A4%8D%E6%96%B9%E5%90%91": {
        "en": "Animation Repeat Direction",
        "zhHans": "动画重复方向"
    },
    "K_%E6%AD%A3%E5%90%91": {
        "en": "Forward",
        "zhHans": "正向"
    },
    "K_%E6%AD%A3%E5%8F%8D%E5%90%91": {
        "en": "Forward And Reverse",
        "zhHans": "正反向"
    },
    "K_%E9%80%89%E9%A1%B9%E5%80%BC": {
        "en": "Option Value",
        "zhHans": "选项值"
    },
    "K_%E9%80%89%E9%A1%B9": {
        "en": "Options",
        "zhHans": "选项"
    },
    "K_%E8%A1%8C%E6%94%BF%E5%8C%BA%E5%88%92": {
        "en": "Administrative Division",
        "zhHans": "行政区划"
    },
    "K_%E5%AE%89%E5%BE%BD": {
        "en": "Anhui",
        "zhHans": "安徽"
    },
    "K_%E6%BE%B3%E9%97%A8": {
        "en": "Macao",
        "zhHans": "澳门"
    },
    "K_%E5%8C%97%E4%BA%AC": {
        "en": "Beijing",
        "zhHans": "北京"
    },
    "K_%E9%87%8D%E5%BA%86": {
        "en": "Chongqing",
        "zhHans": "重庆"
    },
    "K_%E7%A6%8F%E5%BB%BA": {
        "en": "Fujian",
        "zhHans": "福建"
    },
    "K_%E7%94%98%E8%82%83": {
        "en": "Gansu",
        "zhHans": "甘肃"
    },
    "K_%E5%B9%BF%E4%B8%9C": {
        "en": "Guangdong",
        "zhHans": "广东"
    },
    "K_%E5%B9%BF%E8%A5%BF": {
        "en": "Guangxi",
        "zhHans": "广西"
    },
    "K_%E8%B4%B5%E5%B7%9E": {
        "en": "Guizhou",
        "zhHans": "贵州"
    },
    "K_%E6%B5%B7%E5%8D%97": {
        "en": "Hainan",
        "zhHans": "海南"
    },
    "K_%E6%B2%B3%E5%8C%97": {
        "en": "Hebei",
        "zhHans": "河北"
    },
    "K_%E9%BB%91%E9%BE%99%E6%B1%9F": {
        "en": "Heilongjiang",
        "zhHans": "黑龙江"
    },
    "K_%E6%B2%B3%E5%8D%97": {
        "en": "Henan",
        "zhHans": "河南"
    },
    "K_%E6%B9%96%E5%8C%97": {
        "en": "Hubei",
        "zhHans": "湖北"
    },
    "K_%E6%B9%96%E5%8D%97": {
        "en": "Hunan",
        "zhHans": "湖南"
    },
    "K_%E6%B1%9F%E8%8B%8F": {
        "en": "Jiangsu",
        "zhHans": "江苏"
    },
    "K_%E6%B1%9F%E8%A5%BF": {
        "en": "Jiangxi",
        "zhHans": "江西"
    },
    "K_%E5%90%89%E6%9E%97": {
        "en": "Jilin",
        "zhHans": "吉林"
    },
    "K_%E8%BE%BD%E5%AE%81": {
        "en": "Liaoning",
        "zhHans": "辽宁"
    },
    "K_%E5%86%85%E8%92%99%E5%8F%A4": {
        "en": "Inner Mongolia",
        "zhHans": "内蒙古"
    },
    "K_%E5%AE%81%E5%A4%8F": {
        "en": "Ningxia",
        "zhHans": "宁夏"
    },
    "K_%E9%9D%92%E6%B5%B7": {
        "en": "Qinghai",
        "zhHans": "青海"
    },
    "K_%E5%B1%B1%E4%B8%9C": {
        "en": "Shandong",
        "zhHans": "山东"
    },
    "K_%E4%B8%8A%E6%B5%B7": {
        "en": "Shanghai",
        "zhHans": "上海"
    },
    "K_%E5%B1%B1%E8%A5%BF": {
        "en": "Shanxi",
        "zhHans": "山西"
    },
    "K_%E9%99%95%E8%A5%BF": {
        "en": "Shaanxi",
        "zhHans": "陕西"
    },
    "K_%E5%9B%9B%E5%B7%9D": {
        "en": "Sichuan",
        "zhHans": "四川"
    },
    "K_%E5%8F%B0%E6%B9%BE": {
        "en": "Taiwan",
        "zhHans": "台湾"
    },
    "K_%E5%A4%A9%E6%B4%A5": {
        "en": "Tianjin",
        "zhHans": "天津"
    },
    "K_%E9%A6%99%E6%B8%AF": {
        "en": "Hongkong",
        "zhHans": "香港"
    },
    "K_%E6%96%B0%E7%96%86": {
        "en": "Xinjiang",
        "zhHans": "新疆"
    },
    "K_%E8%A5%BF%E8%97%8F": {
        "en": "Tibet",
        "zhHans": "西藏"
    },
    "K_%E4%BA%91%E5%8D%97": {
        "en": "Yunnan",
        "zhHans": "云南"
    },
    "K_%E6%B5%99%E6%B1%9F": {
        "en": "Zhejiang",
        "zhHans": "浙江"
    },
    "K_%E5%85%B6%E4%BB%96": {
        "en": "Other",
        "zhHans": "其他"
    },
    "K_%E5%9C%B0%E5%9B%BE%E6%95%B0%E6%8D%AE": {
        "en": "Map Data",
        "zhHans": "地图数据"
    },
    "K_%E8%81%94%E6%9C%BA%E7%8A%B6%E6%80%81%E6%95%B0%E6%8D%AE": {
        "en": "Online Status Data",
        "zhHans": "联机状态数据"
    },
    "K_%E5%91%8A%E8%AD%A6%E7%8A%B6%E6%80%81%E6%95%B0%E6%8D%AE": {
        "en": "Alarm Status Data",
        "zhHans": "告警状态数据"
    },
    "K_%E5%9B%BE%E4%BE%8B%E4%BD%8D%E7%BD%AE": {
        "en": "Legend Location",
        "zhHans": "图例位置"
    },
    "K_%E5%B7%A6%E4%B8%8A": {
        "en": "Top Left",
        "zhHans": "左上"
    },
    "K_%E5%8F%B3%E4%B8%8A": {
        "en": "Top Right",
        "zhHans": "右上"
    },
    "K_%E5%B7%A6%E4%B8%8B": {
        "en": "Bottom Left",
        "zhHans": "左下"
    },
    "K_%E5%8F%B3%E4%B8%8B": {
        "en": "Bottom Right",
        "zhHans": "右下"
    },
    "K_%E9%9A%90%E8%97%8F": {
        "en": "Hide",
        "zhHans": "隐藏"
    },
    "K_%E6%A8%AA%E8%BD%B4%E6%95%B0%E6%8D%AE": {
        "en": "Horizontal Axis Data",
        "zhHans": "横轴数据"
    },
    "K_%E7%BA%B5%E8%BD%B4%E6%95%B0%E6%8D%AE": {
        "en": "Vertical Axis Data",
        "zhHans": "纵轴数据"
    },
    "K_%E9%A5%BC%E5%9B%BE%E5%8D%8A%E5%BE%84": {
        "en": "Pie Radius",
        "zhHans": "饼图半径"
    },
    "K_%E6%9C%80%E5%B0%8F%E5%80%BC": {
        "en": "Minimum Value",
        "zhHans": "最小值"
    },
    "K_%E6%9C%80%E5%A4%A7%E5%80%BC": {
        "en": "Maximum Value",
        "zhHans": "最大值"
    },
    "K_%E8%B5%B7%E5%A7%8B%E8%A7%92%E5%BA%A6": {
        "en": "Start Angle",
        "zhHans": "起始角度"
    },
    "K_%E7%BB%93%E6%9D%9F%E8%A7%92%E5%BA%A6": {
        "en": "End Angle",
        "zhHans": "结束角度"
    },
    "K_%E5%8D%95%E4%BD%8D": {
        "en": "Unit",
        "zhHans": "单位"
    },
    "K_%E8%A7%86%E9%A2%91%E5%9C%B0%E5%9D%80": {
        "en": "Video Address",
        "zhHans": "视频地址"
    },
    "K_%E5%AF%BC%E8%88%AA%E9%A1%B9": {
        "en": "Navigation Items",
        "zhHans": "导航项"
    },
    "K_%E5%AD%90%E9%A1%B5%E6%B5%8F%E8%A7%88%E5%99%A8ID": {
        "en": "Sub Frame ID",
        "zhHans": "子页浏览器ID"
    },
    "K_%E5%9C%B0%E5%9B%BE%E5%9D%90%E6%A0%87%E7%82%B9": {
        "en": "Map Coordinate Point",
        "zhHans": "地图坐标点"
    },
    "K_%E5%9C%B0%E5%9B%BE%E4%B8%AD%E5%BF%83": {
        "en": "Map Center",
        "zhHans": "地图中心"
    },
    "K_%E5%9C%B0%E5%9B%BE%E7%BC%A9%E6%94%BE%E7%BA%A7%E5%88%AB": {
        "en": "Map Zoom Level",
        "zhHans": "地图缩放级别"
    },
    "K_%E4%BB%AA%E8%A1%A8%E7%9B%B4%E5%BE%84": {
        "en": "Meter Diameter",
        "zhHans": "仪表直径"
    },
    "K_%E4%BB%AA%E8%A1%A8%E4%B8%AD%E5%BF%83": {
        "en": "Meter Center",
        "zhHans": "仪表中心"
    },
    "K_%E5%88%BB%E5%BA%A6%E5%88%86%E6%AE%B5": {
        "en": "Scale Segmentation",
        "zhHans": "刻度分段"
    },
    "K_%E9%A3%8E%E6%A0%BC": {
        "en": "Style",
        "zhHans": "风格"
    },
    "K_%E6%98%8E%E4%BA%AE": {
        "en": "Bright",
        "zhHans": "明亮"
    },
    "K_%E9%BB%91%E6%9A%97": {
        "en": "Dark",
        "zhHans": "黑暗"
    },
    "K_%E6%96%B9%E5%90%91": {
        "en": "Direction",
        "zhHans": "方向"
    },
    "K_%E6%B0%B4%E5%B9%B3": {
        "en": "Level",
        "zhHans": "水平"
    },
    "K_%E5%9E%82%E7%9B%B4": {
        "en": "Vertical",
        "zhHans": "垂直"
    },
    "K_%E8%A7%86%E9%A2%91%E5%8E%82%E5%95%86": {
        "en": "Video Manufacturer",
        "zhHans": "视频厂商"
    },
    "K_%E6%B5%B7%E5%BA%B7": {
        "en": "Hikvision",
        "zhHans": "海康"
    },
    "K_%E5%9B%BE%E6%A0%87": {
        "en": "Icon",
        "zhHans": "图标"
    },
    "K_CSV%E6%95%B0%E6%8D%AE": {
        "en": "CSV Data",
        "zhHans": "CSV数据"
    },
    "K_%E7%82%B9%E5%9D%90%E6%A0%87": {
        "en": "Point Coordinates",
        "zhHans": "点坐标"
    },
    "K_%E7%82%B9%E6%95%B0%E6%8D%AE": {
        "en": "Point Data",
        "zhHans": "点数据"
    },
    "K_%E9%97%B4%E8%B7%9D": {
        "en": "Spacing",
        "zhHans": "间距"
    },
    "K_%E5%AE%BD%E6%9D%BE": {
        "en": "Loose",
        "zhHans": "宽松"
    },
    "K_%E7%B4%A7%E5%87%91": {
        "en": "Compact",
        "zhHans": "紧凑"
    },
    "K_%E5%9B%BE%E8%A1%A8%E9%80%89%E9%A1%B9": {
        "en": "Chart Options",
        "zhHans": "图表选项"
    },
    "K_%E8%AE%BE%E5%A4%87ID": {
        "en": "Device ID",
        "zhHans": "设备ID"
    },
    "K_%E6%B5%8B%E7%82%B9%E7%BC%96%E5%8F%B7": {
        "en": "Spot Number",
        "zhHans": "测点编号"
    },
    "K_%E5%88%97%E6%95%B0": {
        "en": "Number Of Columns",
        "zhHans": "列数"
    },
    "K_%E5%91%8A%E8%AD%A6%E5%89%8D%E6%99%AF%E8%89%B2": {
        "en": "Alarm Foreground Color",
        "zhHans": "告警前景色"
    },
    "K_%E5%91%8A%E8%AD%A6%E8%BE%B9%E6%A1%86%E8%89%B2": {
        "en": "Alarm Border Color",
        "zhHans": "告警边框色"
    },
    "K_%E5%91%8A%E8%AD%A6%E8%83%8C%E6%99%AF%E8%89%B2": {
        "en": "Alarm Background Color",
        "zhHans": "告警背景色"
    },
    "K_%E5%91%8A%E8%AD%A6%E9%98%B4%E5%BD%B1": {
        "en": "Alarm Shadow",
        "zhHans": "告警阴影"
    },
    "K_%E8%A1%8C%E6%95%B0": {
        "en": "Number Of Lines",
        "zhHans": "行数"
    },
    "K_%E5%8D%95%E8%A1%8C": {
        "en": "Single Line",
        "zhHans": "单行"
    },
    "K_%E5%A4%9A%E8%A1%8C": {
        "en": "Multiple Lines",
        "zhHans": "多行"
    },
    "K_%E4%BA%8B%E4%BB%B6": {
        "en": "Event",
        "zhHans": "事件"
    },
    "K_%E9%A1%B9": {
        "en": "Item",
        "zhHans": "项"
    },
    "K_%E4%BA%8B%E4%BB%B6%E9%85%8D%E7%BD%AE": {
        "en": "Event Configuration",
        "zhHans": "事件配置"
    },
    "K_%E5%8A%A8%E4%BD%9C": {
        "en": "Action",
        "zhHans": "动作"
    },
    "K_%E6%B2%A1%E6%9C%89%E5%8A%A8%E4%BD%9C%E9%A1%B9": {
        "en": "No Action Items",
        "zhHans": "没有动作项"
    },
    "K_%E9%A1%B5%E9%9D%A2%E7%BB%91%E5%AE%9A": {
        "en": "Page Binding",
        "zhHans": "页面绑定"
    },
    "K_%E9%A1%B5%E9%9D%A2%E7%BB%91%E5%AE%9A%E9%85%8D%E7%BD%AE": {
        "en": "Page Binding Configuration",
        "zhHans": "页面绑定配置"
    },
    "K_%E9%80%89%E6%8B%A9%E5%9B%BE%E6%A0%87": {
        "en": "Select Icon",
        "zhHans": "选择图标"
    },
    "K_%E5%9C%B0%E5%9B%BE%E5%9D%90%E6%A0%87%E9%85%8D%E7%BD%AE": {
        "en": "Map Coordinate Configuration",
        "zhHans": "地图坐标配置"
    },
    "K_%E7%BB%8F%E7%BA%AC%E5%BA%A6": {
        "en": "Latitude And Longitude",
        "zhHans": "经纬度"
    },
    "K_%E6%B2%A1%E6%9C%89%E5%9C%B0%E5%9B%BE%E5%9D%90%E6%A0%87%E9%A1%B9": {
        "en": "No Map Coordinate Items",
        "zhHans": "没有地图坐标项"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E5%91%8A%E8%AD%A6%E5%8F%91%E9%80%81%E5%99%A8%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Alarm Sender, Please Wait...",
        "zhHans": "正在获取告警发送器,请稍候..."
    },
    "K_%E9%80%89%E9%A1%B9%E9%85%8D%E7%BD%AE": {
        "en": "Options Configuration",
        "zhHans": "选项配置"
    },
    "K_%E6%B2%A1%E6%9C%89%E9%80%89%E9%A1%B9": {
        "en": "No Option",
        "zhHans": "没有选项"
    },
    "K_%E9%80%89%E6%8B%A9%E5%9B%BE%E7%89%87": {
        "en": "Select Picture",
        "zhHans": "选择图片"
    },
    "K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E9%A1%B5%E9%9D%A2%E8%B5%84%E6%BA%90%2C%E8%AF%B7%E7%A8%8D%E5%80%99": {
        "en": "Retrieving Page Resources, Please Wait...",
        "zhHans": "正在获取页面资源,请稍候..."
    },
    "K_%E5%AE%9E%E6%97%B6%E8%A7%86%E9%A2%91%E5%9C%B0%E5%9D%80%E9%85%8D%E7%BD%AE": {
        "en": "Real-time Video Address Configuration",
        "zhHans": "实时视频地址配置"
    },
    "K_%E8%AE%BE%E5%A4%87%E7%AB%AF%E5%8F%A3": {
        "en": "Device Port",
        "zhHans": "设备端口"
    },
    "K_%E9%80%9A%E9%81%93": {
        "en": "Channel",
        "zhHans": "通道"
    },
    "K_%E7%A0%81%E6%B5%81": {
        "en": "Code Stream",
        "zhHans": "码流"
    },
    "K_%E5%A4%A7%E5%8D%8E": {
        "en": "Dahua",
        "zhHans": "大华"
    },
    "K_%E4%B8%BB%E7%A0%81%E6%B5%81": {
        "en": "Main Stream",
        "zhHans": "主码流"
    },
    "K_%E5%AD%90%E7%A0%81%E6%B5%81": {
        "en": "Substream",
        "zhHans": "子码流"
    },
    "K_%E8%AF%B7%E8%BE%93%E5%85%A5%E5%AE%8C%E6%95%B4%E4%BF%A1%E6%81%AF": {
        "en": "Please Enter Complete Information",
        "zhHans": "请输入完整信息"
    },
    "K_%E6%B0%B4%E5%B9%B3%E4%BD%8D%E7%A7%BB": {
        "en": "Horizontal Displacement",
        "zhHans": "水平位移"
    },
    "K_%E5%9E%82%E7%9B%B4%E4%BD%8D%E7%A7%BB": {
        "en": "Vertical Displacement",
        "zhHans": "垂直位移"
    },
    "K_%E8%B7%9D%E7%A6%BB": {
        "en": "Distance",
        "zhHans": "距离"
    },
    "K_%E5%AF%BC%E8%88%AA%E9%A1%B9%E9%85%8D%E7%BD%AE": {
        "en": "Navigation Item Configuration",
        "zhHans": "导航项配置"
    },
    "K_%E6%B2%A1%E6%9C%89%E5%AF%BC%E8%88%AA%E9%A1%B9": {
        "en": "No Navigation Items",
        "zhHans": "没有导航项"
    },
    "K_%E5%85%B3%E9%97%AD": {
        "en": "Close",
        "zhHans": "关闭"
    },
    "K_2D%E6%97%8B%E8%BD%AC": {
        "en": "2D Rotation",
        "zhHans": "2D旋转"
    },
    "K_3D%E6%97%8B%E8%BD%ACX": {
        "en": "3D RotationX",
        "zhHans": "3D旋转X"
    },
    "K_3D%E6%97%8B%E8%BD%ACY": {
        "en": "3D RotateY",
        "zhHans": "3D旋转Y"
    },
    "K_3D%E6%97%8B%E8%BD%ACZ": {
        "en": "3D Rotate Z",
        "zhHans": "3D旋转Z"
    },
    "K_%E5%80%BE%E6%96%9CX": {
        "en": "Tilt X",
        "zhHans": "倾斜X"
    },
    "K_%E5%80%BE%E6%96%9CY": {
        "en": "Tilt Y",
        "zhHans": "倾斜Y"
    },
    "K_%E5%8C%BA%E5%9D%97": {
        "en": "Block",
        "zhHans": "区块"
    },
    "K_%E5%AD%90%E9%A1%B5%E6%B5%8F%E8%A7%88%E5%99%A8": {
        "en": "Sub Frame",
        "zhHans": "子页浏览器"
    },
    "K_%E5%AF%BC%E8%88%AA": {
        "en": "Navigation",
        "zhHans": "导航"
    },
    "K_Rtsp%E8%A7%86%E9%A2%91": {
        "en": "Rtsp Video",
        "zhHans": "Rtsp视频"
    },
    "K_Rtsp%E5%9B%9E%E6%94%BE": {
        "en": "Rtsp Playback",
        "zhHans": "Rtsp回放"
    },
    "K_%E8%87%AA%E5%AE%9A%E4%B9%89%E5%9B%BE%E8%A1%A8": {
        "en": "Custom Charts",
        "zhHans": "自定义图表"
    },
    "K_%E6%9B%B2%E7%BA%BF%E5%9B%BE": {
        "en": "Graph",
        "zhHans": "曲线图"
    },
    "K_%E6%9F%B1%E7%8A%B6%E5%9B%BE": {
        "en": "Bar Chart",
        "zhHans": "柱状图"
    },
    "K_%E9%A5%BC%E5%9B%BE": {
        "en": "Pie Chart",
        "zhHans": "饼图"
    },
    "K_%E4%BB%AA%E8%A1%A8": {
        "en": "Meter",
        "zhHans": "仪表"
    },
    "K_%E8%A1%8C%E6%94%BF%E5%9C%B0%E5%9B%BE": {
        "en": "Administrative Map",
        "zhHans": "行政地图"
    },
    "K_%E5%AE%9A%E4%BD%8D%E5%9C%B0%E5%9B%BE": {
        "en": "Location Map",
        "zhHans": "定位地图"
    },
    "K_%E8%BE%93%E5%85%A5%E6%A1%86": {
        "en": "Input Box",
        "zhHans": "输入框"
    },
    "K_%E9%80%89%E6%8B%A9%E6%A1%86": {
        "en": "Selection Box",
        "zhHans": "选择框"
    },
    "K_%E6%8C%89%E9%92%AE": {
        "en": "Button",
        "zhHans": "按钮"
    },
    "K_CSV%E8%A1%A8%E6%A0%BC": {
        "en": "CSV Table",
        "zhHans": "CSV表格"
    },
    "K_%E5%90%8D%E7%A7%B0%2C%E5%80%BC%5Cn%E5%90%8D%E7%A7%B01%2C%E5%80%BC1": {
        "en": "Name,value\\nname1,value1",
        "zhHans": "名称,值\\n名称1,值1"
    },
    "K_%E9%A2%9C%E8%89%B2%E5%9C%BA": {
        "en": "Color Field",
        "zhHans": "颜色场"
    },
    "K_%E6%B5%8B%E7%82%B9%E5%88%97%E8%A1%A8": {
        "en": "Spot List",
        "zhHans": "测点列表"
    },
    "K_%E5%A4%96%E9%93%BE%E7%BD%91%E9%A1%B5": {
        "en": "Web Page",
        "zhHans": "外链网页"
    },
    "K_SVG%E7%9F%A2%E9%87%8F%E5%9B%BE": {
        "en": "SVG Vector",
        "zhHans": "SVG矢量图"
    },
    "K_RTSP%E8%A7%86%E9%A2%91": {
        "en": "RTSP Video",
        "zhHans": "RTSP视频"
    },
    "K_RTSP%E5%9B%9E%E6%94%BE": {
        "en": "RTSP Playback",
        "zhHans": "RTSP回放"
    },
    "K_%E8%AF%A6%E6%83%85": {
        "en": "Details",
        "zhHans": "详情"
    },
    "K_%E6%B5%8B%E7%82%B9%E5%91%8A%E8%AD%A6": {
        "en": "Spot Alarm",
        "zhHans": "测点告警"
    },
    "K_setInterval%E4%B8%8D%E8%83%BD%E5%87%BA%E7%8E%B0%E5%9C%A8%E7%BB%91%E5%AE%9A%E8%84%9A%E6%9C%AC%E4%B8%AD": {
        "en": "SetInterval Cannot Appear In A Binding Script",
        "zhHans": "setInterval不能出现在绑定脚本中"
    },
    "K_%E6%97%A0%E6%B3%95%E5%B1%95%E7%A4%BA%E6%AD%A4%E9%A1%B5%E9%9D%A2": {
        "en": "This Page Cannot Be Displayed",
        "zhHans": "无法展示此页面"
    },
    "K_%E9%A1%B5%E9%9D%A2%E5%B7%B2%E5%88%A0%E9%99%A4%E6%88%96%E9%A1%B5%E9%9D%A2%E5%86%85%E5%AE%B9%E4%B8%8D%E5%8C%B9%E9%85%8D": {
        "en": "The Page Has Been Deleted Or The Page Content Does Not Match",
        "zhHans": "页面已删除或页面内容不匹配"
    },
    "K_%E8%84%B1%E6%9C%BA": {
        "en": "Offline",
        "zhHans": "脱机"
    },
    "K_%E5%9B%9E%E6%94%BE%E9%80%89%E9%A1%B9": {
        "en": "Playback Options",
        "zhHans": "回放选项"
    },
    "K_%E6%97%A5%E6%9C%9F": {
        "en": "Date",
        "zhHans": "日期"
    },
    "K_%E5%9B%9E%E6%94%BE": {
        "en": "Playback",
        "zhHans": "回放"
    },
    "K_%E7%82%B9%E5%87%BB%E8%AE%BE%E7%BD%AE%E5%9B%9E%E6%94%BE%E5%8F%82%E6%95%B0": {
        "en": "Click To Set Playback Parameters",
        "zhHans": "点击设置回放参数"
    },
    "K_%E5%AF%BC%E8%88%AA1": {
        "en": "Navigation 1",
        "zhHans": "导航1"
    },
    "K_%E5%AF%BC%E8%88%AA2": {
        "en": "Navigation 2",
        "zhHans": "导航2"
    },
    "K_%E5%AF%BC%E8%88%AA3": {
        "en": "Navigation 3",
        "zhHans": "导航3"
    },
};
let enDict = JSON.parse(JSON.stringify(en));
let zhHansDict = JSON.parse(JSON.stringify(zhHans));
enDict.key = {};
zhHansDict.key = {};
Object.keys(dict).forEach(key => {
    enDict.key[key] = dict[key].en;
    zhHansDict.key[key] = dict[key].zhHans;
});

export default {
    en: enDict,
    zhHans: zhHansDict,
}
