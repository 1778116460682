<template>
  <v-dialog :value="show" max-width="480" persistent>
    <v-card>
      <v-card-title v-text="label"></v-card-title>
      <v-divider></v-divider>
      <v-treeview
        :items="orgs"
        :active.sync="selected"
        :open.sync="open"
        item-children="child"
        hoverable
        activatable
        transition
        dense
        style="height: 320px; overflow-y: auto"
      >
        <template v-slot:prepend="{ item }">
          <v-icon>
            {{ item.orgtype == 1 ? "mdi-home-city" : "mdi-home-variant" }}
          </v-icon>
        </template>
      </v-treeview>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="success" @click="submit">{{ $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A") }}</v-btn>
        <v-btn text @click="close">{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  props: { show: Boolean, label: String },
  model: {
    prop: "show",
    event: "change",
  },
  data() {
    return {
      orgs: [],
      open: [],
      selected: [],
    };
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.initOrganizations();
      },
    },
  },
  methods: {
    async initOrganizations() {
      client.$emit(
        "startBusy",
        "OrgSelector.initOrganizations",
        this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E6%9C%BA%E6%9E%84%2C%E8%AF%B7%E7%A8%8D%E5%80%99")
      );
      await proto.sleep(100);
      try {
        let res = await client.send(
          proto.MESSAGE_TYPE.organizationMessage,
          {
            mcd: {
              operate: proto.OperateMode.queryOpt,
              range: "0",
              parentId: client.project.id,
            },
          }
        );
        this.deepSort(res.orgs);
        this.orgs = res.orgs;
        if (res.orgs && res.orgs.length) {
          this.open = [res.orgs[0].id];
          this.selected = [res.orgs[0].id];
        } else {
          this.open = [];
          this.selected = [];
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "OrgSelector.initOrganizations");
    },
    deepSort(orgs) {
      orgs.sort((a, b) => {
        return a.sequence - b.sequence;
      });
      for (let i in orgs) {
        if (orgs[i].child && orgs[i].child.length > 0) {
          this.deepSort(orgs[i].child);
        }
      }
    },
    findModel(id, root) {
      for (let i in root) {
        let model = root[i];
        if (model.id == id) {
          return model;
        }
        if (model.child) {
          let child = this.findModel(id, model.child);
          if (child) {
            return child;
          }
        }
      }
      return null;
    },
    submit() {
      if (this.selected.length == 0) {
        client.$emit("toast", this.$vuetify.lang.t("$vuetify.key.K_%E8%AF%B7%E9%80%89%E6%8B%A9%E4%B8%80%E4%B8%AA%E6%9C%BA%E6%9E%84"));
        return;
      }
      let id = this.selected[0];
      let org = this.findModel(id, this.orgs);
      if (!org) {
        client.$emit("toast", this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%AA%E6%89%BE%E5%88%B0%E6%9C%BA%E6%9E%84%E4%BF%A1%E6%81%AF"));
        return;
      }
      this.$emit("select", org);
      this.close();
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>