<style scoped>
.monitor-page {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}
.v-menu__content {
  box-shadow: none !important;
}
</style>

<template>
  <div class="main">
    <v-navbar v-if="!fullscreen">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="history.length > 1"
            icon
            v-bind="attrs"
            v-on="on"
            @click="goBack"
          >
            <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>{{
          $vuetify.lang.t(
            "$vuetify.key.K_%E8%BF%94%E5%9B%9E%E5%89%8D%E4%B8%80%E9%A1%B5"
          )
        }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="goHome">
            <v-icon>mdi-home-outline</v-icon>
          </v-btn>
        </template>
        <span>{{
          $vuetify.lang.t("$vuetify.key.K_%E8%BF%94%E5%9B%9E%E4%B8%BB%E9%A1%B5")
        }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="unConfirmAlarmShow = true"
          >
            <v-badge
              :value="unConfirmAlarmCount"
              :content="unConfirmAlarmCount"
              color="error"
              overlap
            >
              <v-icon>mdi-alert-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>{{
          $vuetify.lang.t(
            "$vuetify.key.K_%E6%9C%AA%E7%A1%AE%E8%AE%A4%E5%91%8A%E8%AD%A6"
          )
        }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="alarmDetailShow = true">
            <v-icon>mdi-format-list-bulleted-triangle</v-icon>
          </v-btn>
        </template>
        <span>{{
          $vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6%E5%88%97%E8%A1%A8")
        }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="audioSwitch">
            <v-icon v-if="speakEnable">mdi-account-voice</v-icon>
            <v-icon v-else>mdi-voice-off</v-icon>
          </v-btn>
        </template>
        <span v-if="speakEnable">{{
          $vuetify.lang.t(
            "$vuetify.key.K_%E5%85%B3%E9%97%AD%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5"
          )
        }}</span>
        <span v-else>{{
          $vuetify.lang.t(
            "$vuetify.key.K_%E5%BC%80%E5%90%AF%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5"
          )
        }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="showAlarm = !showAlarm">
            <v-icon v-if="showAlarm">mdi-playlist-check</v-icon>
            <v-icon v-else>mdi-playlist-remove</v-icon>
          </v-btn>
        </template>
        <span v-if="showAlarm">{{
          $vuetify.lang.t(
            "$vuetify.key.K_%E5%85%B3%E9%97%AD%E5%91%8A%E8%AD%A6%E5%AD%97%E5%B9%95"
          )
        }}</span>
        <span v-else>{{
          $vuetify.lang.t(
            "$vuetify.key.K_%E5%BC%80%E5%90%AF%E5%91%8A%E8%AD%A6%E5%AD%97%E5%B9%95"
          )
        }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="inspection.show = true">
            <v-icon>mdi-file-restore-outline</v-icon>
          </v-btn>
        </template>
        <span>{{
          $vuetify.lang.t("$vuetify.key.K_%E9%A1%B5%E9%9D%A2%E8%BD%AE%E8%AF%A2")
        }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="fillMode">
            <v-icon>mdi-aspect-ratio</v-icon>
          </v-btn>
          <v-menu
            activator=".fillMode"
            offset-x
            offset-y
            left
            bottom
            rounded
            min-width="100"
          >
            <v-list>
              <v-list-item
                v-for="item in fillModes"
                :key="item.value"
                @click="fillMode = item.value"
              >
                <v-list-item-title
                  v-text="item.text"
                  :style="{
                    color: fillMode == item.value ? '#007BFF' : 'black',
                  }"
                ></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <span>{{
          $vuetify.lang.t("$vuetify.key.K_%E6%8B%89%E4%BC%B8%E6%96%B9%E5%BC%8F")
        }}</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="setFullscreen(true)">
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
        </template>
        <span>{{
          $vuetify.lang.t("$vuetify.key.K_%E5%85%A8%E5%B1%8F%E6%B5%8F%E8%A7%88")
        }}</span>
      </v-tooltip>
    </v-navbar>
    <div class="monitor-page" ref="container" v-resize="onResize">
      <zht-component
        :control="control"
        :pageId="pageId"
        :isPage="true"
        :isView="true"
        :rootWidth="rootWidth"
        :rootHeight="rootHeight"
        :fillMode="fillMode"
      ></zht-component>
    </div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="fullscreen"
          v-bind="attrs"
          v-on="on"
          fab
          small
          right
          absolute
          elevation="0"
          color="rgba(128,128,128,0.2)"
          class="white--text ma-3"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <div class="d-flex flex-column">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="history.length > 1"
              v-bind="attrs"
              v-on="on"
              fab
              small
              elevation="0"
              color="rgba(255,255,255,0.8)"
              class="mt-1"
              @click="goBack"
            >
              <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E8%BF%94%E5%9B%9E%E5%89%8D%E4%B8%80%E9%A1%B5"
            )
          }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              elevation="0"
              color="rgba(255,255,255,0.8)"
              class="mt-1"
              @click="goHome"
            >
              <v-icon>mdi-home</v-icon>
            </v-btn>
          </template>
          <span>{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E8%BF%94%E5%9B%9E%E4%B8%BB%E9%A1%B5"
            )
          }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              elevation="0"
              color="rgba(255,255,255,0.8)"
              class="mt-1"
              @click="unConfirmAlarmShow = true"
            >
              <v-icon>mdi-alert</v-icon>
            </v-btn>
          </template>
          <span>{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E6%9C%AA%E7%A1%AE%E8%AE%A4%E5%91%8A%E8%AD%A6"
            )
          }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              elevation="0"
              color="rgba(255,255,255,0.8)"
              class="mt-1"
              @click="alarmDetailShow = true"
            >
              <v-icon>mdi-format-list-bulleted-triangle</v-icon>
            </v-btn>
          </template>
          <span>{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E5%88%97%E8%A1%A8"
            )
          }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              elevation="0"
              color="rgba(255,255,255,0.8)"
              class="mt-1"
              @click="speakEnable = !speakEnable"
            >
              <v-icon v-if="speakEnable">mdi-account-voice</v-icon>
              <v-icon v-else>mdi-voice-off</v-icon>
            </v-btn>
          </template>
          <span v-if="speakEnable">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E5%85%B3%E9%97%AD%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5"
            )
          }}</span>
          <span v-else>{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E5%BC%80%E5%90%AF%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5"
            )
          }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              elevation="0"
              color="rgba(255,255,255,0.8)"
              class="mt-1"
              @click="showAlarm = !showAlarm"
            >
              <v-icon v-if="showAlarm">mdi-playlist-check</v-icon>
              <v-icon v-else>mdi-playlist-remove</v-icon>
            </v-btn>
          </template>
          <span v-if="showAlarm">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E5%85%B3%E9%97%AD%E5%91%8A%E8%AD%A6%E5%AD%97%E5%B9%95"
            )
          }}</span>
          <span v-else>{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E5%BC%80%E5%90%AF%E5%91%8A%E8%AD%A6%E5%AD%97%E5%B9%95"
            )
          }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              elevation="0"
              color="rgba(255,255,255,0.8)"
              class="mt-1"
              @click="inspection.show = true"
            >
              <v-icon>mdi-file-restore-outline</v-icon>
            </v-btn>
          </template>
          <span>{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E9%A1%B5%E9%9D%A2%E8%BD%AE%E8%AF%A2"
            )
          }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              small
              elevation="0"
              color="rgba(255,255,255,0.8)"
              class="mt-1"
              @click="setFullscreen(false)"
            >
              <v-icon>mdi-fullscreen-exit</v-icon>
            </v-btn>
          </template>
          <span>{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E9%80%80%E5%87%BA%E5%85%A8%E5%B1%8F"
            )
          }}</span>
        </v-tooltip>
      </div>
    </v-menu>
    <AlarmDetailDialog v-model="alarmDetailShow"></AlarmDetailDialog>
    <UnConfirmAlarmDialog v-model="unConfirmAlarmShow"></UnConfirmAlarmDialog>
    <v-dialog v-model="inspection.show" persistent max-width="400">
      <v-card>
        <v-card-title>
          <span>{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E9%A1%B5%E9%9D%A2%E8%BD%AE%E8%AF%A2"
            )
          }}</span>
          <v-spacer></v-spacer>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="inspection.addShow = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>{{
              $vuetify.lang.t(
                "$vuetify.key.K_%E6%B7%BB%E5%8A%A0%E8%BD%AE%E8%AF%A2%E9%A1%B5%E9%9D%A2"
              )
            }}</span>
          </v-tooltip>
          <v-btn icon @click="inspection.show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          dense
          :headers="[
            {
              text: $vuetify.lang.t('$vuetify.key.K_%E5%90%8D%E7%A7%B0'),
              value: 'name',
            },
            {
              text: $vuetify.lang.t('$vuetify.key.K_%E6%93%8D%E4%BD%9C'),
              value: 'actions',
              align: 'right',
            },
          ]"
          :items="inspection.pages"
          hide-default-footer
          disable-pagination
          style="height: 360px; overflow-y: auto"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="inspectionUp(item)">
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn icon @click="inspectionDown(item)">
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
            <v-btn icon @click="inspectionRemove(item)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-card-actions>
          <span>
            {{
              $vuetify.lang.t(
                "$vuetify.key.K_%E8%BD%AE%E8%AF%A2%E7%8A%B6%E6%80%81%EF%BC%9A"
              )
            }}{{
              inspection.enable
                ? $vuetify.lang.t("$vuetify.key.Started")
                : $vuetify.lang.t("$vuetify.key.Stopped")
            }}
          </span>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              inspection.enable = !inspection.enable;
              inspection.show = false;
            "
          >
            {{
              inspection.enable
                ? $vuetify.lang.t(
                    "$vuetify.key.K_%E5%81%9C%E6%AD%A2%E8%BD%AE%E8%AF%A2"
                  )
                : $vuetify.lang.t(
                    "$vuetify.key.K_%E5%90%AF%E5%8A%A8%E8%BD%AE%E8%AF%A2"
                  )
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ObjectSelector
      v-model="inspection.addShow"
      type="page"
      :single="false"
      @add="inspectionAdd"
    ></ObjectSelector>
  </div>
</template>

<script>
import base64 from "../utils/base64";
import RES from "../utils/res";
import proto from "../utils/proto";
import client from "../utils/client";
import store from "../utils/store";
import AlarmDetailDialog from "../components/AlarmDetailDialog.vue";
import UnConfirmAlarmDialog from "../components/UnConfirmAlarmDialog.vue";
import ObjectSelector from "../components/ObjectSelector.vue";
const SpeechSynthesis = window.speechSynthesis || window.webkitSpeechSynthesis;
const SpeechSynthesisUtterance =
  window.SpeechSynthesisUtterance || window.webkitSpeechSynthesisUtterance;

export default {
  components: {
    AlarmDetailDialog,
    UnConfirmAlarmDialog,
    ObjectSelector,
  },
  data() {
    return {
      pageId: null,
      history: [],
      rootWidth: 0,
      rootHeight: 0,
      fillMode: 1,
      fillModes: [
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E5%8E%9F%E5%A7%8B%E5%B0%BA%E5%AF%B8"
          ),
          value: 0,
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%B0%B4%E5%B9%B3%E6%8B%89%E4%BC%B8"
          ),
          value: 1,
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E5%85%A8%E5%B1%8F%E6%8B%89%E4%BC%B8"
          ),
          value: 2,
        },
      ],
      showAlarm: true,
      alarmDetailShow: false,
      unConfirmAlarmShow: false,
      inspection: {
        timer: null,
        show: false,
        enable: false,
        pages: [],
        addShow: false,
        index: 0,
      },
      speakEnable: false,
    };
  },
  computed: {
    control() {
      return { style: {}, config: { pageId: this.pageId } };
    },
    fullscreen() {
      return client.fullscreen;
    },
    unConfirmAlarmCount() {
      return client.statistics.s8;
    },
  },
  watch: {
    speakEnable(value) {
      store.set("speakEnable", value ? "true" : "false");
    },
    fillMode(value) {
      store.set("fillMode", value);
    },
    showAlarm(value) {
      store.set("showAlarm", value ? "true" : "false");
    },
    fullscreen() {
      setTimeout(this.onResize, 1);
    },
    "inspection.enable": {
      handler() {
        this.inspectionChange();
      },
    },
  },
  created() {
    this.speakEnable = store.get("speakEnable") == "true";
    this.fillMode = parseInt(store.get("fillMode") || "1");
    this.showAlarm = store.get("showAlarm") == "true";
    this.inspection.enable = store.get("inspectionEnable") == "true";
    try {
      this.inspection.pages = JSON.parse(
        base64.decode(store.get("inspectionPages"))
      );
    } catch (e) {
      this.inspection.pages = [];
    }
    client.$on("spot-ctrl", this.spotControl);
    client.$on("navigate", this.navigate);
    client.$on("new-tab", this.newTab);
    client.$on("orgChange", this.goHome);
    client.$on("brocast", this.brocast);
    this.goHome();
  },
  mounted() {
    this.onResize();
    this.startEvent();
    this.inspectionChange();
  },
  beforeDestroy() {
    if (this.inspection.timer) clearInterval(this.inspection.timer);
    this.inspection.timer = null;
    client.$off("spot-ctrl", this.spotControl);
    client.$off("navigate", this.navigate);
    client.$off("new-tab", this.newTab);
    client.$off("orgChange", this.goHome);
    client.$off("brocast", this.brocast);
    RES.clear();
  },
  methods: {
    /** page action */
    async spotControl(action) {
      if (!action.spot.uuid) return;
      if (action.srcType != "const") return;
      let value = action.value;
      client.$emit(
        "startBusy",
        "MonitorPage.spotControl",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E6%8E%A7%E5%88%B6%E6%B5%8B%E7%82%B9%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.realTimeDataMessage, {
          mcd: {
            operate: proto.OperateMode.updateOpt,
            range: action.spot.uuid,
          },
          rtdata: [
            {
              uuid: action.spot.uuid,
              sv: {
                valueData: base64.encode(value),
              },
            },
          ],
        });
        client.$emit(
          "toast",
          this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%8E%A7%E5%88%B6%E6%88%90%E5%8A%9F"
          ),
          "success"
        );
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "MonitorPage.spotControl");
    },
    navigate(action) {
      if (action.page.uuid) {
        this.gotoPage(action.page.uuid);
      }
    },
    newTab(action) {
      if (action.value) {
        var newWindow = window.open(action.value, "_blank");
        if (newWindow) {
          newWindow.focus();
        } else {
          client.$emit(
            "toast",
            this.$vuetify.lang.t("$vuetify.key.BrowserNotSupported")
          );
        }
      }
    },
    /** server methods */
    async goHome() {
      client.$emit(
        "startBusy",
        "MonitorPage.goHome",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E4%B8%BB%E9%A1%B5%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.organizationMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "-1",
          },
          orgs: [{ id: client.org.id }],
        });
        if (res.orgs.length) {
          this.gotoPage(res.orgs[0].homepage);
        } else {
          client.$emit(
            "toast",
            this.$vuetify.lang.t(
              "$vuetify.key.K_%E6%AD%A4%E6%9C%BA%E6%9E%84%E6%B2%A1%E6%9C%89%E8%AE%BE%E7%BD%AE%E4%B8%BB%E9%A1%B5"
            )
          );
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "MonitorPage.goHome");
    },
    async startEvent() {
      try {
        await client.send(proto.MESSAGE_TYPE.realTimeDataMessage, {
          mcd: {
            operate: proto.OperateMode.createOpt,
          },
          rtdata: [],
        });
      } catch (error) {
        client.$emit("toast", error);
      }
    },
    gotoPage(pageId) {
      if (pageId != this.pageId) {
        this.pageId = pageId;
        this.history.push(this.pageId);
        if (this.history.length > 11)
          this.history.splice(0, this.history.length - 11);
      }
    },
    goBack() {
      if (this.history.length > 1) {
        let pageId = this.history[this.history.length - 2];
        this.history.splice(this.history.length - 1, 1);
        this.pageId = pageId;
      } else {
        client.$emit(
          "toast",
          this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%B2%A1%E6%9C%89%E6%9B%B4%E5%A4%9A%E7%9A%84%E6%B5%8F%E8%A7%88%E5%8E%86%E5%8F%B2"
          )
        );
      }
    },
    onResize() {
      this.rootWidth = this.$refs.container.clientWidth || 1024;
      this.rootHeight = this.$refs.container.clientHeight || 768;
    },
    brocast(type, msg) {
      switch (type) {
        case proto.MESSAGE_TYPE.realTimeEventMessage:
          if (msg.rtevent) {
            for (let i in msg.rtevent) {
              let event = msg.rtevent[i];
              if (event.description) {
                if (event.et != proto.EVENT_TYPE.sys_event) {
                  if (this.speakEnable) {
                    if (SpeechSynthesis && SpeechSynthesisUtterance) {
                      try {
                        SpeechSynthesis.speak(
                          new SpeechSynthesisUtterance(event.description)
                        );
                      } catch (e) {
                        console.log(e);
                      }
                    }
                  }
                  if (this.showAlarm) {
                    client.$emit(
                      "alarm",
                      `[${event.level}] ${event.description}`,
                      event.level ? "error" : "success"
                    );
                  }
                }
              }
            }
          }
          break;
      }
    },
    setFullscreen(value) {
      client.fullscreen = value;
    },
    /** inspection */
    inspectionAdd(page) {
      for (let i in this.inspection.pages) {
        if (this.inspection.pages[i].id == page.id) return;
      }
      this.inspection.pages.push(page);
      store.set(
        "inspectionPages",
        base64.encode(JSON.stringify(this.inspection.pages))
      );
    },
    inspectionRemove(page) {
      let index = this.inspection.pages.indexOf(page);
      if (index != -1) this.inspection.pages.splice(index, 1);
      store.set(
        "inspectionPages",
        base64.encode(JSON.stringify(this.inspection.pages))
      );
    },
    inspectionUp(page) {
      let index = this.inspection.pages.indexOf(page);
      if (index > 0) {
        this.inspection.pages.splice(index, 1);
        this.inspection.pages.splice(index - 1, 0, page);
      }
      store.set(
        "inspectionPages",
        base64.encode(JSON.stringify(this.inspection.pages))
      );
    },
    inspectionDown(page) {
      let index = this.inspection.pages.indexOf(page);
      if (index != -1 && index < this.inspection.pages.length - 1) {
        this.inspection.pages.splice(index, 1);
        this.inspection.pages.splice(index + 1, 0, page);
      }
      store.set(
        "inspectionPages",
        base64.encode(JSON.stringify(this.inspection.pages))
      );
    },
    inspectionChange() {
      store.set("inspectionEnable", this.inspection.enable ? "true" : "false");
      if (this.inspection.timer) clearInterval(this.inspection.timer);
      if (this.inspection.enable) {
        this.inspection.index = 0;
        this.inspection.timer = setInterval(this.inspectionWork, 10000);
      } else {
        this.inspection.timer = null;
      }
    },
    inspectionWork() {
      let current = this.inspection.index;
      let total = this.inspection.pages.length;
      if (total) {
        current = current % total;
        let page = this.inspection.pages[current];
        this.inspection.index = current + 1;
        this.gotoPage(page.id);
      }
    },
    /** speak */
    audioSwitch() {
      if (this.speakEnable == true) {
        this.speakEnable = false;
        if (SpeechSynthesis && SpeechSynthesisUtterance) {
          try {
            SpeechSynthesis.cancel();
          } catch (e) {
            console.log(e);
          }
        }
      } else {
        this.speakEnable = true;
        if (SpeechSynthesis && SpeechSynthesisUtterance) {
          try {
            SpeechSynthesis.speak(
              new SpeechSynthesisUtterance(
                this.$vuetify.lang.t(
                  "$vuetify.key.K_%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5%E5%B7%B2%E5%BC%80%E5%90%AF"
                )
              )
            );
          } catch (e) {
            client.$emit(
              "toast",
              this.$vuetify.lang.t(
                "$vuetify.key.K_%E5%BD%93%E5%89%8D%E8%AE%BE%E5%A4%87%E6%97%A0%E6%B3%95%E8%BF%9B%E8%A1%8C%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5"
              )
            );
            console.log(e);
          }
        }
      }
    },
  },
};
</script>
