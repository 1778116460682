<template>
  <v-dialog :value="show" max-width="480" persistent>
    <v-card>
      <v-card-title>
        <span>{{ $vuetify.lang.t("$vuetify.key.K_%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF") }}</span>
        <v-spacer></v-spacer>
        <v-btn text @click="pwdShow = true">{{ $vuetify.lang.t("$vuetify.key.K_%E4%BF%AE%E6%94%B9%E5%AF%86%E7%A0%81") }}</v-btn>
        <ChangePasswordDialog
          v-model="pwdShow"
          :userId="userId"
        ></ChangePasswordDialog>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-6" v-show="personId">
        <v-text-field dense :label="$vuetify.lang.t('$vuetify.key.K_%E5%A7%93%E5%90%8D')" v-model="info.name"></v-text-field>
        <v-select
          dense
          :label="$vuetify.lang.t('$vuetify.key.K_%E6%80%A7%E5%88%AB')"
          v-model="info.sex"
          :items="[
            { text: $vuetify.lang.t('$vuetify.key.K_%E7%94%B7'), value: 1 },
            { text: $vuetify.lang.t('$vuetify.key.K_%E5%A5%B3'), value: 2 },
          ]"
        ></v-select>
        <v-text-field dense :label="$vuetify.lang.t('$vuetify.key.K_%E5%B7%A5%E5%8F%B7')" v-model="info.empno"></v-text-field>
        <v-text-field dense :label="$vuetify.lang.t('$vuetify.key.K_%E9%83%A8%E9%97%A8')" v-model="info.company"></v-text-field>
        <v-text-field dense :label="$vuetify.lang.t('$vuetify.key.K_%E8%81%8C%E4%BD%8D')" v-model="info.position"></v-text-field>
        <v-text-field
          dense
          :label="$vuetify.lang.t('$vuetify.key.K_%E8%AF%AD%E9%9F%B3%E5%8F%B7%E7%A0%81')"
          v-model="info.telephone"
        ></v-text-field>
        <v-text-field
          dense
          :label="$vuetify.lang.t('$vuetify.key.K_%E7%9F%AD%E4%BF%A1%E5%8F%B7%E7%A0%81')"
          v-model="info.mobilephone"
        ></v-text-field>
        <v-text-field dense :label="$vuetify.lang.t('$vuetify.key.K_%E9%82%AE%E7%AE%B1')" v-model="info.email"></v-text-field>
      </v-card-text>
      <v-card-text class="pt-6" v-show="!personId">
        <div>{{ $vuetify.lang.t("$vuetify.key.K_%E5%BC%80%E5%8F%91%E8%80%85%E5%B8%90%E6%88%B7%E6%B2%A1%E6%9C%89%E5%AF%B9%E5%BA%94%E7%9A%84%E9%85%8D%E7%BD%AE%E9%A1%B9") }}</div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="success" @click="submit">{{ $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A") }}</v-btn>
        <v-btn text @click="close">{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ChangePasswordDialog from "./ChangePasswordDialog.vue";
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  components: { ChangePasswordDialog },
  props: { show: Boolean },
  model: {
    prop: "show",
    event: "change",
  },
  data() {
    return {
      info: {},
      pwdShow: false,
    };
  },
  computed: {
    userId() {
      return client.user.uuid;
    },
    personId() {
      return client.user.person ? client.user.person.id : "";
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.getInfo();
      },
    },
  },
  methods: {
    async getInfo() {
      let result = {};
      if (this.personId) {
        client.$emit(
          "startBusy",
          "UserInfoDialog.getInfo",
          this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF%2C%E8%AF%B7%E7%A8%8D%E5%80%99")
        );
        await proto.sleep(100);
        try {
          let res = await client.send(proto.MESSAGE_TYPE.personMessage, {
            mcd: {
              operate: proto.OperateMode.retrieveOpt,
              range: "-1",
              parentId: client.project.id,
            },
            Persons: [{ uuid: this.personId, orgid: "orgid" }],
          });
          if (res.Persons && res.Persons.length) {
            result = res.Persons[0];
          }
        } catch (error) {
          client.$emit("toast", error);
        }
        client.$emit("endBusy", "UserInfoDialog.getInfo");
      }
      this.info = result;
    },
    async submit() {
      if (this.personId) {
        client.$emit(
          "startBusy",
          "UserInfoDialog.submit",
          this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E4%BF%AE%E6%94%B9%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF%2C%E8%AF%B7%E7%A8%8D%E5%80%99")
        );
        await proto.sleep(100);
        try {
          await client.send(proto.MESSAGE_TYPE.personMessage, {
            mcd: {
              operate: proto.OperateMode.updateOpt,
            },
            Persons: [this.info],
          });
          this.close();
        } catch (e) {
          client.$emit("toast", e.toString());
        }
        client.$emit("endBusy", "UserInfoDialog.submit");
      } else {
        this.close();
      }
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>