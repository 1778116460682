<template>
  <v-dialog :value="show" persistent max-width="800">
    <v-card>
      <v-card-title>
        <span>{{
          $vuetify.lang.t(
            "$vuetify.key.K_%E6%9C%AA%E7%A1%AE%E8%AE%A4%E5%91%8A%E8%AD%A6"
          )
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title class="py-0">
        <v-btn text class="px-0" @click="orgShow = true">
          <v-icon class="mr-1">mdi-sitemap</v-icon>
          <span>{{ orgName }}</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="
                confirmMode = '-1';
                confirmText = '';
                confirmShow = true;
              "
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </template>
          <span>{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E7%A1%AE%E8%AE%A4%E9%80%89%E4%B8%AD"
            )
          }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              @click="
                confirmMode = '0';
                confirmText = '';
                confirmShow = true;
              "
            >
              <v-icon>mdi-check-all</v-icon>
            </v-btn>
          </template>
          <span>{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E7%A1%AE%E8%AE%A4%E5%85%A8%E9%83%A8"
            )
          }}</span>
        </v-tooltip>
        <v-btn icon @click="query(1)">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        v-model="rows"
        dense
        :headers="headers"
        :items="records"
        hide-default-footer
        disable-pagination
        show-select
        style="height: 360px; overflow-y: auto"
      >
        <template v-slot:[`item.eventtype`]="{ item }">
          <span v-if="item.eventtype == 2">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA"
            )
          }}</span>
          <span v-if="item.eventtype == 3">{{
            $vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E5%A4%87")
          }}</span>
          <span v-if="item.eventtype == 4">{{
            $vuetify.lang.t("$vuetify.key.K_%E6%B5%8B%E7%82%B9")
          }}</span>
        </template>
        <template v-slot:[`item.stateAlarm`]="{ item }">
          <span v-if="item.stateAlarm == 1">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E8%B6%85%E4%B8%8A%E9%99%90%E5%91%8A%E8%AD%A6"
            )
          }}</span>
          <span v-if="item.stateAlarm == 2">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E8%B6%85%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6"
            )
          }}</span>
          <span v-if="item.stateAlarm == 3">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E4%B8%B4%E4%B8%8A%E9%99%90%E5%91%8A%E8%AD%A6"
            )
          }}</span>
          <span v-if="item.stateAlarm == 4">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E4%B8%B4%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6"
            )
          }}</span>
          <span v-if="item.stateAlarm == 5">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E6%95%B0%E6%8D%AE%E5%BC%82%E5%B8%B8%E5%91%8A%E8%AD%A6"
            )
          }}</span>
          <span v-if="item.stateAlarm == 6">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E7%8A%B6%E6%80%81%E5%BC%82%E5%B8%B8%E5%91%8A%E8%AD%A6"
            )
          }}</span>
          <span v-if="item.stateAlarm == 7">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E6%81%A2%E5%A4%8D"
            )
          }}</span>
          <span v-if="item.stateAlarm == 8">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E8%81%94%E6%9C%BA%E6%81%A2%E5%A4%8D"
            )
          }}</span>
          <span v-if="item.stateAlarm == 9">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E8%84%B1%E6%9C%BA%E5%91%8A%E8%AD%A6"
            )
          }}</span>
          <span v-if="item.stateAlarm == 10">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E6%96%87%E6%9C%AC%E6%8A%A5%E8%AD%A6"
            )
          }}</span>
          <span v-if="item.stateAlarm == 11">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E6%9E%81%E4%B8%8A%E9%99%90%E5%91%8A%E8%AD%A6"
            )
          }}</span>
          <span v-if="item.stateAlarm == 12">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E6%9E%81%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6"
            )
          }}</span>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-pagination
        v-model="pageIndex"
        :length="pageCount"
        :total-visible="7"
        @input="query($event)"
      ></v-pagination>
      <v-navigation-drawer
        v-model="orgShow"
        temporary
        left
        absolute
        width="240"
      >
        <v-treeview
          :items="orgs"
          :active.sync="selected"
          :open.sync="open"
          item-children="child"
          hoverable
          activatable
          transition
          dense
          @update:active="orgShow = false"
        >
          <template v-slot:prepend="{ item }">
            <v-icon>
              {{ item.orgtype == 1 ? "mdi-home-city" : "mdi-home-variant" }}
            </v-icon>
          </template>
        </v-treeview>
      </v-navigation-drawer>
    </v-card>
    <v-dialog v-model="confirmShow" persistent max-width="400">
      <v-card>
        <v-card-title>
          {{
            confirmMode == "-1"
              ? $vuetify.lang.t(
                  "$vuetify.key.K_%E7%A1%AE%E8%AE%A4%E9%80%89%E4%B8%AD"
                )
              : $vuetify.lang.t(
                  "$vuetify.key.K_%E7%A1%AE%E8%AE%A4%E5%85%A8%E9%83%A8"
                )
          }}
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="confirmText"
            :label="
              $vuetify.lang.t(
                '$vuetify.key.K_%E5%A4%84%E7%90%86%E6%84%8F%E8%A7%81'
              )
            "
            rows="5"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="confirm">{{
            $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")
          }}</v-btn>
          <v-btn text @click="confirmShow = false">{{
            $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";

export default {
  model: {
    prop: "show",
    event: "change",
  },
  props: { show: Boolean },
  data() {
    return {
      orgShow: false,
      orgs: [],
      open: [],
      selected: [],

      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E5%8F%B7"),
          value: "id",
          sortable: false,
          width: 64,
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E4%BA%A7%E7%94%9F%E6%97%B6%E9%97%B4"
          ),
          value: "datatime",
          sortable: false,
          width: 110,
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E5%AF%B9%E8%B1%A1"
          ),
          value: "eventtype",
          sortable: false,
          width: 80,
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%B1%BB%E5%9E%8B"
          ),
          value: "stateAlarm",
          sortable: false,
          width: 80,
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%BA%A7%E5%88%AB"
          ),
          value: "level",
          sortable: false,
          width: 80,
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E6%8F%8F%E8%BF%B0"
          ),
          value: "description",
          sortable: false,
        },
      ],
      records: [],
      pageIndex: 0,
      pageCount: 0,
      recordCount: 0,

      rows: [],
      confirmMode: "-1",
      confirmText: "",
      confirmShow: false,
    };
  },
  computed: {
    orgName() {
      if (this.selected.length) {
        let id = this.selected[0];
        let org = this.findModel(id, this.orgs);
        if (org) return org.name;
      }
      return this.$vuetify.lang.t(
        "$vuetify.key.K_%E5%BD%93%E5%89%8D%E6%9C%BA%E6%9E%84"
      );
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.initOrganizations();
      },
    },
    selected: {
      handler() {
        this.query(1);
      },
    },
  },
  methods: {
    async initOrganizations() {
      client.$emit(
        "startBusy",
        "UnConfirmAlarmDialog.initOrganizations",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E6%9C%BA%E6%9E%84%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.organizationMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: client.project.id,
          },
        });
        this.deepSort(res.orgs);
        this.orgs = res.orgs;
        if (res.orgs && res.orgs.length) {
          this.open = [res.orgs[0].id];
          this.selected = [res.orgs[0].id];
        } else {
          this.open = [];
          this.selected = [];
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "UnConfirmAlarmDialog.initOrganizations");
    },
    deepSort(orgs) {
      orgs.sort((a, b) => {
        return a.sequence - b.sequence;
      });
      for (let i in orgs) {
        if (orgs[i].child && orgs[i].child.length > 0) {
          this.deepSort(orgs[i].child);
        }
      }
    },
    findModel(id, root) {
      for (let i in root) {
        let model = root[i];
        if (model.id == id) {
          return model;
        }
        if (model.child) {
          let child = this.findModel(id, model.child);
          if (child) {
            return child;
          }
        }
      }
      return null;
    },
    async query(page) {
      let result = [];
      client.$emit(
        "startBusy",
        "UnConfirmAlarmDialog.query",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E8%AE%B0%E5%BD%95%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.histEventMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            orgid: this.selected[0],
          },
          histeventClause: {
            alarmtype: 0,
            act: 2,
            timeContion: 1,
            begintime: "1970-01-01",
            alarmlevel: 0,
          },
          rpp: 20,
          pageindex: page - 1,
          bContainReset: false,
        });
        this.pageIndex = res.pageindex + 1;
        this.pageCount = res.pagecount || 0;
        this.recordCount = res.recordcount || 0;
        if (res.heo && res.heo.length) {
          res.heo.sort((a, b) => {
            return b.datatime.localeCompare(a.datatime);
          });
          for (let i = 0; i < res.heo.length; i++) {
            result.push(res.heo[i]);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "UnConfirmAlarmDialog.query");
      this.records = result;
    },
    async confirm() {
      client.$emit(
        "startBusy",
        "UnConfirmAlarmDialog.confirm",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E7%A1%AE%E8%AE%A4%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        let heo;
        if (this.confirmMode == "-1") {
          heo = this.rows;
        } else {
          heo = [{ id: 0 }];
        }
        for (let i in heo) {
          heo[i].confirmation = this.confirmText;
        }
        await client.send(proto.MESSAGE_TYPE.histEventMessage, {
          mcd: {
            operate: proto.OperateMode.createOpt,
            orgid: this.selected[0],
            range: this.confirmMode,
          },
          histeventClause: {
            alarmtype: 0,
            act: 2,
            timeContion: 1,
            begintime: "1970-01-01",
            alarmlevel: 0,
          },
          rpp: 0,
          heo,
        });
        this.confirmShow = false;
        this.query(1);
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "UnConfirmAlarmDialog.confirm");
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>
