<template>
  <div class="main">
    <v-navbar></v-navbar>
    <v-expansion-panels dark class="flex-shrink-0">
      <v-expansion-panel style="background-color: transparent">
        <v-expansion-panel-header>{{ $vuetify.lang.t("$vuetify.key.K_%E7%AD%9B%E9%80%89%E6%9D%A1%E4%BB%B6") }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container fluid>
            <v-row align="baseline">
              <v-col cols="6" sm="3" lg="2">
                <v-select
                  :label="$vuetify.lang.t('$vuetify.key.K_%E4%BA%8B%E4%BB%B6%E7%B1%BB%E5%9E%8B')"
                  v-model="eventType"
                  :items="eventTypes"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" lg="2" v-if="eventType == 0">
                <v-select
                  :label="$vuetify.lang.t('$vuetify.key.K_%E5%91%8A%E8%AD%A6%E5%AF%B9%E8%B1%A1')"
                  v-model="alarmObject"
                  :items="alarmObjects"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" lg="2" v-if="eventType == 0">
                <v-select
                  :label="$vuetify.lang.t('$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%B1%BB%E5%9E%8B')"
                  v-model="alarmType"
                  :items="alarmTypes"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" lg="2" v-if="eventType == 0">
                <v-select
                  :label="$vuetify.lang.t('$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%BA%A7%E5%88%AB')"
                  v-model="alarmLevel"
                  :items="alarmLevels"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" lg="4">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.key.K_%E5%91%8A%E8%AD%A6%E6%8F%8F%E8%BF%B0')"
                  v-model="decription"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-select
                  :label="$vuetify.lang.t('$vuetify.key.K_%E6%97%B6%E9%97%B4%E6%9D%A1%E4%BB%B6')"
                  v-model="condition"
                  :items="conditions"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-menu
                  v-model="dateStartShow"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateStart"
                      :label="
                        condition == 6
                          ? $vuetify.lang.t('$vuetify.key.startDate')
                          : $vuetify.lang.t('$vuetify.key.date')
                      "
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateStart"
                    :max="dateEnd"
                    @input="dateStartShow = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="3" lg="2" v-if="condition == 6">
                <v-menu
                  v-model="dateEndShow"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateEnd"
                      :label="$vuetify.lang.t('$vuetify.key.K_%E7%BB%93%E6%9D%9F%E6%97%A5%E6%9C%9F')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateEnd"
                    :min="dateStart"
                    @input="dateEndShow = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" sm="3" lg="2">
                <v-btn block color="rgba(255,255,255,0.2)" @click="query(1)">
                  {{ $vuetify.lang.t("$vuetify.key.K_%E6%9F%A5%E8%AF%A2") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="scroll flex-grow-1 flex-shrink-1">
      <v-data-table
        :headers="headers"
        :items="records"
        dark
        style="background-color: transparent"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.eventtype`]="{ item }">
          <span>{{ alarmObjectNames(item.eventtype) }}</span>
        </template>
        <template v-slot:[`item.stateAlarm`]="{ item }">
          <span>{{ alarmTypeNames(item.stateAlarm) }}</span>
        </template>
      </v-data-table>
    </div>
    <v-divider></v-divider>
    <v-pagination
      v-model="pageIndex"
      :length="pageCount"
      :total-visible="7"
      @input="query($event)"
    ></v-pagination>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  data() {
    return {
      eventType: 0,
      eventTypes: [
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E7%B3%BB%E7%BB%9F%E4%BA%8B%E4%BB%B6"), value: 1 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E8%BF%90%E7%BB%B4%E4%BA%8B%E4%BB%B6"), value: 7 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6%E4%BA%8B%E4%BB%B6"), value: 0 },
      ],

      alarmObject: 0,
      alarmObjects: [
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%85%A8%E9%83%A8"), value: 0 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E7%9B%91%E6%8E%A7%E5%8D%95%E5%85%83"), value: 2 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E5%A4%87"), value: 3 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E6%B5%8B%E7%82%B9"), value: 4 },
      ],

      alarmType: 0,
      alarmTypes: [
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%85%A8%E9%83%A8"), value: 0 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E6%9E%81%E4%B8%8A%E9%99%90%E5%91%8A%E8%AD%A6"), value: 11 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E6%9E%81%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6"), value: 12 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E8%B6%85%E4%B8%8A%E9%99%90%E5%91%8A%E8%AD%A6"), value: 1 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E8%B6%85%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6"), value: 2 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E4%B8%B4%E4%B8%8A%E9%99%90%E5%91%8A%E8%AD%A6"), value: 3 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E4%B8%B4%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6"), value: 4 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E6%95%B0%E6%8D%AE%E5%BC%82%E5%B8%B8%E5%91%8A%E8%AD%A6"), value: 5 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E7%8A%B6%E6%80%81%E5%BC%82%E5%B8%B8%E5%91%8A%E8%AD%A6"), value: 6 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6%E6%81%A2%E5%A4%8D"), value: 7 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E8%81%94%E6%9C%BA%E6%81%A2%E5%A4%8D"), value: 8 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E8%84%B1%E6%9C%BA%E5%91%8A%E8%AD%A6"), value: 9 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E6%96%87%E6%9C%AC%E6%8A%A5%E8%AD%A6"), value: 10 },
      ],

      alarmLevel: 0,
      alarmLevels: [
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E5%85%A8%E9%83%A8"), value: 0 },
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5", value: 5 },
        { text: "6", value: 6 },
        { text: "7", value: 7 },
        { text: "8", value: 8 },
        { text: "9", value: 9 },
        { text: "10", value: 10 },
      ],

      decription: "",

      condition: 1,
      conditions: [
        { text: "=", value: 1 },
        { text: ">", value: 2 },
        { text: ">=", value: 4 },
        { text: "<", value: 3 },
        { text: "<=", value: 5 },
        { text: this.$vuetify.lang.t("$vuetify.key.K_%E6%97%B6%E9%97%B4%E6%AE%B5"), value: 6 },
      ],

      dateStartShow: false,
      dateStart: undefined,
      dateEndShow: false,
      dateEnd: undefined,

      recordType: 0,
      records: [],
      pageIndex: 0,
      pageCount: 0,
      recordCount: 0,
    };
  },
  computed: {
    headers: function() {
      if (this.recordType == 0) {
        return [
          {
            text: this.$vuetify.lang.t("$vuetify.key.K_%E4%BA%A7%E7%94%9F%E6%97%B6%E9%97%B4"),
            value: "datatime",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6%E5%AF%B9%E8%B1%A1"),
            value: "eventtype",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%B1%BB%E5%9E%8B"),
            value: "stateAlarm",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%BA%A7%E5%88%AB"),
            value: "level",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6%E6%8F%8F%E8%BF%B0"),
            value: "description",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E8%AE%A4%E4%BA%BA"),
            value: "hander",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E8%AE%A4%E6%97%B6%E9%97%B4"),
            value: "handledate",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.key.K_%E5%A4%84%E7%90%86%E6%84%8F%E8%A7%81"),
            value: "confirmation",
            sortable: false,
          },
        ];
      } else {
        return [
          {
            text: this.$vuetify.lang.t("$vuetify.key.K_%E4%BA%A7%E7%94%9F%E6%97%B6%E9%97%B4"),
            value: "datatime",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.key.K_%E4%BA%8B%E4%BB%B6%E6%8F%8F%E8%BF%B0"),
            value: "description",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E8%AE%A4%E4%BA%BA"),
            value: "hander",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E8%AE%A4%E6%97%B6%E9%97%B4"),
            value: "handledate",
            sortable: false,
          },
          {
            text: this.$vuetify.lang.t("$vuetify.key.K_%E5%A4%84%E7%90%86%E6%84%8F%E8%A7%81"),
            value: "confirmation",
            sortable: false,
          },
        ];
      }
    },
  },
  mounted() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) month = "0" + month;
    let day = date.getDate();
    if (day < 10) day = "0" + day;
    this.dateStart = this.dateEnd = `${year}-${month}-${day}`;
    client.$on("orgChange", this.orgChange);
    this.orgChange();
  },
  beforeDestroy() {
    client.$off("orgChange", this.orgChange);
  },
  methods: {
    orgChange() {
      this.records = [];
      this.pageIndex = 0;
      this.pageCount = 0;
      this.recordCount = 0;
      this.query(1);
    },
    async query(page) {
      let result = [];
      client.$emit("startBusy", "HistoryEvent.query", this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E8%AE%B0%E5%BD%95%2C%E8%AF%B7%E7%A8%8D%E5%80%99"));
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.histEventMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: "0",
          },
          histeventClause: {
            timeContion: this.condition,
            begintime: this.dateStart,
            endtime: this.dateEnd,
            eventtype: this.eventType == 0 ? this.alarmObject : this.eventType,
            alarmtype: this.eventType == 0 ? this.alarmType : 0,
            decription: this.decription,
            act: 2,
            alarmlevel: this.alarmLevel,
          },
          rpp: 20,
          pageindex: page - 1,
          bContainReset: true,
        });
        this.recordType = this.eventType;
        this.pageIndex = res.pageindex + 1;
        this.pageCount = res.pagecount || 0;
        this.recordCount = res.recordcount || 0;
        if (res.heo && res.heo.length) {
          res.heo.sort((a, b) => {
            return b.datatime.localeCompare(a.datatime);
          });
          for (let i = 0; i < res.heo.length; i++) {
            let record = res.heo[i];
            delete record.id;
            result.push(record);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "HistoryEvent.query");
      this.records = result;
    },
    alarmObjectNames(value) {
      switch (value) {
        case 2:
          return this.$vuetify.lang.t("$vuetify.key.K_%E7%9B%91%E6%8E%A7%E5%8D%95%E5%85%83");
        case 3:
          return this.$vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E5%A4%87");
        case 4:
          return this.$vuetify.lang.t("$vuetify.key.K_%E6%B5%8B%E7%82%B9");
      }
      return this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%AA%E7%9F%A5");
    },
    alarmTypeNames(value) {
      switch (value) {
        case 1:
          return this.$vuetify.lang.t("$vuetify.key.K_%E8%B6%85%E4%B8%8A%E9%99%90%E5%91%8A%E8%AD%A6");
        case 2:
          return this.$vuetify.lang.t("$vuetify.key.K_%E8%B6%85%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6");
        case 3:
          return this.$vuetify.lang.t("$vuetify.key.K_%E4%B8%B4%E4%B8%8A%E9%99%90%E5%91%8A%E8%AD%A6");
        case 4:
          return this.$vuetify.lang.t("$vuetify.key.K_%E4%B8%B4%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6");
        case 5:
          return this.$vuetify.lang.t("$vuetify.key.K_%E6%95%B0%E6%8D%AE%E5%BC%82%E5%B8%B8%E5%91%8A%E8%AD%A6");
        case 6:
          return this.$vuetify.lang.t("$vuetify.key.K_%E7%8A%B6%E6%80%81%E5%BC%82%E5%B8%B8%E5%91%8A%E8%AD%A6");
        case 7:
          return this.$vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6%E6%81%A2%E5%A4%8D");
        case 8:
          return this.$vuetify.lang.t("$vuetify.key.K_%E8%81%94%E6%9C%BA%E6%81%A2%E5%A4%8D");
        case 9:
          return this.$vuetify.lang.t("$vuetify.key.K_%E8%84%B1%E6%9C%BA%E5%91%8A%E8%AD%A6");
        case 10:
          return this.$vuetify.lang.t("$vuetify.key.K_%E6%96%87%E6%9C%AC%E6%8A%A5%E8%AD%A6");
        case 11:
          return this.$vuetify.lang.t("$vuetify.key.K_%E6%9E%81%E4%B8%8A%E9%99%90%E5%91%8A%E8%AD%A6");
        case 12:
          return this.$vuetify.lang.t("$vuetify.key.K_%E6%9E%81%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6");
      }
      return this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%AA%E7%9F%A5");
    },
  },
};
</script>
