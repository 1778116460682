<template>
  <v-dialog :value="show" persistent max-width="480">
    <v-form @submit.prevent="submit">
      <v-card>
        <v-card-title class="text-subtitle-1">{{ $vuetify.lang.t("$vuetify.key.K_%E6%B5%8B%E7%82%B9%E6%8E%A7%E5%88%B6") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field :label="$vuetify.lang.t('$vuetify.key.K_%E6%B5%8B%E7%82%B9%E5%90%8D%E7%A7%B0')" :value="name" readonly></v-text-field>
          <v-text-field :label="$vuetify.lang.t('$vuetify.key.K_%E6%B5%8B%E7%82%B9%E5%80%BC')" v-model="value" autofocus></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain type="submit" color="success">{{ $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A") }}</v-btn>
          <v-btn plain @click="cancel">{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: "show",
    event: "change",
  },
  props: ["uuid", "name", "show"],
  data() {
    return {
      value: "",
    };
  },
  methods: {
    submit() {
      this.$emit("submit", this.uuid, this.value);
      this.$emit("change", false);
    },
    cancel() {
      this.$emit("change", false);
    },
  },
};
</script>
