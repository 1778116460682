import protobuf from 'protobufjs';
import imcp from './imcp.json';

let root = protobuf.Root.fromJSON(imcp);

const AppState = {
    init: 'init',
    anonymous: 'anonymous',
    login: 'login',
};
const ChnState = {
    close: 'close',
    connecting: 'connecting',
    handshake: 'handshake',
    logining: 'logining',
    ready: 'ready',
    closing: 'closing',
};

let MESSAGE_TYPE = root.lookupEnum('imcp.protocol.PreambleMessage.MESSAGE_TYPE').values;
let keys = Object.keys(MESSAGE_TYPE);
let Types = [];
for (let i in keys) {
    let message = keys[i];
    let index = MESSAGE_TYPE[message];
    message = message[0].toUpperCase() + message.substr(1);
    if (index == 0) message = 'PreambleMessage';
    if (index == 2) message = 'UserAuthenMessage';
    if (index == 3) continue;
    if (index == 21) message = 'HistoryDataMessage';
    if (index == 23) message = 'SystemLogMessage';
    if (index == 24) message = 'ImexportMessage';
    if (index == 25) continue;
    if (index == 26) message = 'IMCPVersionMessage';
    if (index == 30) continue;
    if (index == 32) message = 'HistoryEventMessage';
    if (index == 34) continue;
    if (index == 42) message = 'EventHandlerMessage';
    if (index == 43) continue;
    if (index == 44) continue;
    if (index == 52) continue;
    if (index == 59) message = 'LevelSendlMessage';
    if (index == 60) continue;
    if (index == 70) continue;
    if (index == 71) continue;
    if (index == 72) continue;
    if (index == 73) continue;
    if (index == 74) continue;
    if (index == 75) continue;
    if (index == 76) continue;
    if (index == 77) continue;
    if (index == 78) continue;
    Types[index] = root.lookupType("imcp.protocol." + message);
}

export default {
    AppState,
    ChnState,
    bufferToArray: (buffer) => {
        var result = [];
        for (let i = 0; i < buffer.length; i++) {
            result[i] = buffer[i];
        }
        return result;
    },
    arrayToBuffer: (array) => {
        return new Uint8Array(array);
    },
    sleep: function (ms) {
        return new Promise(resolve => {
            setTimeout(() => { resolve(); }, ms);
        });
    },
    Types,
    MESSAGE_TYPE: {
        "dummy": 0,
        "userMessage": 1,
        "userAuthen": 2,
        "agentAuthen": 3,
        "deviceProtocolMessage": 4,
        "realTimeDataMessage": 5,
        "realTimeEventMessage": 6,
        "projectMessage": 7,
        "agentMessage": 8,
        "deviceMessage": 9,
        "pageMessage": 10,
        "elementMessage": 11,
        "resourceMessage": 12,
        "policyMessage": 13,
        "bindingMessage": 14,
        "spotMessage": 15,
        "projectManipulateMessage": 16,
        "agentSyncMessage": 17,
        "commPortMessage": 18,
        "sysPluginMessage": 19,
        "sysPluginRepoMessage": 20,
        "histDataMessage": 21,
        "spotTableMessage": 22,
        "sysLogMessage": 23,
        "imexPortMessage": 24,
        "pageTemplateMessage": 25,
        "imcpVersionMessage": 26,
        "commonAlarmPolicyMessage": 27,
        "alarmPolicyMessage": 28,
        "rtdPersistPolicyMessage": 29,
        "coordinatedControlMessage": 30,
        "statusMessage": 31,
        "histEventMessage": 32,
        "chartMessage": 33,
        "icoreBroadcast": 34,
        "dataExternMessage": 35,
        "reportMessage": 36,
        "commonRtdPersistPolicyMessage": 37,
        "autoReportPeriodPolicySetMessage": 38,
        "syncPluginMessage": 39,
        "queryMessage": 40,
        "personMessage": 41,
        "eventHandlerMsg": 42,
        "fileTransferMessage": 43,
        "comAuthMessage": 44,
        "progressMessage": 45,
        "periodMessage": 46,
        "periodsItemMessage": 47,
        "configMessage": 49,
        "notifierMessage": 50,
        "stateValueAlarmMessage": 51,
        "idebuggerCmdOrder": 52,
        "organizationMessage": 53,
        "receivergroupMessage": 54,
        "linkAlarmMessage": 55,
        "devLinkageMessage": 56,
        "realTimeStatusMessage": 57,
        "realTimeStatisticsMessage": 58,
        "levelSendMessage": 59,
        "databufferMessage": 60,
        "alarmLevelMessage": 61,
        "timerMessage": 62,
        "orgMemberMessage": 63,
        "userGroupMessage": 64,
        "permissionMessage": 65,
        "alarmDescMessage": 66,
        "syncMemMessage": 67,
        "pauseAlarmMessage": 68,
        "targetGroupMessage": 69,
        "tElementMessage": 70,
        "tPageMessage": 71,
        "systElementMessage": 72,
        "systPageMessage": 73,
        "tAgentMessage": 74,
        "tDeviceMessage": 75,
        "systAgentMessage": 76,
        "systDeviceMessage": 77,
        "imageRepoMessage": 78,
        "realTimeDetailMessage": 79,
        "syncTimerMessage": 80,
        "syncAlarmLeveMessage": 90,
        "agentProcesses": 120,
        "agentManage": 121,
        "syncAlarmInfoMessage": 122,
        "syncAlarmRelationMessage": 123,
        "syncAlarmDelayMessage": 124,
        "syncFileTransferMessage": 125,
        "heartbeatMessage": 126,
        "syncDelayDevLinkageMessage": 127
    },
    OperateMode: {
        "createOpt": 1,
        "retrieveOpt": 2,
        "updateOpt": 3,
        "deleteOpt": 4,
        "addOpt": 5,
        "queryOpt": 6,
        "modifyOpt": 7,
        "removeOpt": 8,
        "other": 9
    },
    USER_TYPE: {
        "SYSTEM": 1,
        "PROJECT": 2
    },
    ALARM_TYPE: {
        "upperlim": 1,
        "lowerlim": 2,
        "nearuplim": 3,
        "nearlowlim": 4,
        "except_data": 5,
        "state_change": 6,
        "alarm_reset": 7,
        "online": 8,
        "offline": 9,
        "text_alarm": 10,
        "ultra_hi": 11,
        "ultra_low": 12
    },
    EVENT_TYPE: {
        "sys_event": 1,
        "agt_event": 2,
        "dev_event": 3,
        "spt_event": 4,
        "dev_state": 5,
        "agt_state": 6,
        "opt_event": 7,
        "org_event": 8
    },
    STATUS_TYPE: {
        "agent_status": 1,
        "device_status": 2,
        "spot_status": 3,
        "org_status": 4
    },
    STATUS_VALUE: {
        "st_offline": 0,
        "st_online": 1,
        "st_disabled": 2
    },
    StatisticalType: {
        "agentNum": 1,
        "agentOnNum": 2,
        "agentOffNum": 3,
        "agentNouseNum": 4,
        "agentAlarmNum": 5,
        "currentAlarmNum": 6,
        "noHandleEvent": 7,
        "noHandleAlarmEvent": 8,
        "deviceNum": 9,
        "deviceOnNum": 10,
        "deviceOffNum": 11,
        "deviceAlarmNum": 12
    },
    OBJECT_TYPE: {
        "Agent": 1,
        "Device": 2,
        "Spot": 3,
        "Page": 4,
        "Organization": 5
    },
};
