<template>
  <v-text-field
    :label="
      $vuetify.lang.t('$vuetify.key.K_%E9%A1%B5%E9%9D%A2%E7%BB%91%E5%AE%9A')
    "
    :value="`${value.length}${$vuetify.lang.t('$vuetify.key.K_%E9%A1%B9')}`"
    readonly
    filled
    background-color="transparent"
    hide-details
  >
    <template slot="append">
      <v-btn icon tile @click="showDialog">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-dialog v-model="show" max-width="640" persistent>
        <v-card>
          <v-card-title>
            <div>
              {{
                $vuetify.lang.t(
                  "$vuetify.key.K_%E9%A1%B5%E9%9D%A2%E7%BB%91%E5%AE%9A%E9%85%8D%E7%BD%AE"
                )
              }}
            </div>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="replaceShow = true"
                >
                  <v-icon>mdi-file-restore-outline</v-icon>
                </v-btn>
              </template>
              <span>{{
                $vuetify.lang.t(
                  "$vuetify.key.K_%E5%BF%AB%E9%80%9F%E6%9B%BF%E6%8D%A2%E7%BB%91%E5%AE%9A%E6%BA%90"
                )
              }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="addShow = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{
                $vuetify.lang.t(
                  "$vuetify.key.K_%E6%B7%BB%E5%8A%A0%E7%BB%91%E5%AE%9A"
                )
              }}</span>
            </v-tooltip>
            <AddBinding
              v-if="addShow"
              :controls="controls"
              @close="addClose"
            ></AddBinding>
            <ReplaceBinding
              v-model="replaceShow"
              :controls="controls"
              :bindings="items"
              @submit="replaceSubmit"
            ></ReplaceBinding>
          </v-card-title>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="items"
            hide-default-footer
            disable-pagination
            style="height: 360px; overflow-y: auto"
          >
            <template v-slot:[`item.uuid`]="{ item }">
              <div v-text="controlName(item.uuid)"></div>
            </template>
            <template v-slot:[`item.prop`]="{ item }">
              <div v-text="propertyName(item.prop)"></div>
            </template>
            <template v-slot:[`item.srcType`]="{ item }">
              <div v-text="srcTypes[item.srcType]"></div>
            </template>
            <template v-slot:[`item.valueType`]="{ item }">
              <div v-text="valueTypes[item.valueType] || item.valueType"></div>
            </template>
            <template v-slot:[`item.src`]="{ item }">
              <div v-text="item.src.length"></div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon @click="edit(item)">
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
              <v-btn icon @click="remove(item)">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <BindingSource
            v-if="editShow"
            :value="editModel"
            :controls="controls"
            @close="editClose"
          ></BindingSource>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="success" @click="submit">{{
              $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")
            }}</v-btn>
            <v-btn text @click="show = false">{{
              $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-text-field>
</template>

<script>
import editors from "../editor";
import AddBinding from "../../components/AddBinding.vue";
import ReplaceBinding from "../../components/ReplaceBinding.vue";
import BindingSource from "../../components/BindingSource.vue";
export default {
  components: { AddBinding, ReplaceBinding, BindingSource },
  model: {
    prop: "value",
    event: "change",
  },
  props: ["controls", "value"],
  data() {
    return {
      srcTypes: {
        org: this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%BA%E6%9E%84"),
        agent: this.$vuetify.lang.t(
          "$vuetify.key.K_%E7%9B%91%E6%8E%A7%E5%8D%95%E5%85%83"
        ),
        device: this.$vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E5%A4%87"),
        spot: this.$vuetify.lang.t("$vuetify.key.K_%E6%B5%8B%E7%82%B9"),
        statistics: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E7%BB%9F%E8%AE%A1"
        ),
        api: "API",
      },
      valueTypes: {
        alarm: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%8A%B6%E6%80%81"
        ),
        status: this.$vuetify.lang.t(
          "$vuetify.key.K_%E8%81%94%E6%9C%BA%E7%8A%B6%E6%80%81"
        ),
        data: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E6%95%B0%E6%8D%AE"
        ),
        history: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%8E%86%E5%8F%B2%E6%95%B0%E6%8D%AE"
        ),
        statistics: this.$vuetify.lang.t(
          "$vuetify.key.K_%E5%AE%9E%E6%97%B6%E7%BB%9F%E8%AE%A1%E5%80%BC"
        ),
      },
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%8E%A7%E4%BB%B6"),
          value: "uuid",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E5%B1%9E%E6%80%A7"),
          value: "prop",
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%BA%90%E7%B1%BB%E5%9E%8B"
          ),
          value: "srcType",
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%95%B0%E6%8D%AE%E7%B1%BB%E5%9E%8B"
          ),
          value: "valueType",
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%BA%90%E6%95%B0%E9%87%8F"
          ),
          value: "src",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E6%93%8D%E4%BD%9C"),
          value: "actions",
          sortable: false,
        },
      ],
      items: [],
      show: false,
      addShow: false,
      editShow: false,
      editModel: null,
      replaceShow: false,
    };
  },
  methods: {
    showDialog() {
      this.items = JSON.parse(JSON.stringify(this.value));
      this.show = true;
    },
    controlName(id) {
      for (let i in this.controls) {
        if (this.controls[i].uuid == id) return this.controls[i].name;
      }
      return this.$vuetify.lang.t("$vuetify.key.K_%E5%B7%B2%E5%88%A0%E9%99%A4");
    },
    propertyName(prop) {
      if (editors[prop]) return this.$vuetify.lang.t(editors[prop].name);
      return this.$vuetify.lang.t(
        "$vuetify.key.K_%E6%9C%AA%E7%9F%A5%E5%B1%9E%E6%80%A7"
      );
    },
    addClose(model) {
      this.addShow = false;
      if (model) {
        this.items.push(model);
        this.editModel = model;
        this.editShow = true;
      }
    },
    edit(item) {
      this.editModel = item;
      this.editShow = true;
    },
    editClose(model) {
      this.editShow = false;
      if (model) {
        this.editModel.src = model.src;
        this.editModel.JScript = model.JScript;
        this.editModel.interval = model.interval;
        this.editModel.param = model.param;
      }
    },
    remove(item) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i] == item) {
          this.items.splice(i, 1);
          break;
        }
      }
    },
    submit() {
      this.change(this.items);
      this.show = false;
    },
    change(value) {
      this.$emit("change", value);
    },
    replaceSubmit(changes) {
      for (let i in changes) {
        let change = changes[i];
        for (let j in this.items) {
          let item = this.items[j];
          if (change.uuid == item.uuid) {
            item.src = [{ id: change.spotId, name: change.spotName }];
          }
        }
      }
    },
  },
};
</script>
