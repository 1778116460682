<template>
  <v-dialog :value="show" persistent max-width="480">
    <v-card>
      <v-card-title>
        <div>
          {{
            $vuetify.lang.t(
              "$vuetify.key.K_%E5%8A%A8%E4%BD%9C%E7%BC%96%E8%BE%91"
            )
          }}
        </div>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-select
          v-model="action.type"
          :label="
            $vuetify.lang.t(
              '$vuetify.key.K_%E5%8A%A8%E4%BD%9C%E7%B1%BB%E5%9E%8B'
            )
          "
          :items="types"
          @change="action.srcType = 'const'"
        ></v-select>
        <v-text-field
          v-show="action.type == 'navigate'"
          :value="action.page.name"
          :label="$vuetify.lang.t('$vuetify.key.K_%E9%A1%B5%E9%9D%A2')"
          readonly
        >
          <template slot="append">
            <v-btn icon tile x-small @click="pageShow = true">
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <ObjectSelector
          v-model="pageShow"
          type="page"
          :single="true"
          @select="pageSelect"
        ></ObjectSelector>
        <v-text-field
          v-show="action.type == 'spot-ctrl'"
          :value="action.spot.name"
          :label="$vuetify.lang.t('$vuetify.key.K_%E6%B5%8B%E7%82%B9')"
          readonly
        >
          <template slot="append">
            <v-btn icon tile x-small @click="spotShow = true">
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <ObjectSelector
          v-model="spotShow"
          type="spot"
          :single="true"
          @select="spotSelect"
        ></ObjectSelector>
        <v-select
          v-show="action.type == 'spot-ctrl'"
          v-model="action.srcType"
          :label="$vuetify.lang.t('$vuetify.key.K_%E5%80%BC%E6%9D%A5%E6%BA%90')"
          :items="srcTypes"
        ></v-select>
        <v-select
          v-show="
            action.type == 'prop-set' ||
              (action.type == 'spot-ctrl' && action.srcType == 'property')
          "
          v-model="action.uuid"
          :label="$vuetify.lang.t('$vuetify.key.K_%E6%8E%A7%E4%BB%B6')"
          item-value="uuid"
          item-text="name"
          :items="controls"
        ></v-select>
        <v-select
          v-show="
            action.type == 'prop-set' ||
              (action.type == 'spot-ctrl' && action.srcType == 'property')
          "
          v-model="action.prop"
          :label="$vuetify.lang.t('$vuetify.key.K_%E5%B1%9E%E6%80%A7')"
          :items="props"
        ></v-select>
        <v-text-field
          v-show="
            action.type == 'prop-set' ||
              action.type == 'new-tab' ||
              (action.type == 'spot-ctrl' && action.srcType == 'const')
          "
          v-model="action.value"
          :label="$vuetify.lang.t('$vuetify.key.K_%E5%80%BC')"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="submit">{{
          $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A")
        }}</v-btn>
        <v-btn text @click="close">{{
          $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ObjectSelector from "../components/ObjectSelector.vue";
import editors from "../zht-ui/editor";
export default {
  components: { ObjectSelector },
  model: {
    prop: "show",
    event: "change",
  },
  props: { show: Boolean, model: Object, controls: Array },
  data() {
    return {
      pageShow: false,
      spotShow: false,
      types: [
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E8%AE%BE%E7%BD%AE%E6%8E%A7%E4%BB%B6%E5%B1%9E%E6%80%A7"
          ),
          value: "prop-set",
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E8%AE%BE%E7%BD%AE%E6%B5%8B%E7%82%B9%E5%80%BC"
          ),
          value: "spot-ctrl",
        },
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E9%A1%B5%E9%9D%A2%E8%B7%B3%E8%BD%AC"
          ),
          value: "navigate",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.OpenNewTab"),
          value: "new-tab",
        },
      ],
      srcTypes: [
        {
          text: this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%8E%A7%E4%BB%B6%E5%B1%9E%E6%80%A7"
          ),
          value: "property",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.key.K_%E5%B8%B8%E9%87%8F"),
          value: "const",
        },
      ],
      props: [],
      action: {
        type: "",
        srcType: "",
        spot: {
          uuid: "",
          name: "",
        },
        page: {
          uuid: "",
          name: "",
        },
        uuid: "",
        prop: "",
        value: "",
      },
    };
  },
  watch: {
    show: function() {
      if (this.show) {
        let baseAction = this.model.action;
        this.action.type = baseAction.type || "spot-ctrl";
        this.action.srcType = baseAction.srcType || "const";
        let spot = baseAction.spot || { uuid: "", name: "" };
        this.action.spot.uuid = spot.uuid || "";
        this.action.spot.name = spot.name || "";
        let page = baseAction.page || { uuid: "", name: "" };
        this.action.page.uuid = page.uuid || "";
        this.action.page.name = page.name || "";
        this.action.uuid = baseAction.uuid || "";
        this.action.prop = baseAction.prop || "";
        this.action.value = baseAction.value || "";
      }
    },
    "action.uuid": function() {
      let result = [];
      if (this.action.uuid) {
        for (let i in this.controls) {
          if (this.controls[i].uuid == this.action.uuid) {
            let control = this.controls[i];
            let bases = ["size", "location", "style", "config"];
            for (let j in bases) {
              let base = bases[j];
              for (let attr in control[base]) {
                let value = `${base}.${attr}`;
                if (editors[value]) {
                  let text = this.$vuetify.lang.t(editors[value].name);
                  result.push({ text, value });
                }
              }
            }
            break;
          }
        }
      }
      this.props = result;
    },
  },
  methods: {
    pageSelect(model) {
      this.action.page.uuid = model.id;
      this.action.page.name = model.name;
    },
    spotSelect(model) {
      this.action.spot.uuid = model.id;
      this.action.spot.name = model.name;
    },
    submit() {
      this.$emit("submit", JSON.parse(JSON.stringify(this.action)));
      this.close();
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>
