<style>
.assets.theme--dark.v-data-table tbody tr.v-data-table__selected {
  background: #41737b !important;
}
</style>
<template>
  <div class="main">
    <v-navbar>
      <v-btn text class="link-text" @click="save">
        <span>{{ $vuetify.lang.t("$vuetify.key.K_%E4%BF%9D%E5%AD%98") }}</span>
      </v-btn>
      <v-btn text class="link-text" @click="edit">
        <span>{{ $vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E8%BE%91") }}</span>
      </v-btn>
    </v-navbar>
    <div class="scroll">
      <v-data-table
        dark
        :headers="headers"
        :items="items"
        v-model="selected"
        item-key="id"
        show-select
        style="background-color: transparent"
        hide-default-footer
        disable-pagination
        class="assets"
      >
      </v-data-table>
    </div>
    <v-navigation-drawer
      v-model="editorShow"
      absolute
      temporary
      right
      width="240"
    >
      <div v-if="editModel" class="d-flex flex-column fill-height">
        <div class="flex-grow-1 bg-editor" style="overflow: auto">
          <v-text-field
            v-for="attr in basicEditors"
            :key="`model-${attr.key}`"
            :label="$vuetify.lang.t(attr.name)"
            v-model="editModel[attr.key]"
            :readonly="attr.readonly || false"
            filled
            background-color="transparent"
            hide-details
            @input="onInput($event, attr.key)"
          ></v-text-field>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
import base64 from "../utils/base64";

const basicEditors = [
  { key: "id", name: "ID", readonly: true },
  { key: "name", name: "$vuetify.key.K_%E5%90%8D%E7%A7%B0", readonly: true },
  { key: "brand", name: "$vuetify.key.K_%E5%93%81%E7%89%8C" },
  { key: "model", name: "$vuetify.key.K_%E5%9E%8B%E5%8F%B7" },
  { key: "vendor", name: "$vuetify.key.K_%E4%BE%9B%E5%BA%94%E5%95%86" },
  {
    key: "purchaseDate",
    name: "$vuetify.key.K_%E8%B4%AD%E4%B9%B0%E6%97%A5%E6%9C%9F",
  },
  {
    key: "expiredDate",
    name: "$vuetify.key.K_%E8%B4%A8%E4%BF%9D%E6%97%A5%E6%9C%9F",
  },
  { key: "responsible", name: "$vuetify.key.K_%E8%B4%9F%E8%B4%A3%E4%BA%BA" },
  {
    key: "contact",
    name: "$vuetify.key.K_%E8%81%94%E7%B3%BB%E6%96%B9%E5%BC%8F",
  },
  {
    key: "maintenance",
    name: "$vuetify.key.K_%E7%BB%B4%E4%BF%AE%E8%AE%B0%E5%BD%95",
  },
  { key: "description", name: "$vuetify.key.K_%E5%A4%87%E6%B3%A8" },
];

export default {
  data() {
    return {
      selected: [],
      items: [],
      basicEditors,
      editorShow: false,
      editModel: basicEditors.reduce((p, c) => {
        if (p.key == "id") {
          let obj = {};
          obj[p.key] = "";
          obj[c.key] = "";
          return obj;
        } else {
          p[c.key] = "";
          return p;
        }
      }),
      headers: basicEditors
        .filter((h) => h.key != "id")
        .map((h) => {
          return { text: this.$vuetify.lang.t(h.name), value: h.key };
        }),
      json: "{}",
      changes: false,
      isAdd: true,
    };
  },
  mounted() {
    client.$on("orgChange", this.orgChange);
    this.init();
  },
  beforeDestroy() {
    client.$off("orgChange", this.orgChange);
    this.save();
  },
  methods: {
    async orgChange() {
      await this.save();
      client.$emit(
        "startBusy",
        "Asset.orgChange",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E8%AE%BE%E5%A4%87%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.deviceMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: client.org.id,
          },
          act: 2,
        });
        let items = [];
        let dict;
        try {
          dict = JSON.parse(this.json);
        } catch (e) {
          dict = {};
        }
        if (res.devices && res.devices.length) {
          res.devices.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.devices.length; i++) {
            let device = res.devices[i];
            let model;
            if (dict[device.uuid]) {
              model = dict[device.uuid];
            } else {
              model = {};
            }
            model.id = device.uuid;
            model.name = device.name;
            items.push(model);
          }
        }
        this.selected = [];
        this.items = items;
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "Asset.orgChange");
    },
    async init() {
      client.$emit(
        "startBusy",
        "Asset.init",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E8%B5%84%E4%BA%A7%E4%BF%A1%E6%81%AF%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.resourceMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: client.project.id,
          },
          resType: 2,
        });
        if (res.resources && res.resources.length == 1) {
          this.isAdd = false;
          let item = res.resources[0];
          try {
            this.json = base64.decode(item.content);
          } catch (e) {
            this.json = "{}";
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "Asset.init");
      this.orgChange();
    },
    async save() {
      if (this.changes) {
        this.changes = false;
        client.$emit(
          "startBusy",
          "Asset.save",
          this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E4%BF%9D%E5%AD%98%E8%B5%84%E4%BA%A7%E4%BF%A1%E6%81%AF%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
          )
        );
        await proto.sleep(100);
        try {
          let dict;
          try {
            dict = JSON.parse(this.json);
          } catch (e) {
            dict = {};
          }
          for (let i in this.items) {
            let model = JSON.parse(JSON.stringify(this.items[i]));
            for (let attr in model) {
              if (model[attr] == "") delete model[attr];
            }
            dict[model.id] = model;
          }
          this.json = JSON.stringify(dict);
          await client.send(proto.MESSAGE_TYPE.resourceMessage, {
            mcd: {
              operate: this.isAdd
                ? proto.OperateMode.createOpt
                : proto.OperateMode.updateOpt,
              range: "-1",
            },
            resType: 2,
            resources: [
              {
                uuid: client.project.id,
                libtype: 1,
                name: this.$vuetify.lang.t(
                  "$vuetify.key.K_%E5%B7%A5%E7%A8%8B%E8%B5%84%E4%BA%A7%E4%BF%A1%E6%81%AF%E8%AF%B7%E5%8B%BF%E5%88%A0%E9%99%A4"
                ),
                content: base64.encode(this.json),
              },
            ],
          });
        } catch (error) {
          client.$emit("toast", error);
        }
        client.$emit("endBusy", "Asset.save");
      }
    },
    edit() {
      if (this.selected.length == 1) {
        for (let i in basicEditors) {
          let attr = basicEditors[i];
          this.editModel[attr.key] = this.selected[0][attr.key] || "";
        }
      } else if (this.selected.length > 1) {
        for (let i in basicEditors) {
          let attr = basicEditors[i];
          this.editModel[attr.key] = "";
        }
        this.editModel.id = this.$vuetify.lang.t(
          "$vuetify.key.K_%3C%E5%A4%9A%E6%9D%A1%E9%80%89%E4%B8%AD%E9%A1%B9%3E"
        );
        this.editModel.name = this.$vuetify.lang.t(
          "$vuetify.key.K_%3C%E5%A4%9A%E6%9D%A1%E9%80%89%E4%B8%AD%E9%A1%B9%3E"
        );
      } else {
        return;
      }
      this.editorShow = true;
    },
    onInput(value, attr) {
      this.changes = true;
      for (let i in this.selected) {
        this.selected[i][attr] = value;
      }
    },
  },
};
</script>
