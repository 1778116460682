var base64Chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".split("");

function byteCode(codes) {
    var codeStr = '', binStr;
    for (var i = 0; i < codes.length; i += 2) {
        binStr = parseInt(codes.slice(i, i + 2), 16).toString(2);
        if (binStr.length < 8) {
            binStr = ('0000000' + binStr).slice(-8);
        }
        codeStr += binStr;
    }
    return codeStr
}

function base64encode(str) {
    var bb = byteCode(charToUtf8(str)), i, b6 = [], x, base64 = '', n;
    for (i = 0; i < bb.length; i += 6) {
        b6.push(bb.slice(i, i + 6));
    }
    for (x in b6) {
        if (Number(x) + 1 == b6.length) {
            b6[x] = (b6[x] + '00000').slice(0, 6)
        }
        base64 += base64Chars[parseInt(b6[x], 2)]
    }
    if (bb.length % 24 != 0) {
        n = (24 - bb.length % 24) / 8;
        if (n == 1) base64 += '=';
        else if (n == 2) base64 += '==';
    }
    return base64;
}

function base64decode(str) {
    var bc = str.replace(/=/g, '').split(""), y, z, c, b, bs = '';
    for (y in bc) {
        c = '';
        for (z in base64Chars) {
            if (bc[y] == base64Chars[z]) {
                c = z;
                break;
            }
        }
        if (c == '') return this.$vuetify.lang.t('$vuetify.key.K_%E7%BC%96%E7%A0%81%E6%9C%89%E8%AF%AF');
        b = Number(c).toString(2);
        if (b.length < 6) {
            b = ('00000' + b).slice(-6)
        }
        bs += b
    }
    var j = Math.floor(bs.length / 8), i, ha = '', hb;
    for (i = 0; i < j; i++) {
        hb = parseInt(bs.slice(i * 8, i * 8 + 8), 2).toString(16);
        if (hb.length < 2) hb = ('00' + hb).slice(-2);
        ha += '%' + hb;
    }
    let result;
    try {
        result = decodeURIComponent(ha);
    } catch (e) {
        result = '';
    }
    return result;
}

function charToUtf8(text) {
    var index = 0, output = '';
    while (index < text.length) {
        var character = text.charAt(index), charCode = text.charCodeAt(index);
        if (charCode >= 0xD800 && charCode <= 0xDBFF) {
            output += encodeURI(character + text.charAt(++index)).replace(/%/g, '');
        } else {
            if (charCode > 127)
                output += encodeURI(character).replace(/%/g, '');
            else {
                var asc = charCode.toString(16).toUpperCase();
                if (asc.length < 2) asc = '0' + asc;
                output += asc;
            }
        }
        ++index
    }
    return output;
}

export default {
    encode: base64encode,
    decode: base64decode,
}
