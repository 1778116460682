import MonitorPage from './MonitorPage.vue';
import MonitorDevice from './MonitorDevice.vue';
import ConfigPage from './ConfigPage.vue';
import ConfigUser from './ConfigUser.vue';
import ConfigPeriod from './ConfigPeriod.vue';
import ConfigReceive from './ConfigReceive.vue';
import ConfigDevice from './ConfigDevice.vue';
import ConfigResource from './ConfigResource.vue';
import ConfigPolicy from './ConfigPolicy.vue';
import HistoryData from './HistoryData.vue';
import HistoryEvent from './HistoryEvent.vue';
import HistoryLog from "./HistoryLog.vue";
import SystemStorage from "./SystemStorage.vue";
import Asset from "./Asset.vue";

const views = [
  { name: 'monitor', title: '$vuetify.key.K_%E7%9B%91%E6%8E%A7' },
  { name: 'monitor-page', title: '$vuetify.key.K_%E9%A1%B5%E9%9D%A2%E7%9B%91%E6%8E%A7', icon: 'mdi-monitor-dashboard', component: MonitorPage },
  { name: 'monitor-device', title: '$vuetify.key.K_%E8%AE%BE%E5%A4%87%E7%9B%91%E6%8E%A7', icon: 'mdi-layers-search', component: MonitorDevice },
  { name: 'config-3', title: '$vuetify.key.K_%E9%85%8D%E7%BD%AE-%E9%A1%B5%E9%9D%A2' },
  { name: 'config-page', title: '$vuetify.key.K_%E9%A1%B5%E9%9D%A2%E7%BB%84%E6%80%81', icon: 'mdi-monitor-edit', component: ConfigPage },
  { name: 'config-resource', title: '$vuetify.key.K_%E8%B5%84%E6%BA%90%E9%85%8D%E7%BD%AE', icon: 'mdi-folder-multiple-image', component: ConfigResource },
  { name: 'config-2', title: '$vuetify.key.K_%E9%85%8D%E7%BD%AE-%E8%AE%BE%E5%A4%87' },
  { name: 'config-device', title: '$vuetify.key.K_%E8%AE%BE%E5%A4%87%E7%BB%84%E6%80%81', icon: 'mdi-lan-connect', component: ConfigDevice },
  { name: 'config-policy', title: '$vuetify.key.K_%E7%AD%96%E7%95%A5%E9%85%8D%E7%BD%AE', icon: 'mdi-brain', component: ConfigPolicy },
  { name: 'config-asset', title: '$vuetify.key.K_%E8%B5%84%E4%BA%A7%E4%BF%A1%E6%81%AF', icon: 'mdi-database-edit', component: Asset }, // remdmine#21757
  { name: 'config-1', title: '$vuetify.key.K_%E9%85%8D%E7%BD%AE-%E7%94%A8%E6%88%B7' },
  { name: 'config-user', title: '$vuetify.key.K_%E7%94%A8%E6%88%B7%E7%AE%A1%E7%90%86', icon: 'mdi-account-multiple', component: ConfigUser },
  { name: 'config-receive', title: '$vuetify.key.K_%E6%8E%A5%E8%AD%A6%E7%BB%84%E7%AE%A1%E7%90%86', icon: 'mdi-account-group', component: ConfigReceive },
  { name: 'config-period', title: '$vuetify.key.K_%E6%8E%A5%E8%AD%A6%E6%97%B6%E6%AE%B5%E7%AE%A1%E7%90%86', icon: 'mdi-timetable', component: ConfigPeriod },
  { name: 'history', title: '$vuetify.key.K_%E8%AE%B0%E5%BD%95' },
  { name: 'history-data', title: '$vuetify.key.K_%E5%8E%86%E5%8F%B2%E6%95%B0%E6%8D%AE', icon: 'mdi-calendar-text', component: HistoryData },
  { name: 'history-event', title: '$vuetify.key.K_%E5%8E%86%E5%8F%B2%E4%BA%8B%E4%BB%B6', icon: 'mdi-calendar-alert', component: HistoryEvent },
  { name: 'history-log', title: '$vuetify.key.K_%E6%93%8D%E4%BD%9C%E6%97%A5%E5%BF%97', icon: 'mdi-calendar-account', component: HistoryLog },
  { name: 'system', title: '$vuetify.key.K_%E7%B3%BB%E7%BB%9F' },
  { name: 'system-storage', title: '$vuetify.key.K_%E5%AD%98%E5%82%A8%E9%85%8D%E7%BD%AE', icon: 'mdi-database-cog', component: SystemStorage },
];

export default {
  views,
  install: function (Vue) {
    for (let i in views) {
      let view = views[i];
      if (view.name && view.component) Vue.component(view.name, view.component);
    }
  },
};
