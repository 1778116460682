var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[(!_vm.fullscreen)?_c('v-navbar',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.history.length > 1),expression:"history.length > 1"}],attrs:{"icon":""},on:{"click":_vm.goBack}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left-bold-circle-outline")])],1)]}}],null,false,1107416943)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E8%BF%94%E5%9B%9E%E5%89%8D%E4%B8%80%E9%A1%B5" )))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.goHome}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home-outline")])],1)]}}],null,false,2452007621)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.key.K_%E8%BF%94%E5%9B%9E%E4%B8%BB%E9%A1%B5")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.unConfirmAlarmShow = true}}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"value":_vm.unConfirmAlarmCount,"content":_vm.unConfirmAlarmCount,"color":"error","overlap":""}},[_c('v-icon',[_vm._v("mdi-alert-outline")])],1)],1)]}}],null,false,2696366086)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E6%9C%AA%E7%A1%AE%E8%AE%A4%E5%91%8A%E8%AD%A6" )))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.alarmDetailShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-triangle")])],1)]}}],null,false,3601982690)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.key.K_%E5%91%8A%E8%AD%A6%E5%88%97%E8%A1%A8")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.audioSwitch}},'v-btn',attrs,false),on),[(_vm.speakEnable)?_c('v-icon',[_vm._v("mdi-account-voice")]):_c('v-icon',[_vm._v("mdi-voice-off")])],1)]}}],null,false,3526078625)},[(_vm.speakEnable)?_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%85%B3%E9%97%AD%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5" )))]):_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%BC%80%E5%90%AF%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5" )))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showAlarm = !_vm.showAlarm}}},'v-btn',attrs,false),on),[(_vm.showAlarm)?_c('v-icon',[_vm._v("mdi-playlist-check")]):_c('v-icon',[_vm._v("mdi-playlist-remove")])],1)]}}],null,false,2570033351)},[(_vm.showAlarm)?_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%85%B3%E9%97%AD%E5%91%8A%E8%AD%A6%E5%AD%97%E5%B9%95" )))]):_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%BC%80%E5%90%AF%E5%91%8A%E8%AD%A6%E5%AD%97%E5%B9%95" )))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.inspection.show = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-restore-outline")])],1)]}}],null,false,532211259)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.key.K_%E9%A1%B5%E9%9D%A2%E8%BD%AE%E8%AF%A2")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"fillMode",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-aspect-ratio")])],1),_c('v-menu',{attrs:{"activator":".fillMode","offset-x":"","offset-y":"","left":"","bottom":"","rounded":"","min-width":"100"}},[_c('v-list',_vm._l((_vm.fillModes),function(item){return _c('v-list-item',{key:item.value,on:{"click":function($event){_vm.fillMode = item.value}}},[_c('v-list-item-title',{style:({
                  color: _vm.fillMode == item.value ? '#007BFF' : 'black',
                }),domProps:{"textContent":_vm._s(item.text)}})],1)}),1)],1)]}}],null,false,1762622311)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.key.K_%E6%8B%89%E4%BC%B8%E6%96%B9%E5%BC%8F")))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.setFullscreen(true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-fullscreen")])],1)]}}],null,false,504953170)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.key.K_%E5%85%A8%E5%B1%8F%E6%B5%8F%E8%A7%88")))])])],1):_vm._e(),_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticClass:"monitor-page"},[_c('zht-component',{attrs:{"control":_vm.control,"pageId":_vm.pageId,"isPage":true,"isView":true,"rootWidth":_vm.rootWidth,"rootHeight":_vm.rootHeight,"fillMode":_vm.fillMode}})],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.fullscreen),expression:"fullscreen"}],staticClass:"white--text ma-3",attrs:{"fab":"","small":"","right":"","absolute":"","elevation":"0","color":"rgba(128,128,128,0.2)"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}])},[_c('div',{staticClass:"d-flex flex-column"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.history.length > 1),expression:"history.length > 1"}],staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":_vm.goBack}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-left-bold-circle-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E8%BF%94%E5%9B%9E%E5%89%8D%E4%B8%80%E9%A1%B5" )))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":_vm.goHome}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E8%BF%94%E5%9B%9E%E4%B8%BB%E9%A1%B5" )))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":function($event){_vm.unConfirmAlarmShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-alert")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E6%9C%AA%E7%A1%AE%E8%AE%A4%E5%91%8A%E8%AD%A6" )))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":function($event){_vm.alarmDetailShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted-triangle")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E5%88%97%E8%A1%A8" )))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":function($event){_vm.speakEnable = !_vm.speakEnable}}},'v-btn',attrs,false),on),[(_vm.speakEnable)?_c('v-icon',[_vm._v("mdi-account-voice")]):_c('v-icon',[_vm._v("mdi-voice-off")])],1)]}}])},[(_vm.speakEnable)?_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%85%B3%E9%97%AD%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5" )))]):_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%BC%80%E5%90%AF%E8%AF%AD%E9%9F%B3%E6%92%AD%E6%8A%A5" )))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":function($event){_vm.showAlarm = !_vm.showAlarm}}},'v-btn',attrs,false),on),[(_vm.showAlarm)?_c('v-icon',[_vm._v("mdi-playlist-check")]):_c('v-icon',[_vm._v("mdi-playlist-remove")])],1)]}}])},[(_vm.showAlarm)?_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%85%B3%E9%97%AD%E5%91%8A%E8%AD%A6%E5%AD%97%E5%B9%95" )))]):_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E5%BC%80%E5%90%AF%E5%91%8A%E8%AD%A6%E5%AD%97%E5%B9%95" )))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":function($event){_vm.inspection.show = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-restore-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E9%A1%B5%E9%9D%A2%E8%BD%AE%E8%AF%A2" )))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"fab":"","small":"","elevation":"0","color":"rgba(255,255,255,0.8)"},on:{"click":function($event){return _vm.setFullscreen(false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-fullscreen-exit")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E9%80%80%E5%87%BA%E5%85%A8%E5%B1%8F" )))])])],1)]),_c('AlarmDetailDialog',{model:{value:(_vm.alarmDetailShow),callback:function ($$v) {_vm.alarmDetailShow=$$v},expression:"alarmDetailShow"}}),_c('UnConfirmAlarmDialog',{model:{value:(_vm.unConfirmAlarmShow),callback:function ($$v) {_vm.unConfirmAlarmShow=$$v},expression:"unConfirmAlarmShow"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.inspection.show),callback:function ($$v) {_vm.$set(_vm.inspection, "show", $$v)},expression:"inspection.show"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E9%A1%B5%E9%9D%A2%E8%BD%AE%E8%AF%A2" )))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.inspection.addShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E6%B7%BB%E5%8A%A0%E8%BD%AE%E8%AF%A2%E9%A1%B5%E9%9D%A2" )))])]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.inspection.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{staticStyle:{"height":"360px","overflow-y":"auto"},attrs:{"dense":"","headers":[
          {
            text: _vm.$vuetify.lang.t('$vuetify.key.K_%E5%90%8D%E7%A7%B0'),
            value: 'name',
          },
          {
            text: _vm.$vuetify.lang.t('$vuetify.key.K_%E6%93%8D%E4%BD%9C'),
            value: 'actions',
            align: 'right',
          } ],"items":_vm.inspection.pages,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.inspectionUp(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.inspectionDown(item)}}},[_c('v-icon',[_vm._v("mdi-arrow-down")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.inspectionRemove(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)}),_c('v-divider'),_c('v-card-actions',[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( "$vuetify.key.K_%E8%BD%AE%E8%AF%A2%E7%8A%B6%E6%80%81%EF%BC%9A" ))+_vm._s(_vm.inspection.enable ? _vm.$vuetify.lang.t("$vuetify.key.Started") : _vm.$vuetify.lang.t("$vuetify.key.Stopped"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.inspection.enable = !_vm.inspection.enable;
            _vm.inspection.show = false;}}},[_vm._v(" "+_vm._s(_vm.inspection.enable ? _vm.$vuetify.lang.t( "$vuetify.key.K_%E5%81%9C%E6%AD%A2%E8%BD%AE%E8%AF%A2" ) : _vm.$vuetify.lang.t( "$vuetify.key.K_%E5%90%AF%E5%8A%A8%E8%BD%AE%E8%AF%A2" ))+" ")])],1)],1)],1),_c('ObjectSelector',{attrs:{"type":"page","single":false},on:{"add":_vm.inspectionAdd},model:{value:(_vm.inspection.addShow),callback:function ($$v) {_vm.$set(_vm.inspection, "addShow", $$v)},expression:"inspection.addShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }