<template>
  <v-dialog :value="show" max-width="480" persistent>
    <v-card>
      <v-card-title>{{ $vuetify.lang.t("$vuetify.key.K_%E4%BF%AE%E6%94%B9%E5%AF%86%E7%A0%81") }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-text-field
          :label="$vuetify.lang.t('$vuetify.key.K_%E5%AF%86%E7%A0%81')"
          v-model="password"
          type="password"
        ></v-text-field>
        <v-text-field
          :label="$vuetify.lang.t('$vuetify.key.K_%E7%A1%AE%E8%AE%A4%E5%AF%86%E7%A0%81')"
          v-model="passwordRe"
          type="password"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="success" @click="submit">{{ $vuetify.lang.t("$vuetify.key.K_%E7%A1%AE%E5%AE%9A") }}</v-btn>
        <v-btn text @click="close">{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%96%E6%B6%88") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
export default {
  props: { show: Boolean, userId: String },
  model: {
    prop: "show",
    event: "change",
  },
  data() {
    return {
      password: "",
      passwordRe: "",
    };
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.password = "";
          this.passwordRe = "";
        }
      },
    },
  },
  methods: {
    async submit() {
      try {
        if (!this.userId) throw this.$vuetify.lang.t("$vuetify.key.K_%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF%E6%9C%89%E8%AF%AF");
        if (!this.password) throw this.$vuetify.lang.t("$vuetify.key.K_%E5%AF%86%E7%A0%81%E4%B8%8D%E8%83%BD%E4%B8%BA%E7%A9%BA");
        if (this.password != this.passwordRe) throw this.$vuetify.lang.t("$vuetify.key.K_%E5%AF%86%E7%A0%81%E4%B8%8D%E4%B8%80%E8%87%B4");
        client.$emit(
          "startBusy",
          "UserInfoDialog.submit",
          this.$vuetify.lang.t("$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E4%BF%AE%E6%94%B9%E5%AF%86%E7%A0%81%2C%E8%AF%B7%E7%A8%8D%E5%80%99")
        );
        await proto.sleep(100);
        let res = await client.send(proto.MESSAGE_TYPE.userMessage, {
          mcd: {
            operate: proto.OperateMode.retrieveOpt,
            range: this.userId,
          },
        });
        if (!res.users || !res.users.length) throw this.$vuetify.lang.t("$vuetify.key.K_%E6%9C%AA%E6%89%BE%E5%88%B0%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF");
        let user = res.users[0];
        user.password = this.password;
        await client.send(proto.MESSAGE_TYPE.userMessage, {
          mcd: {
            operate: proto.OperateMode.updateOpt,
          },
          users: [user],
        });
        this.close();
      } catch (e) {
        client.$emit("toast", e.toString());
      }
      client.$emit("endBusy", "UserInfoDialog.submit");
    },
    close() {
      this.$emit("change", false);
    },
  },
};
</script>
