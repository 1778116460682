<template>
  <div class="main">
    <v-navbar>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="parent.modelType != 'org'"
            icon
            v-bind="attrs"
            v-on="on"
            @click="backword"
          >
            <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>{{
          $vuetify.lang.t(
            "$vuetify.key.K_%E8%BF%94%E5%9B%9E%E4%B8%8A%E4%B8%80%E7%BA%A7"
          )
        }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="parent.modelType == 'org'"
            icon
            v-bind="attrs"
            v-on="on"
            @click="addAgentShow = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{
          $vuetify.lang.t(
            "$vuetify.key.K_%E6%B7%BB%E5%8A%A0%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA"
          )
        }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="parent.modelType == 'port'"
            icon
            v-bind="attrs"
            v-on="on"
            @click="addDeviceShow = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{
          $vuetify.lang.t("$vuetify.key.K_%E6%B7%BB%E5%8A%A0%E8%AE%BE%E5%A4%87")
        }}</span>
      </v-tooltip>
      <v-text-field
        v-model="filter"
        :label="$vuetify.lang.t('$vuetify.key.K_%E6%90%9C%E7%B4%A2')"
        hide-details
        dense
        outlined
        prepend-inner-icon="mdi-magnify"
        style="max-width: 150px"
      ></v-text-field>
    </v-navbar>
    <v-breadcrumbs dark class="pb-0 px-0" :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          class="white--text"
          v-text="item.name"
        ></v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <div class="scroll">
      <v-data-table
        :headers="headers"
        :items="matchItems"
        dark
        style="background-color: transparent"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.name`]="{ item }">
          <a
            v-if="item.modelType != 'spot'"
            style="color:#FFF"
            @click="view(item)"
          >
            {{ item.name }}
          </a>
          <span v-else>{{ item.name }}</span>
        </template>
        <template v-slot:[`item.modelType`]="{ item }">
          <span>{{
            item.modelType == "agent"
              ? $vuetify.lang.t(
                  "$vuetify.key.K_%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA"
                )
              : $vuetify.lang.t("$vuetify.key.K_%E8%AE%BE%E5%A4%87")
          }}</span>
        </template>
        <template v-slot:[`item.portType`]="{ item }">
          <span v-if="item.portType == 1">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E4%BB%A5%E5%A4%AA%E7%BD%91%E5%8F%A3"
            )
          }}</span>
          <span v-if="item.portType == 2">{{
            $vuetify.lang.t("$vuetify.key.K_%E4%B8%B2%E5%8F%A3")
          }}</span>
          <span v-if="item.portType == 3"
            >DI{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%A3") }}</span
          >
          <span v-if="item.portType == 4"
            >DO{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%A3") }}</span
          >
          <span v-if="item.portType == 5">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E5%86%85%E9%83%A8%E7%AB%AF%E5%8F%A3"
            )
          }}</span>
          <span v-if="item.portType == 6">{{
            $vuetify.lang.t(
              "$vuetify.key.K_%E9%80%9A%E7%94%A8%E7%AB%AF%E5%8F%A3"
            )
          }}</span>
          <span v-if="item.portType == 7"
            >AI{{ $vuetify.lang.t("$vuetify.key.K_%E5%8F%A3") }}</span
          >
        </template>
        <template v-slot:[`item.devices`]="{ item }">
          <span>{{ item.devices.length }}</span>
        </template>
        <template v-slot:[`item.sv`]="{ item }">
          <span v-if="item.sv.valueType == 1">{{
            $vuetify.lang.t("$vuetify.key.K_%E6%A8%A1%E6%8B%9F%E9%87%8F")
          }}</span>
          <span v-if="item.sv.valueType == 2">{{
            $vuetify.lang.t("$vuetify.key.K_%E5%AD%97%E7%AC%A6%E4%B8%B2")
          }}</span>
          <span v-if="item.sv.valueType == 3">{{
            $vuetify.lang.t("$vuetify.key.K_%E5%BC%80%E5%85%B3%E9%87%8F")
          }}</span>
          <span v-if="item.sv.valueType == 4">{{
            $vuetify.lang.t("$vuetify.key.K_%E6%95%B0%E6%8D%AE%E5%9D%97")
          }}</span>
          <span v-if="item.sv.valueType == 5">{{
            $vuetify.lang.t("$vuetify.key.K_%E7%8A%B6%E6%80%81%E9%87%8F")
          }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            text
            class="link-text"
            v-if="item.modelType == 'agent'"
            @click="syncToCore(item)"
          >
            <span>{{
              $vuetify.lang.t(
                "$vuetify.key.K_%E5%90%91%E4%B8%8A%E5%90%8C%E6%AD%A5"
              )
            }}</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            v-if="item.modelType == 'agent'"
            @click="syncToAgent(item)"
          >
            <span>{{
              $vuetify.lang.t(
                "$vuetify.key.K_%E5%90%91%E4%B8%8B%E5%90%8C%E6%AD%A5"
              )
            }}</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            v-if="item.modelType == 'agent' || item.modelType == 'device'"
            @click="
              moveModel = item;
              moveShow = true;
            "
          >
            <span>{{
              $vuetify.lang.t("$vuetify.key.K_%E8%BF%81%E5%87%BA")
            }}</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            v-if="item.modelType == 'agent' || item.modelType == 'device'"
            @click="remove(item)"
          >
            <span>{{
              $vuetify.lang.t("$vuetify.key.K_%E5%88%A0%E9%99%A4")
            }}</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            @click="
              current = item;
              editorShow = true;
            "
          >
            <span>{{
              $vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E8%BE%91")
            }}</span>
          </v-btn>
          <v-btn
            text
            class="link-text"
            v-if="item.modelType != 'spot'"
            @click="view(item)"
          >
            <span>{{
              $vuetify.lang.t("$vuetify.key.K_%E6%9F%A5%E7%9C%8B")
            }}</span>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-navigation-drawer
      v-model="editorShow"
      absolute
      temporary
      right
      width="240"
    >
      <div v-if="editModel" class="d-flex flex-column fill-height">
        <div class="flex-grow-0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ editModel.name }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                text
                class="link-text"
                @click="
                  cancel = true;
                  editorShow = false;
                "
              >
                {{
                  $vuetify.lang.t(
                    "$vuetify.key.K_%E6%94%BE%E5%BC%83%E4%BF%AE%E6%94%B9"
                  )
                }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
        <v-divider></v-divider>
        <div class="flex-grow-1 bg-editor" style="overflow: auto">
          <template v-for="attr in basicEditors">
            <component
              v-if="
                editModel[attr.key] !== undefined &&
                  showEditor(editModel, attr.key)
              "
              :is="attr.editor"
              :key="`model-${attr.key}`"
              :label="$vuetify.lang.t(attr.name)"
              :options="attr.options"
              :model="editModel"
              v-model="editModel[attr.key]"
            ></component>
          </template>
          <template v-for="attr in sapEditors">
            <component
              v-if="
                editModel.sap !== undefined &&
                  editModel.sap[attr.key] !== undefined &&
                  showEditor(editModel, `sap.${attr.key}`)
              "
              :is="attr.editor"
              :key="`sap-${attr.key}`"
              :label="$vuetify.lang.t(attr.name)"
              :options="attr.options"
              :model="editModel"
              v-model="editModel.sap[attr.key]"
            ></component>
          </template>
        </div>
      </div>
    </v-navigation-drawer>
    <AddAgentDialog v-model="addAgentShow" @submit="addAgent"></AddAgentDialog>
    <AddDeviceDialog
      v-model="addDeviceShow"
      @submit="addDevice"
    ></AddDeviceDialog>
    <OrgSelector
      v-model="moveShow"
      :label="
        $vuetify.lang.t(
          '$vuetify.key.K_%E8%AF%B7%E9%80%89%E6%8B%A9%E8%A6%81%E8%BF%81%E5%85%A5%E7%9A%84%E6%9C%BA%E6%9E%84'
        )
      "
      @select="changeOrg"
    ></OrgSelector>
    <v-confirm
      v-model="removeAgentShow"
      :label="
        $vuetify.lang.t(
          '$vuetify.key.K_%E6%98%AF%E5%90%A6%E5%88%A0%E9%99%A4%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA%3F'
        )
      "
      @ok="removeAgent"
    ></v-confirm>
    <v-confirm
      v-model="removeDeviceShow"
      :label="
        $vuetify.lang.t(
          '$vuetify.key.K_%E6%98%AF%E5%90%A6%E5%88%A0%E9%99%A4%E8%AE%BE%E5%A4%87%3F'
        )
      "
      @ok="removeDevice"
    ></v-confirm>
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
import uuid from "../utils/uuid";
import EnumText from "../components/EnumText.vue";
import PluginEditor from "../components/PluginEditor.vue";
import PortParamEditor from "../components/PortParamEditor.vue";
import NotifierEditor from "../components/NotifierEditor.vue";
import AlarmDescEditor from "../components/AlarmDescEditor.vue";
import StateAlarmEditor from "../components/StateAlarmEditor.vue";
import AddAgentDialog from "../components/AddAgentDialog.vue";
import AddDeviceDialog from "../components/AddDeviceDialog.vue";
import OrgSelector from "../components/OrgSelector.vue";

const basicEditors = [
  {
    key: "uuid",
    name: "ID",
    editor: "zht-editor-text",
    options: { readonly: true },
  },
  {
    key: "name",
    name: "$vuetify.key.K_%E5%90%8D%E7%A7%B0",
    editor: "zht-editor-text",
  },
  {
    key: "description",
    name: "$vuetify.key.K_%E6%8F%8F%E8%BF%B0",
    editor: "zht-editor-text",
  },
  {
    key: "commport",
    name: "$vuetify.key.K_%E6%8C%82%E8%BD%BD%E7%82%B9",
    editor: "zht-editor-object",
    options: { readonly: true },
  },
  {
    key: "no",
    name: "$vuetify.key.K_%E7%BC%96%E5%8F%B7",
    editor: "zht-editor-text",
    options: { readonly: true },
  },
  {
    key: "portType",
    name: "$vuetify.key.K_%E7%AB%AF%E5%8F%A3%E7%B1%BB%E5%9E%8B",
    editor: "enum-text",
    options: [
      {
        text: "$vuetify.key.K_%E4%BB%A5%E5%A4%AA%E7%BD%91%E5%8F%A3",
        value: 1,
      },
      {
        text: "$vuetify.key.K_%E4%B8%B2%E5%8F%A3",
        value: 2,
      },
      { text: "DI", value: 3 },
      { text: "DO", value: 4 },
      {
        text: "$vuetify.key.K_%E5%86%85%E9%83%A8%E7%AB%AF%E5%8F%A3",
        value: 5,
      },
      {
        text: "$vuetify.key.K_%E9%80%9A%E7%94%A8%E7%AB%AF%E5%8F%A3",
        value: 6,
      },
      { text: "AI", value: 7 },
    ],
  },
  {
    key: "port",
    name: "$vuetify.key.K_%E7%AB%AF%E5%8F%A3%E7%BC%96%E5%8F%B7",
    editor: "zht-editor-text",
    options: { readonly: true },
  },
  {
    key: "devoverride",
    name: "$vuetify.key.K_%E5%80%8D%E7%8E%87",
    editor: "zht-editor-text",
  },
  {
    key: "devAddr",
    name: "$vuetify.key.K_%E8%AE%BE%E5%A4%87%E5%9C%B0%E5%9D%80",
    editor: "zht-editor-text",
  },
  {
    key: "ipaddr",
    name: "$vuetify.key.K_IP%E5%9C%B0%E5%9D%80",
    editor: "zht-editor-text",
  },
  {
    key: "ipport",
    name: "$vuetify.key.K_IP%E7%AB%AF%E5%8F%A3",
    editor: "zht-editor-text",
  },
  {
    key: "useraccount",
    name: "$vuetify.key.K_%E5%B8%90%E6%88%B7",
    editor: "zht-editor-text",
  },
  {
    key: "password",
    name: "$vuetify.key.K_%E5%AF%86%E7%A0%81",
    editor: "zht-editor-password",
  },
  {
    key: "site",
    name: "$vuetify.key.K_%E5%AE%89%E8%A3%85%E4%BD%8D%E7%BD%AE",
    editor: "zht-editor-text",
  },
  {
    key: "period",
    name: "$vuetify.key.K_%E9%87%87%E9%9B%86%E5%91%A8%E6%9C%9F",
    editor: "zht-editor-text",
  },
  {
    key: "param",
    name: "$vuetify.key.K_%E7%AB%AF%E5%8F%A3%E5%8F%82%E6%95%B0",
    editor: "port-param-editor",
  },
  {
    key: "plugin",
    name: "$vuetify.key.K_%E6%8F%92%E4%BB%B6",
    editor: "plugin-editor",
  },
  {
    key: "spotOverride",
    name: "$vuetify.key.K_%E5%80%8D%E7%8E%87",
    editor: "zht-editor-text",
  },
  {
    key: "exceedValue",
    name: "$vuetify.key.K_%E6%BA%A2%E5%87%BA%E8%8C%83%E5%9B%B4",
    editor: "zht-editor-text",
  },
  {
    key: "edr",
    name:
      "$vuetify.key.K_%E5%BC%82%E5%B8%B8%E6%95%B0%E6%8D%AE%E5%A4%84%E7%90%86%E8%A7%84%E5%88%99",
    editor: "zht-editor-enum",
    options: [
      {
        text: "$vuetify.key.K_%E6%8A%9B%E5%BC%83",
        value: 1,
      },
      {
        text: "$vuetify.key.K_%E5%91%8A%E8%AD%A6",
        value: 2,
      },
    ],
  },
  {
    key: "other",
    name: "$vuetify.key.K_%E5%85%B6%E4%BB%96%E5%B1%9E%E6%80%A7",
    editor: "zht-editor-text",
  },
  {
    key: "persist",
    name: "$vuetify.key.K_%E6%95%B0%E6%8D%AE%E4%BF%9D%E5%AD%98",
    editor: "zht-editor-enum",
    options: [
      {
        text: "$vuetify.key.K_%E4%BF%9D%E5%AD%98",
        value: true,
      },
      {
        text: "$vuetify.key.K_%E4%B8%8D%E4%BF%9D%E5%AD%98",
        value: false,
      },
    ],
  },
  {
    key: "PartPp",
    name: "$vuetify.key.K_%E4%BF%9D%E5%AD%98%E7%AD%96%E7%95%A5",
    editor: "zht-editor-enum",
    options: [
      {
        text: "$vuetify.key.K_%E5%85%A8%E5%B1%80%E7%AD%96%E7%95%A5",
        value: 0,
      },
      {
        text: "$vuetify.key.K_%E5%85%A8%E9%83%A8%E4%BF%9D%E5%AD%98",
        value: 1,
      },
      {
        text: "$vuetify.key.K_%E5%91%A8%E6%9C%9F%E6%80%A7",
        value: 2,
      },
      {
        text: "$vuetify.key.K_%E5%8F%98%E5%8C%96%E7%8E%87",
        value: 3,
      },
      {
        text:
          "$vuetify.key.K_%E5%91%A8%E6%9C%9F%E6%80%A7%E4%B8%8E%E5%8F%98%E5%8C%96%E7%8E%87",
        value: 4,
      },
      {
        text:
          "$vuetify.key.K_%E5%91%A8%E6%9C%9F%E6%80%A7%E6%88%96%E5%8F%98%E5%8C%96%E7%8E%87",
        value: 5,
      },
    ],
  },
  {
    key: "PartTimePeriod",
    name:
      "$vuetify.key.K_%E6%AD%A3%E5%B8%B8%E4%BF%9D%E5%AD%98%E5%91%A8%E6%9C%9F(%E7%A7%92)",
    editor: "zht-editor-text",
  },
  {
    key: "PartAlarmPeriod",
    name:
      "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E4%BF%9D%E5%AD%98%E5%91%A8%E6%9C%9F(%E7%A7%92)",
    editor: "zht-editor-text",
  },
  {
    key: "PartVarianceRatio",
    name: "$vuetify.key.K_%E5%8F%98%E5%8C%96%E7%8E%87(%25)",
    editor: "zht-editor-text",
  },
  {
    key: "isDataExtern",
    name: "$vuetify.key.K_%E6%95%B0%E6%8D%AE%E5%85%B1%E4%BA%AB",
    editor: "zht-editor-enum",
    options: [
      {
        text: "$vuetify.key.K_%E5%85%B1%E4%BA%AB",
        value: true,
      },
      {
        text: "$vuetify.key.K_%E4%B8%8D%E5%85%B1%E4%BA%AB",
        value: false,
      },
    ],
  },
  {
    key: "notifier",
    name: "$vuetify.key.K_%E4%BD%9C%E4%B8%BA%E5%8F%91%E9%80%81%E5%99%A8",
    editor: "notifier-editor",
  },
  {
    key: "alarm",
    name: "$vuetify.key.K_%E6%98%AF%E5%90%A6%E5%91%8A%E8%AD%A6",
    editor: "zht-editor-enum",
    options: [
      {
        text: "$vuetify.key.K_%E5%91%8A%E8%AD%A6",
        value: true,
      },
      {
        text: "$vuetify.key.K_%E4%B8%8D%E5%91%8A%E8%AD%A6",
        value: false,
      },
    ],
  },
  {
    key: "statealarms",
    name:
      "$vuetify.key.K_%E7%8A%B6%E6%80%81%E5%91%8A%E8%AD%A6%E9%85%8D%E7%BD%AE",
    editor: "state-alarm-editor",
  },
];

const sapEditors = [
  {
    key: "normalState",
    name: "$vuetify.key.K_%E5%B8%B8%E6%80%81%E5%80%BC",
    editor: "zht-editor-enum",
    options: [
      { text: "true", value: true },
      { text: "false", value: false },
    ],
  },
  {
    key: "limitway",
    name: "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E9%99%90%E7%BA%A7",
    editor: "zht-editor-enum",
    options: [
      {
        text: "$vuetify.key.K_%E9%BB%98%E8%AE%A4",
        value: 0,
      },
      {
        text: "$vuetify.key.K_%E4%B8%80%E9%99%90%E7%BA%A7",
        value: 1,
      },
      {
        text: "$vuetify.key.K_%E4%BA%8C%E9%99%90%E7%BA%A7",
        value: 2,
      },
      {
        text: "$vuetify.key.K_%E4%B8%89%E9%99%90%E7%BA%A7",
        value: 3,
      },
    ],
  },
  {
    key: "ultraHilimit",
    name: "$vuetify.key.K_%E6%9E%81%E4%B8%8A%E9%99%90",
    editor: "zht-editor-text",
  },
  {
    key: "upperlimit",
    name: "$vuetify.key.K_%E4%B8%8A%E9%99%90",
    editor: "zht-editor-text",
  },
  {
    key: "nearupperlimit",
    name: "$vuetify.key.K_%E4%B8%B4%E4%B8%8A%E9%99%90",
    editor: "zht-editor-text",
  },
  {
    key: "nearlowerlimit",
    name: "$vuetify.key.K_%E4%B8%B4%E4%B8%8B%E9%99%90",
    editor: "zht-editor-text",
  },
  {
    key: "lowerlimit",
    name: "$vuetify.key.K_%E4%B8%8B%E9%99%90",
    editor: "zht-editor-text",
  },
  {
    key: "ultraLowlimit",
    name: "$vuetify.key.K_%E6%9E%81%E4%B8%8B%E9%99%90",
    editor: "zht-editor-text",
  },
  {
    key: "alarmLevel",
    name: "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%BA%A7%E5%88%AB",
    editor: "zht-editor-text",
  },
  {
    key: "ultraLevel",
    name:
      "$vuetify.key.K_%E6%9E%81%E4%B8%8A%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6%E7%BA%A7%E5%88%AB",
    editor: "zht-editor-text",
  },
  {
    key: "ulAlarmLevel",
    name: "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E7%BA%A7%E5%88%AB",
    editor: "zht-editor-text",
  },
  {
    key: "nulAlarmLevel",
    name:
      "$vuetify.key.K_%E4%B8%B4%E4%B8%8A%E4%B8%8B%E9%99%90%E5%91%8A%E8%AD%A6%E7%BA%A7%E5%88%AB",
    editor: "zht-editor-text",
  },
  {
    key: "alarmdesc",
    name: "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E6%8F%8F%E8%BF%B0",
    editor: "alarm-desc-editor",
  },
  {
    key: "alarmdelayway",
    name: "$vuetify.key.K_%E6%BB%9E%E5%9B%9E%E6%96%B9%E5%BC%8F",
    editor: "zht-editor-enum",
    options: [
      {
        text: "$vuetify.key.K_%E9%BB%98%E8%AE%A4",
        value: 0,
      },
      {
        text: "$vuetify.key.K_%E7%AA%81%E5%8F%98%E8%BF%87%E6%BB%A4",
        value: 1,
      },
      {
        text: "$vuetify.key.K_%E7%A6%81%E6%AD%A2%E9%87%8D%E5%A4%8D",
        value: 2,
      },
    ],
  },
  {
    key: "hysteresis",
    name: "$vuetify.key.K_%E6%BB%9E%E5%9B%9E%E6%97%B6%E9%97%B4",
    editor: "zht-editor-text",
  },
  {
    key: "valueHys",
    name: "$vuetify.key.K_%E6%BB%9E%E5%9B%9E%E5%80%BC",
    editor: "zht-editor-text",
  },
  {
    key: "periodsid",
    name: "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E6%97%B6%E6%AE%B5",
    editor: "zht-editor-object",
    options: {
      type: "period",
    },
  },
  {
    key: "notifier",
    name: "$vuetify.key.K_%E5%91%8A%E8%AD%A6%E5%8F%91%E9%80%81%E5%99%A8",
    editor: "zht-editor-object",
    options: {
      type: "notifier",
    },
  },
  {
    key: "receiver",
    name: "$vuetify.key.K_%E6%8E%A5%E8%AD%A6%E7%BB%84",
    editor: "zht-editor-object",
    options: {
      type: "receiver",
    },
  },
];

export default {
  components: {
    EnumText,
    PluginEditor,
    PortParamEditor,
    NotifierEditor,
    AlarmDescEditor,
    StateAlarmEditor,
    AddAgentDialog,
    AddDeviceDialog,
    OrgSelector,
  },
  data() {
    return {
      filter: "",
      items: [],
      current: null,
      parent: { uuid: "", modelType: "org" },
      breadcrumbs: [],
      basicEditors,
      sapEditors,
      editorShow: false,
      editModel: null,
      cancel: false,
      addAgentShow: false,
      removeAgentShow: false,
      removeAgentModel: null,
      addDeviceShow: false,
      removeDeviceShow: false,
      removeDeviceModel: null,
      moveShow: false,
      moveModel: {},
    };
  },
  computed: {
    matchItems() {
      if (this.filter) {
        return this.items.filter(this.matches);
      } else {
        return this.items;
      }
    },
    headers() {
      let result = [];
      let type = this.parent.modelType;
      result.push({
        text: this.$vuetify.lang.t("$vuetify.key.K_%E5%90%8D%E7%A7%B0"),
        value: "name",
      });
      switch (type) {
        case "org":
          result.push({
            text: this.$vuetify.lang.t("$vuetify.key.K_%E7%B1%BB%E5%9E%8B"),
            value: "modelType",
          });
          result.push({
            text: this.$vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E5%8F%B7"),
            value: "no",
          });
          break;
        case "agent":
          result.push({
            text: this.$vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E5%8F%B7"),
            value: "portno",
          });
          result.push({
            text: this.$vuetify.lang.t("$vuetify.key.K_%E7%B1%BB%E5%9E%8B"),
            value: "portType",
          });
          result.push({
            text: this.$vuetify.lang.t(
              "$vuetify.key.K_%E8%AE%BE%E5%A4%87%E6%95%B0"
            ),
            value: "devices",
            sortable: false,
          });
          break;
        case "port":
          result.push({
            text: this.$vuetify.lang.t(
              "$vuetify.key.K_%E8%AE%BE%E5%A4%87%E5%9C%B0%E5%9D%80"
            ),
            value: "devAddr",
          });
          result.push({
            text: this.$vuetify.lang.t("$vuetify.key.K_IP%E5%9C%B0%E5%9D%80"),
            value: "ipaddr",
          });
          result.push({
            text: this.$vuetify.lang.t("$vuetify.key.K_IP%E7%AB%AF%E5%8F%A3"),
            value: "ipport",
          });
          break;
        case "device":
          result.push({
            text: this.$vuetify.lang.t("$vuetify.key.K_%E7%BC%96%E5%8F%B7"),
            value: "no",
          });
          result.push({
            text: this.$vuetify.lang.t("$vuetify.key.K_%E7%B1%BB%E5%9E%8B"),
            value: "sv",
            sortable: false,
          });
          break;
      }
      result.push({
        text: this.$vuetify.lang.t("$vuetify.key.K_%E6%93%8D%E4%BD%9C"),
        value: "actions",
        sortable: false,
        align: "end",
      });
      return result;
    },
  },
  watch: {
    editorShow: {
      handler(val) {
        if (val) {
          this.editModel = JSON.parse(JSON.stringify(this.current));
          this.cancel = false;
        } else if (!this.cancel) {
          let newVal = JSON.stringify(this.editModel);
          let oldVal = JSON.stringify(this.current);
          if (newVal != oldVal) {
            this.save();
          }
        }
      },
    },
  },
  mounted() {
    client.$on("orgChange", this.orgChange);
    client.$on("brocast", this.brocast);
    this.orgChange();
  },
  beforeDestroy() {
    client.$off("orgChange", this.orgChange);
    client.$off("brocast", this.brocast);
  },
  methods: {
    showEditor(current, attr) {
      switch (attr) {
        //three limit
        case "sap.ultraHilimit":
        case "sap.ultraLowlimit":
        case "sap.ultraLevel":
          return (
            current.modelType == "spot" &&
            current.sv.valueType == 1 &&
            current.sap.limitway >= 3
          );
        case "sap.nearupperlimit":
        case "sap.nearlowerlimit":
        case "sap.nulAlarmLevel":
          return (
            current.modelType == "spot" &&
            current.sv.valueType == 1 &&
            current.sap.limitway >= 2
          );
        //Data Save
        case "PartPp":
          return current.persist;
        case "PartTimePeriod":
        case "PartAlarmPeriod":
          return (
            current.persist &&
            (current.PartPp == 2 || current.PartPp == 4 || current.PartPp == 5)
          );
        case "PartVarianceRatio":
          return (
            current.persist &&
            (current.PartPp == 3 || current.PartPp == 4 || current.PartPp == 5)
          );
        //Number
        case "sap.limitway":
        case "sap.upperlimit":
        case "sap.lowerlimit":
        case "sap.valueHys":
        case "spotOverride":
        case "exceedValue":
        case "edr":
          return current.modelType == "spot" && current.sv.valueType == 1;
        //Boolean
        case "sap.normalState":
          return current.modelType == "spot" && current.sv.valueType == 3;
        //State
        case "statealarms":
          return current.modelType == "spot" && current.sv.valueType == 5;
        //Alarm Notifier
        case "alarm":
        case "sap.periodsid":
        case "sap.alarmdelayway":
        case "sap.notifier":
        case "sap.receiver":
          return !(
            current.modelType == "spot" &&
            (current.sv.valueType == 2 || current.sv.valueType == 4)
          );
        //Alarm Level
        case "sap.alarmLevel":
          return current.modelType == "agent" || current.modelType == "device";
        case "sap.ulAlarmLevel":
          return (
            current.modelType == "spot" &&
            (current.sv.valueType == 1 ||
              current.sv.valueType == 3 ||
              current.sv.valueType == 5)
          );
        //Port Param
        case "param":
          return (
            current.modelType == "port" &&
            (current.portType == 1 || current.portType == 2)
          );
        //Alamr Description
        case "sap.alarmdesc":
          return (
            current.modelType == "agent" ||
            current.modelType == "device" ||
            (current.modelType == "spot" &&
              (current.sv.valueType == 1 || current.sv.valueType == 3))
          );
        //Alamr Description
        case "sap.hysteresis":
          return current.sap && current.sap.alarmdelayway != 0;
      }
      return true;
    },
    matches(item) {
      return item.name.indexOf(this.filter) != -1;
    },
    icon(item) {
      switch (item.modelType) {
        case "agent":
          return "mdi-server";
        case "port":
          if (item.portType == 1) {
            return "mdi-access-point-network";
          } else {
            return "mdi-video-input-component";
          }
        case "device":
          return "mdi-devices";
        case "spot":
          return "mdi-alarm-light";
      }
      return "";
    },
    async orgChange() {
      this.breadcrumbs = [];
      await this.view({
        uuid: "org",
        name: "",
        modelType: "org",
      });
    },
    async view(item) {
      if (!item) return;
      this.items = [];
      this.filter = "";
      let exist;
      for (let i in this.breadcrumbs) {
        let breadcrumb = this.breadcrumbs[i];
        if (breadcrumb.uuid == item.uuid) {
          exist = breadcrumb;
          this.breadcrumbs = this.breadcrumbs.slice(0, parseInt(i) + 1);
          break;
        }
      }
      if (!exist) {
        exist = item;
        this.breadcrumbs.push(item);
      }
      this.parent = item;
      await this.getAgents(item);
      await this.getPorts(item);
      await this.getDevices(item);
      await this.getSpots(item);
    },
    async getAgents(item) {
      if (item.modelType != "org") return;
      client.$emit(
        "startBusy",
        "ConfigDevice.getAgents",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.agentMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
          },
        });
        if (res.agents && res.agents.length) {
          res.agents.sort((a, b) => {
            return a.no > b.no ? 1 : -1;
          });
          for (let i = 0; i < res.agents.length; i++) {
            let agent = res.agents[i];
            agent.modelType = "agent";
            this.items.push(agent);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.getAgents");
    },
    async getPorts(item) {
      if (item.modelType != "agent") return;
      client.$emit(
        "startBusy",
        "ConfigDevice.getPorts",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E7%AB%AF%E5%8F%A3%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.commPortMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: item.uuid,
          },
        });
        if (res.commPorts && res.commPorts.length) {
          res.commPorts.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.commPorts.length; i++) {
            let port = res.commPorts[i];
            port.modelType = "port";
            this.items.push(port);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.getPorts");
    },
    async getDevices(item) {
      if (item.modelType != "org" && item.modelType != "port") return;
      client.$emit(
        "startBusy",
        "ConfigDevice.getDevices",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E8%AE%BE%E5%A4%87%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.deviceMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: item.modelType == "org" ? "" : item.uuid,
          },
          act: item.modelType == "org" ? 2 : 0,
        });
        if (res.devices && res.devices.length) {
          res.devices.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          for (let i = 0; i < res.devices.length; i++) {
            let device = res.devices[i];
            device.modelType = "device";
            this.items.push(device);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.getDevices");
    },
    async getSpots(item) {
      if (item.modelType != "device") return;
      client.$emit(
        "startBusy",
        "ConfigDevice.getSpots",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%8E%B7%E5%8F%96%E6%B5%8B%E7%82%B9%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        let res = await client.send(proto.MESSAGE_TYPE.spotMessage, {
          mcd: {
            operate: proto.OperateMode.queryOpt,
            range: "0",
            parentId: item.uuid,
          },
          act: 0,
        });
        if (res.spots && res.spots.length) {
          res.spots.sort((a, b) => {
            return a.no > b.no ? 1 : -1;
          });
          for (let i = 0; i < res.spots.length; i++) {
            let spot = res.spots[i];
            spot.modelType = "spot";
            this.items.push(spot);
          }
        }
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.getSpots");
    },
    async save() {
      client.$emit(
        "startBusy",
        "ConfigDevice.save",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E4%BF%9D%E5%AD%98%E9%85%8D%E7%BD%AE%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        let type;
        let msg = {
          mcd: {
            operate: proto.OperateMode.modifyOpt,
          },
        };
        switch (this.editModel.modelType) {
          case "agent":
            type = proto.MESSAGE_TYPE.agentMessage;
            msg.agents = [this.editModel];
            break;
          case "port":
            type = proto.MESSAGE_TYPE.commPortMessage;
            msg.commPorts = [this.editModel];
            break;
          case "device":
            type = proto.MESSAGE_TYPE.deviceMessage;
            msg.devices = [this.editModel];
            msg.act = 0;
            break;
          case "spot":
            type = proto.MESSAGE_TYPE.spotMessage;
            msg.spots = [this.editModel];
            msg.act = 0;
            break;
          default:
            throw this.$vuetify.lang.t(
              "$vuetify.key.K_%E5%AF%B9%E8%B1%A1%E6%A8%A1%E5%9E%8B%E4%B8%8D%E6%AD%A3%E7%A1%AE"
            );
        }
        await client.send(type, msg);
        this.view(this.parent);
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.save");
    },
    backword() {
      let len = this.breadcrumbs.length;
      if (len > 1) {
        this.view(this.breadcrumbs[len - 2]);
      }
    },
    brocast(type, msg) {
      if (type == proto.MESSAGE_TYPE.progressMessage) {
        let eventName =
          msg.currentStep < msg.totalSteps ? "startBusy" : "endBusy";
        client.$emit(eventName, msg.uuid, msg.description);
        if (msg.currentStep == msg.totalSteps) {
          client.$emit(
            "toast",
            msg.description,
            msg.optResult ? "success" : "error"
          );
        }
      }
    },
    async syncToAgent(agent) {
      client.$emit(
        "startBusy",
        "ConfigDevice.syncToAgent",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E4%BF%9D%E5%AD%98%E9%85%8D%E7%BD%AE%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.agentMessage, {
          mcd: {
            operate: proto.OperateMode.other,
            range: agent.uuid,
          },
          act: 1,
        });
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.syncToAgent");
    },
    async syncToCore(agent) {
      client.$emit(
        "startBusy",
        "ConfigDevice.syncToCore",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E4%BF%9D%E5%AD%98%E9%85%8D%E7%BD%AE%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        await client.send(proto.MESSAGE_TYPE.agentMessage, {
          mcd: {
            operate: proto.OperateMode.other,
            range: agent.uuid,
          },
          act: 2,
        });
        this.view(this.parent);
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.syncToCore");
    },
    remove(item) {
      if (item.modelType == "agent") {
        this.removeAgentModel = item;
        this.removeAgentShow = true;
      }
      if (item.modelType == "device") {
        this.removeDeviceModel = item;
        this.removeDeviceShow = true;
      }
    },
    async addAgent(agent) {
      client.$emit(
        "startBusy",
        "ConfigDevice.addAgent",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E6%B7%BB%E5%8A%A0%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        agent.aeid = agent.uuid;
        agent.uuid = uuid();
        agent.orgid = client.org.id;
        await client.send(proto.MESSAGE_TYPE.agentMessage, {
          mcd: {
            operate: proto.OperateMode.addOpt,
            parentId: client.project.id,
          },
          agents: [agent],
        });
        this.view(this.parent);
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.addAgent");
    },
    async removeAgent() {
      if (this.removeAgentModel) {
        client.$emit(
          "startBusy",
          "ConfigDevice.removeAgent",
          this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E7%9B%91%E6%8E%A7%E4%B8%BB%E6%9C%BA%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
          )
        );
        await proto.sleep(100);
        try {
          await client.send(proto.MESSAGE_TYPE.agentMessage, {
            mcd: {
              operate: proto.OperateMode.removeOpt,
              range: "-1",
            },
            agents: [this.removeAgentModel],
          });
          this.view(this.parent);
        } catch (error) {
          client.$emit("toast", error);
        }
        client.$emit("endBusy", "ConfigDevice.removeAgent");
      }
    },
    async addDevice(device) {
      client.$emit(
        "startBusy",
        "ConfigDevice.addDevice",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E6%B7%BB%E5%8A%A0%E8%AE%BE%E5%A4%87%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        device.deid = device.uuid;
        device.uuid = uuid();
        device.commport = { id: this.parent.uuid };
        device.organization = { id: client.org.id };
        await client.send(proto.MESSAGE_TYPE.deviceMessage, {
          mcd: {
            operate: proto.OperateMode.addOpt,
            parentId: this.parent.uuid,
          },
          act: 0,
          devices: [device],
        });
        this.view(this.parent);
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.addDevice");
    },
    async removeDevice() {
      if (this.removeDeviceModel) {
        client.$emit(
          "startBusy",
          "ConfigDevice.removeDevice",
          this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E5%88%A0%E9%99%A4%E8%AE%BE%E5%A4%87%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
          )
        );
        await proto.sleep(100);
        try {
          await client.send(proto.MESSAGE_TYPE.deviceMessage, {
            mcd: {
              operate: proto.OperateMode.removeOpt,
              range: "-1",
            },
            act: 0,
            devices: [this.removeDeviceModel],
          });
          this.view(this.parent);
        } catch (error) {
          client.$emit("toast", error);
        }
        client.$emit("endBusy", "ConfigDevice.removeDevice");
      }
    },
    async changeOrg(org) {
      client.$emit(
        "startBusy",
        "ConfigDevice.changeOrg",
        this.$vuetify.lang.t(
          "$vuetify.key.K_%E6%AD%A3%E5%9C%A8%E8%BF%81%E7%A7%BB%E5%AF%B9%E8%B1%A1%2C%E8%AF%B7%E7%A8%8D%E5%80%99"
        )
      );
      await proto.sleep(100);
      try {
        if (!org || !org.id)
          throw this.$vuetify.lang.t(
            "$vuetify.key.K_%E6%9C%BA%E6%9E%84%E4%BF%A1%E6%81%AF%E6%9C%89%E8%AF%AF"
          );
        if (!this.moveModel)
          throw this.$vuetify.lang.t(
            "$vuetify.key.K_%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF%E7%BC%BA%E5%A4%B1"
          );
        let member = {
          msgType:
            this.moveModel.modelType == "agent"
              ? proto.MESSAGE_TYPE.agentMessage
              : proto.MESSAGE_TYPE.deviceMessage,
          id: this.moveModel.uuid,
          targetId: org.id,
        };
        await client.send(proto.MESSAGE_TYPE.orgMemberMessage, {
          mcd: {
            operate: proto.OperateMode.updateOpt,
          },
          members: [member],
        });
        this.view(this.parent);
      } catch (error) {
        client.$emit("toast", error);
      }
      client.$emit("endBusy", "ConfigDevice.changeOrg");
    },
  },
};
</script>
